<form #journalVoucherForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-9 form-breadcrumb">
            <h3><a [routerLink]="['/journal_voucher']" routerLinkActive="router-link-active">Journal Voucher</a> > {{journal_voucher.voucher_number}}</h3>
        </div>
        <div class="col-3 form-operation" *ngIf="!['submitted','canceled'].includes(journal_voucher.status)">

            <button *ngIf="isFormDisabled && journal_voucher.status == 'draft'" class="m-2" type="button" mat-raised-button color="primary" (click)="confirmDialog(journal_voucher.id, 'submit')">Submit</button>
            <button *ngIf="isFormDisabled && journal_voucher.status == 'draft'" class="m-2" type="button" mat-raised-button color="link" (click)="confirmDialog(journal_voucher.id,'cancel')">Cancel</button>
            <button *ngIf="!isFormDisabled" [disabled]="onSaveDisable" class="m-2" type="submit" mat-raised-button color="primary" (click)='save(journalVoucherForm.valid)'>Save</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="!isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>
    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
        <div class="row">
            <label for="name" class="col-sm-2 col-form-label">Voucher No. <span class="span-class">
                *</span></label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input #voucher_number="ngModel" [disabled]="true" type="text" matInput name="voucher_number"
                        [(ngModel)]="journal_voucher.voucher_number" required>
                </mat-form-field>
                <div class="validation-class" *ngIf="voucher_number.touched && voucher_number.invalid">Required
                </div>
            </div>
            <label for="voucher_date" class="col-sm-2 col-form-label">Voucher Date <span class="span-class">
                *</span></label>
            <div class="col-sm-4 dateRangePickStyle">
                <mat-form-field class="example-full-width" appearance="fill">
                    <input #date="ngModel" matInput [(ngModel)]="journal_voucher.voucher_date" name="voucher_date"
                        [matDatepicker]="voucher_date" [min]="minDate" [max]="maxDate" required>
                    <mat-datepicker-toggle matSuffix [for]="voucher_date">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #voucher_date></mat-datepicker>
                </mat-form-field>
                <div class="validation-class" *ngIf="date.touched && date.invalid">Required</div>
            </div>
        </div>
        <div class="row">
            <label for="company_id" class="col-sm-2 col-form-label">Company<span class="span-class">
                *</span></label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <mat-select #company_id (openedChange)="OnCompanyScroll($event)"
                        [disabled]="isFormDisabled || disableCompanySelection" [(value)]="selectedCompany" (selectionChange)="onCompanyChange()" >
                        <input class="select-input" placeholder="Search..." type="text" autocomplete="nope"
                            (keydown)="$event.stopPropagation()" (keyup)="OnCompanyKey($event)" matInput
                            name="company_list">
                        <mat-option *ngFor="let company of allCompanies"
                            [value]="company.id">{{company.company_name}}</mat-option>
                    </mat-select>   
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <label for="credit_account_type" class="col-sm-2 col-form-label">Credit Account Type<span class="span-class"> *</span></label>
            <div class="col-sm-4">
                <div class="d-flex justify-content-start">
                    <mat-radio-group name="credit_account_type" [(ngModel)]="selectedCreditType" required>
                        <mat-radio-button value="internal">Intenal<h5>Internal&nbsp;&nbsp;&nbsp;</h5></mat-radio-button>
                        <mat-radio-button value="doctor">Doctor<h5>Doctor&nbsp;&nbsp;&nbsp;</h5></mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <ng-container *ngIf="selectedCreditType == 'doctor'">
                <label for="doctor_name" class="col-sm-2 col-form-label">Doctor Account<span class="span-class">
                    *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select #doctor_id (openedChange)="onDoctorScroll($event)"
                            [disabled]="isFormDisabled || disableDoctorSelection" [(value)]="selectedCreditDoctor" >
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope"
                                (keydown)="$event.stopPropagation()" (keyup)="onDoctorKey($event)" matInput
                                name="doctort_list">
                            <mat-option *ngFor="let doctor of allDoctors"
                                [value]="doctor.id">{{doctor.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                </ng-container>
    
                <ng-container *ngIf="selectedCreditType == 'internal'">
                    <label for="account_id" class="col-sm-2 col-form-label"> Internal Account<span class="span-class">
                        *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select #account_id (openedChange)="onAccountScroll($event)"
                            [disabled]="isFormDisabled || disableAccountSelection" [(value)]="selectedCreditAccount" >
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope"
                                (keydown)="$event.stopPropagation()" (keyup)="onAccountKey($event)" matInput
                                name="account_list">
                            <mat-option *ngFor="let account of allAccounts"
                                [value]="account.id">{{account.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                    </ng-container>
        </div>
        <div class="row">
            <label for="debit_account_type" class="col-sm-2 col-form-label">Debit Account Type<span class="span-class"> *</span></label>
            <div class="col-sm-4">
                <div class="d-flex justify-content-start">
                    <mat-radio-group name="debit_account_type" [(ngModel)]="selectedDebitType" required>
                        <mat-radio-button value="internal">Intenal<h5>Internal&nbsp;&nbsp;&nbsp;</h5></mat-radio-button>
                        <mat-radio-button value="doctor">Doctor<h5>Doctor&nbsp;&nbsp;&nbsp;</h5></mat-radio-button>
                    </mat-radio-group>
                </div>  
            </div>

            <ng-container *ngIf="selectedDebitType == 'doctor'">
            <label for="doctor_name" class="col-sm-2 col-form-label">Doctor Account<span class="span-class">
                *</span></label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <mat-select #doctor_id (openedChange)="onDoctorScroll($event)"
                        [disabled]="isFormDisabled || disableDoctorSelection" [(value)]="selectedDebitDoctor" >
                        <input class="select-input" placeholder="Search..." type="text" autocomplete="nope"
                            (keydown)="$event.stopPropagation()" (keyup)="onDoctorKey($event)" matInput
                            name="doctort_list">
                        <mat-option *ngFor="let doctor of allDoctors"
                            [value]="doctor.id">{{doctor.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            </ng-container>

            <ng-container *ngIf="selectedDebitType == 'internal'">
                <label for="account_id" class="col-sm-2 col-form-label"> Internal Account<span class="span-class">
                    *</span></label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <mat-select #account_id (openedChange)="onAccountScroll($event)"
                        [disabled]="isFormDisabled || disableAccountSelection" [(value)]="selectedDebitAccount" >
                        <input class="select-input" placeholder="Search..." type="text" autocomplete="nope"
                            (keydown)="$event.stopPropagation()" (keyup)="onAccountKey($event)" matInput
                            name="account_list">
                        <mat-option *ngFor="let account of allAccounts"
                            [value]="account.id">{{account.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
                </ng-container>
        </div>
        
        <div class="row">         
            <label for="name" class="col-sm-2 col-form-label">Amount <span class="span-class"> *</span></label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input #amount="ngModel" type="number" matInput name="amount" [(ngModel)]="journal_voucher.amount"  >
                </mat-form-field>
                <div class="validation-class" *ngIf="amount.touched && amount.invalid">Required</div>
            </div>
        </div>
        
        <div class="row">
            <label for="name" class="col-sm-2 col-form-label">Narration <span class="span-class"></span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <textarea #narration="ngModel" type="textarea-bg" matInput name="narration"
                            [(ngModel)]="journal_voucher.narration"></textarea>
                    </mat-form-field>
                </div>
            
           
                
              
        </div>
        </fieldset>
    </div>
</form>