import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { UserRole } from './userrole.interface';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { SnackbarService } from '../../snackbar.service';


@Component({
    selector: 'app-userrole.form',
    templateUrl: './userrole.form.component.html',
    styleUrls: ['./userrole.form.component.css']
})
export class UserRoleFormComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private api: CommonApiService,
        private router: Router,
        public dialog: MatDialog,
        private snackbar: SnackbarService,
    ) { }

    public model = 'userrole'
    public userrole = {} as UserRole;
    isFormDisabled = true;
    result: string;

    ngOnInit() {
        let roleId = this.route.snapshot.params.id
        if (roleId === 'create') {
            console.log('False')
            this.isFormDisabled = false;
        }
        else {
            this.getRoles(Number(roleId));
        }
    }

    getRoles(roleId: number) {
        this.api.get(this.model, roleId).subscribe(
            (data: any) => {
                if (data) {
                    this.userrole = data;
                }
            }
        );
    }

    save() {
        this.api.createUpdate(this.model, this.userrole).subscribe(
            (data: any) => {
                if (data) {
                    this.userrole = data;
                    this.isFormDisabled = true;
                    this.snackbar.success('Saved Successfully!');
                }
            },
            error => {
                alert(error.error.message)
                console.log(error)
            }
        );
    }
    edit(){
        this.isFormDisabled = false;
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
                if(this.userrole.id){
                    this.isFormDisabled = true;
                    this.getRoles(this.userrole.id);
                }else{
                    this.router.navigate([`/userrole`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('userrole/create');
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

}
