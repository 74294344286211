import { Process } from "../process/process.interface";
export interface Department {
    id: number;
    name: string;
    active: boolean;
    processes:Process[];
  };

export var displayColumns = [
  'name'
]

export var displayHeaders = [
  'Name'
]

// actual database columns
export var orderByColumns = [
  'name'
]

// for dropdown the options are set via ts file, so modify the code there
export const filterParameters = [
  { 
      name: 'created_at', 
      type: 'date_range', 
      operator: 'between', 
      label: 'Select Date Range' 
  },
  { 
      name: 'name', 
      type: 'string', 
      operator: 'like', 
      label: 'Department', 
  },
  { 
    name: 'order_by_columns', 
    type: 'multi_select', 
    operator: 'order_by_columns',
    values: displayHeaders.map((header, index) => ({
            label: header,
            value: orderByColumns[index]
        })),
    model_name:'department',
    label: 'Select columns for order by' 
  }
]; 