import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateStorageService {

    constructor() { }

    /**
     * Retrieves a date from localStorage and returns it as a Date object.
     * @param {string} key - The key under which the date string is stored in localStorage.
     * @returns {Date | null} - The Date object if the key exists and the date string is valid, otherwise null.
     */
    getDateFromLocalStorage(key: string): Date | null {

        const userDataString = localStorage.getItem('currentUser');
        const userData = userDataString ? JSON.parse(userDataString) : null;
        if (userData) {
            const dateValue = userData.data[key]; // Access the key directly instead of using getItem method
            const date = new Date(dateValue);
            if (!isNaN(date.getTime())) {
                return date;
            }
        }
        return null;
    }
}
   