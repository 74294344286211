<form #companyForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-9 form-breadcrumb">
            <h3><a [routerLink]="['/company']" routerLinkActive="router-link-active">Company</a> > {{company.company_name}} </h3>
        </div>
        <div class="col-3 form-operation">
            <button *ngIf="!isFormDisabled" [disabled]="onSaveDisable" class="m-2" type="submit" mat-raised-button color="primary" (click)='save(companyForm.valid)'>Save</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="primary" (click)='create()'>Create</button>
            <button *ngIf="!isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>

    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Company Name</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="company_name" [(ngModel)]="company.company_name">
                    </mat-form-field>
                </div>

                <label for="description" class="col-sm-2 col-form-label">Email</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="email_id" [(ngModel)]="company.email_id">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Gst No.</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="gst_number" [(ngModel)]="company.gst_number">
                    </mat-form-field>
                </div>

                <label for="name" class="col-sm-2 col-form-label">PAN</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="pan_number" [(ngModel)]="company.pan_number">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Bank Name and Branch</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="bank_name_and_branch" [(ngModel)]="company.bank_name_and_branch">
                    </mat-form-field>
                </div>

                <label for="name" class="col-sm-2 col-form-label">Bank Account No.</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="bank_acount_number" [(ngModel)]="company.bank_acount_number">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Ifsc code</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="ifsc_code" [(ngModel)]="company.ifsc_code">
                    </mat-form-field>
                </div>

                <label for="name" class="col-sm-2 col-form-label">Cheque in favour of</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="cheque_in_favour_of" [(ngModel)]="company.cheque_in_favour_of">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Contact No.</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="contact_number" [(ngModel)]="company.contact_number">
                    </mat-form-field>
                </div>

                <label for="name" class="col-sm-2 col-form-label">Gst(%) for Invoicing</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input maxlength="2" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" type="text" matInput name="gst_percentage" [(ngModel)]="company.gst_percentage">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label for="address_1" class="col-sm-2 col-form-label">Address Line 1</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="address_1" [(ngModel)]="company.address_1">
                    </mat-form-field>
                </div>

                <label for="address_2" class="col-sm-2 col-form-label">Address Line 2</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="address_2" [(ngModel)]="company.address_2">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <label for="city_list" class="col-sm-2 col-form-label">City <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [disabled]="isFormDisabled" [(value)]="selectedCity" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keyup)="onCityKey($event)" matInput name="city_list">
                            <mat-option *ngFor="let city of filteredCities" [value]="city.id">{{city.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <label for="state_list" class="col-sm-2 col-form-label">State <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [disabled]="isFormDisabled" [(value)]="selectedState" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keyup)="onStateKey($event)" matInput name="state_list">
                            <mat-option *ngFor="let state of filteredStates" [value]="state.id">{{state.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <label for="pincode" class="col-sm-2 col-form-label">Pincode</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="pincode" [(ngModel)]="company.pincode">
                    </mat-form-field>
                </div>
            </div>

        </fieldset>
    </div>
</form>