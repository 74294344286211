<form (ngSubmit)="submitFilter()" class="dialogContainer">
	<div class="pb-4 row">
		<div class="col-sm-6 divBackgroundColor" *ngFor="let field of dynamicFilterField">
			<div [ngSwitch]="field.type">
				<div class="row" *ngIf="field.type === 'date_range'">
					<!-- Date range form field -->
					<label for="description" class="col-form-label">Select Date Range</label>
					<div class="col-sm-12 dateRangePickStyle">
						<mat-form-field appearance="fill">
							<mat-label></mat-label>
							<mat-date-range-input [rangePicker]="picker">
								<input #dateRangeStart matStartDate placeholder="Start date">
								<input #dateRangeEnd matEndDate placeholder="End date"
									(dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
							</mat-date-range-input>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-date-range-picker #picker></mat-date-range-picker>
						</mat-form-field>
					</div>
				</div>

				<div class="row" *ngIf="field.type === 'string'">
					<!-- String form field -->
					<label for="{{ field.label }}" class="col-form-label">{{ field.label }}</label>
					<mat-form-field class="MatFormFieldControl">
						<input class="" type="text" matInput [(ngModel)]="field.value"
							name="{{ field.name }}">
					</mat-form-field>
				</div>

				<!-- Use openedChange event instead of scroll -->
				<div class="row" *ngIf="field.type === 'dropdown'">
					<mat-form-field class="col-sm-12">
					  <label for="{{ field.label }}" class="col-form-label">{{ field.label }}</label>
					  <!-- Use field.name as a dynamic part of the ID to ensure uniqueness -->
					  <mat-select #matSelectRef (openedChange)="onDropdownScroll(field, matSelectRef)"
								  [(ngModel)]="field.value" name="{{ field.name }}">
						<input class="select-input" placeholder="Search..." type="text" autocomplete="nope"
							   (keydown)="$event.stopPropagation()" (keyup)="onDropdownKey($event, field)" matInput name="{{ field.name }}" />
						<mat-option *ngFor="let option of field.options" [value]="option.value">{{ option.label }}</mat-option>
					  </mat-select>
					</mat-form-field>
				</div>

				<!-- Dynamic Option Buttons Section -->
				<div class="row" *ngIf="field.type === 'radio_option'">
					<div class="col-sm-12">
						<label for="{{ field.label }}" class="col-form-label pt-4">{{ field.label }}</label>
						<div class="d-flex justify-content-start">
							<mat-radio-group [(ngModel)]="field.value" [name]="field.name">
								<mat-radio-button *ngFor="let option of field.values" [value]="option.value">{{option.name}}<h5>{{ option.label }}&nbsp;&nbsp;&nbsp;</h5></mat-radio-button>
							</mat-radio-group>
						</div>
					</div>
				</div>
				
				<div class="row" *ngIf="field.type === 'multi_select'">
					
					<mat-form-field class="col-sm-12 pt-4">
						<label for="{{ field.label }}" class="col-form-label">{{ field.label }}</label>
						<mat-select [(ngModel)]="field.value" name="{{ field.name }}" multiple>
						<mat-option *ngFor="let option of field.values" [value]="option.value">{{ option.label }}</mat-option>
						</mat-select>
					</mat-form-field>

					<div class="col-sm-12">
						<label for="order_by" class="col-form-label pt-4">Order By</label>
						<div class="d-flex justify-content-start">
							<mat-radio-group [(ngModel)]="orderBy" [name]="orderBy">
								<mat-radio-button value="asc"><h5>Asc&nbsp;&nbsp;&nbsp;</h5></mat-radio-button>
								<mat-radio-button value="desc"><h5>Desc&nbsp;&nbsp;&nbsp;</h5></mat-radio-button>
							</mat-radio-group>
						</div>
					</div>
		
				</div>

				
				<!-- Add cases for other field types such as date, checkbox, etc. -->
			</div>
		</div>
	</div>
	<br>

	<div class="col-sm-12 pt-4">
		<button mat-raised-button color="primary" type="submit" mat-button>Search</button>
	</div>
</form>