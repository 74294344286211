import { Component, OnInit,ViewChild } from '@angular/core';
import { CommonApiService } from '../../../common-api.service';
import { SpeakerService } from '../../../speaker.service';
import { SnackbarService } from '../../../snackbar.service';
import { CommonDropdownService } from '../../../common-dropdown.service';
import { environment } from "../../../../../environments/environment"
import { MatSelect } from '@angular/material/select';
import { tap, filter, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-doctor-ledger',
  templateUrl: './doctor-ledger.component.html',
  styleUrls: ['./doctor-ledger.component.css']
})

export class DoctorLedgerComponent implements OnInit {

    constructor(
		public speaker: SpeakerService,
		private api: CommonApiService,
		private snackbar: SnackbarService,
        private dropdownService: CommonDropdownService
	) { }

    @ViewChild('doctor_id', { static: false }) doctor_id: MatSelect;


    public dateRangeStart: any;
	public dateRangeEnd: any;
	public startDate: any;
	public endDate: any;

    public allData:any[]=[]

    doctorSearchPage = 0;
    allDoctorsCount = 0
    public allDoctors: any[] = [];
    public selectedDoctor: number;
    public selectedDoctorObject: any[] = [];
    disableDoctorSelection: boolean;
    public pdf_url : string;

    displayColumns = [
		'transaction_type',
		'transaction',
		'date',
		'debit_amount',
		'credit_amount',
        'running_balance',
        'narration',
	];
	displayHeaders = [
		'Transaction Type',
		'Transaction',
		'Date',
		'Debit Amount',
		'Credit Amount',
        'Closing Balance',
        'Description/Narration',
	];
  

    ngOnInit(): void {
        this.onLoadDoctorList();
		this.speaker.listData = this.allData;
	
    }

    dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
		this.dateRangeStart =dateRangeStart.value;
		this.dateRangeEnd =dateRangeEnd.value;

	}

    getReportDetails(payload:any) {
		this.api.callRoute('doctor','/doctor_ledger', payload).subscribe(res => {
            this.allData = res.data;
			this.speaker.listData = this.allData;
			this.speaker.openingBalance = res.opening_balance;
			this.speaker.showOpeningBalance = true;
            this.pdf_url = 'api/doctor/account_ledger_pdf?doctor_id='+this.selectedDoctor+'&start_date='+this.startDate+'&end_date='+this.endDate;
			if(this.allData.length == 0){
				this.snackbar.info("No records found");
				return;
			}
        })
    }

    generateReport(){
		this.startDate =this.dateRangeStart;
		this.endDate =this.dateRangeEnd;
		
		if(this.startDate == undefined || this.startDate == ""){
			alert("Select date range.");
			return;
		}
		if(this.endDate == undefined || this.endDate == ""){
			alert("Select date range.");
			return;
		}
        if(this.selectedDoctor == undefined || this.selectedDoctor == 0){
            alert("Select doctor.");
            return;
        }

        var payload={
            doctor_id:this.selectedDoctor,
            start_date: this.startDate,
			end_date: this.endDate,
        }	

        this.speaker.listData = [];
		this.getReportDetails(payload);
        this.speaker.displayColumns = this.displayColumns;
        this.speaker.displayHeaders = this.displayHeaders;
	}


    onLoadDoctorList() {
        let params = {
            'pageIndex': this.doctorSearchPage,
            'pageSize': environment.defaultPageSizeOnScroll
        }

        this.api.postAll('doctor/list/display', params).pipe(
            tap((res: any) => {
                this.allDoctors.push(...res.items);
                this.allDoctorsCount = res.rowCount
            }),
            filter(() => this.selectedDoctorObject.length !== 0),
            tap(() => {
              const selectedDoctorId = this.selectedDoctorObject[0].id;
              this.allDoctors = this.allDoctors.filter(doctor => doctor.id !== selectedDoctorId);
              this.allDoctors.unshift(this.selectedDoctorObject[0]);
              
            }),
            finalize(() => this.doctorSearchPage++)
        ).subscribe();
    }

    onDoctorKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'doctor', this.allDoctors, this.selectedDoctorObject, 'doctorSearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allDoctors = result.entityList;
            this.doctorSearchPage = result.pageVariable;
        });
    }

    onDoctorScroll(event:any) {

        if (!this.doctor_id?.panel || !this.doctor_id?.panel.nativeElement) {
            // Panel or nativeElement is not available, return or handle the situation accordingly.
            return;
        }
        if (event) {
            this.doctor_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.doctor_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.doctor_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.doctor_id.panel.nativeElement.offsetHeight)
                    ){
                    let doctorparams = {
                        'pageIndex': this.doctorSearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }
                    
                    if (this.allDoctors.length >= this.allDoctorsCount) {
                        return;
                    }
                    this.api.postAll('doctor/list/display', doctorparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedDoctorObject[0]?.id);
                        this.allDoctors.push(...res.items);
                    });
                    this.doctorSearchPage++;// send page number for fetching the records
                }
            });
        }
    }

    ngOnDestroy() {
        this.speaker.showOpeningBalance = false;
    }

}
