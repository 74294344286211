<div class="title">
    <h3>Address</h3>
</div>
<form #addressForm="ngForm" autocomplete="off" (ngSubmit)="saveAddress(addressForm.valid)" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">

    <div class="base-form p-4">
        <div class="row">
            <label for="address_line_1" class="col-sm-2 col-form-label">Address Line 1 <span class="span-class"> *</span></label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input #addressLine1="ngModel" type="text" matInput name="address_line_1" [(ngModel)]="doctorAddress.address_line_1" required>
                </mat-form-field>
                <div class="validation-class" *ngIf="addressLine1.touched && addressLine1.invalid">Required</div>
            </div>

            <label for="address_line_2" class="col-sm-2 col-form-label">Address Line 2</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input type="text" matInput name="address_line_2" [(ngModel)]="doctorAddress.address_line_2">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <label for="state_list" class="col-sm-2 col-form-label">State</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <mat-select [(value)]="selectedState">
                        <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keyup)="onStateKey($event)" matInput name="state_list">
                        <mat-option *ngFor="let state of filteredStates" [value]="state.id">{{state.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <label for="area_list" class="col-sm-2 col-form-label">City</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <mat-select [(value)]="selectedArea">
                        <input class="select-input" type="text" autocomplete="nope" (keyup)="onAreaKey($event)" matInput placeholder="Search..." name="area_list">
                        <mat-option *ngFor="let area of filteredAreas" [value]="area.id">{{area.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
           
        </div>

        <div class="row">
            <!-- <label for="city" class="col-sm-2 col-form-label">City</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input type="text" matInput name="city" [(ngModel)]="doctorAddress.city">
                </mat-form-field>
            </div> -->

            <label for="pincode" class="col-sm-2 col-form-label">Pincode</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input type="text" matInput name="pincode" [(ngModel)]="doctorAddress.pincode">
                </mat-form-field>
            </div>
        </div>
    </div>
    <br><br>

    <ng-container>
        <button class="mx-3" type="button" (click)="close()" mat-raised-button color="link">Discard</button>
    </ng-container>

    <ng-container>
        <button type="submit" mat-raised-button color="primary">Add</button>
    </ng-container>

</form>