import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { User } from "./user.interface";
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class LoginService {
    
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(
        private http: HttpClient,
        private cookie: CookieService,
        private router: Router,
    ) {
        const storageData = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser') || '') : '';
        this.currentUserSubject = new BehaviorSubject<User>(storageData);
        this.currentUser = this.currentUserSubject.asObservable();
    }


    login(username: string, password: string) {
        let payload = {
            'username': username,
            'password': password,
        }
        this.http.post<any[]>(`${environment.api}auth/login`, payload).subscribe(
            (resp: any) => {
                if (resp.status != "success") {
                    alert(resp.message)
                }
                else {
                    this.cookie.set('session-key', resp.auth_token, 1);
                    this.user();
                    return true;
                }
                return false;
            },
            error => {
                console.log(error)
                return false;
            }
        );
    }


    user() {
        return this.http.get<any>(`${environment.api}auth/user`, { headers: this.getHeaders()})
        .subscribe(
            (user: any) => {
                if (user) {
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                    this.router.navigate(['/dashboard']);
                }
            },
            error => {
                alert("Invalid Username or Password");
                console.log(error)
            }
        );
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: this.cookie.get('session-key')
        });
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.cookie.deleteAll();
        const storageData = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser') || '') : '';
        this.currentUserSubject.next(storageData);
        this.router.navigate(['/login'])
    }

}

