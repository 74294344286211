<form class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-sm-9 form-breadcrumb">
            <h3>Production Job Out</h3>
        </div>
        <div class="col-sm-3 form-operation">
            <button type="submit" class="m-2" mat-raised-button color="primary" (click)='confirmDialog()'>Save</button>
            <button *ngIf="isNextProcess" type="submit" class="m-2" mat-raised-button color="primary" (click)='saveAndHoldJob()'>Save and Hold Job</button>
            <!-- <button type="button" class="mx-3" mat-raised-button color="link">Reset</button> -->
        </div>
    </div>

    <div class="base-form mat-elevation-z2 p-4">

        <div class="row">
            <label for="job_no" class="col-sm-2 col-form-label">Select / Scan Job No.</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <mat-select (selectionChange)="onJobChange($event)" [(value)]="selectedJob" name='job_no'>
                        <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keyup)="onJobKey($event)" matInput name="job_list">
                        <mat-option *ngFor="let joborder of filteredJobs" [value]="joborder">{{joborder.job_no}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row" *ngIf="viewData">
            <label for="process_name" class="col-sm-2 col-form-label">Current Process</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input type="text" disabled matInput name="current_process" [(ngModel)]="selectedJob.current_process.process.name">
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="viewData">
            <label for="product_name" class="col-sm-2 col-form-label">Product Name</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input type="text" disabled matInput name="product_name" [(ngModel)]="product_name">
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="viewData">

            <label for="doctor_name" class="col-sm-2 col-form-label">Doctor Name</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input type="text" disabled matInput name="doctor_name" [(ngModel)]="selectedJob.doctor.name">
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="viewData">
            <label for="patient_name" class="col-sm-2 col-form-label">Patient Name</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input type="text" disabled matInput name="patient_name" [(ngModel)]="selectedJob.patient_name">
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="viewData">
            <label for="rework" class="col-sm-2 col-form-label">Sent to rework</label>
            <div class="col-sm-4">
                <mat-slide-toggle class="pt-2" (change)="onChangeToggle(selectedJob.current_process.process.name,$event)">
                </mat-slide-toggle>
            </div>
        </div>
        <div *ngIf="viewReworkProcess" class="row">
            <label for="rework_process" class="col-sm-2 col-form-label">Select Rework Process.</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <mat-select [(value)]="selectedReworkProcess" name='rework_process'>
                        <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keyup)="onReworkProcessKey($event)" matInput name="job_list">
                        <mat-option *ngFor="let process of filteredReworkProcesses" [value]="process.process.id">{{process.process.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row" *ngIf="viewData  && selectedJob.id">
            <label for="document_attached" class="col-sm-2 col-form-label">Attached Document</label>
            <div style="padding-top: 15px;" class="col-sm-4">
                <a style="font-size: 12px;" target="_blank" href="/src/job_order_uploads/{{selectedJob.filename}}">{{selectedJob.filename}}</a>
            </div>
        </div>

        <div class="row" *ngIf="viewData">
            <label for="leave_reply" class="col-sm-2 col-form-label">Leave a comment</label>
        </div>
        <div class="row" *ngIf="viewData">
            <div class="col-6 commentDiv">
                <textarea id="comment_description" placeholder="Your Comment" rows="1" class="textarea-bg" [(ngModel)]="comment_description" matInput name="comment_description"></textarea>
                <br/>
                <hr/>
            </div>
        </div>

        <div class="row" *ngIf="viewData">
            <label for="leave_reply" class="col-form-label">Comments</label>
        </div>
        <div class="row" *ngIf="viewData">
            <div class="col-6">
                <ul class="commentUl">
                    <li *ngFor="let comments of allComments">
                        <p class="commentSpan col-sm-12"><b>{{comments.employee?.name}}</b> {{ comments.created_at | date :'medium'}}
                            <br><br>{{comments.comment_description}} </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</form>