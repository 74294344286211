import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { Doctor } from './doctor.interface';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DoctorAddressComponent } from './doctor-address.component';
import { MatTable } from '@angular/material/table';
import { DoctorAddress } from './doctor-address.interface';
import { DoctorPoc } from "./poc.interface";
import { PocComponent } from './poc.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SnackbarService } from '../../snackbar.service';
import { CommonDropdownService } from '../../common-dropdown.service';
import { environment } from "../../../../environments/environment"
import { MatSelect } from '@angular/material/select';
import { tap, filter, finalize } from 'rxjs/operators';


@Component({
    selector: 'doctor-form',
    templateUrl: './doctor.form.component.html',
    styleUrls: ['./doctor.form.component.css']
})
@Injectable({
    providedIn: 'root'
})
export class DoctorFormComponent implements OnInit {

    @ViewChild('addr_table') addr_table: MatTable<any>;
    @ViewChild('poc_table') poc_table: MatTable<any>;
    @ViewChild('city_id', { static: false }) city_id: MatSelect;// for dropdown
    @ViewChild('state_id', { static: false }) state_id: MatSelect;// for dropdown
    @ViewChild('pricelist_id', { static: false }) pricelist_id: MatSelect;// for dropdown

    constructor(    
        private route: ActivatedRoute,
        private api: CommonApiService,
        private dialog: MatDialog,
        private router: Router,
        private snackbar: SnackbarService,
        private dropdownService: CommonDropdownService
    ) { }

    public model = 'doctor'
    public doctor = {} as Doctor;
    addressDisplayedColumns: string[] = ['address_line_1', 'city', 'pincode', 'state'];
    addressDataSource:any = []
    pocDisplayedColumns: string[] = ['name', 'email', 'designation', 'mobile'];
    pocDataSource:any = [];

    isFormDisabled = true;
    result: string;
    onSaveDisable = false;

    citySearchPage = 0;
    allCitiesCount = 0
    public allCities: any[] = [];
    public selectedCityObject: any[] = [];
    public selectedCity: number;

    stateSearchPage = 0;
    allStatesCount = 0
    public allStates: any[] = [];
    public selectedStateObject: any[] = [];
    public selectedState: number;

    priceListSearchPage = 0;
    allPriceListCount = 0
    public allPriceLists: any[] = [];
    public selectedPriceListObject: any[] = [];
    public selectedPriceList: number;

    ngOnInit() {
        let doctorId = this.route.snapshot.params.id
        if (doctorId === 'create') {
            this.isFormDisabled = false;
        }
        else {
            this.api.refreshNeeded.subscribe(() => {
                this.getDoctor(Number(doctorId));
            });
            this.getDoctor(Number(doctorId));
        }
        this.onLoadCityList();
        this.onLoadStateList();
        this.onLoadPriceListList();
    }

     //api call to bring search values in dropdown and also the selected value in dropdown is displayed
     onCityKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'area', this.allCities, this.selectedCityObject, 'citySearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allCities = result.entityList;
            this.citySearchPage = result.pageVariable;
        });
    }

    // On scroll of dropdown api call to fetch next records
    onCityScroll(event:any) {
        if (event) {
            if (!this.city_id?.panel || !this.city_id?.panel.nativeElement) {
                // Panel or nativeElement is not available, return or handle the situation accordingly.
                return;
            }
            this.city_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.city_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.city_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.city_id.panel.nativeElement.offsetHeight)
                ){
                    let cityparams = {
                        'pageIndex': this.citySearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }

                    if (this.allCities.length >= this.allCitiesCount) {
                        return;
                    }
                    this.api.postAll('area/list/display', cityparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedCityObject[0]?.id);
                        this.allCities.push(...res.items);
                    });
                    this.citySearchPage++;// send page number for fetching the records 
                }
            });
        }
    }

    // On load of component default pageSize for dropdown to load and also set selected value if present
    onLoadCityList() {
        let params = {
            'pageIndex': this.citySearchPage,
            'pageSize': environment.defaultPageSizeOnScroll
        }

        this.api.postAll('area/list/display', params).pipe(
            tap((res: any) => {
              this.allCities.push(...res.items);
              this.allCitiesCount = res.rowCount
            }),
            filter(() => this.selectedCityObject.length !== 0),
            tap(() => {
              const selectedCityId = this.selectedCityObject[0].id;
              this.allCities = this.allCities.filter(city => city.id !== selectedCityId);
              this.allCities.unshift(this.selectedCityObject[0]);
              
            }),
            finalize(() => this.citySearchPage++)
        ).subscribe();
    }

    //api call to bring search values in dropdown and also the selected value in dropdown is displayed
    onStateKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'state', this.allStates, this.selectedStateObject, 'stateSearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allStates = result.entityList;
            this.stateSearchPage = result.pageVariable;
        });
    }

    // On scroll of dropdown api call to fetch next records
    onStateScroll(event:any) {
        if (event) {
            if (!this.state_id?.panel || !this.state_id?.panel.nativeElement) {
                // Panel or nativeElement is not available, return or handle the situation accordingly.
                return;
            }
            this.state_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.state_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.state_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.state_id.panel.nativeElement.offsetHeight)
                ){
                    let stateparams = {
                        'pageIndex': this.stateSearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }

                    if (this.allStates.length >= this.allStatesCount) {
                        return;
                    }
                    this.api.postAll('state/list/display', stateparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedStateObject[0]?.id);
                        this.allStates.push(...res.items);
                    });
                    this.stateSearchPage++;// send page number for fetching the records
                }
            });
        }
    }

    // On load of component default pageSize for dropdown to load and also set selected value if present
    onLoadStateList() {
        let params = {
            'pageIndex': this.stateSearchPage,
            'pageSize': environment.defaultPageSizeOnScroll
        }

        this.api.postAll('state/list/display', params).pipe(
            tap((res: any) => {
              this.allStates.push(...res.items);
              this.allStatesCount = res.rowCount
            }),
            filter(() => this.selectedStateObject.length !== 0),
            tap(() => {
              const selectedStateId = this.selectedStateObject[0].id;
              this.allStates = this.allStates.filter(state => state.id !== selectedStateId);
              this.allStates.unshift(this.selectedStateObject[0]);
              
            }),
            finalize(() => this.stateSearchPage++)
        ).subscribe();
    }

    //api call to bring search values in dropdown and also the selected value in dropdown is displayed
    onPriceListKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'pricelist', this.allPriceLists, this.selectedPriceListObject, 'priceListSearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allPriceLists = result.entityList;
            this.priceListSearchPage = result.pageVariable;
        });
    }

    // On scroll of dropdown api call to fetch next records
    onPriceListScroll(event:any) {
        if (event) {
            if (!this.pricelist_id?.panel || !this.pricelist_id?.panel.nativeElement) {
                // Panel or nativeElement is not available, return or handle the situation accordingly.
                return;
            }
            this.pricelist_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.pricelist_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.pricelist_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.pricelist_id.panel.nativeElement.offsetHeight)
                ){
                    let pricelistparams = {
                        'pageIndex': this.priceListSearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }

                    if (this.allPriceLists.length >= this.allPriceListCount) {
                        return;
                    }
                    this.api.postAll('pricelist/list/display', pricelistparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedPriceListObject[0]?.id);
                        this.allPriceLists.push(...res.items);
                    });
                    this.priceListSearchPage++;// send page number for fetching the records
                }
            });
        }
    }

    // On load of component default pageSize for dropdown to load and also set selected value if present
    onLoadPriceListList() {
        let params = {
            'pageIndex': this.priceListSearchPage,
            'pageSize': environment.defaultPageSizeOnScroll
        }

        this.api.postAll('pricelist/list/display', params).pipe(
            tap((res: any) => {
              this.allPriceLists.push(...res.items);
              this.allPriceListCount = res.rowCount
            }),
            filter(() => this.selectedPriceListObject.length !== 0),
            tap(() => {
              const selectedPriceListId = this.selectedPriceListObject[0].id;
              this.allPriceLists = this.allPriceLists.filter(pricelist => pricelist.id !== selectedPriceListId);
              this.allPriceLists.unshift(this.selectedPriceListObject[0]);
              
            }),
            finalize(() => this.priceListSearchPage++)
        ).subscribe();
    }

    getDoctor(doctorId: number) {
        this.api.get(this.model, doctorId).subscribe(
            (data: any) => {
                if (data) {
                    this.doctor = data;
                    this.addressDataSource = data.doctor_addresses ? data.doctor_addresses : []
                    this.pocDataSource = data.pocs ? data.pocs : []

                    if(data.city){
                        this.selectedCity = data.city.id;
                        this.allCities = this.allCities.filter(city => city.id !== data.city.id);
                        this.allCities.push(data.city) // insert specific selected into the dropdown list
                        this.selectedCityObject.push(data.city)
                    }
                    if(data.state){
                        this.selectedState = data.state.id;
                        this.allStates = this.allStates.filter(state => state.id !== data.state.id);
                        this.allStates.push(data.state) // insert specific selected into the dropdown list
                        this.selectedStateObject.push(data.state)
                    }
                    if(data.pricelist){
                        this.selectedPriceList = data.pricelist.id;
                        this.allPriceLists = this.allPriceLists.filter(pricelist => pricelist.id !== data.pricelist.id);
                        this.allPriceLists.push(data.pricelist) // insert specific selected into the dropdown list
                        this.selectedPriceListObject.push(data.pricelist)
                    }
                }
            }
        );
    }

    save(formValid:any) {
        if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
        if(this.selectedCity == undefined){
            alert("Please select city.");
            return;
        }
        if(this.selectedState == undefined){
            alert("Please select state.");
            return;
        }
        this.onSaveDisable = true;
        this.doctor.city_id = this.selectedCity;
        this.doctor.state_id = this.selectedState;
        this.doctor.pricelist_id = this.selectedPriceList;

        this.api.createUpdate(this.model, this.doctor).subscribe(
            (data: any) => {
                if (data) {
                    this.doctor = data;
                    this.isFormDisabled = true;
                    this.snackbar.success('Saved Successfully!');
                    this.router.navigate([`/${this.model}/${data.id}`])
                    this.onSaveDisable = false;
                }
            },
            error => {
                alert(error.error.message)
                this.onSaveDisable = false;
            }
        );
    }

    openDialog(activeAddress: any) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            uid: activeAddress.id,
            doctorId: this.doctor.id,
            address: activeAddress
        }
        const dialogRef = this.dialog.open(DoctorAddressComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.addDoctorAddress(result.doctorAddress, activeAddress);
            }
          });

    }
    openDialogPoc(activePoc: any) {
        const dialogConfigPoc = new MatDialogConfig();
        dialogConfigPoc.data = {
            uid: activePoc.id,
            doctorId: this.doctor.id,
            poc: activePoc
        }
        
        const dialogRefPoc = this.dialog.open(PocComponent, dialogConfigPoc);

        dialogRefPoc.afterClosed().subscribe(result => {
            if (result) {
                this.addDoctorPoc(result.doctorPoc, activePoc);
            }
        });
    }

    addDoctorPoc(poc: DoctorPoc, activePoc: any) {
        var replaced1 = false
        for (let i = 0; i < this.pocDataSource.length; i++) {
            if (this.pocDataSource[i] == activePoc) {
                this.pocDataSource[i] = poc
                replaced1 = true
                break
            }
        }
        if (!replaced1) {
            this.pocDataSource.push(poc);
        }
        this.doctor.doctor_pocs = this.pocDataSource
        this.poc_table.renderRows(); 
    }


    addDoctorAddress(address: DoctorAddress, activeAddress: any) {
        var replaced = false
        for (let i = 0; i < this.addressDataSource.length; i++) {
            if (this.addressDataSource[i] == activeAddress) {
                this.addressDataSource[i] = address
                replaced = true
                break
            }
        }
        if (!replaced) {
            this.addressDataSource.push(address);
        }
        this.doctor.doctor_addresses = this.addressDataSource
        this.addr_table.renderRows();
    }

    edit(){
        this.isFormDisabled = false;
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
                if(this.doctor.id){
                    this.isFormDisabled = true;
                    this.getDoctor(this.doctor.id);
                }else{
                    this.router.navigate([`/doctor`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('doctor/create');
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

}
