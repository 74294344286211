<div>
  <div>
    <div style="display: block">
      <canvas baseChart
        [data]="doughnutChartData"
        [labels]="doughnutChartLabels"
        [chartType]="doughnutChartType">
      </canvas>
    </div>
  </div>
</div>