import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { Department } from './department.interface';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { SnackbarService } from '../../snackbar.service';

@Component({
    selector: 'app-department-form',
    templateUrl: './department.form.component.html',
    styleUrls: ['./department.form.component.css']
})

export class DepartmentFormComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private api: CommonApiService,
        private router: Router,
        public dialog: MatDialog,
        private snackbar: SnackbarService,
    ) { }


    public todo: any[] = [];  
    public onLoadProcesses: any[] = [];  
    public done: any[] = [];
    isFormDisabled = true;
    result: string;
    onSaveDisable = false;
    
    drop(event: CdkDragDrop<any[]>) {
        if (event.previousContainer === event.container) {
          moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
          transferArrayItem(event.previousContainer.data,
                            event.container.data,
                            event.previousIndex,
                            event.currentIndex);
        }
    }

    getMappedProcess(){
        this.done.forEach((value, index) => {
            var a = index + 1;
            this.done[index]['sequence'] = a;
        });
    }
   
    public model = 'department'
    public department = {} as Department;

    ngOnInit() {

        let departmentId = this.route.snapshot.params.id
        if (departmentId === 'create') {
            this.isFormDisabled = false;
            this.api.getAll('process').subscribe((res: any[]) => {
                this.todo = res
            })
        }
        else {
            this.api.getAll('process').subscribe((res: any[]) => {
                this.onLoadProcesses = res;
                this.getDepartment(Number(departmentId));
            })
           // this.getDepartment(Number(departmentId));
        }

       
    }

    getDepartment(departmentId: number) {
        this.api.get(this.model, departmentId).subscribe(
            (data: any) => {
                if (data) {
                    this.department = data;
                    data.processes.forEach((value:any, index:any) => {
                        this.done.push(data.processes[index]['process']);
                    });
                    this.rearrangeProcesses();
                }
            }
        );
    }


    rearrangeProcesses(){
        this.done.forEach((value:any, index:any) => {
            let  k = 0;      
            var len = this.onLoadProcesses.length;
            for (k = 0; k < len; k = k + 1) {
                if (this.onLoadProcesses[k]['id'] === this.done[index]['id']) {
                    this.onLoadProcesses.splice(k, 1);
                    break;
                } 
            }
        });
        this.todo =this.onLoadProcesses
    }

    save(formValid:any) {
        if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
        this.getMappedProcess();
        this.department.processes = this.done
        if(this.department.processes.length == 0){
            alert("Map atleast one process");
            return

        }
        this.onSaveDisable = true;
        this.api.createUpdate(this.model, this.department).subscribe(
            (data: any) => {
                if (data) {
                    this.department = data;
                   
                    this.isFormDisabled = true;
                    this.snackbar.success('Saved Successfully!');
                    this.onSaveDisable = false;
                }
            },
            error => {
                alert(error.error.message)
                this.onSaveDisable = false;
            }
        );
    }

    edit(){
        this.isFormDisabled = false;
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
                if(this.department.id){
                    this.isFormDisabled = true;
                    this.done=[];
                    this.todo=[];
                    this.api.getAll('process').subscribe((res: any[]) => {
                        this.onLoadProcesses = res
                    })
                    this.getDepartment(this.department.id);
                }else{
                    this.router.navigate([`/department`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('department/create');
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

}
