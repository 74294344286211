<form #processForm="ngForm"  class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-9 form-breadcrumb">
            <h3><a [routerLink]="['/process']" routerLinkActive="router-link-active">Process</a> > {{process.name}}</h3>
        </div>
        <div class="col-3 form-operation">
            <button *ngIf="!isFormDisabled" [disabled]="onSaveDisable" class="m-2" type="submit" mat-raised-button color="primary" (click)='save(processForm.valid)'>Save</button>
            <button *ngIf="isFormDisabled"  class="m-2" type="button" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="isFormDisabled"  class="m-2" type="button" mat-raised-button color="primary" (click)='create()'>Create</button>
            <button *ngIf="!isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>
    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Name <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" #name="ngModel" matInput name="name" [(ngModel)]="process.name" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="name.touched && name.invalid">Required</div>
                </div>
            </div>
        </fieldset>        
    </div>
</form>