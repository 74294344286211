<form #balanceForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-9 form-breadcrumb">
            <h3><a [routerLink]="['/balance']" routerLinkActive="router-link-active">Opening Balance</a> ></h3>
        </div>
        <div class="col-3 form-operation">
            <button *ngIf="!isFormDisabled" [disabled]="onSaveDisable" class="m-2" type="submit" mat-raised-button color="primary" (click)='save(balanceForm.valid)'>Save</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="primary" (click)='create()'>Create</button>
            <button *ngIf="!isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>
    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="financial_year_element" class="col-sm-2 col-form-label">FY Code <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select #financial_year_element (openedChange)="onFinancialYearScroll($event)" [(value)]="selectedFinancialYear" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keydown)="$event.stopPropagation()" (keyup)="onFinancialYearKey($event)" matInput name="financial_year_list">
                            <mat-option *ngFor="let fy of allFinancialYears" [value]="fy.id">{{fy.fy_code}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <label for="doctor_id" class="col-sm-2 col-form-label">Doctor<span class="span-class">
                    *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select #doctor_id (openedChange)="onDoctorScroll($event)" [disabled]="isFormDisabled || disableDoctorSelection" [(value)]="selectedDoctor" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keydown)="$event.stopPropagation()" (keyup)="onDoctorKey($event)" matInput name="doctor_list">
                            <mat-option *ngFor="let doctor of allDoctors" [value]="doctor.id">{{doctor.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label for="opening_balance" class="col-sm-2 col-form-label">Opening Balance <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #opening_balance="ngModel" type="text" matInput name="opening_balance" [(ngModel)]="balance.opening_balance" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="opening_balance.touched && opening_balance.invalid">Required</div>
                </div>

            </div>
        </fieldset>
    </div>
</form>