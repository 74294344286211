<div class="container-login100">
    <div class="wrap-login100">
        <mat-card>
            <mat-card-content>
                <div class="mt-2 mb-5">
                    <img width="180px" height="200px" src="src/assets/Groovesheader.jpg">
                    <h3 class="pt-4">Login into Grooves Dental Design</h3>
                    <hr>
                </div>
                <form class="MatFormFieldControl">
                    <div>
                        <mat-form-field class="MatFormFieldControl">
                            <mat-label>Financial Year</mat-label>
                            <mat-select #financial_year_element (openedChange)="onFinancialYearScroll($event)" [(value)]="selectedFinancialYear" required>
                                <input class="select-input" placeholder="Search..." type="text" autocomplete="off" (keydown)="$event.stopPropagation()" (keyup)="onFinancialYearKey($event)" matInput name="financial_year_list">
                                <mat-option *ngFor="let fy of allFinancialYears" [value]="fy.id">{{fy.fy_code}}</mat-option>
                            </mat-select>

                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="MatFormFieldControl">
                            <input type="text" placeholder="Username" matInput name="username" [(ngModel)]="username">
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="MatFormFieldControl">
                            <input type="password" placeholder="Password" matInput name="password" [(ngModel)]="password">
                        </mat-form-field>
                    </div>
                    <div class="pt-4">
                        <button type="submit" mat-raised-button color="primary" (click)='login()'>Login</button>
                    </div>
                    <p class="pt-4">Forget Password?</p>
                    <p class="pt-3">© Aniket Software Solutions</p>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>