<form #noteForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-7 form-breadcrumb">
            <h3><a [routerLink]="['/note']" routerLinkActive="router-link-active">Note</a> > {{note.voucher_number}}</h3>
        </div>
        <div class="col-sm-1 form-operation">
            <a *ngIf="note.id && isFormDisabled && !['cancelled'].includes(note.status)" target="_blank" href="/api/note/{{note.id}}/download">
                <button class="m-2" type="button" mat-raised-button color="primary">Print</button>
            </a>
        </div>
        <div class="col-sm-4 form-operation" *ngIf="!['submitted','canceled'].includes(note.status)">
            <button *ngIf="isFormDisabled && note.status == 'draft'" class="m-2" type="button" mat-raised-button color="primary" (click)="confirmDialog(note.id, 'submit')">Submit</button>
            <button *ngIf="isFormDisabled && note.status == 'draft'" class="m-2" type="button" mat-raised-button color="link" (click)="confirmDialog(note.id,'cancel')">Cancel</button>
            <button *ngIf="!isFormDisabled" class="m-2" [disabled]="onSaveDisable" type="submit" mat-raised-button color="primary" (click)='save(noteForm.valid)'>Save</button>
            <button *ngIf="isFormDisabled" type="button" class="m-2" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="!isFormDisabled" type="button" class="m-2" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>
    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Voucher No. <span class="span-class">
                        *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #voucher_number="ngModel" [disabled]="true" type="text" matInput name="voucher_number"
                            [(ngModel)]="note.voucher_number" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="voucher_number.touched && voucher_number.invalid">Required
                    </div>
                </div>
                <label for="voucher_date" class="col-sm-2 col-form-label">Voucher Date <span class="span-class">
                    *</span></label>
                <div class="col-sm-4 dateRangePickStyle">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <input #date="ngModel" matInput [(ngModel)]="note.voucher_date" name="voucher_date"
                            [matDatepicker]="voucher_date" [min]="minDate" [max]="maxDate" required>
                        <mat-datepicker-toggle matSuffix [for]="voucher_date">
                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #voucher_date></mat-datepicker>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="date.touched && date.invalid">Required</div>
                </div>
            </div>
            <div class="row">
                <label for="type" class="col-sm-2 col-form-label">Note Type <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [disabled]="isFormDisabled" [(value)]="selected_note_type" (selectionChange)="onAccountOrDoctorChange()">
                            <input class="select-input" type="text" autocomplete="nope" matInput name="note_type">
                            <mat-option *ngFor="let noteType of note_types" [value]="noteType">{{noteType | titlecase
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <label for="doctor_name" class="col-sm-2 col-form-label">Doctor<span class="span-class">
                        *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select #doctor_id (openedChange)="onDoctorScroll($event)"
                            [disabled]="isFormDisabled || disableDoctorSelection" [(value)]="selectedDoctor" (selectionChange)="onAccountOrDoctorChange()" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope"
                                (keydown)="$event.stopPropagation()" (keyup)="onDoctorKey($event)" matInput
                                name="doctort_list">
                            <mat-option *ngFor="let doctor of allDoctors"
                                [value]="doctor.id">{{doctor.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label for="company_id" class="col-sm-2 col-form-label">Company<span class="span-class">
                    *</span></label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <mat-select #company_id (openedChange)="OnCompanyScroll($event)"
                        [disabled]="isFormDisabled || disableCompanySelection" [(value)]="selectedCompany" (selectionChange)="onCompanyChange()" required>
                        <input class="select-input" placeholder="Search..." type="text" autocomplete="nope"
                            (keydown)="$event.stopPropagation()" (keyup)="OnCompanyKey($event)" matInput
                            name="company_list">
                        <mat-option *ngFor="let company of allCompanies"
                            [value]="company.id">{{company.company_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
                <label for="account_id" class="col-sm-2 col-form-label">Account<span class="span-class">
                        *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select #account_id (openedChange)="onAccountScroll($event)"
                            [disabled]="isFormDisabled || disableAccountSelection" [(value)]="selectedAccount" (selectionChange)="onAccountOrDoctorChange()" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope"
                                (keydown)="$event.stopPropagation()" (keyup)="onAccountKey($event)" matInput
                                name="account_list">
                            <mat-option *ngFor="let account of allAccounts"
                                [value]="account.id">{{account.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Narration <span class="span-class"></span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <textarea #narration="ngModel" type="textarea-bg" matInput name="narration"
                            [(ngModel)]="note.narration"></textarea>
                    </mat-form-field>
                    <!-- <div class="validation-class" *ngIf="narration.touched && narration.invalid">Required</div> -->
                </div>
            </div>
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Balance</label>
                <div class="col-sm-4">
                        <span class="bottom-0">
                            <p class="note-balance">{{note.balance}}</p>
                        </span>                    
                </div> 
            </div>
            <div class="row pt-4">

                <div class="col-sm-6">

                </div>

                <div class="col-sm-6">
                    <div class="mat-table">
                        <div class="mat-header-row">
                            <div class="mat-header-cell amt-td-height col-sm-12">
                                <div class="col-sm-6 text-right">
                                    <label>Amount (&#8377;):<span class="span-class">
                                        *</span></label>
                                </div>
                                <div class="col-sm-6 text-right amount-col-pad">
                                    <input style="text-align: right;" required [(ngModel)]="note.amount" name="amount"
                                        type="number" (focusout)="calculateNoteTaxes(note)">
                                </div>
                            </div>
                            <div class="mat-header-cell amt-td-height col-sm-12">
                                <div class="col-sm-6 text-right">
                                    <span>SGST (&#8377;):</span>
                                </div>
                                <div class="col-sm-6 text-right amount-col-pad">
                                    <span>{{note.sgst_amount | currency :'INR':'symbol':'1.2-2'}}</span>
                                </div>
                            </div>
                            <div class="mat-header-cell amt-td-height col-sm-12">
                                <div class="col-sm-6 text-right">
                                    <span>CGST (&#8377;):</span>
                                </div>
                                <div class="col-sm-6 text-right amount-col-pad">
                                    <span>{{note.cgst_amount | currency :'INR':'symbol':'1.2-2'}}</span>
                                </div>
                            </div>
                            <div class="mat-header-cell amt-td-height col-sm-12">
                                <div class="col-sm-6 text-right">
                                    <span>IGST (&#8377;):</span>
                                </div>
                                <div class="col-sm-6 text-right amount-col-pad">
                                    <span>{{note.igst_amount | currency :'INR':'symbol':'1.2-2'}}</span>
                                </div>
                            </div>
                            <div class="mat-header-cell amt-td-height col-sm-12">
                                <div class="col-sm-6 text-right">
                                    <span>Grand Total (&#8377;):</span>
                                </div>
                                <div class="col-sm-6 text-right amount-col-pad">
                                    <span>{{note.grand_total | currency :'INR':'symbol':'1.2-2'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <mat-tab-group animationDuration="0ms" class="mt-5">
                <mat-tab id="knock_off_list" label="Knock-Off">
                    <table mat-table [dataSource]="knockoffsDataSource" #knock_off_lines class="mat-elevation-z8">
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                Select
                                <!-- <mat-checkbox color="primary" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                                </mat-checkbox> -->
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                    (change)="toggleSelection(row)" [checked]="selection.isSelected(row)" [disabled]="isFormDisabled || (note.balance <= 0 && !selection.isSelected(row))">
                                </mat-checkbox>
                            </td>
                        </ng-container>
    
                        <ng-container class="mat-table-row" [matColumnDef]="column"
                            *ngFor="let column of displayColumns; let i = index;">
                            <th class="font-weight-bold" mat-header-cell *matHeaderCellDef> {{displayHeaders[i]}} </th>
                            <td mat-cell *matCellDef="let obj" class="header-cell"> {{obj[column]}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayColumnsWithSelect"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayColumnsWithSelect;"></tr>
                    </table>
                </mat-tab>
            </mat-tab-group>
        </fieldset>
    </div>
</form>

<!-- if state id of doctor and company is equal then apply cgst and sgst half each of the gst percentage of the company
and if not then apply the whole gst percentage and as igst on the amount. -->