import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SpeakerService } from '../../speaker.service';
import { CommonApiService } from "../../common-api.service";
import { JobOrder, displayColumns, displayHeaders,filterParameters } from './joborder.interface';
import { DatePipe } from '@angular/common';
import { EventEmitterService } from '../../event-emitter.service';
import { environment } from "../../../../environments/environment";
import { DynamicFilterComponent } from '../dynamic-filter/dynamic-filter.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';   
import { RenderFilterDropdownService } from '../../render-filter-dropdown.service';

@Component({
    selector: 'app-joborder',
    templateUrl: './joborder.component.html',
    styleUrls: ['./joborder.component.css']
})
export class JobOrderComponent implements OnInit {

    displayColumns = displayColumns;
    displayHeaders = displayHeaders;
    jobOrder: JobOrder[] = [];
    filterFields: any[] =[]
    filterCriteria: any[] =[]
    doctorOptions:any[] = [];
    categoryOptions:any[] = [];

    @ViewChild('dynamicFilterContainer', { read: ViewContainerRef, static: true })
	dynamicFilterContainer!: ViewContainerRef;


    constructor(
        public speaker: SpeakerService,
        private api: CommonApiService,
        private datePipe: DatePipe,
        private eventEmitterService: EventEmitterService,
        private dialog: MatDialog,
        private router: Router,
        private renderFilterDropdown: RenderFilterDropdownService
    ) { }

    // common code above this line for all components

    transformDate(date: any) {
        date = new Date(date);
        var converteddate: any = this.datePipe.transform(date, 'dd-MM-yyyy');
        return converteddate;
    }

    ngOnInit(): void {
        //resetting event emmitter variables onload of component
        this.eventEmitterService.pageSize = environment.defaultPageSizeOnLoad
        this.eventEmitterService.pageIndex = environment.defaultPageIndexOnLoad

        this.getListDetails();
        this.speaker.model = 'joborder';
        this.speaker.displayColumns = this.displayColumns;
        this.speaker.displayHeaders = this.displayHeaders;
        this.speaker.listData = this.jobOrder;  

        //function gets called by default when the component is loaded
        this.eventEmitterService.subsVar = this.eventEmitterService.    
        invokeFirstComponentFunction.subscribe(() => {  
            this.getListDetails();   
        });

        this.onDropdownRenderOptions('doctor/list/display', this.doctorOptions);
        this.onDropdownRenderOptions('category/list/display', this.categoryOptions);
    }

    // dynamic func for dropdown loading in filters
    onDropdownRenderOptions(apiEndpoint: string, targetOptions: any[]) {
        this.renderFilterDropdown.onDropdownLoadOptions(apiEndpoint, targetOptions);
    }

    ngOnDestroy(): void {
        this.eventEmitterService.subsVar.unsubscribe();
    }

    getListDetails() {
 
        let params = {
            'pageIndex': this.eventEmitterService.pageIndex ? this.eventEmitterService.pageIndex: environment.defaultPageIndexOnLoad,
            'pageSize': this.eventEmitterService.pageSize ? this.eventEmitterService.pageSize: environment.defaultPageSizeOnLoad,
            filterCriteria: this.filterCriteria
        }

        this.api.postAll('joborder/list/display', params).subscribe((res: any) => {
            this.jobOrder = res.items
            
            this.jobOrder.forEach((element) => {
                element.doctor_name = element.doctor?.name;
                element.category_name = element.category?.name;
                if(element.job_date){
                    element.job_date = this.transformDate(element.job_date)
                }
                if(element.delivery_date){
                    element.delivery_date = this.transformDate(element.delivery_date)
                }
                if (element.status == "draft") {
                    element.status = "Draft";
                } else if(element.status == "in_production") {
                    element.status = "In Production";
                } else if (element.status == "done") {
                    element.status = "Done";
                } else if (element.status == "hold") {
                    element.status = "Hold";
                } else if (element.status == "canceled") {
                    element.status = "Canceled";
                }

                if (element.job_type == "repair") {
                    element.job_type = "Repair";
                } else if(element.job_type == "repeat") {
                    element.job_type = "Repeat";
                } else if (element.job_type == "regular") {
                    element.job_type = "Regular";
                }

                if (element.procedure == "physical") {
                    element.procedure = "Physical";
                } else if(element.procedure == "digital") {
                    element.procedure = "Digital";
                }
            });

            this.speaker.setListData(this.jobOrder, res.rowCount, res.pageIndex, res.pageSize);
            
        });
    }

    // set options for dropdown filters
    getOptionArray<T>(modelName: any): T[] {
        const ModelOptionsMap: Record<string, T[]> = {
            doctor: this.doctorOptions,
            category: this.categoryOptions,
            // visittype: this.visittypeOptions
            // Add other model names and their corresponding options arrays here
        };
        
        const optionKey = ModelOptionsMap[modelName];
        return optionKey;
    }

    openDialogFilter() {
  
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            filterFields: filterParameters.map(field => {
                if (field.type == 'dropdown') {
                    return {
                    ...field,
                    options: this.getOptionArray<typeof field.model_name>(field.model_name),
                    };
                }
                return field;
            }),
        };
        const dialogRef = this.dialog.open(DynamicFilterComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.filterCriteria = result
                this.eventEmitterService.pageIndex = environment.defaultPageIndexOnLoad
                this.eventEmitterService.pageSize = environment.defaultPageSizeOnLoad
                this.getListDetails();
            }
        });
    }

    resetFilters() {
        this.redirectTo(this.speaker.model);
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }
}
