import { Doctor } from "../doctor/doctor.interface";
import { Company } from "../company/company.interface";
export interface Note{
    id:number;
    voucher_number:string;
    voucher_date:Date;
    note_type:string;
    amount:number;
    doctor_id:number;
    account_id:number;
    company_id:number;
    sgst:number;
    cgst:number;
    igst:number;
    sgst_amount:number;
    cgst_amount:number;
    igst_amount:number;
    grand_total:number;
    narration:string;
    status:string;
    knock_offs: any[];
    knocked_off: any[];
    balance:number;
    doctor_name:string;
    company_name:string;
    doctor:Doctor;
    company:Company;
}

export var displayColumns=[
    'voucher_number',
    'voucher_date',
    'note_type',
    'doctor_name',
	'company_name',
    'grand_total',
    'status'
]

export var displayHeaders=[
    'Voucher No.',
    'Voucher Date',
    'Note Type',
    'Doctor',
	'Company',
    'Grand Total',
    'Status'
]

export var orderByColumns=[
    'voucher_number',
    'voucher_date',
    'note_type',
    'doctor_name',
    'company_name',
    'grand_total',
    'status'
]

export var knockOffdisplayColumns = [
    'type',
    'name',
    'date',
    'grand_total',
    'balance'
  ]
export var knockOffdisplayHeaders = [
    'Type',
    'Name',
    'Date',
    'Grand Total',
    'Balance'
  ]

// for dropdown the options are set via ts file, so modify the code there
export const filterParameters = [
    { 
        name: 'created_at', 
        type: 'date_range', 
        operator: 'between', 
        label: 'Select Date Range' 
    },
	{ 
		name: 'voucher_number', 
		type: 'string', 
		operator: 'like', 
		label: 'Voucher No.', 
	},
		{ 
		name: 'note_type', 
		type: 'radio_option', 
		operator: '=',
		values: [
					{ label: 'Debit', value: "debit" },
					{ label: 'Credit', value: "credit" }
				],
		model_name:'note',
		label: 'Note Type' 
	},
	{ 
		name: 'doctor_id', 
		type: 'dropdown', 
		operator: '=',
		model_name:'doctor',
		label: 'Doctor' 
	},
	{ 
		name: 'company_id', 
		type: 'dropdown', 
		operator: '=',
		model_name:'company',
		label: 'Company' 
	},
	{ 
		name: 'status', 
		type: 'radio_option', 
		operator: '=',
		values: [
					{ label: 'Draft', value: "draft" },
					{ label: 'Submitted', value: "submitted" },
					{ label: 'Cancelled', value: "cancelled" }
				],
		model_name:'invoice',
		label: 'Status' 
	},
  	{ 
		name: 'order_by_columns', 
		type: 'multi_select', 
		operator: 'order_by_columns',
		values: displayHeaders.map((header, index) => ({
				label: header,
				value: orderByColumns[index]
			})),
		model_name:'note',
		label: 'Select columns for order by' 
  	}
  ]; 
  