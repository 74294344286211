

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { Employee } from './employee.interface';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { SnackbarService } from '../../snackbar.service';


@Component({
    selector: 'app-employee-form',
    templateUrl: './employee.form.component.html',
    styleUrls: ['./employee.form.component.css']
})
export class EmployeeFormComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private api: CommonApiService,
        private router: Router,
        public dialog: MatDialog,
        private snackbar: SnackbarService,
    ) { }

    public model = 'employee'
    public employee = {} as Employee;

    public categories: any[] = [
        {value: 1, viewValue: 'Production'},
        {value: 2, viewValue: 'Sales'},
        {value: 3, viewValue: 'Other'}
    ];

    public allDepartments: any[] = [];
    public filteredDepartments = this.allDepartments;
    public selectedDepartment: number;

    public selectedCategory: any;
    isFormDisabled = true;
    result: string;
    onSaveDisable = false;

    ngOnInit() {
        let employeeId = this.route.snapshot.params.id
        if (employeeId === 'create') {
            this.isFormDisabled = false;
        }
        else {
            this.getEmployee(Number(employeeId));
        }
        this.api.getAll('department').subscribe(res => {
            this.allDepartments = res;
            this.filteredDepartments = res;
        })
    }

    changeValue(category:any){

        this.employee.category =  category;
    }

    onDepartmentKey = (event: Event) => {
        var value = (event.target as HTMLInputElement).value;
        this.filteredDepartments = this.searchDepartments(value);
    }
    searchDepartments(value: string) {
        let filter = value.toLowerCase();        
        return this.allDepartments.filter(option =>
            option.name.toLowerCase().includes(filter)
        );
    }  

    getEmployee(employeeId: number) {
        this.api.get(this.model, employeeId).subscribe(
          (data: any) => {
                if (data) {
                    this.employee = data;
                   this.selectedCategory = data.category
                   this.selectedDepartment = data.department.id
                }
            }
        );
    }

    save(formValid:any) {
        if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
        if(this.selectedCategory == undefined){
            alert("Please select category.");
            return;
        }
        if(this.selectedDepartment == undefined){
            alert("Please select department.");
            return;
        }
        this.onSaveDisable = true;

        this.employee.department_id = this.selectedDepartment;
        this.api.createUpdate(this.model, this.employee).subscribe(
            (data: any) => {
                if (data) {
                    this.employee = data;
                    this.isFormDisabled = true;
                    this.snackbar.success('Saved Successfully!');
                    this.onSaveDisable = false;
                }
            },
            error => {
                alert(error.error.message)
                this.onSaveDisable = false;
            }
        );
    }
    edit(){
        this.isFormDisabled = false;
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
                if(this.employee.id){
                    this.isFormDisabled = true;
                    this.getEmployee(this.employee.id);
                }else{
                    this.router.navigate([`/employee`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('employee/create');
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

}
