<form #hsnForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-9 form-breadcrumb">
            <h3><a [routerLink]="['/hsn']" routerLinkActive="router-link-active">Hsn / Sac</a> > {{hsn.hsn_sac}}</h3>
        </div>
        <div class="col-3 form-operation">
            <button *ngIf="!isFormDisabled" [disabled]="onSaveDisable" class="m-2" type="submit" mat-raised-button color="primary" (click)='save(hsnForm.valid)'>Save</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="primary" (click)='create()'>Create</button>
            <button *ngIf="!isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>


    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="hsn_sac" class="col-sm-2 col-form-label">Hsn / Sac <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #name="ngModel" type="text" matInput name="hsn_sac" [(ngModel)]="hsn.hsn_sac" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="name.touched && name.invalid">Required</div>
                </div>

                <label for="gst" class="col-sm-2 col-form-label">Gst (%) <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #gst="ngModel" type="text" matInput name="gst" [(ngModel)]="hsn.gst" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="gst.touched && gst.invalid">Required</div>
                </div>
            </div>
            <div class="row">
                <label for="description" class="col-sm-2 col-form-label">Description</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="description" [(ngModel)]="hsn.description">
                    </mat-form-field>
                </div>
            </div>
        </fieldset>
    </div>
</form>