<div class="container-fluid p-4">
    <h3>Masters</h3>
</div>
<br>
<div class="col-sm-6">
    <div class="pb-4">
        <button routerLink="/doctor" mat-stroked-button color="primary">Doctors</button>
    </div>
    <div class="pb-4">
        <button routerLink="/product" mat-stroked-button color="primary">Products</button>
    </div>
    <div class="pb-4">
        <button routerLink="/category" mat-stroked-button color="primary">Product Category</button>
    </div>
    <div class="pb-4">
        <button routerLink="/jobchecklist" mat-stroked-button color="primary">Job Check List</button>
    </div>
    <div class="pb-4">
        <button  routerLink="/shipment" mat-stroked-button color="primary">Shipment</button>
    </div>
    <div class="pb-4">
        <button routerLink="/hsn" mat-stroked-button color="primary">HSN</button>
    </div>
</div>
<div class="col-sm-6">

    <div class="pb-4">
        <button routerLink="/area" mat-stroked-button color="primary">City</button>
    </div>
    <div class="pb-4">
        <button routerLink="/state" mat-stroked-button color="primary">State</button>
    </div>
    <div class="pb-4">
        <button routerLink="/remark" mat-stroked-button color="primary">Remark</button>
    </div>
    <div class="pb-4">
        <button routerLink="/employee" mat-stroked-button color="primary">Employee</button>
    </div>
    <div class="pb-4">
        <button routerLink="/department" mat-stroked-button color="primary">Department</button>
    </div>
    <div class="pb-4">
        <button routerLink="/process" mat-stroked-button color="primary">Process</button>
    </div>
    <div class="pb-4">
        <button routerLink="/pricelist" mat-stroked-button color="primary">Product Price List</button>
    </div>
    <div class="pb-4">
        <button routerLink="/account" mat-stroked-button color="primary">Account</button>
    </div>
</div>
