import { UserRole } from "../userrole/userrole.interface";
export interface User {
    id: number;
    username: string;
    password: string;
    email: number;
    role_id: number;
    role: UserRole;
    role_name: string;

};

export var displayColumns = [
    'username',
    'email',
    'role_name',
]

export var displayHeaders = [
    'User name',
    'Email',
    'Role'
]

// actual database columns
export var orderByColumns = [
    'username',
    'email',
    'role_name'
]


// for dropdown the options are set via ts file, so modify the code there
export const filterParameters = [
    { 
        name: 'created_at', 
        type: 'date_range', 
        operator: 'between', 
        label: 'Select Date Range' 
    },
    { 
        name: 'username', 
        type: 'string', 
        operator: 'like', 
        label: 'User Name', 
    },
    { 
        name: 'email', 
        type: 'string', 
        operator: 'like', 
        label: 'Email', 
    },
    { 
        name: 'role_id', 
        type: 'dropdown', 
        model_name: 'userrole', 
        operator: '=',
        label: 'Role Name' 
    },
    { 
        name: 'order_by_columns', 
        type: 'multi_select', 
        operator: 'order_by_columns',
        values: displayHeaders.map((header, index) => ({
                label: header,
                value: orderByColumns[index]
            })),
        model_name:'user',
        label: 'Select columns for order by' 
    }
  ]; 


