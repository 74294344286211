import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { Account } from './account.interface';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { SnackbarService } from '../../snackbar.service';
import { Company } from '../company/company.interface';
import { CommonDropdownService } from '../../common-dropdown.service';
import { MatSelect } from '@angular/material/select';
import { environment } from "../../../../environments/environment";
import { tap, filter, finalize } from 'rxjs/operators';

@Component({
    selector: 'app-account.form',
    templateUrl: './account.form.component.html',
    styleUrls: ['./account.form.component.css']
})

export class AccountFormComponent implements OnInit {

    @ViewChild('company_id', { static: false }) company_id: MatSelect;
    constructor(
        private route: ActivatedRoute,
        private api: CommonApiService,
        private router: Router,
        public dialog: MatDialog,
        private snackbar: SnackbarService,
        private dropdownService: CommonDropdownService
    ) { }

    public model = 'account'
    public account = {} as Account;
    isFormDisabled = true;
    result: string;
    onSaveDisable = false;

    public allAccountTypes : any[] = [ "cash", "bank", "other"];
    public selectedAccountType : string

    companySearchPage=0
    allCompaniesCount = 0
    public allCompanies: any[] = [];
    public selectedCompany: number;
    public selectedCompanyObject: Company[] = [];
    disableCompanySelection: boolean;

    ngOnInit() {
        this.disableCompanySelection=false
        let accountId = this.route.snapshot.params.id
        if (accountId === 'create') {
            this.isFormDisabled = false;
        }
        else {
            this.disableCompanySelection=true
            this.getAccount(Number(accountId));
        }

        this.OnLoadCompanyList();
    }

    OnCompanyKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'company', this.allCompanies, this.selectedCompanyObject, 'companySearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            
            this.allCompanies = result.entityList;
            this.companySearchPage = result.pageVariable;
        });
    }

    getAccount(accountId: number) {
        this.api.get(this.model, accountId).subscribe(
            (data: any) => {
                if (data) {
                    this.account = data;
                    this.selectedAccountType = data.type


                    this.selectedCompany = data.company.id;
                    this.allCompanies = this.allCompanies.filter(company => company.id !== data.company.id);
                    this.allCompanies.push(data.company) // insert specific selected doctor into the dropdown list
                    this.selectedCompanyObject.push(data.company)
                }
            }
        );
    }

    OnCompanyScroll(event:any) {
        if (!this.company_id?.panel || !this.company_id?.panel.nativeElement) {
            // Panel or nativeElement is not available, return or handle the situation accordingly.
            return;
        }
        if (event) {
            this.company_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.company_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.company_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.company_id.panel.nativeElement.offsetHeight)
                    ){
                    let companyparams = {
                        'pageIndex': this.companySearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }
                    
                    if (this.allCompanies.length >= this.allCompaniesCount) {
                        return;
                    }
                    this.api.postAll('company/list/display', companyparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedCompanyObject[0]?.id);
                        this.allCompanies.push(...res.items);
                    });
                    this.companySearchPage++;// send page number for fetching the records
                }
            });
        }
    }


    OnLoadCompanyList() {
        this.api.getAll('company').pipe(
            tap((res: any) => {
                this.allCompanies=res
            }),
            filter(() => this.selectedCompanyObject.length !== 0),
            tap(() => {
              const selectedCompanyId = this.selectedCompanyObject[0].id;
              this.allCompanies = this.allCompanies.filter(company => company.id !== selectedCompanyId);
              this.allCompanies.unshift(this.selectedCompanyObject[0]);
              
            }),
        ).subscribe();
    }


    save(formValid:any) {
        if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
        if (this.selectedAccountType == undefined) {
			alert("Please select account type.");
			return;
		}
        if (this.selectedCompany == undefined) {
			alert("Please select company.");
			return;
		}
        this.account.type   = this.selectedAccountType
        this.account.company_id=this.selectedCompany
        this.onSaveDisable = true;
        this.api.createUpdate(this.model, this.account).subscribe(
            (data: any) => {
                if (data) {
                    this.account = data;
                    this.isFormDisabled = true;
                    this.snackbar.success('Saved Successfully!');
                    this.onSaveDisable = false;
                }
            },
            error => {
                alert(error.error.message)
                this.onSaveDisable = false;
            }
        );
    }
    edit(){
        this.isFormDisabled = false;
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
                if(this.account.id){
                    this.isFormDisabled = true;
                    this.getAccount(this.account.id);
                }else{
                    this.router.navigate([`/account`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('account/create');
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

}
