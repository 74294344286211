import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrderComponent } from '../order/order.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProcessComponent } from './process/process.component';
import { ProcessFormComponent } from './process/process.form.component';
import { ProductComponent } from './product/product.component';
import { ProductFormComponent } from './product/product.form.component';
import { DoctorComponent } from './doctor/doctor.component';
import { DoctorFormComponent } from './doctor/doctor.form.component';
import { StateComponent } from './state/state.component';
import { StateFormComponent } from './state/state.form.component';
import { HsnSacComponent } from './hsn_sac/hsn_sac.component';
import { HsnSacFormComponent } from './hsn_sac/hsn_sac.form.component';
import { ShipmentComponent } from './shipment/shipment.component';
import { ShipmentFormComponent } from './shipment/shipment.form.component';
import { ShadeComponent } from './shade/shade.component';
import { ShadeFormComponent } from './shade/shade.form.component';
import { JobchecklistComponent } from './jobchecklist/jobchecklist.component';
import { JobchecklistFormComponent } from './jobchecklist/jobchecklist.form.component';
import { ProductCategoryComponent } from './product-category/product-category.component';
import { ProductCategoryFormComponent } from './product-category/product-category.form.component';
import { DepartmentComponent } from './department/department.component';
import { DepartmentFormComponent } from './department/department.form.component';
import { JobOrderComponent } from './joborder/joborder.component';
import { JobOrderFormComponent } from './joborder/joborder.form.component';
import { AreaComponent } from './area/area.component';
import { AreaFormComponent } from './area/area.form.component';
import { RemarkComponent } from './remark/remark.component';
import { RemarkFormComponent } from './remark/remark.form.component';
import { JobScheduleComponent } from './job-schedule/job-schedule.component';
import { ProdJobInComponent } from './prod-job-in/prod-job-in.component';  
import { EmployeeLoginComponent } from './employee-login/employee-login.component';   
import { EmployeeComponent } from './employee/employee.component';   
import { EmployeeFormComponent } from './employee/employee.form.component';
import { CompanyComponent } from './company/company.component'; 
import { CompanyFormComponent } from './company/company-form.component';
import { ChallanComponent } from './challan/challan.component';
import { ChallanFormComponent } from './challan/challan-form.component';  
import { ProdJobOutComponent } from'./prod-job-out/prod-job-out.component';  
import { ReportsComponent } from'./reports/reports.component';  
import { PendingJobDataComponent } from'./reports/pending-job-data/pending-job-data.component';  
import { AccountAgingComponent } from'./reports/account-aging/account-aging.component';  
import { JobOrderLogComponent } from'./reports/job-order-log/job-order-log.component';  
import { UserRoleComponent } from './userrole/userrole.component';
import { UserRoleFormComponent }  from './userrole/userrole.form.component';
import { AccessControlComponent } from './access-control/access-control.component';
import { UserComponent } from './user/user.component';
import { UserFormComponent }  from './user/user.form.component';
import { AuthGuard } from "../auth-guard.guard";
import { InvoiceComponent } from './invoice/invoice.component';
import { InvoiceFormComponent } from './invoice/invoice-form.component';
import { MainInvoiceComponent } from './invoice/main-invoice.component';
import { MainInvoiceFormComponent } from './invoice/main-invoice-form.component';
import { MasterComponent } from './master/master.component';
import { AccountingLandingScreenComponent } from './accounting-land-screen/accounting-land-screen.component';
import { PriceListComponent } from './price-list/price-list.component';
import { PriceListFormComponent } from './price-list/price-list.form.component';
import { AllDoctorsComponent } from './reports/all-doctors/all-doctors.component';
import { JobProductionDetailsComponent } from './reports/job-production-details/job-production-details.component';
import { DailyJobActivitySummaryComponent } from './reports/daily-job-activity-summary/daily-job-activity-summary.component';
import { TaxInvoiceComponent } from './reports/tax-invoice/tax-invoice.component';
import { AccountComponent } from './account/account.component';
import { AccountFormComponent } from './account/account.form.component';
import { AccountReceiptComponent } from './account-receipt/account-receipt.component';
import { AccountReceiptFormComponent } from './account-receipt/account-receipt.form.component';
import { NoteComponent } from './note/note.component';
import { NoteFormComponent } from './note/note.form.component';
import { JournalVoucherComponent } from './journal-voucher/journal-voucher.component';
import { JournalVoucherFormComponent } from './journal-voucher/journal-voucher.form.component';
import { KnockOffComponent } from './knock-off/knock-off.component';
import { DoctorLedgerComponent } from './reports/doctor-ledger/doctor-ledger.component';
import { BalanceComponent } from './balance/balance.component';
import { BalanceFormComponent } from './balance/balance.form.component';
import { SalesReturnComponent } from './sales-return/sales-return.component';
import { SalesReturnFormComponent } from './sales-return/sales-return-form.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

const routes: Routes = [
    { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard]},
    { path: 'pricelist/:id', component: PriceListFormComponent, canActivate: [AuthGuard]},
    { path: 'pricelist', component: PriceListComponent, canActivate: [AuthGuard]},
    { path: 'master', component: MasterComponent, canActivate: [AuthGuard]},
    { path: 'accounting', component: AccountingLandingScreenComponent, canActivate: [AuthGuard]},
    { path: 'auth', component: UserComponent, canActivate: [AuthGuard]},
    { path: 'auth/:id', component: UserFormComponent, canActivate: [AuthGuard]},
    { path: 'access-control', component: AccessControlComponent, canActivate: [AuthGuard]},
    { path: 'userrole', component: UserRoleComponent, canActivate: [AuthGuard]},
    { path: 'userrole/:id', component: UserRoleFormComponent, canActivate: [AuthGuard]},
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
    { path: 'doctor', component: DoctorComponent, canActivate: [AuthGuard]},
    { path: 'doctor/:id', component: DoctorFormComponent, canActivate: [AuthGuard]},
    { path: 'order', component: OrderComponent, canActivate: [AuthGuard]},  
    { path: 'product', component: ProductComponent, canActivate: [AuthGuard]},
    { path: 'product/:id', component: ProductFormComponent, canActivate: [AuthGuard]},
    { path: 'process', component: ProcessComponent, canActivate: [AuthGuard]},
    { path: 'process/:id', component: ProcessFormComponent, canActivate: [AuthGuard]},
    { path: 'state', component: StateComponent, canActivate: [AuthGuard]},
    { path: 'state/:id', component: StateFormComponent, canActivate: [AuthGuard]},
    { path: 'hsn', component: HsnSacComponent, canActivate: [AuthGuard]},
    { path: 'hsn/:id', component: HsnSacFormComponent, canActivate: [AuthGuard]},
    { path: 'shipment', component: ShipmentComponent, canActivate: [AuthGuard]},
    { path: 'shipment/:id', component: ShipmentFormComponent, canActivate: [AuthGuard]},
    { path: 'shade', component: ShadeComponent, canActivate: [AuthGuard]},
    { path: 'shade/:id', component: ShadeFormComponent, canActivate: [AuthGuard]},
    { path: 'jobchecklist', component: JobchecklistComponent, canActivate: [AuthGuard]},
    { path: 'jobchecklist/:id', component: JobchecklistFormComponent, canActivate: [AuthGuard]},
    { path: 'category', component: ProductCategoryComponent, canActivate: [AuthGuard]},
    { path: 'category/:id', component: ProductCategoryFormComponent, canActivate: [AuthGuard]},
    { path: 'department', component: DepartmentComponent, canActivate: [AuthGuard]},
    { path: 'department/:id', component: DepartmentFormComponent, canActivate: [AuthGuard]},
    { path: 'joborder', component: JobOrderComponent, canActivate: [AuthGuard]},
    { path: 'joborder/:id', component: JobOrderFormComponent, canActivate: [AuthGuard]},
    { path: 'area', component: AreaComponent, canActivate: [AuthGuard]},
    { path: 'area/:id', component: AreaFormComponent, canActivate: [AuthGuard]},
    { path: 'remark', component: RemarkComponent, canActivate: [AuthGuard]},
    { path: 'remark/:id', component: RemarkFormComponent, canActivate: [AuthGuard]},
    { path: 'jobschedule', component: JobScheduleComponent, canActivate: [AuthGuard]},
    { path: 'prodjobin', component: ProdJobInComponent, canActivate: [AuthGuard]},
    { path: 'employeelogin/:type', component: EmployeeLoginComponent, canActivate: [AuthGuard]},
    { path: 'employee', component: EmployeeComponent, canActivate: [AuthGuard]},
    { path: 'employee/:id', component: EmployeeFormComponent, canActivate: [AuthGuard]},
    { path: 'company', component: CompanyComponent, canActivate: [AuthGuard]},
    { path: 'company/:id', component: CompanyFormComponent, canActivate: [AuthGuard]},
    { path: 'challan', component: ChallanComponent, canActivate: [AuthGuard]},
    { path: 'challan/:id', component: ChallanFormComponent, canActivate: [AuthGuard]},
    { path: 'proforma', component: InvoiceComponent, canActivate: [AuthGuard]},
    { path: 'proforma/:id', component: InvoiceFormComponent, canActivate: [AuthGuard]},
    { path: 'invoice', component: MainInvoiceComponent, canActivate: [AuthGuard]},
    { path: 'invoice/:id', component: MainInvoiceFormComponent, canActivate: [AuthGuard]},
    { path: 'prodjobout', component: ProdJobOutComponent, canActivate: [AuthGuard]},
    { path: 'account', component: AccountComponent, canActivate: [AuthGuard]},
    { path: 'account/:id', component: AccountFormComponent, canActivate: [AuthGuard]},
    { path: 'account_receipt', component: AccountReceiptComponent, canActivate: [AuthGuard]},
    { path: 'account_receipt/:id', component: AccountReceiptFormComponent, canActivate: [AuthGuard]},
    { path: 'note', component: NoteComponent, canActivate: [AuthGuard]},
    { path: 'note/:id', component: NoteFormComponent, canActivate: [AuthGuard]},
    { path: 'journal_voucher', component: JournalVoucherComponent, canActivate: [AuthGuard]},
    { path: 'journal_voucher/:id', component: JournalVoucherFormComponent, canActivate: [AuthGuard]},
    { path: 'knock_off', component: KnockOffComponent, canActivate: [AuthGuard]},
    { path: 'balance', component: BalanceComponent, canActivate: [AuthGuard]},
    { path: 'balance/:id', component: BalanceFormComponent, canActivate: [AuthGuard]},
    { path: 'sales_return', component: SalesReturnComponent, canActivate: [AuthGuard]},
    { path: 'sales_return/:id', component: SalesReturnFormComponent, canActivate: [AuthGuard]},
    
    
    { path: 'reports', canActivate: [AuthGuard],
      children: [
        { path: '', component: ReportsComponent, canActivate: [AuthGuard]},
        { path: 'pendingJobData', component: PendingJobDataComponent, canActivate: [AuthGuard]},
        { path: 'accountAging', component: AccountAgingComponent, canActivate: [AuthGuard]},
        { path: 'jobOrderLog', component: JobOrderLogComponent, canActivate: [AuthGuard]},
        { path: 'allDoctors', component: AllDoctorsComponent, canActivate: [AuthGuard]},
        { path: 'productionJobData', component: JobProductionDetailsComponent, canActivate: [AuthGuard]},
        { path: 'dailyJobActivity', component: DailyJobActivitySummaryComponent, canActivate: [AuthGuard]},
        { path: 'taxInvoice', component: TaxInvoiceComponent, canActivate: [AuthGuard]},
        { path: 'account_ledger', component: DoctorLedgerComponent, canActivate: [AuthGuard]},
        
      ]
    },
    
    
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BodyRoutingModule { }
