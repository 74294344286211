<div class="container-fluid p-4">
    <h3>Reports</h3>
</div>
<br>
<div class="col-sm-6">

    <div class="pb-4">
        <button routerLink="pendingJobData" mat-stroked-button color="primary">Pending Job Data</button>
    </div>
    <div class="pb-4">
        <button routerLink="jobOrderLog" mat-stroked-button color="primary">Job Order Log</button>
    </div>
    <div class="pb-4">
        <button routerLink="allDoctors" mat-stroked-button color="primary">All Doctors</button>
    </div>
    <div class="pb-4">
        <button routerLink="productionJobData" mat-stroked-button color="primary">City Wise Production Details</button>
    </div>
    <div class="pb-4">
        <button routerLink="dailyJobActivity" mat-stroked-button color="primary">Daily Order Active Summary</button>
    </div>
    <!-- <div class="pb-4">
        <button mat-stroked-button color="primary">Month & Doctor Wise Order Summary</button>
    </div> -->
    <div class="pb-4">
        <button routerLink="taxInvoice" mat-stroked-button color="primary">Sales Tax Register</button>
    </div>
    <div class="pb-4">
        <button routerLink="account_ledger" mat-stroked-button color="primary">Account Ledger</button>
    </div>
    <div class="pb-4">
        <button routerLink="accountAging" mat-stroked-button color="primary">Outstanding Receivables</button>
    </div>

</div>