<div class="container-fluid pt-4 pl-4">
    <h3><a [routerLink]="['/reports']" routerLinkActive="router-link-active">Reports</a> > Job Order Log</h3>
</div>

<div class="pt-4 pl-4">
    <div class="row">
        <label for="job_list" class="col-sm-2 col-form-label">Select Job No.<span class="span-class"> *</span></label>
        <div class="col-sm-4">
            <mat-form-field class="MatFormFieldControl">
                <mat-select #job_id (openedChange)="onJobScroll($event)" [(value)]="selectedJob" required>
                    <input class="select-input" placeholder="Search..." type="text" autocomplete="off" (keydown)="$event.stopPropagation()" (keyup)="onJobKey($event)" matInput name="job_list">
                    <mat-option *ngFor="let job of allJobs" [value]="job.id">{{job.job_no}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        
    </div><br>
    <div class="row col-12 p-4">
        <div class="col-2"></div>
        <div class="col-4 btnGenerate">
            <button type="button" mat-flat-button color="primary" (click)='generateReport()'>Generate Report</button>
        </div>
    </div>
</div>

<app-report-list-container *ngIf="speaker.listData.length > 0"></app-report-list-container>