import { Doctor } from "../doctor/doctor.interface";
import { Company } from "../company/company.interface";
export interface AccountReceipt {
	id: number;
	type: string;
	receipt_date:Date;
	narration:string;
	reference_number:string;
	net_amount:number;
	total_amount:number;
	tds_amount:number;
	receipt_number:string;
	company_id:number;
	company_name:string;
	company_account_id:number;
	receiving_account_id:number;
	knock_offs: any[];
	knocked_off: any[];
	balance: number;
	status:string;
	receiving_account:Doctor;
	company:Company;
	doctor_name:string;
};

export var displayColumns = [
	'receipt_number',
	'receipt_date',
	'type',
	'doctor_name',
	'company_name',
	'total_amount',
	'status'
]
export var displayHeaders = [
	'Receipt No.',
	'Receipt Date',
	'Type',
	'Doctor',
	'Company',
	'Total Amount',
	'Status'
]

export var knockOffdisplayColumns = [
	'type',
	'name',
	'date',
	'amount',
	'balance'
]
export var knockOffdisplayHeaders = [
	'Type',
	'Name',
	'Date',
	'Amount',
	'Balance'
]
// actual database columns
export var orderByColumns = [
	'receipt_number',
	'receipt_date',
	'type',
	'doctor_name',
	'company_name',
	'total_amount',
	'status'
]

// for dropdown the options are set via ts file, so modify the code there
export const filterParameters = [
	{ 
		name: 'created_at', 
		type: 'date_range', 
		operator: 'between', 
		label: 'Select Date Range' 
	},
	{ 
        name: 'receipt_number', 
        type: 'string', 
        operator: 'like', 
        label: 'Receipt No.', 
    },
	{ 
		name: 'type', 
		type: 'radio_option', 
		operator: '=',
		values: [
					{ label: 'Cash', value: "cash" },
					{ label: 'Cheque', value: "cheque" },
					{ label: 'Online', value: "online" }
				],
		model_name:'account_receipt',
		label: 'Type' 
	},
	{ 
		name: 'receiving_account_id', 
		type: 'dropdown', 
		operator: '=',
		model_name:'doctor',
		label: 'Doctor' 
	},
	{ 
		name: 'company_id', 
		type: 'dropdown', 
		operator: '=',
		model_name:'company',
		label: 'Company' 
	},
	{ 
		name: 'status', 
		type: 'radio_option', 
		operator: '=',
		values: [
					{ label: 'Draft', value: "draft" },
					{ label: 'Submitted', value: "submitted" },
					{ label: 'Cancelled', value: "cancelled" }
				],
		model_name:'invoice',
		label: 'Status' 
	},
	{ 
		name: 'order_by_columns', 
		type: 'multi_select', 
		operator: 'order_by_columns',
		values: displayHeaders.map((header, index) => ({
				label: header,
				value: orderByColumns[index]
			})),
		model_name:'account_receipt',
		label: 'Select columns for order by' 
	}
]; 
