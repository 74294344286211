import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { Shade } from './shade.interface';


@Component({
    selector: 'app-shade.form',
    templateUrl: './shade.form.component.html',
    styleUrls: ['./shade.form.component.css']
})
export class ShadeFormComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private api: CommonApiService,
    ) { }

    public model = 'shade'
    public shade = {} as Shade;

    ngOnInit() {
        let shadeId = this.route.snapshot.params.id
        if (shadeId === 'create') {
            console.log('False')
        }
        else {
            this.getShade(Number(shadeId));
        }
    }

    getShade(shadeId: number) {
        this.api.get(this.model, shadeId).subscribe(
            (data: any) => {
                if (data) {
                    this.shade = data;
                }
            }
        );
    }

    save() {
        this.api.createUpdate(this.model, this.shade).subscribe(
            (data: any) => {
                if (data) {
                    this.shade = data;
                }
            },
            error => {
                alert(error.error.message)
                console.log(error)
            }
        );
    }

}
