<form class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-9 form-breadcrumb">
            <h3>Employee Login</h3>
            <h4 *ngIf="type == 'in'">Job Inward</h4>
            <h4 *ngIf="type == 'out'">Job Outward</h4>
        </div>
    </div>

    <div class="base-form mat-elevation-z2 p-4">
        <div class="row">
            <label for="employee_list" class="col-sm-2 col-form-label">Employee Name</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <mat-select [(value)]="selectedEmployee" placeholder="Select Employee">
                        <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keyup)="onEmployeeKey($event)" matInput name="employee_list">
                        <mat-option *ngFor="let employee of filteredEmployees" [value]="employee.id">{{employee.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <label for="password" class="col-sm-2 col-form-label">4 Digit Security Key</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input (keypress)="keyPress($event)" placeholder="Enter security key" autocomplete="off" type="text" matInput name="password">
                </mat-form-field>
            </div>
        </div>
    </div>
</form>