<form class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-sm-9 form-breadcrumb">
            <h3><a [routerLink]="['/shade']" routerLinkActive="router-link-active">Shade</a> > {{shade.name}}</h3>
        </div>
        <div class="col-sm-3 text-right form-operation">
            <button type="submit" mat-raised-button color="primary" (click)='save()'>Save</button>
            <button type="button" class="mx-3" mat-raised-button color="link">Reset</button>
        </div>
    </div>
    <div class="base-form mat-elevation-z2 p-4">
        <div class="row">
            <label for="name" class="col-sm-2 col-form-label">Name</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input type="text" matInput name="name" [(ngModel)]="shade.name">
                </mat-form-field>
            </div>
        
            <label for="code" class="col-sm-2 col-form-label">Code</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input type="text" matInput name="code" [(ngModel)]="shade.code">
                </mat-form-field>
            </div>
        </div>
    </div>
</form>