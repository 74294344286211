import { PriceList } from "../price-list/price-list.interface";
import { DoctorAddress } from "./doctor-address.interface";
import { DoctorPoc } from "./poc.interface";
import { State } from "../state/state.interface";
import { Area } from "../area/area.interface";
export interface Doctor {
    id: number;
    name: string;
    clinic_name: string;
    email: string;
    mobile: number;
    bill_to: string;
    doctor_addresses: DoctorAddress[];
    doctor_pocs: DoctorPoc[];
    gst_no: string;
    pan: string;
    registration_no: string;
    credit_no: string;
    address_1: string;
    address_2: string;
    city_id: number;
    state_id: number;
    pincode: string;
    payment_terms: string;
    pricelist_id: number;
    pricelist: PriceList;
    city_name:string;
    state_name: string;
    city : Area;
    state: State;
};

export var displayColumns = [
    'name',
    'clinic_name',
    'city_name',
    'state_name',
    'email',
    'mobile'
]
export var displayHeaders = [
    'Name',
    'Clinic Name',
    'City',
    'State',
    'Email',
    'Mobile'
]

// actual database columns
export var orderByColumns = [
    'name',
    'clinic_name',
    'city_name',
    'state_name',
    'email',
    'mobile'
]

// for dropdown the options are set via ts file, so modify the code there
export const filterParameters = [
    { 
        name: 'created_at', 
        type: 'date_range', 
        operator: 'between', 
        label: 'Select Date Range' 
    },
    { 
        name: 'name', 
        type: 'string', 
        operator: 'like', 
        label: 'Name', 
    },
    { 
        name: 'clinic_name', 
        type: 'string', 
        operator: 'like', 
        label: 'Clinic name', 
    },
    { 
        name: 'email', 
        type: 'string', 
        operator: 'like', 
        label: 'Email', 
    },
    { 
        name: 'mobile', 
        type: 'string', 
        operator: 'like', 
        label: 'Mobile', 
    },
    { 
        name: 'state_id', 
        type: 'dropdown', 
        model_name:'state',
        operator: '=',
        label: 'State' 
    },
    { 
        name: 'city_id', 
        type: 'dropdown', 
        model_name:'area',
        operator: '=',
        label: 'City' 
    },
    { 
        name: 'order_by_columns', 
        type: 'multi_select', 
        operator: 'order_by_columns',
        values: displayHeaders.map((header, index) => ({
                label: header,
                value: orderByColumns[index]
            })),
        model_name:'doctor',
        label: 'Select columns for order by' 
    }
];  
