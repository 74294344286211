
export var knockOffdisplayColumns = [
    'type',
    'name',
    'date',
    'amount',
    'balance'
  ]
  export var knockOffdisplayHeaders = [
    'Type',
    'Name',
    'Date',
    'Amount',
    'Balance'
  ]