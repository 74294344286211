export interface Shade {
    id: number;
    name: string;
    code: number;
    active: boolean
  };

export var displayColumns = [
  'name',
  'code'
]
