import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SpeakerService } from '../../speaker.service';
import { Jobchecklist, displayColumns,displayHeaders, filterParameters } from './jobchecklist.interface';
import { CommonApiService } from "../../common-api.service";
import { DynamicFilterComponent } from '../dynamic-filter/dynamic-filter.component';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EventEmitterService } from '../../event-emitter.service';
import { environment } from "../../../../environments/environment";


@Component({
  selector: 'app-jobchecklist',
  templateUrl: './jobchecklist.component.html',
  styleUrls: ['./jobchecklist.component.css']
})
export class JobchecklistComponent implements OnInit {

    displayColumns = displayColumns;
    displayHeaders = displayHeaders;
    jobchecklist : Jobchecklist[] =[];
    filterFields: any[] =[]
    filterCriteria: any[] =[] 

    @ViewChild('dynamicFilterContainer', { read: ViewContainerRef, static: true })
	dynamicFilterContainer!: ViewContainerRef;

    constructor(
        public speaker: SpeakerService,
        private api: CommonApiService,
        private eventEmitterService: EventEmitterService,
        private router: Router,
		private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        //resetting event emmitter variables onload of component
        this.eventEmitterService.pageSize = environment.defaultPageSizeOnLoad
        this.eventEmitterService.pageIndex = environment.defaultPageIndexOnLoad 

        this.getListDetails();
        this.speaker.model = 'jobchecklist';
        this.speaker.displayColumns = this.displayColumns;
        this.speaker.displayHeaders = this.displayHeaders;
        this.speaker.listData = this.jobchecklist;

        //function gets called by default when the component is loaded
        this.eventEmitterService.subsVar = this.eventEmitterService.    
        invokeFirstComponentFunction.subscribe(() => {  
            this.getListDetails();   
        });

    }

    ngOnDestroy(): void {
        this.eventEmitterService.subsVar.unsubscribe(); 
    }

    getListDetails() {
        let params = {
            pageIndex: this.eventEmitterService.pageIndex ? this.eventEmitterService.pageIndex: environment.defaultPageIndexOnLoad,
            pageSize: this.eventEmitterService.pageSize ? this.eventEmitterService.pageSize: environment.defaultPageSizeOnLoad,
            filterCriteria: this.filterCriteria
        }
        
        this.api.postAll('jobchecklist/list/display', params).subscribe((res: any) => {
            this.jobchecklist = res;
            this.speaker.listData = this.jobchecklist;
            this.jobchecklist = res.items;
            this.speaker.setListData(this.jobchecklist, res.rowCount, res.pageIndex, res.pageSize);
        });
    }

    openDialogFilter() {
  
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            filterFields: filterParameters.map(field => {
                return field;
            }),
        };
        const dialogRef = this.dialog.open(DynamicFilterComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.filterCriteria = result
                this.eventEmitterService.pageIndex = environment.defaultPageIndexOnLoad
                this.eventEmitterService.pageSize = environment.defaultPageSizeOnLoad
                this.getListDetails();
            }
        });
    }

    resetFilters() {
        this.redirectTo(this.speaker.model);
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }
}
