import { ProductCategory } from "../product-category/product-category.interface";
export interface Product {
    id: number;
    sku: string;
    name: string;
    lead_time: number;
    rate: number;
    product_category: ProductCategory;
    category_id: number;
    category_name: string;
    hsn_sac_id: number;

};

export var displayColumns = [
    'name',
    'category_name',
    'lead_time',
    'rate',
]

export var displayHeaders = [
    'Name',
    'Category',
    'Lead Time ( In Days )',
    'Rate'
]

// actual database columns
export var orderByColumns = [
    'id',
    'category_name',
    'lead_time',
    'rate'
]

// for dropdown the options are set via ts file, so modify the code there
export const filterParameters = [
    { 
        name: 'created_at', 
        type: 'date_range', 
        operator: 'between', 
        label: 'Select Date Range' 
    },
    { 
        name: 'name', 
        type: 'string', 
        operator: 'like', 
        label: 'Name', 
    },
    { 
        name: 'category_id', 
        type: 'dropdown', 
        model_name:'category',
        operator: '=',
        label: 'Category' 
    },
    { 
        name: 'order_by_columns', 
        type: 'multi_select', 
        operator: 'order_by_columns',
        values: displayHeaders.map((header, index) => ({
                label: header,
                value: orderByColumns[index]
            })),
        model_name:'product',
        label: 'Select columns for order by' 
    }
];  

