import { NgModule } from '@angular/core';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { AngularMaterialModule } from '../../material.module'
import { BodyComponent } from './body.component';
import { OrderModule } from '../order/order.module';
import { BodyRoutingModule } from './body.routing.module';
import { ListContainerComponent } from './list-container/list-container.component';
import { ProductComponent } from './product/product.component';
import { ProductFormComponent } from './product/product.form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LineChartComponent } from './dashboard/line-chart/line-chart.component';
import { BarChartComponent } from './dashboard/bar-chart/bar-chart.component';
import { DoughnutChartComponent } from './dashboard/doughnut-chart/doughnut-chart.component';
import { PieChartComponent } from './dashboard/pie-chart/pie-chart.component';
import { ProcessComponent } from './process/process.component';
import { ProcessFormComponent } from './process/process.form.component';
import { StateComponent } from './state/state.component';
import { StateFormComponent } from './state/state.form.component';
import { HsnSacComponent } from './hsn_sac/hsn_sac.component';
import { HsnSacFormComponent } from './hsn_sac/hsn_sac.form.component';
import { ShipmentComponent } from './shipment/shipment.component';
import { ShipmentFormComponent } from './shipment/shipment.form.component';
import { ShadeFormComponent } from './shade/shade.form.component';
import { ShadeComponent } from './shade/shade.component';
import { JobchecklistComponent } from './jobchecklist/jobchecklist.component';
import { JobchecklistFormComponent } from './jobchecklist/jobchecklist.form.component';
import { ProductCategoryComponent } from "./product-category/product-category.component";
import { ProductCategoryFormComponent } from "./product-category/product-category.form.component";
import { DoctorComponent } from './doctor/doctor.component';
import { DoctorFormComponent } from './doctor/doctor.form.component';
import { DoctorAddressComponent } from './doctor/doctor-address.component';
import { PocComponent } from './doctor/poc.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { JobOrderComponent } from './joborder/joborder.component';
import { JobOrderFormComponent } from './joborder/joborder.form.component';
import { JobOrderLineComponent } from './joborder/order-line.component';
import { AreaComponent } from './area/area.component'
import { AreaFormComponent } from './area/area.form.component';           
import { RemarkComponent } from './remark/remark.component';
import { RemarkFormComponent } from './remark/remark.form.component';
import { JobScheduleComponent } from './job-schedule/job-schedule.component';
import { ProdJobInComponent } from './prod-job-in/prod-job-in.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { EmployeeLoginComponent } from './employee-login/employee-login.component';
import { EmployeeComponent } from './employee/employee.component';   
import { EmployeeFormComponent } from './employee/employee.form.component';
import { CompanyComponent } from './company/company.component';
import { CompanyFormComponent } from './company/company-form.component';
import { ChallanComponent } from './challan/challan.component';
import { ChallanFormComponent } from './challan/challan-form.component';   
import { ProdJobOutComponent } from './prod-job-out/prod-job-out.component';   
import { DepartmentComponent } from "./department/department.component";
import { DepartmentFormComponent } from "./department/department.form.component";
import { ReportsComponent } from './reports/reports.component';
import { ReportListContainerComponent } from './reports/report-list-container/report-list-container.component';
import { PendingJobDataComponent } from './reports/pending-job-data/pending-job-data.component';
import { UserRoleComponent } from './userrole/userrole.component';
import { UserRoleFormComponent }  from './userrole/userrole.form.component';
import { AccessControlComponent } from './access-control/access-control.component';
import { UserComponent } from './user/user.component';
import { UserFormComponent } from './user/user.form.component';
import { JobOrderLogComponent } from './reports/job-order-log/job-order-log.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MasterComponent } from './master/master.component';
import { InvoiceComponent } from './invoice/invoice.component'
import { InvoiceFormComponent } from './invoice/invoice-form.component';
import { PriceListComponent } from './price-list/price-list.component'
import { PriceListFormComponent } from './price-list/price-list.form.component'
import { MainInvoiceComponent } from './invoice/main-invoice.component';
import { MainInvoiceFormComponent } from './invoice/main-invoice-form.component';
import { AllDoctorsComponent } from './reports/all-doctors/all-doctors.component';
import { JobProductionDetailsComponent } from './reports/job-production-details/job-production-details.component';
import { DailyJobActivitySummaryComponent } from './reports/daily-job-activity-summary/daily-job-activity-summary.component';
import { TaxInvoiceComponent } from './reports/tax-invoice/tax-invoice.component';
import { DynamicFilterComponent } from './dynamic-filter/dynamic-filter.component';


  
@NgModule({
  declarations: [
    BodyComponent,
    DynamicFilterComponent,
    ListContainerComponent,
    ProductComponent,
    ProductFormComponent,
    DashboardComponent,
    LineChartComponent,
    BarChartComponent,
    DoughnutChartComponent,
    PieChartComponent,
    ProcessComponent,
    ProcessFormComponent,
    StateComponent,
    StateFormComponent,
    HsnSacComponent,
    HsnSacFormComponent,
    ShipmentComponent,
    ShipmentFormComponent,
    ShadeComponent,
    ShadeFormComponent,
    JobchecklistComponent,
    JobchecklistFormComponent,
    ProductCategoryComponent,
    ProductCategoryFormComponent,
    DoctorComponent,
    DoctorFormComponent,
    DoctorAddressComponent,
    JobOrderComponent,
    JobOrderFormComponent,
    JobOrderLineComponent,
    AreaComponent,
    AreaFormComponent,
    RemarkComponent,
    RemarkFormComponent,
    JobScheduleComponent,
    ProdJobInComponent,
    ConfirmDialogComponent,
    EmployeeLoginComponent,
    EmployeeComponent,
    EmployeeFormComponent,
    PocComponent,
    CompanyComponent,
    CompanyFormComponent,
    ChallanComponent,
    ChallanFormComponent,
    ProdJobOutComponent,
    DepartmentComponent,
    DepartmentFormComponent,
    ReportsComponent,
    ReportListContainerComponent,
    PendingJobDataComponent,
    UserRoleComponent,
    UserRoleFormComponent,
    AccessControlComponent,
    UserComponent,
    UserFormComponent,
    JobOrderLogComponent,
    MasterComponent,
    InvoiceComponent,
    InvoiceFormComponent,
    MainInvoiceComponent,
    MainInvoiceFormComponent,
    PriceListComponent,
    PriceListFormComponent,
    AllDoctorsComponent,
    JobProductionDetailsComponent,
    DailyJobActivitySummaryComponent,
    TaxInvoiceComponent
  ],
  exports: [
    BodyComponent,
  ],
  imports: [
    CommonModule,
    AngularFileUploaderModule,
    AngularMaterialModule,
    ChartsModule,
    OrderModule,
    BodyRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    DragDropModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ]
})


export class BodyModule { }
