import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { PriceList } from './price-list.interface';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { SnackbarService } from '../../snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


@Component({
    selector: 'app-price-list.form',
    templateUrl: './price-list.form.component.html',
    styleUrls: ['./price-list.form.component.css']
})
export class PriceListFormComponent implements OnInit {

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private route: ActivatedRoute,
        private api: CommonApiService,
        private router: Router,
        public dialog: MatDialog,
        private snackbar: SnackbarService,
    ) { }

    public model = 'pricelist'
    public pricelist = {} as PriceList;
    isFormDisabled = true;
    result: string;
    showTable =false;
    onSaveDisable = false;

    dataSource = new MatTableDataSource<any>();
	displayedColumns: any[] = ['name','rate'];
    listData: any[] = [];
    priceListChangedData: any[] = [];
    pr_rates: any = {};


    ngOnInit() {
        let priceListId = this.route.snapshot.params.id
        if (priceListId === 'create') {
            console.log('False')
            this.isFormDisabled = false;
            this.showTable= false;
        }
        else {
            this. getPriceList(Number(priceListId));
            this.showTable= true;
        }
    }

    onlyNumber(evt:any) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    public doFilter = (event: Event) => {
		var value = (event.target as HTMLInputElement).value;

		if (typeof value === 'string') {
			this.dataSource.filter = value.trim().toLocaleLowerCase();
		}
	}


     getPriceList(priceListId: number) {
        this.api.get(this.model, priceListId).subscribe(
            (data: any) => {
                if (data) {
                    this.pricelist = data;
                    this.listData = data.products
                    this.dataSource = new MatTableDataSource<any>(this.listData);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                }
            }
        );
    }
    
    onFocusOut(id:any,rate:any) {
        this.pr_rates[id] = rate;
    }

    save(formValid:any) {
        if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
        this.onSaveDisable = true;
        this.pricelist.pr_rates = this.pr_rates;
        this.api.createUpdate(this.model, this.pricelist).subscribe(
            (data: any) => {
                if (data) {
                    this.pricelist = data;
                    this.isFormDisabled = true;
                    this.getPriceList(this.pricelist.id);
                    this.showTable= true;
                    this.snackbar.success('Saved Successfully!');
                    this.onSaveDisable = false;
                }
            },
            error => {
                alert(error.error.message)
                this.onSaveDisable = false;
            }
        );
    }

    edit(){
        this.isFormDisabled = false;
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			console.log(this.result);
			if (this.result) {
                if(this.pricelist.id){
                    this.isFormDisabled = true;
                    this. getPriceList(this.pricelist.id);
                }else{
                    this.router.navigate([`/pricelist`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('pricelist/create');
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

}
