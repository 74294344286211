<form #docForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header row pt-4">
        <div class="col-9 form-breadcrumb">
            <h3><a [routerLink]="['/doctor']" routerLinkActive="router-link-active">Doctors</a> > {{doctor.name}} </h3>
        </div>
        <div class="col-3 form-operation">
            <button *ngIf="!isFormDisabled" [disabled]="onSaveDisable" class="m-2" type="submit" mat-raised-button color="primary" (click)='save(docForm.valid)'>Save</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="primary" (click)='create()'>Create</button>
            <button *ngIf="!isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>
    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="doctor_name" class="col-sm-2 col-form-label">Doctor Name <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #name="ngModel" type="text" matInput name="doctor_name" [(ngModel)]="doctor.name" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="name.touched && name.invalid">Required</div>
                </div>

                <label for="clinic_name" class="col-sm-2 col-form-label">Clinic Name </label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="clinic_name" [(ngModel)]="doctor.clinic_name">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <label for="email" class="col-sm-2 col-form-label">Email</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="email" matInput name="email" [(ngModel)]="doctor.email">
                    </mat-form-field>
                </div>

                <label for="mobile" class="col-sm-2 col-form-label">Mobile Number <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #mob="ngModel" type="number" matInput name="mobile" [(ngModel)]="doctor.mobile" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="mob.touched && mob.invalid">Required</div>
                </div>
            </div>

            <div class="row">
                <label for="pricelist" class="col-sm-2 col-form-label">Pricelist</label>
                <div class="col-sm-4">
                    <!-- <mat-form-field class="MatFormFieldControl">
                        <mat-select [disabled]="isFormDisabled" [(value)]="selectedPricelist" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keyup)="onPricelistKey($event)" matInput name="pricelist">
                            <mat-option *ngFor="let pricelist of filteredPricelists" [value]="pricelist.id">{{pricelist.name}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select #pricelist_id (openedChange)="onPriceListScroll($event)" [disabled]="isFormDisabled" [(value)]="selectedPriceList" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="off" (keydown)="$event.stopPropagation()" (keyup)="onPriceListKey($event)" matInput name="price_list">
                            <mat-option *ngFor="let pricelist of allPriceLists" [value]="pricelist.id">{{pricelist.name}}</mat-option>
                        </mat-select>
    
                    </mat-form-field>
                </div>

               
            </div>

            <div class="row">
                <label for="address_1" class="col-sm-2 col-form-label">Address Line 1</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="address_1" [(ngModel)]="doctor.address_1">
                    </mat-form-field>
                </div>

                <label for="address_2" class="col-sm-2 col-form-label">Address Line 2</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="address_2" [(ngModel)]="doctor.address_2">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <label for="city_list" class="col-sm-2 col-form-label">City <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select #city_id (openedChange)="onCityScroll($event)" [disabled]="isFormDisabled" [(value)]="selectedCity" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="off" (keydown)="$event.stopPropagation()" (keyup)="onCityKey($event)" matInput name="city_list">
                            <mat-option *ngFor="let city of allCities" [value]="city.id">{{city.name}}</mat-option>
                        </mat-select>

                    </mat-form-field>
                </div>
                <label for="state_list" class="col-sm-2 col-form-label">State <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select #state_id (openedChange)="onStateScroll($event)" [disabled]="isFormDisabled" [(value)]="selectedState" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="off" (keydown)="$event.stopPropagation()" (keyup)="onStateKey($event)" matInput name="state_list">
                            <mat-option *ngFor="let state of allStates" [value]="state.id">{{state.name}}</mat-option>
                        </mat-select>

                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <label for="pincode" class="col-sm-2 col-form-label">Pincode</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="pincode" [(ngModel)]="doctor.pincode">
                    </mat-form-field>
                </div>
            </div>



            <mat-tab-group animationDuration="0ms" class="mt-5">
                <mat-tab id="addresses" label="Additional Addresses">
                    <button [disabled]="isFormDisabled" mat-raised-button (click)="openDialog(0)" color="primary" class="example-action-button m-4">
                        Add Address
                    </button>
                    <table mat-table [dataSource]="addressDataSource" #addr_table class="mat-elevation-z8">

                        <ng-container matColumnDef="address_line_1">
                            <th mat-header-cell *matHeaderCellDef> Address </th>
                            <td mat-cell *matCellDef="let element" (click)="openDialog(element)">{{element.address_line_1}} {{element.address_line_2}}</td>
                        </ng-container>

                        <ng-container matColumnDef="pincode">
                            <th mat-header-cell *matHeaderCellDef> Pincode </th>
                            <td mat-cell *matCellDef="let element" (click)="openDialog(element)"> {{element.pincode}} </td>
                        </ng-container>

                        <ng-container matColumnDef="city">
                            <th mat-header-cell *matHeaderCellDef> City </th>
                            <td mat-cell *matCellDef="let element" (click)="openDialog(element)"> {{element.area ? element.area.name : ''}} </td>
                        </ng-container>

                        <ng-container matColumnDef="state">
                            <th mat-header-cell *matHeaderCellDef> State </th>
                            <td mat-cell *matCellDef="let element" (click)="openDialog(element)"> {{element.state ? element.state.name : ''}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="addressDisplayedColumns"></tr>
                        <tr [ngClass]="{'disableOnClick':isFormDisabled}" mat-row *matRowDef="let row; columns: addressDisplayedColumns;"></tr>
                    </table>
                </mat-tab>

                <mat-tab id="point_of_contact" label="Additional Point of Contact">
                    <button [disabled]="isFormDisabled" mat-raised-button (click)="openDialogPoc(0)" color="primary" class="example-action-button m-4">
                        Add POC
                    </button>
                    <table mat-table [dataSource]="pocDataSource" #poc_table class="mat-elevation-z8">

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell *matCellDef="let element" (click)="openDialogPoc(element)">{{element.name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef> Email </th>
                            <td mat-cell *matCellDef="let element" (click)="openDialogPoc(element)"> {{element.email}} </td>
                        </ng-container>

                        <ng-container matColumnDef="mobile">
                            <th mat-header-cell *matHeaderCellDef> Mobile </th>
                            <td mat-cell *matCellDef="let element" (click)="openDialogPoc(element)"> {{element.mobile}} </td>
                        </ng-container>

                        <ng-container matColumnDef="designation">
                            <th mat-header-cell *matHeaderCellDef> Designation </th>
                            <td mat-cell *matCellDef="let element" (click)="openDialogPoc(element)"> {{element.designation}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="pocDisplayedColumns"></tr>
                        <tr [ngClass]="{'disableOnClick':isFormDisabled}" mat-row *matRowDef="let row; columns: pocDisplayedColumns;"></tr>
                    </table>
                </mat-tab>

                <mat-tab id="billing_and_taxation" label="Billing & Taxation Details">

                    <div class=" pt-5 row">
                        <label for="bill_to" class="col-sm-2 col-form-label">Bill To</label>
                        <div class="col-4">
                            <mat-form-field class="MatFormFieldControl">
                                <input type="text" matInput name="bill_to" [(ngModel)]="doctor.bill_to">
                            </mat-form-field>
                        </div>

                        <label for="pan" class="col-sm-2 col-form-label">PAN</label>
                        <div class="col-sm-4">
                            <mat-form-field class="MatFormFieldControl">
                                <input type="text" matInput name="pan" [(ngModel)]="doctor.pan">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <label for="gst_no" class="col-sm-2 col-form-label">Gst Number</label>
                        <div class="col-sm-4">
                            <mat-form-field class="MatFormFieldControl">
                                <input type="text" matInput name="gst_no" [(ngModel)]="doctor.gst_no">
                            </mat-form-field>
                        </div>

                        <label for="credit_no" class="col-sm-2 col-form-label">Credit Limit</label>
                        <div class="col-sm-4">
                            <mat-form-field class="MatFormFieldControl">
                                <input type="text" matInput name="credit_no" [(ngModel)]="doctor.credit_no">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <label for="credit_days" class="col-sm-2 col-form-label">Credit Days</label>
                        <div class="col-sm-4">
                            <mat-form-field class="MatFormFieldControl">
                                <input type="text" matInput name="credit_days" [(ngModel)]="doctor.credit_days">
                            </mat-form-field>
                        </div>

                        <label for="registration_no" class="col-sm-2 col-form-label">Registration Number</label>
                        <div class="col-sm-4">
                            <mat-form-field class="MatFormFieldControl">
                                <input type="text" matInput name="registration_no" [(ngModel)]="doctor.registration_no">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row pt-5">
                        <label for="payment_terms" class="col-sm-2 col-form-label">Payment Terms & Conditions</label>
                        <div class="col-sm-9">
                            <textarea rows="3" class="textarea-bg" matInput name="payment_terms" [(ngModel)]="doctor.payment_terms"></textarea>
                        </div>
                    </div>

                </mat-tab>
            </mat-tab-group>
        </fieldset>
    </div>
</form>