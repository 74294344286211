<form #challanForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-sm-7 form-breadcrumb">
            <h3><a [routerLink]="['/challan']" routerLinkActive="router-link-active">Challan</a> > {{challan.challan_no}} </h3>
        </div>
        <div class="col-sm-1 form-operation">
            <a *ngIf="challan.id && isFormDisabled" target="_blank" href="/api/challan/{{challan.id}}/download">
                <button type="button" class="m-2" mat-raised-button color="primary">Print</button>
            </a>
        </div>
        <div class="col-sm-4 form-operation" *ngIf="!['submitted','cancelled'].includes(challan.status)">
            <button *ngIf="isFormDisabled && challan.status == 'draft'" class="m-2" type="button" mat-raised-button color="primary" (click)="confirmDialog(challan.id)">Submit</button>
            <button *ngIf="!isFormDisabled" class="m-2" [disabled]="onSaveDisable" type="submit" mat-raised-button color="primary" (click)='save(challanForm.valid)'>Save</button>
            <button *ngIf="isFormDisabled" type="button" class="m-2" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="isFormDisabled" type="button" class="m-2" mat-raised-button color="primary" (click)='create()'>Create</button>
            <button *ngIf="!isFormDisabled" type="button" class="m-2" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>

    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="challan_number" class="col-sm-2 col-form-label">Challan No</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #challan_no="ngModel" [disabled]="true" type="text" matInput name="challan_number" [(ngModel)]="challan.challan_no">
                    </mat-form-field>
                    <div class="validation-class" *ngIf="challan_no.touched && challan_no.invalid">Required</div>
                </div>

                <label for="challan_date" class="col-sm-2 col-form-label">Challan Date <span class="span-class"> *</span></label>
                <div class="col-sm-4 dateRangePickStyle">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <input #date="ngModel" matInput [(ngModel)]="challan.challan_date" name="job_date" [matDatepicker]="challan_date" required>
                        <mat-datepicker-toggle matSuffix [for]="challan_date">
                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #challan_date></mat-datepicker>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="date.touched && date.invalid">Required</div>
                </div>
            </div>

            <div class="row">
                <label for="doctor_name" class="col-sm-2 col-form-label">Doctor Name <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select #doctor_id (openedChange)="onDoctorScroll($event)" [disabled]="isFormDisabled || disableDoctorSelection" [(value)]="selectedDoctor" (selectionChange)="onDoctorChange()" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keydown)="$event.stopPropagation()" (keyup)="onDoctorKey($event)" matInput name="doctort_list">
                            <mat-option *ngFor="let doctor of allDoctors" [value]="doctor.id">{{doctor.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <label for="job_type" class="col-sm-2 col-form-label">Job Type<span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [disabled]="isFormDisabled || disableDoctorSelection" [(value)]="selectedJobType" (selectionChange)="onDoctorChange()">
                            <input class="select-input" type="text" autocomplete="nope" matInput name="job_type">
                            <mat-option *ngFor="let jobType of allJobTypes" [value]="jobType">{{jobType | titlecase }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <div class="row">
                <label for="shipment_type" class="col-sm-2 col-form-label">Shipment Type</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [disabled]="isFormDisabled" [(value)]="selectedShips">
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keyup)="onShipKey($event)" matInput name="ship_list">
                            <mat-option *ngFor="let ship of filteredShips" [value]="ship.id">{{ship.type}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <div class="row pt-4 pb-4">
                <label for="remark" class="col-sm-2 col-form-label">Remark</label>
                <div class="col-sm-9">
                    <!-- <mat-form-field class="MatFormFieldControl"> -->
                    <textarea rows="2" class="textarea-bg" matInput name="remark" [(ngModel)]="challan.remark"></textarea>
                    <!-- </mat-form-field> -->
                </div>

            </div>
            <div class="row pb-4" *ngIf="selectedDoctor">
                <label for="doctor_name" class="col-sm-2 col-form-label">Select Job(s)<span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="example-chip-list">
                        <mat-chip-list class="matChipClasscross" #chipList aria-label="Job selection">
                            <mat-chip class="matChipClass" *ngFor="let job of selectedJobChips" [selectable]="selectable" [removable]="removable" (removed)="remove(job)">
                                {{job}}
                                <button matChipRemove *ngIf="removable">
                            <mat-icon>cancel</mat-icon>
                        </button>
                            </mat-chip>
                            <input #chipInput onkeydown="return false;" [formControl]="jobCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                            <mat-option *ngFor="let job of filteredJobs" [value]="job">
                                {{job.job_no}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>


            <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z2">

                <ng-container matColumnDef="job_no">
                    <th mat-header-cell *matHeaderCellDef>Job No.</th>
                    <td mat-cell *matCellDef="let element"> {{element.job_no}} </td>
                </ng-container>

                <ng-container matColumnDef="job_date">
                    <th mat-header-cell *matHeaderCellDef>Job Date</th>
                    <td mat-cell *matCellDef="let element"> {{this.transformDate(element.job_date)}} </td>
                </ng-container>

                <ng-container matColumnDef="patient_name">
                    <th mat-header-cell *matHeaderCellDef>Patient Name</th>
                    <td mat-cell *matCellDef="let element"> {{element.patient_name}} </td>
                </ng-container>

                <ng-container matColumnDef="product_name">
                    <th mat-header-cell *matHeaderCellDef>Product Name</th>
                    <td mat-cell *matCellDef="let element"> {{element.product_name}} </td>
                </ng-container>

                <ng-container matColumnDef="product_units">
                    <th mat-header-cell *matHeaderCellDef>Units</th>
                    <td mat-cell *matCellDef="let element"> {{element.product_units}} </td>
                </ng-container>

                <ng-container matColumnDef="product_rate">
                    <th mat-header-cell *matHeaderCellDef>Rate</th>
                    <td mat-cell *matCellDef="let element"> {{element.rate | currency :'INR':'symbol':'1.2-2'}} </td>
                </ng-container>

                <ng-container matColumnDef="amount_total">
                    <th mat-header-cell *matHeaderCellDef>Total Amount</th>
                    <td mat-cell *matCellDef="let element"> {{element.amount_total | currency :'INR':'symbol':'1.2-2'}} </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>


        </fieldset>
    </div>
</form>