import { Component, ViewChild, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { Permissions } from '../auth-guard.guard';
import { environment } from 'src/environments/environment';




@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.css']
})

export class NavComponent implements OnInit {
    @ViewChild('sidenav') public sidenav: MatSidenav;

    constructor(
        public auth: LoginService,
        public router: Router,
        public permissions: Permissions
    ) {

    }

    productToolbar: string[] = [
        '/product',
        '/process',
        '/category',
        '/jobchecklist',
        '/shipment',
        '/hsn',
        '/pricelist',
    ]

    companyToolbar: string[] = [
        '/company',
        '/employee',
        '/department',
    ]

    accessToolbar: string[] = [
        '/access-control',
        '/userrole',
        '/auth',
    ]

    doctorToolbar: string[] = [
        '/doctor',
        '/state',
        '/area'
    ]
    jobOrderToolbar: string[] = [
        '/joborder',
        '/remark'
        
    ]
    productionToolbar: string[] = [
        '/jobschedule',
        '/prodjobin',
        '/prodjobout',
        '/productionout',
        '/employeelogin/out',
        '/employeelogin/in',
    ]
    invoicingToolbar: string[] = [
        '/invoice',
        '/proforma',
        '/account',
        '/account_receipt',
        '/note',
        '/journal_voucher',
        '/knock_off',
    ]
    challanToolbar: string[] = [
        '/challan'
    ]

    isExpanded = true;
    showSubmenu: boolean = false;
    isShowing = false;
    showSubSubMenu: boolean = false;
    currentUser: any;
    uat = environment.uat


    ngOnInit() {
        this.currentUser = this.auth.currentUserValue;
    }

    mouseenter() {
        if (!this.isExpanded) {
            this.isShowing = true;
        }
    }

    mouseleave() {
        if (!this.isExpanded) {
            this.isShowing = false;
        }
    }

    logout() {
        this.auth.logout();
    }

}
