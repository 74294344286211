export interface HsnSac {
    id: number;
    hsn_sac: string;
    gst: number;
    description: string
    active: boolean
  };

export var displayColumns = [
  'hsn_sac',
  'gst'
]
export var displayHeaders = [
  'Hsn Hac',
  'Gst (%)'

]

// actual database columns
export var orderByColumns = [
  'hsn_hac'
]

// for dropdown the options are set via ts file, so modify the code there
export const filterParameters = [
  { 
      name: 'created_at', 
      type: 'date_range', 
      operator: 'between', 
      label: 'Select Date Range' 
  },
  { 
      name: 'hsn_sac', 
      type: 'string', 
      operator: 'like', 
      label: 'Hsn', 
  },
  { 
    name: 'order_by_columns', 
    type: 'multi_select', 
    operator: 'order_by_columns',
    values: displayHeaders.map((header, index) => ({
            label: header,
            value: orderByColumns[index]
        })),
    model_name:'hsn',
    label: 'Select columns for order by' 
  }
]; 
