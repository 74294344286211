import { Doctor } from "../doctor/doctor.interface";
export interface Balance {
    id:number;
    fy_code:string;
    doctor_id:number;
    doctor_name:string;
    opening_balance:number;
    closing_balance:number;
    doctor:Doctor;
}

export var displayColumns = [
    'fy_code',
    'doctor_name',
    'opening_balance',
]

export var displayHeaders = [
    'FY code',
    'Doctor',
    'Opening Balance',
]

export var orderByColumns = [
    'fy_code',
    'doctor_name',
    'opening_balance',
]

export const filterParameters =[
    { 
        name: 'created_at', 
        type: 'date_range', 
        operator: 'between', 
        label: 'Select Date Range' 
    },
    {
        name:'fy_code',
        type:'string',
        operator:'like',
        label:'FY Code'
    },
    { 
		name: 'doctor_id', 
		type: 'dropdown', 
		operator: '=',
		model_name:'doctor',
		label: 'Doctor' 
	},
    { 
        name: 'order_by_columns', 
        type: 'multi_select', 
        operator: 'order_by_columns',
        values: displayHeaders.map((header, index) => ({
                label: header,
                value: orderByColumns[index]
            })),
        model_name:'balance',
        label: 'Select columns for order by' 
      }
]
