import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from './login.service';
import { Router } from '@angular/router';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    constructor(
        private router: Router,
        private auth: LoginService
    ) { }

    public username: string;
    public password: string;

    ngOnInit(): void {
        const currentUser = this.auth.currentUserValue;
        if (currentUser) {
            this.router.navigate(['/dashboard'])
        }
    }

    login() {
        this.auth.login(this.username, this.password)
    }

}
