import { Component, OnInit } from '@angular/core';
import { SpeakerService } from '../../speaker.service';
import { Company, displayColumns, displayHeaders } from './company.interface';
import { CommonApiService } from "../../common-api.service";

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.css']
})

export class CompanyComponent implements OnInit {

    displayColumns = displayColumns;
    displayHeaders = displayHeaders;
    company: Company[] =[];

    constructor(
        public speaker: SpeakerService,
        private api: CommonApiService
    ) { }

    ngOnInit(): void {
        this.getListDetails();
        this.speaker.model = 'company';
        this.speaker.displayColumns = this.displayColumns;
        this.speaker.displayHeaders = this.displayHeaders;
        this.speaker.listData = this.company;

    }

    getListDetails() {
        this.api.getAll('company').subscribe((res: any[]) => {
            this.company = res;
            this.speaker.listData = this.company;
        });
    }
}

