<form class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-9 form-breadcrumb">
            <h3><a [routerLink]="['/userrole']" routerLinkActive="router-link-active">User Role</a> > {{userrole.name}}</h3>
        </div>
        <div class="col-3 form-operation">
            <button *ngIf="!isFormDisabled" class="m-2" type="submit" mat-raised-button color="primary" (click)='save()'>Save</button>
            <button *ngIf="isFormDisabled"  class="m-2" type="button" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="isFormDisabled"  class="m-2" type="button" mat-raised-button color="primary" (click)='create()'>Create</button>
            <button *ngIf="!isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>
    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="type" class="col-sm-2 col-form-label">Name</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input type="text" matInput name="name" [(ngModel)]="userrole.name">
                    </mat-form-field>
                </div>
            </div>
        </fieldset>    
    </div>
</form>