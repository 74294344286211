<div class="container-fluid pt-4 pl-4">
    <h3><a [routerLink]="['/reports']" routerLinkActive="router-link-active">Reports</a> > Invoice With Tax Info</h3>
</div>

<div class="pt-4 pl-4">
    <div class="row">
        <label for="description" class="col-sm-2 col-form-label">Select Date Range</label>
        <div class="col-5 dateRangePickStyle">
            <mat-form-field appearance="fill">
                <mat-label></mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input #dateRangeStart matStartDate placeholder="Start date">
                    <input #dateRangeEnd matEndDate placeholder="End date" (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <label for="city_list" class="col-sm-2 col-form-label">Select City <span class="span-class"> *</span></label>
        <div class="col-sm-4">
            <mat-form-field class="MatFormFieldControl">
                <mat-select #city_id (openedChange)="onCityScroll($event)" [(value)]="selectedCity" required>
                    <input class="select-input" placeholder="Search..." type="text" autocomplete="off" (keydown)="$event.stopPropagation()" (keyup)="onCityKey($event)" matInput name="city_list">
                    <mat-option [value]="0">All</mat-option>
                    <mat-option *ngFor="let city of allCities" [value]="city.id">{{city.name}}</mat-option>
                </mat-select>

            </mat-form-field>
        </div>
    </div><br>
    <div class="row col-12 p-4">
        <div class="col-2"></div>
        <div class="col-4 btnGenerate">
            <button type="button" mat-flat-button color="primary" (click)='generateReport()'>Generate Report</button>
        </div>
    </div>
</div>

<app-report-list-container *ngIf="speaker.listData.length > 0"></app-report-list-container>