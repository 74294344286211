<form class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-9 form-breadcrumb">
            <h3>Production Job In</h3>
        </div>
        <div class="col-3 form-operation">
            <button type="submit" mat-raised-button color="primary" (click)='confirmDialog()'>Save</button>
            <!-- <button type="button" class="mx-3" mat-raised-button color="link">Reset</button> -->
        </div>
    </div>

    <div class="base-form mat-elevation-z2 p-4">
        <div class="row">
            <label for="process_name" class="col-sm-2 col-form-label">Select Process</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <mat-select (selectionChange)="onProcessChange($event)" [(value)]="selectedProcess" name='process_name'>
                        <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keyup)="onProcessKey($event)" matInput name="process_list">
                        <mat-option *ngFor="let process of filteredProcesses" [value]="process">{{process.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row" *ngIf="processSelected">
            <label for="job_no" class="col-sm-2 col-form-label">Select / Scan Job No.</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <mat-select #job_id (selectionChange)="onJobChange($event)" [(value)]="selectedJob"  (openedChange)="onJobScroll($event)"  name='job_no'>
                        <input class="select-input" placeholder="Search..." type="text" autocomplete="off" (keydown)="$event.stopPropagation()" (keyup)="onJobKey($event)" matInput name="job_list">
                        <mat-option *ngFor="let job of allJobs" [value]="job">{{job.job_no}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>

        <!-- <div class="row" *ngIf="viewData">
            <label for="process_name" class="col-sm-2 col-form-label">Current Process</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input type="text" disabled matInput name="next_process" [(ngModel)]="selectedJob.next_process.process.name">
                </mat-form-field>
            </div>
        </div> -->
        <div class="row" *ngIf="viewData">
            <label for="product_name" class="col-sm-2 col-form-label">Product Name</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input type="text" disabled matInput name="product_name" [(ngModel)]="product_name">
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="viewData">

            <label for="doctor_name" class="col-sm-2 col-form-label">Doctor Name</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input type="text" disabled matInput name="doctor_name" [(ngModel)]="selectedJob.doctor.name">
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="viewData">
            <label for="patient_name" class="col-sm-2 col-form-label">Patient Name</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input type="text" disabled matInput name="patient_name" [(ngModel)]="selectedJob.patient_name">
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="viewData && selectedJob.id">
            <label for="document_attached" class="col-sm-2 col-form-label">Attached Document</label>
            <div style="padding-top: 15px;" class="col-sm-4">
                <a style="font-size: 12px;" target="_blank" href="/src/job_order_uploads/{{selectedJob.filename}}">{{selectedJob.filename}}</a>
            </div>
        </div>

        <br/>
        <div class="row" *ngIf="viewData">
            <label for="leave_reply" class="col-sm-2 col-form-label">Leave a comment</label>
        </div>
        <div class="row" *ngIf="viewData">
            <div class="col-6 commentDiv">
                <textarea id="comment_description" placeholder="Your Comment" rows="1" class="textarea-bg" [(ngModel)]="comment_description" matInput name="comment_description"></textarea>
                <br/>
                <hr/>
            </div>
        </div>


        <div class="row" *ngIf="viewData">
            <label for="leave_reply" class="col-form-label">Comments</label>
        </div>
        <div class="row" *ngIf="viewData">
            <div class="col-6">
                <ul class="commentUl">
                    <li *ngFor="let comments of allComments">
                        <p class="commentSpan col-sm-12"><b>{{comments.employee?.name}}</b> {{ comments.created_at | date :'medium'}}
                            <br><br>{{comments.comment_description}} </p>

                    </li>
                </ul>
            </div>
        </div>
    </div>
</form>