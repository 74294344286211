import { Component, OnInit } from '@angular/core';
import { CommonApiService } from '../../common-api.service';
import { Permissions } from '../../auth-guard.guard';
import { LoginService } from '../../login/login.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {

	public clientCount :number = 0
	public doctorCount :number = 0
	public productCount :number = 0
	public userCount :number = 0
	public employeeCount :number = 0
	public inProductionJobsCount :number = 0
	public holdJobsCount :number = 0
	public repairJobsCount :number = 0
	public repeatJobsCount :number = 0
	public visitCountCurrentMonth :number = 0
	public totalVisitsCount :number = 0
	public totalUpcomingVisitsCount :number = 0
	currentUser :any
  displayColumnsWithSelect = ['job_no','delivery_date','delivery_due']
  listData: any[] = [];
  dataSource: any = new MatTableDataSource<any>(this.listData);
  lateDeliveriesListData: any[] = [];
  lateDeliveries: any = new MatTableDataSource<any>(this.lateDeliveriesListData);
  todayDeliveriesListData: any[] = [];
  todayDeliveries: any = new MatTableDataSource<any>(this.todayDeliveriesListData);
  upcomingDeliveriesListData: any[] = [];
  upcomingDeliveries: any = new MatTableDataSource<any>(this.upcomingDeliveriesListData);
	
	
	constructor(
		private api: CommonApiService,
		public auth: LoginService,
		public permissions: Permissions
	) { }

	ngOnInit(): void {
		this.currentUser = this.auth.currentUserValue
		this.currentUser = this.currentUser.user.role_id
    console.log(this.currentUser)
		// 1 represents Super admin, dashboard count visible to super admin only
		if (this.currentUser == 2) {
			this.getDoctorsCount();
			this.getProductsCount();
			this.getEmployeesCount();
			this.getUsersCount();
			this.getInProductionCountThisMonth();
			this.getHoldCountThisMonth();
			this.getRepairedCountThisMonth();
			this.getRepeatCountThisMonth();
			this.getdashboardDeliveryStatusJobs();
		}
    // this.dataSource = new MatTableDataSource<any>([
    //   { job_no: '12121', delivery_date: '30-09-2023' },
    //   { job_no: '212122', delivery_date: '23-39-2033' },
    //   { job_no: '12121', delivery_date: '30-09-2023' },
    //   { job_no: '212122', delivery_date: '23-39-2033' },
    //   { job_no: '12121', delivery_date: '30-09-2023' },
    //   { job_no: '212122', delivery_date: '23-s39-2033' },
    //   { job_no: '12121', delivery_date: '30-09-2023' },
    //   { job_no: '212122', delivery_date: '23-39-2033' },
    //   { job_no: '12121', delivery_date: '30-09-2023' },
    //   { job_no: '212122', delivery_date: '23-39-2033' },
    //   { job_no: '12121', delivery_date: '30-09-2023' },
    //   { job_no: '212122', delivery_date: '23-s39-2033' },
    //   { job_no: '12121', delivery_date: '30-09-2023' },
    //   { job_no: '212122', delivery_date: '23-39-2033' },
    //   { job_no: '12121', delivery_date: '30-09-2023' },
    //   { job_no: '212122', delivery_date: '23-39-2033' },
    //   { job_no: '12121', delivery_date: '30-09-2023' },
    //   { job_no: '212122', delivery_date: '23-s39-2033' },
    //   // Add more data as needed
    // ]);
  
	}

	getdashboardDeliveryStatusJobs(): any {
			this.api.getAll('joborder/dashboardDeliveryStatusJobs').subscribe(
			(data: any) => {                
				if (data) {
					//console.log(data)
					data.forEach((element:any) => {
						
						if (element.status == 'late') {
							this.lateDeliveriesListData.push(element)
						} else if (element.status == 'today') {
							this.todayDeliveriesListData.push(element)
						} else if (element.status == 'upcoming') {
							this.upcomingDeliveriesListData.push(element)
						}
					});
				}
				this.lateDeliveries= this.lateDeliveriesListData
				this.todayDeliveries= this.todayDeliveriesListData
				this.upcomingDeliveries= this.upcomingDeliveriesListData
			}
		);
		console.log(this.upcomingDeliveries)
	}
	getRepeatCountThisMonth(): any {
			this.api.getAll('joborder/getRepeatCountThisMonth').subscribe(
			(data: any) => {                
				if (data) {
					this.repeatJobsCount = data
				}
			}
		);
	}
	getRepairedCountThisMonth(): any {
			this.api.getAll('joborder/getRepairedCountThisMonth').subscribe(
			(data: any) => {                
				if (data) {
					this.repairJobsCount = data
				}
			}
		);
	}
	getHoldCountThisMonth(): any {
			this.api.getAll('joborder/getHoldCountThisMonth').subscribe(
			(data: any) => {                
				if (data) {
					this.holdJobsCount = data
				}
			}
		);
	}

	getInProductionCountThisMonth(): any {
			this.api.getAll('joborder/getInProductionCountThisMonth').subscribe(
			(data: any) => {                
				if (data) {
					this.inProductionJobsCount = data
				}
			}
		);
	}

	getDoctorsCount(): any {
			this.api.getAll('doctor/getDoctorCount').subscribe(
			(data: any) => {                
				if (data) {
					this.doctorCount = data
				}
			}
		);
	}
	getEmployeesCount(): any {
			this.api.getAll('employee/getEmployeeCount').subscribe(
			(data: any) => {                
				if (data) {
					this.employeeCount = data
				}
			}
		);
	}
	getProductsCount(): any {
			this.api.getAll('product/getProductCount').subscribe(
			(data: any) => {                
				if (data) {
					this.productCount = data
				}
			}
		);
	}
	getUsersCount(): any {
			this.api.getAll('auth/getUserCount').subscribe(
			(data: any) => {                
				if (data) {
					this.userCount = data
				}
			}
		);
	}

	// getCurrentMonthVisitsCount(): any {
	// 		this.api.getAll('visit/getVisitsCountThisMonth').subscribe(
	// 		(data: any) => {                
	// 			if (data) {
	// 				this.visitCountCurrentMonth = data
	// 			}
	// 		}
	// 	);
	// }

	// getTotalVisitsCount(): any {
	// 		this.api.getAll('visit/getTotalVisitsCount').subscribe(
	// 		(data: any) => {                
	// 			if (data) {
	// 				this.totalVisitsCount = data || 0
	// 			}
	// 		}
	// 	);
	// }

	// getUpcomingVisitsCount(): any {
	// 		this.api.getAll('visit/getUpcomingVisitsCount').subscribe(
	// 		(data: any) => {         
	// 			if (data) {
	// 				this.totalUpcomingVisitsCount = data
	// 			} 
	// 		}
	// 	);
	// }


}
