import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from './body/user/user.interface';
import { LoginService } from './login/login.service';


@Injectable({
    providedIn: 'root'
})
export class Permissions {

    public dashboardRoutes = [
        'dashboard'
    ]
    public joOrderRoutes = [
        'joborder', 'remark'
    ]
    public productionRoutes = [
        'jobschedule', 'employeelogin', 'prodjobin', 'prodjobout'
    ]
    public invoicingRoutes = [
        'invoice', 'proforma'
    ]
    public challanRoutes = [
        'challan'
    ]
    public productRoutes = [
        'product', 'process', 'category', 'jobchecklist', 'shipment', 'hsn','pricelist'
    ]
    public doctorRoutes = [
        'doctor', 'state', 'area'
    ]
    public reportRoutes = [
        'reports'
    ]
    public companyRoutes = [
        'company', 'department', 'employee'
    ]
    public accessRoutes = [
        'access-control', 'auth', 'userrole'
    ]
    public masterRoutes = [
        'master', 'product', 'process', 'category', 'jobchecklist', 'shipment', 'hsn','doctor', 'state', 'area','remark','pricelist'
    ]

    canActivate(user: User, url: string): boolean {
        if (!user || !user.role || !user.role.access) {
            return false;
        }
        if (url.startsWith('/', 0)) {
            url = url.slice(1)
        }
        var url_part = url.split('/', 1)
        if (url_part.length < 1 || (!url_part[0])) {
            return true;
        }
        if (this.dashboardRoutes.includes(url_part[0]) && user.role.access.includes('Dashboard')) {
            return true;
        }
        else if (this.joOrderRoutes.includes(url_part[0]) && user.role.access.includes('Joborders')) {
            return true;
        }
        else if (this.productionRoutes.includes(url_part[0]) && user.role.access.includes('Production')) {
            return true;
        }
        else if (this.invoicingRoutes.includes(url_part[0]) && user.role.access.includes('Invoice')) {
            return true;
        }
        else if (this.challanRoutes.includes(url_part[0]) && user.role.access.includes('Challan')) {
            return true;
        }
        else if (this.productRoutes.includes(url_part[0]) && user.role.access.includes('Products')) {
            return true;
        }
        else if (this.doctorRoutes.includes(url_part[0]) && user.role.access.includes('Doctors')) {
            return true;
        }
        else if (this.reportRoutes.includes(url_part[0]) && user.role.access.includes('Reports')) {
            return true;
        }
        else if (this.companyRoutes.includes(url_part[0]) && user.role.access.includes('Company')) {
            return true;
        }
        else if (this.accessRoutes.includes(url_part[0]) && user.role.access.includes('Access Control')) {
            return true;
        }
        else if (this.masterRoutes.includes(url_part[0]) && user.role.access.includes('Master')) {
            return true;
        }
        // alert(`Not allowed access for ${url_part[0]} for user ${user.username}`)
        return false;
    }
}

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    currentUser: any;

    constructor(
        private router: Router,
        public auth: LoginService,
        private permissions: Permissions
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        this.currentUser = this.auth.currentUserValue;
        if (this.currentUser) {
            var success = this.permissions.canActivate(this.currentUser.user, state.url);
            if (!success) {
                this.router.navigate(['/login'])
            }
            return success;
        }
        this.router.navigate(['/login'])
        return false;
    }
}
