
<div class="container-fluid pt-4 pl-4">
	<div class="row align-items-center">
		<div class="col-10">
			<h3>Change Password</h3>
		</div>
	</div>
</div>
<form #changePasswordForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">

    <div class="row pt-4">
        <label for="mobile" class="col-sm-3 col-form-label">Current Password<span class="span-class"> *</span></label>
        <div class="col-sm-4">
            <mat-form-field appearance="fill">
                <input matInput type="password" name="currentPassword" [(ngModel)]="changePassword.currentPassword" required>
            </mat-form-field>
        </div>
    </div>
    <div class="row pt-4">
        <label for="address_1" class="col-sm-3 col-form-label">New Password<span class="span-class"> *</span></label>
        <div class="col-sm-4">
            <mat-form-field appearance="fill">
                <input matInput type="password" name="newPassword" [(ngModel)]="changePassword.newPassword" required>
            </mat-form-field>
        </div>
    </div>
    <div class="row pt-4">
        <label for="address_1" class="col-sm-3 col-form-label">Confirm Password<span class="span-class"> *</span></label>
        <div class="col-sm-4">
            <mat-form-field appearance="fill">
                <input matInput type="password" name="confirmPassword" [(ngModel)]="changePassword.confirmPassword" required>
            </mat-form-field>
        </div>
    </div>
    <div class="row pt-4">
        <div class="col-sm-3">
        </div>
        <div class="col-sm-4">
            
             <button type="submit" mat-raised-button color="primary" (click)='save(changePasswordForm.valid)'>Save</button>
        </div>
    </div>
</form>
  
