import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { JobOrder , schedulefilterParameters} from '../joborder/joborder.interface';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DatePipe } from '@angular/common';
import {PageEvent} from '@angular/material/paginator';
import { environment } from "../../../../environments/environment";
import { DynamicFilterComponent } from '../dynamic-filter/dynamic-filter.component';
import { RenderFilterDropdownService } from '../../render-filter-dropdown.service';


@Component({
	selector: 'app-job-schedule',
	templateUrl: './job-schedule.component.html',
	styleUrls: ['./job-schedule.component.css']
})

export class JobScheduleComponent implements OnInit {

	length = 0;
	pageSize = 10;
	pageIndex = 0;
	pageSizeOptions = [5, 10, 25, 50];
	showFirstLastButtons = true;

	result: string;
	jobOrder: JobOrder[];
	listData: any[] = [];
	displayColumns = [ 'job_type','procedure','job_no','job_date','doctor_name','category_name', 'delivery_date', 'status'];
	selection = new SelectionModel<any>(true, []);
	displayColumnsWithSelect = ['action']
	IsWait = false;
	row: any[];
	filterFields: any[] =[]
    filterCriteria: any[] =[]
    doctorOptions:any[] = [];
    categoryOptions:any[] = [];

    @ViewChild('dynamicFilterContainer', { read: ViewContainerRef, static: true })
	dynamicFilterContainer!: ViewContainerRef;

	dataSource: any = new MatTableDataSource<any>(this.listData);

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		private api: CommonApiService,
		private router: Router,
		public dialog: MatDialog,
		private datePipe: DatePipe,
        private renderFilterDropdown: RenderFilterDropdownService
	) { }
	public route = 'joborder/jobschedule'


	ngOnInit(): void {
		this.api.refreshNeeded.subscribe(() => {
			this.getDraftJobs();
		});
		this.getDraftJobs();
		this.displayColumnsWithSelect = this.displayColumnsWithSelect.concat(this.displayColumns)
		this.onDropdownRenderOptions('doctor/list/display', this.doctorOptions);
		this.onDropdownRenderOptions('category/list/display', this.categoryOptions);
	}
	
	// dynamic func for dropdown loading in filters
    onDropdownRenderOptions(apiEndpoint: string, targetOptions: any[]) {
        this.renderFilterDropdown.onDropdownLoadOptions(apiEndpoint, targetOptions);
    }

	handlePageEvent(event: PageEvent) {
		this.length = event.length;
		this.pageSize = event.pageSize;
		this.pageIndex = event.pageIndex;
		this.getDraftJobs();
	}

	public doFilter = (event: Event) => {
		var value = (event.target as HTMLInputElement).value;

		if (typeof value === 'string') {
			this.dataSource.filter = value.trim().toLocaleLowerCase();
		}
	}


	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.filteredData.length;
		return numSelected === numRows;
	}
	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource.filteredData.forEach((row: any) => this.selection.select(row));
	}

	rowClicked(objId: number) {
		this.router.navigate([`/${this.route}`, objId])
	}

	create() {
		this.router.navigate([`/${this.route}`, 'create'])
	}

	confirmDialog(job_id: number): void {
		const message = `Are you sure you want to schedule this job for production?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Confirm Job Schedule",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
				this.scheduleJobOrder(job_id);
			}
		});
	}

	scheduleJobOrder(job_id: number): any {
		this.api.callRoute('joborder', `/schedule/${job_id}`, null).subscribe(
			(resp) => {
                if (resp.status != 'error') {
                    alert('Scheduled Successfully!');
                    this.redirectTo('jobschedule');
                }
                else {
                    alert(resp.message)
                }
            },
            error => {
                console.log(error)
            }
		);
	}

	getDraftJobs() {

		let params = {
            'pageIndex': this.pageIndex,
			'pageSize': this.pageSize,
            filterCriteria: this.filterCriteria
        }

		this.api.postAll(this.route, params).subscribe((res: any) => {
			this.listData = res.items
			this.listData.forEach((element) => {
                element.job_date = this.transformDate(element.job_date)
                element.delivery_date = this.transformDate(element.delivery_date)
                element.doctor_name = element.doctor.name;
                element.category_name = element.category.name;
            });
			console.log(this.listData)
			this.length = res.rowCount
			this.pageIndex = res.pageIndex
			this.pageSize = res.pageSize
			this.pageSizeOptions = this.pageSizeOptions
			
			this.dataSource = new MatTableDataSource<any>(this.listData);
			//this.dataSource.paginator = this.paginator;
			//this.dataSource.sort = this.sort;

		});
	}

	transformDate(date: any) {
        date = new Date(date);
        var converteddate: any = this.datePipe.transform(date, 'dd-MM-yyyy');
        return converteddate;
    }

	redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

	 // set options for dropdown filters
	 getOptionArray<T>(modelName: any): T[] {
        const ModelOptionsMap: Record<string, T[]> = {
            doctor: this.doctorOptions,
            category: this.categoryOptions,
            // client: this.clientOptions,
            // visittype: this.visittypeOptions
            // Add other model names and their corresponding options arrays here
        };
        
        const optionKey = ModelOptionsMap[modelName];
        return optionKey;
    }

    openDialogFilter() {
  
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            filterFields: schedulefilterParameters.map(field => {
                if (field.type == 'dropdown') {
                    return {
                    ...field,
                    options: this.getOptionArray<typeof field.model_name>(field.model_name),
                    };
                }
                return field;
            }),
        };
        const dialogRef = this.dialog.open(DynamicFilterComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.filterCriteria = result
                this.pageIndex = environment.defaultPageIndexOnLoad
                this.pageSize = environment.defaultPageSizeOnLoad
                this.getDraftJobs();
            }
        });
    }

    resetFilters() {
        this.redirectTo('jobschedule');
    }

	
	
}

