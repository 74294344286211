import { Department } from "../department/department.interface"

export interface Employee {
    id: number;
    name: string;
    user_name: string;
    password: string;
    category: string;
    department_id: number;
    active: boolean
    department: Department;
    department_name: string;
  };

export var displayColumns = [
  'name',
  'user_name',
  'category',
  'department_name'
]

export var displayHeaders = [
  'Name',
  'User Name',
  'Category',
  'Department Name'
]


// actual database columns
export var orderByColumns = [
  'name',
  'user_name',
  'category',
  'department_name'
]

// for dropdown the options are set via ts file, so modify the code there
export const filterParameters = [
  { 
      name: 'created_at', 
      type: 'date_range', 
      operator: 'between', 
      label: 'Select Date Range' 
  },
  { 
      name: 'name', 
      type: 'string', 
      operator: 'like', 
      label: 'Name', 
  },
  { 
      name: 'user_name', 
      type: 'string', 
      operator: 'like', 
      label: 'User name', 
  },
  { 
      name: 'department_id', 
      type: 'dropdown', 
      model_name:'department',
      operator: '=',
      label: 'Department' 
  },
  { 
      name: 'category', 
      type: 'radio_option', 
      operator: '=',
      values: [
                  { label: 'Production', value: "1" },
                  { label: 'Sales', value: "2" },
                  { label: 'Other', value: "3" }
              ],
      model_name:'client',
      label: 'Category' 
  },
  { 
      name: 'order_by_columns', 
      type: 'multi_select', 
      operator: 'order_by_columns',
      values: displayHeaders.map((header, index) => ({
              label: header,
              value: orderByColumns[index]
          })),
      model_name:'client',
      label: 'Select columns for order by' 
  }
];  


