import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { environment } from "../../environments/environment"
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from './login/login.service';


@Injectable({
    providedIn: 'root'
})
export class CommonApiService {

    headers = new HttpHeaders({
        'Content-Type': 'application/json',

    });

    constructor(
        private http: HttpClient,
        private cookie: CookieService,
        public auth: LoginService,
    ) { }

    public _refreshNeeded$ = new Subject<void>();

    get refreshNeeded() {
        this._refreshNeeded$.observers.pop() 
        return this._refreshNeeded$;
    }

    getAll(model: string, params = {} ): Observable<any[]> {
        return this.http.get<any[]>(`${environment.api}${model}/`, { headers: this.getHeaders(), params: params });
    }

    postAll(model: string, data:any = {}): Observable<any[]> {
        return this.http.post<any[]>(`${environment.api}${model}/`, data, { headers: this.getHeaders() });
    }

    get(model: string, id: number): Observable<any[]> {
        return this.http.get<any[]>(`${environment.api}${model}/${id}`, { headers: this.getHeaders() });
    }

    createUpdate(model: string, payload: any): Observable<any[]> {
        return this.http.post<any[]>(`${environment.api}${model}/update`, payload, { headers: this.getHeaders() }).
            pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                }),
            );
        ;
    }

    callRoute(model: string, route: string, payload: any) {
        return this.http.post<any>(`${environment.api}${model}${route}`, payload, { headers: this.getHeaders() }).
            pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                }
                )
            )
    }

    callRouteGet(model: string, route: string) {
        return this.http.get<any>(`${environment.api}${model}${route}`, { headers: this.getHeaders() }).
            pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                }
                )
            )
    }

    refresh(model: string, id: number): Observable<any[]> {
        return this.http.get<any[]>(`${environment.api}${model}/${id}`, { headers: this.getHeaders() }).
            pipe(
                tap(() => {
                    this._refreshNeeded$.next();
                    console.log(this._refreshNeeded$)

                }),
            );
        ;
    }

    getHeaders() {
        if (!this.cookie.get('session-key')) {
            this.auth.logout();
        }
        return new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: this.cookie.get('session-key')
        });
    }
}
