<div class="container-fluid p-4">
    <h3>Accounting</h3>
</div>
<br>
<div class="col-sm-6">
    <div class="pb-4">
        <button routerLink="/account" mat-stroked-button color="primary">Account</button>
    </div>
    <div class="pb-4">
        <button routerLink="/account_receipt" mat-stroked-button color="primary">Account Receipt</button>
    </div>
    <div class="pb-4">
        <button routerLink="/note" mat-stroked-button color="primary">Credit/Debit Note</button>
    </div>
    <div class="pb-4">
        <button routerLink="/knock_off" mat-stroked-button color="primary">Knock Off</button>
    </div>
    <div class="pb-4">
        <button routerLink="/sales_return" mat-stroked-button color="primary">Sales Return</button>
    </div>

</div>
<div class="col-sm-6">
    <div class="pb-4">
        <button routerLink="/proforma" mat-stroked-button color="primary">Proforma</button>
    </div>
    <div class="pb-4">
        <button routerLink="/invoice" mat-stroked-button color="primary">Invoice</button>
    </div>
    <div class="pb-4">
        <button routerLink="/journal_voucher" mat-stroked-button color="primary">Journal Voucher</button>
    </div>
    <div class="pb-4">
        <button routerLink="/balance" mat-stroked-button color="primary">Opening Balance</button>
    </div>
</div>