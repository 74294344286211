import { Component, OnInit, ViewChild } from '@angular/core'; import { ActivatedRoute, Router } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '../../snackbar.service';
import { CommonDropdownService } from '../../common-dropdown.service';
import { MatSelect } from '@angular/material/select';
import { environment } from 'src/environments/environment';
import { tap, filter, finalize } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { knockOffdisplayColumns, knockOffdisplayHeaders } from './knock-off.interface';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';


@Component({
    selector: 'app-knock-off',
    templateUrl: './knock-off.component.html',
    styleUrls: ['./knock-off.component.css']
})
export class KnockOffComponent implements OnInit {

    @ViewChild('against_object_id', { static: false }) against_object_element: MatSelect;


    constructor(
        private route: ActivatedRoute,
        private api: CommonApiService,
        private router: Router,
        public dialog: MatDialog,
        private snackbar: SnackbarService,
        private dropdownService: CommonDropdownService,
    ) { }

    selectedAgainstModel: string;
    public againstMapping = [
        {
            'label': 'Account Receipt',
            'value': 'account_receipt'
        },
        {
            'label': 'Credit/Debit Note',
            'value': 'note'
        },
        {
            'label': 'Journal Voucher',
            'value': 'journal_voucher'
        },

    ]

    public labels: any = {
        'account_receipt': 'Account Receipt',
        'note': 'Credit/Debit Note',
        'journal_voucher': 'Journal Voucher',
    }

    result: string;

    againstSearchPage = 0;
    againstObjectCount = 0
    public allAgainstObjects: any[] = [];
    public selectedAgainstObject: any[] = [];
    public againstObject: any;

    disableAgainstObjectSelection: boolean;

    knockoffsDataSource: any = []
    displayHeaders = knockOffdisplayHeaders
    displayColumns = knockOffdisplayColumns
    displayColumnsWithSelect = ['select']
    selection = new SelectionModel<any>(true, []);
    knockOffSelected: any[] = [];


    ngOnInit(): void {
        this.displayColumnsWithSelect = this.displayColumnsWithSelect.concat(this.displayColumns);
    }

    confirmDialog() {
        const message = `Are you sure you want to submit the knock off?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Submit Knock Off",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
				this.submitKnockOffs();
			}
		});

    }

    submitKnockOffs(){
        if(this.againstObject == undefined){
            alert("Please select an object for knock off")
            return;
        }

        this.againstObject.knock_offs = this.knockOffSelected
        var payload = {
            'against_object': this.againstObject,
            'against_model': this.selectedAgainstModel
        }

        this.api.postAll('account_knock_off/create', payload).subscribe(
            (data: any) => {
                if (data) {
                    this.snackbar.success("Knock Off created successfully");
                    this.againstObject = data
                    switch (this.selectedAgainstModel) {
                        case 'account_receipt':
                            this.getKnockOffsList(this.againstObject.receiving_account.id, this.againstObject.company_account.id, 'account_receipt')
                            break;
                        case 'note':
                            this.getKnockOffsList(this.againstObject.doctor_id, this.againstObject.account_id, this.againstObject.note_type)
                            break;
                        case 'journal_voucher':
                            var payload = {
                                "credit_account_internal_id": this.againstObject.credit_account_internal_id,
                                "credit_account_doctor_id": this.againstObject.credit_account_doctor_id,
                                "debit_account_internal_id": this.againstObject.debit_account_internal_id,
                                "debit_account_doctor_id": this.againstObject.debit_account_doctor_id,
                                "company_id": this.againstObject.company_id
                            }
                            this.getKnockOffsListJV(payload)
                            break;
                    }
                }
                this.api.refreshNeeded.subscribe(() => {
                });
            },
            error => {
                alert(error.error.message)
            }
        );
    }

    onAgainstModelChange() {
        // Clear search results
        this.againstObject = undefined
        this.allAgainstObjects = [];
        this.selectedAgainstObject = [];
        this.againstSearchPage = 0;
        this.knockoffsDataSource = [];
        this.getAllAgainstObjects();

    }

    getAllAgainstObjects() {
        let params = {
            'pageIndex': this.againstSearchPage,
            'pageSize': environment.defaultPageSizeOnScroll,
            'filterCriteria': {
                'status': ['radio_option', 'submitted'],
                "order_by_columns": [
                    "multi_select",
                    null
                ],
                "order_by": "asc"
            },
            'use_default_created_at_filter': false
        }

        this.api.postAll(`${this.selectedAgainstModel}/list/display`, params).pipe(
            tap((res: any) => {
                this.allAgainstObjects.push(...res.items);
                this.againstObjectCount = res.rowCount
            }),
            filter(() => this.selectedAgainstObject.length !== 0),
            tap(() => {
                const selectedAgainstId = this.selectedAgainstObject[0].id;
                this.allAgainstObjects = this.allAgainstObjects.filter(obj => obj.id !== selectedAgainstId);
                this.allAgainstObjects.unshift(this.selectedAgainstObject[0]);

            }),
            finalize(() => this.againstSearchPage++)
        ).subscribe();
    }

    getKnockOffsListJV(payload: any){
        this.api.postAll(`account_knock_off/list/${this.selectedAgainstModel}`, payload).subscribe(
            (data: any) => {
                if (data) {
                    this.knockoffsDataSource = data
                    this.selection.clear();

                    if (this.againstObject.knocked_off != undefined && this.againstObject.knocked_off.length > 0) {
                        this.againstObject.knocked_off.forEach((knock_off_element: any) => {
                            // Push the default selected items to knockOffSelected array
                            this.knockOffSelected.push({
                                "id": knock_off_element.for_object.id,
                                "type": knock_off_element.for_object.type
                            });
                            // Select the default items in MatTable selection

                            const exists = this.knockoffsDataSource.find((row: any) => row.id === knock_off_element.for_object.id && row.type === knock_off_element.for_object.type);
                            if (!exists) {
                                this.knockoffsDataSource = this.knockoffsDataSource.concat({
                                    "id": knock_off_element.for_object.id,
                                    "type": knock_off_element.for_object.type,
                                    "name": knock_off_element.for_object.name,
                                    "date": knock_off_element.for_object.date,
                                    "amount": knock_off_element.for_object.amount,
                                    "balance": knock_off_element.for_object.balance
                                });
                            }


                            const selectedRow = this.knockoffsDataSource.find((row: any) => row.id === knock_off_element.for_object.id && row.type === knock_off_element.for_object.type);
                            if (selectedRow) {
                                this.selection.select(selectedRow);
                            }

                        });
                    }
                }
            }
        );
    }

    getKnockOffsList(doctorId: number, accountId: number, type?: string) {
        this.api.getAll(`account_knock_off/list/${this.selectedAgainstModel}`, { "doctor_id": doctorId, "account_id": accountId, "type": type }).subscribe(
            (data: any) => {
                if (data) {
                    this.knockoffsDataSource = data
                    this.selection.clear();

                    if (this.againstObject.knocked_off != undefined && this.againstObject.knocked_off.length > 0) {
                        this.againstObject.knocked_off.forEach((knock_off_element: any) => {
                            // Push the default selected items to knockOffSelected array
                            this.knockOffSelected.push({
                                "id": knock_off_element.for_object.id,
                                "type": knock_off_element.for_object.type
                            });
                            // Select the default items in MatTable selection

                            const exists = this.knockoffsDataSource.find((row: any) => row.id === knock_off_element.for_object.id && row.type === knock_off_element.for_object.type);
                            if (!exists) {
                                this.knockoffsDataSource = this.knockoffsDataSource.concat({
                                    "id": knock_off_element.for_object.id,
                                    "type": knock_off_element.for_object.type,
                                    "name": knock_off_element.for_object.name,
                                    "date": knock_off_element.for_object.date,
                                    "amount": knock_off_element.for_object.amount,
                                    "balance": knock_off_element.for_object.balance
                                });
                            }


                            const selectedRow = this.knockoffsDataSource.find((row: any) => row.id === knock_off_element.for_object.id && row.type === knock_off_element.for_object.type);
                            if (selectedRow) {
                                this.selection.select(selectedRow);
                            }

                        });
                    }
                }
            }
        );
    }



    onAgainstObjectScroll(event: any) {

        if (!this.against_object_element?.panel || !this.against_object_element?.panel.nativeElement) {
            // Panel or nativeElement is not available, return or handle the situation accordingly.
            return;
        }
        if (event) {
            this.against_object_element.panel.nativeElement.addEventListener(
                'scroll',
                (event: any) => {
                    const tolerance = 10
                    if (
                        Math.floor(this.against_object_element.panel.nativeElement.scrollTop) + tolerance >=
                        Math.floor(this.against_object_element.panel.nativeElement.scrollHeight) -
                        Math.floor(this.against_object_element.panel.nativeElement.offsetHeight)
                    ) {
                        let params = {
                            'pageIndex': this.againstSearchPage,
                            'pageSize': environment.defaultPageSizeOnScroll
                        }

                        if (this.allAgainstObjects.length >= this.againstObjectCount) {
                            return;
                        }
                        this.api.postAll(`${this.selectedAgainstModel}/list/display`, params).subscribe((res: any) => {
                            res.items = res.items.filter((entity: any) => entity.id !== this.selectedAgainstObject[0]?.id);
                            this.allAgainstObjects.push(...res.items);
                        });
                        this.againstSearchPage++;// send page number for fetching the records
                    }
                });
        }
    }

    onAgainstObjectKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, this.selectedAgainstModel, this.allAgainstObjects, this.selectedAgainstObject, 'againstSearchPage')
            .subscribe((result: { entityList: any[], pageVariable: number }) => {
                this.allAgainstObjects = result.entityList;
                this.againstSearchPage = result.pageVariable;
            });
    }

    onAgainstObjectChange() {
        this.againstObject = this.allAgainstObjects.find((entity: any) => entity.id === this.selectedAgainstObject);
        if (!this.againstObject) {
            return;
        }
        switch (this.selectedAgainstModel) {
            case 'account_receipt':
                this.getKnockOffsList(this.againstObject.receiving_account.id, this.againstObject.company_account.id, 'account_receipt')
                break;
            case 'note':
                this.getKnockOffsList(this.againstObject.doctor_id, this.againstObject.account_id, this.againstObject.note_type)
                break;
            case 'journal_voucher':
                var payload = {
                    "credit_account_internal_id": this.againstObject.credit_account_internal_id,
                    "credit_account_doctor_id": this.againstObject.credit_account_doctor_id,
                    "debit_account_internal_id": this.againstObject.debit_account_internal_id,
                    "debit_account_doctor_id": this.againstObject.debit_account_doctor_id,
                    "company_id": this.againstObject.company_id
                }
                this.getKnockOffsListJV(payload)
                break;
        }
    }


    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.knockoffsDataSource.filteredData.length;
        return numSelected === numRows;
    }

    toggleSelection(row: any) {
        this.selection.toggle(row);
        if (this.selection.isSelected(row)) {
            this.knockOffSelected.push({
                'id': row.id,
                'type': row.type
            });
        } else {

            var index = this.knockOffSelected.findIndex((item: any) => item.id === row.id && item.type === row.type);
            while (index > -1) {
                var index = this.knockOffSelected.findIndex((item: any) => item.id === row.id && item.type === row.type);
                if (index > -1) {
                    this.knockOffSelected.splice(index, 1); // Remove ID if it's deselected                            
                }
            }
        }
    }

}

