import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonApiService } from '../../common-api.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { JobOrder } from '../joborder/joborder.interface';
import { SnackbarService } from '../../snackbar.service';

@Component({
    selector: 'app-prod-job-out',
    templateUrl: './prod-job-out.component.html',
    styleUrls: ['./prod-job-out.component.css']
})
export class ProdJobOutComponent implements OnInit, OnDestroy {

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private api: CommonApiService,
        private snackbar: SnackbarService
    ) { }

    result: string = '';
    passwordLength: any;
    public allJobs: any[] = [];
    public filteredJobs = this.allJobs;
    public selectedJob: JobOrder;
    public allReworkProcesses: any[] = [];
    public filteredReworkProcesses = this.allReworkProcesses;
    public selectedReworkProcess: number;
    viewData: boolean;
    public employee_id: number;
    viewReworkProcess :boolean;
    sentToRework :boolean;
    public allComments: any[] = [];
    public comment_description : string;
    public product_name: string;
    public jobHold: boolean = false
    public isNextProcess: boolean = false

    ngOnInit(): void {
        this.employee_id = Number(localStorage.getItem('employee_id'));
        if (!this.employee_id) {
            alert('Employee session expired');
            this.router.navigate(['/employeelogin/out']);
        }
        const employee_data = {
            'employee_id': this.employee_id
        }
        this.api.callRoute('joborder','/list/out', employee_data).subscribe(res => {
            this.allJobs = res;
            this.filteredJobs = res;
        })
        this.viewData = false;
    }

    onChangeToggle(elementData:any,event:any){
		
		var value = event.checked; //true or false
		if(value == true){
            this.sentToRework = true;
            this.viewReworkProcess = true;
		} else {
            this.viewReworkProcess = false;
            this.sentToRework = false;

		}
	}

    onReworkProcessKey = (event: Event) => {
        var value = (event.target as HTMLInputElement).value;
        this.filteredReworkProcesses = this.searchReworkProcesses(value);
    }


    searchReworkProcesses(value: string) {
        let filter = value.toLowerCase();
        return this.allReworkProcesses.filter(option =>
            option.process.name.toLowerCase().includes(filter)
        );
    }

    onJobKey = (event: Event) => {
        var value = (event.target as HTMLInputElement).value;
        this.filteredJobs = this.searchJobs(value);
    }

    onJobChange(event:any) {
        this.viewData = true;
        const employee_data = {
            'employee_id': this.employee_id,
            'job_id': this.selectedJob.id
        }
        
        if (this.selectedJob.next_process) {
            this.isNextProcess = true
        }

        this.api.callRoute('joborder','/list/rework', employee_data).subscribe(res => {
            this.allReworkProcesses = res;
            this.filteredReworkProcesses = res;
        })

        var value = event.value.id;
        const data = {
           'job_id': value
        }
        var product_names: any = []
        this.selectedJob.products.forEach((p: any) => {
            product_names.push(p.name)
        });
        this.product_name = product_names.join(", ");
        this.api.callRoute('joborder', '/comments', data).subscribe(
            (data: any) => {
                if (data) {
                    this.allComments = data;
                }
            },
            error => {
                alert(error.message)
            }
        );
    }

    searchJobs(value: string) {
        let filter = value.toLowerCase();
        return this.allJobs.filter(option =>
            option.job_no.toLowerCase().includes(filter)
        );
    }

    confirmDialog(): void {

        if(this.selectedJob == undefined){
            alert("Please select job.");
            return;
        }

        var outOrRework ="to outward";
        if(this.sentToRework == true){
            var outOrRework ="to rework";
        }
        
        const message = 'Are you sure you want ' +outOrRework+' this job?';
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            title: "Confirm job out",
            message: message
        }
        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(dialogResult => {
            this.result = dialogResult;
            if (this.result) {
                if (this.sentToRework == true) {
                    this.sendToRework();
                }else{
                    this.createJobOut();
                }
                
                
            }
        });
    }

    saveAndHoldJob(): void {

        if(this.selectedJob == undefined){
            alert("Please select job.");
            return;
        }
        
        const message = 'Are you sure you want save and hold this job?';
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            title: "Confirm save and hold job",
            message: message
        }
        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(dialogResult => {
            this.result = dialogResult;
            if (this.result) {
                this.jobHold = true
                this.createJobOut();
                
            }
        });
    }

    keyPress(event: any) {
        this.passwordLength = event.target.value.length;
        if (this.passwordLength >= 0) {

        }
    }

    createJobOut() {

        const data = {
            'employee_id': this.employee_id,
            'job_id': this.selectedJob.id,
            'comment_description': this.comment_description,
            'process_id': this.selectedJob.current_process.process_id,
            'hold': this.jobHold
        }

        this.api.callRoute('joborder', '/job/out', data).subscribe(
            (data: any) => {
                if (data) {
                    // alert(data.message)
                    if (data.status == 'success') {
                        alert("Successfully processed job outward");
                        this.router.navigate(['/employeelogin/out']);
                    }
                    else if (data.status == 'error') {
                        alert(data.message)
                    }
                }
            },
            error => {
                alert(error.message)
            }

        );
        this.jobHold = false
    }

    sendToRework() {
        if (!this.selectedJob.id) {
            alert('Please select job to rework');
            return;
        }
        if (!this.selectedReworkProcess) {
            alert('Please select process to rework');
            return;
        }
        else {
            const data = {
                'employee_id': this.employee_id,
                'job_id': this.selectedJob.id,
                'process_id': this.selectedJob.current_process.process.id,
                'rework_process_id': this.selectedReworkProcess,
                'prod_type': 'rework',

            }
            this.api.callRoute('joborder', `/rework/${this.selectedJob.id}`, data).subscribe(
                (data: any) => {
                    if (data) {
                        // alert(data.message)
                        if (data.success) {
                            this.snackbar.success(data.message);
                            this.router.navigate(['/employeelogin/out']);
                        }
                        else if (!data.success) {
                            alert(data.message)
                        }
                    }
                },
                error => {
                    alert(error.message)
                }
                
            );
        }
    }    

    ngOnDestroy(): void {
        localStorage.removeItem('employee_id')
    }
}
