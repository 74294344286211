<mat-sidenav-container class="nav-container" autosize>
    <mat-sidenav #sidenav class="nav-sidenav" [ngClass]="{'expanded': isExpanded, 'showing': isShowing}" mode="side" [opened]="isExpanded" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()">
        <div *ngIf="isExpanded || isShowing" class="logo">
            <img width="100%" height="100%" src="src/assets/Groovesheader.jpg">
        </div>
        <div *ngIf="!isExpanded && !isShowing" class="logo-hidden">

        </div>
        <mat-nav-list>
            <mat-list-item *ngIf="permissions.canActivate(currentUser.user, '/dashboard')" routerLink="dashboard" (click)="showSubmenu = !showSubmenu" class="parent">
                <span class="full-width" *ngIf="isExpanded || isShowing">Dashboard</span>
                <mat-icon mat-list-icon>dashboard</mat-icon>
            </mat-list-item>
            <mat-list-item *ngIf="permissions.canActivate(currentUser.user, '/joborder')" routerLink="joborder" (click)="showSubmenu = !showSubmenu" class="parent">
                <span class="full-width" *ngIf="isExpanded || isShowing">Job Entry</span>
                <mat-icon mat-list-icon>format_list_bulleted</mat-icon>
            </mat-list-item>
            <mat-list-item *ngIf="permissions.canActivate(currentUser.user, '/jobschedule')" routerLink="jobschedule" (click)="showSubmenu = !showSubmenu" class="parent">
                <span class="full-width" *ngIf="isExpanded || isShowing">Production</span>
                <mat-icon mat-list-icon>format_list_bulleted</mat-icon>
            </mat-list-item>
            <mat-list-item *ngIf="permissions.canActivate(currentUser.user, '/master')" routerLink="master" (click)="showSubmenu = !showSubmenu" class="parent">
                <span class="full-width" *ngIf="isExpanded || isShowing">Masters</span>
                <mat-icon mat-list-icon>list_alt</mat-icon>
            </mat-list-item>
            <mat-list-item *ngIf="permissions.canActivate(currentUser.user, '/challan')" routerLink="challan" (click)="showSubmenu = !showSubmenu" class="parent">
                <span class="full-width" *ngIf="isExpanded || isShowing">Challan</span>
                <mat-icon mat-list-icon>format_list_bulleted</mat-icon>
            </mat-list-item>
            <mat-list-item *ngIf="permissions.canActivate(currentUser.user, '/invoice')" routerLink="invoice" (click)="showSubmenu = !showSubmenu" class="parent">
                <span class="full-width" *ngIf="isExpanded || isShowing">Invoicing</span>
                <mat-icon mat-list-icon>format_list_bulleted</mat-icon>
            </mat-list-item>
            <!-- <mat-list-item *ngIf="permissions.canActivate(currentUser.user, '/product')" routerLink="product" (click)="showSubmenu = !showSubmenu" class="parent">
                <span class="full-width" *ngIf="isExpanded || isShowing">Products</span>
                <mat-icon mat-list-icon>format_list_bulleted</mat-icon>
            </mat-list-item>
            <mat-list-item *ngIf="permissions.canActivate(currentUser.user, '/doctor')" routerLink="doctor" (click)="showSubmenu = !showSubmenu" class="parent">
                <span class="full-width" *ngIf="isExpanded || isShowing">Doctors</span>
                <mat-icon mat-list-icon>people</mat-icon>
            </mat-list-item> -->
            <mat-list-item *ngIf="permissions.canActivate(currentUser.user, '/company')" routerLink="company" (click)="showSubmenu = !showSubmenu" class="parent">
                <span class="full-width" *ngIf="isExpanded || isShowing">Company Setup</span>
                <mat-icon mat-list-icon>settings</mat-icon>
            </mat-list-item>
            <mat-list-item *ngIf="permissions.canActivate(currentUser.user, '/reports')" routerLink="reports" (click)="showSubmenu = !showSubmenu" class="parent">
                <span class="full-width" *ngIf="isExpanded || isShowing">Reports</span>
                <mat-icon mat-list-icon>description</mat-icon>
            </mat-list-item>
            <mat-list-item *ngIf="permissions.canActivate(currentUser.user, '/access-control')" routerLink="access-control" (click)="showSubmenu = !showSubmenu" class="parent">
                <span class="full-width" *ngIf="isExpanded || isShowing">Access Control</span>
                <mat-icon mat-list-icon>security</mat-icon>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>
    <div class="nav-content">
        <mat-toolbar color="" class="sticky">
            <button mat-icon-button (click)="isExpanded = !isExpanded"><mat-icon>menu</mat-icon></button>
            <div *ngIf="productToolbar.includes(router.url)">
                <button mat-button routerLink="process">Process</button>
                <button mat-button routerLink="category">Category</button>
                <button mat-button routerLink="jobchecklist">Job Check List</button>
                <button mat-button routerLink="shipment">Shipment</button>
                <button mat-button routerLink="hsn">HSN</button>
                <button mat-button routerLink="pricelist">Price List</button>
            </div>
            <div *ngIf="doctorToolbar.includes(router.url)">
                <button mat-button routerLink="state">State</button>
                <button mat-button routerLink="area">City</button>
            </div>
            <div *ngIf="jobOrderToolbar.includes(router.url)">
                <button mat-button routerLink="remark">Remark</button>
            </div>
            <div *ngIf="productionToolbar.includes(router.url)">
                <button mat-button routerLink="employeelogin/in">Job In Details</button>
                <button mat-button routerLink="employeelogin/out">Job Out Details</button>
            </div>
            <div *ngIf="invoicingToolbar.includes(router.url)">
                <button mat-button routerLink="proforma">Proforma</button>
                <button mat-button routerLink="invoice">Invoice</button>
            </div>
            <div *ngIf="challanToolbar.includes(router.url)">
                <button mat-button routerLink="challan">Challan</button>
            </div>
            <div *ngIf="companyToolbar.includes(router.url)">
                <button mat-button routerLink="employee">Employee Details</button>
                <button mat-button routerLink="department">Department</button>
            </div>
            <div *ngIf="accessToolbar.includes(router.url)">
                <button mat-button routerLink="access-control">Access Control</button>
                <button mat-button routerLink="userrole">User Roles</button>
                <button mat-button routerLink="auth">Users</button>
            </div>
            <span class="spacer"></span>
            <span class="fill-remaining-space">
                <button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" (onclick)="menuTrigger.openMenu()">
                  <mat-icon>account_circle</mat-icon>
                </button>
                <mat-menu #menu="matMenu" [overlapTrigger]="false">
                    <span>
                        <!-- <button routerLink="change-password" mat-menu-item>
                            <mat-icon>password</mat-icon>
                            <span >Change Password</span>
                        </button> -->
                        <button mat-menu-item (click)="logout()">
                            <mat-icon>exit_to_app</mat-icon>
                            <span >Log out, {{currentUser?.user?.username}}</span>
                        </button>
                    </span>
              </mat-menu>
            </span>
            
        </mat-toolbar>
        
        <app-body></app-body>
    </div>
</mat-sidenav-container>