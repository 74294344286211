import { Doctor } from "../doctor/doctor.interface";
import { Company } from "../company/company.interface";
import { InvoiceLine } from "./invoice-line.interface";

export interface Invoice {
    id: number;
    invoice_no: string;
    invoice_date: Date;
    invoice_lines: InvoiceLine[];
    type: string;
    total_amount: number;
    sgst: number;
    cgst: number;
    igst: number;
    sgst_amount: number;
    cgst_amount: number;
    igst_amount: number;
    grand_total: number;
    status: string;
    discount_amount: number;
    doctor_id: number;
    doctor: Doctor;
    doctor_name: string;
    parent_id: number;
    challan_ids: number[];
    company_id: number;
    company_name: string;
    company: Company;
};

export var displayColumns = [
    'invoice_no',
    'invoice_date',
    'doctor_name',
	'company_name',
    'grand_total',
    'status',
]
export var displayHeaders = [
    'No.',
    'Date',
    'Doctor Name',
	'Company Name',
    'Grand Total',
    'Status',
]


// actual database columns
export var orderByColumns = [
    'invoice_no',
    'invoice_date',
    'doctor_name',
	'company_name',
    'grand_total',
    'status'
]

// for dropdown the options are set via ts file, so modify the code there
export const filterParameters = [
    { 
        name: 'invoice_date', 
        type: 'date_range', 
        operator: 'between', 
        label: 'Select Date Range' 
    },
    { 
        name: 'invoice_no', 
        type: 'string', 
        operator: 'like', 
        label: 'No.', 
    },
    { 
        name: 'doctor_id', 
        type: 'dropdown', 
        model_name:'doctor',
        operator: '=',
        label: 'Doctor' 
    },
    { 
		name: 'company_id', 
		type: 'dropdown', 
		operator: '=',
		model_name:'company',
		label: 'Company' 
	},
    { 
        name: 'status', 
        type: 'radio_option', 
        operator: '=',
        values: [
                    { label: 'Draft', value: "draft" },
                    { label: 'Submitted', value: "submitted" },
                    { label: 'Cancelled', value: "cancelled" }
                ],
        model_name:'invoice',
        label: 'Status' 
    },
    { 
        name: 'order_by_columns', 
        type: 'multi_select', 
        operator: 'order_by_columns',
        values: displayHeaders.map((header, index) => ({
                label: header,
                value: orderByColumns[index]
            })),
        model_name:'invoice',
        label: 'Select columns for order by' 
    }
];  

