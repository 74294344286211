import { Component, OnInit } from '@angular/core';
import { CommonApiService } from '../../common-api.service';
import { SnackbarService } from '../../snackbar.service';
import { UserRole } from '../userrole/userrole.interface';

export interface ModelName {
  name: string;
  displayName: string;
  no: number;
}

const ELEMENT_DATA: ModelName[] = [
	
    { name: "Dashboard", no: 1, displayName: "Dashboard" },
    { name: "Joborders", no: 2, displayName: "Job Entry"  },
    { name: "Production", no:3, displayName: "Production"  },
    { name: "Challan", no:4, displayName: "Challan"  },
    { name: "Invoice", no:5, displayName: "Invoice"  },
    // { name: "Products", no:5, displayName: "Products"  },
    // { name: "Doctors", no:6, displayName: "Doctors"  },
    { name: "Reports", no:7, displayName: "Reports"  },
    { name: "Company", no:8, displayName: "Company Setup"  },
    { name: "Access Control", no:9, displayName: "Access Control"  },
    { name: "Master", no:10, displayName: "Masters"  },
    { name: "Accounting", no:11, displayName: "Accounting"  },
]

@Component({
  selector: 'app-access-control',
  templateUrl: './access-control.component.html',
  styleUrls: ['./access-control.component.css']
})

export class AccessControlComponent implements OnInit {

	constructor(
		private api: CommonApiService,
		private snackbar: SnackbarService
	) { }

	displayedColumns: string[] = ['name', 'no'];
	dataSource = ELEMENT_DATA;
	showContent= false;

	public allRoles: any[] = [];
	public allJobs: any[] = [];
	public filteredRoles = this.allRoles;
	public selectedRole: UserRole;
	modules_selected:any =[];
	// public allModules = [
	// 	"Dashboard",
	// 	"Joborders",
	// 	"Production",
	// 	"Challan & Invoice",
	// 	"Products",
	// 	"Doctors",
	// 	"Reports",
	// 	"Company",
	// 	"Access Control",
	// ]
	
	ngOnInit(): void {
		this.api.getAll('userrole').subscribe(res => {
			this.allRoles = res;
			this.filteredRoles = res;
		})
    }

	save(){
		const data = {
			'userrole_id': this.selectedRole.id,
			'modules': this.modules_selected
		}
		this.api.callRoute('userrole', '/access/update', data).subscribe(
			(data: any) => {
				if (data) {
					this.snackbar.success('User role access saved successfully')
				}
			}
		);
	}

	onChangeToggle(elementData:any,event:any){
		var value = event.checked; //true or false
		if(value == true){
			this.modules_selected.push(elementData);
		}else{
			let  k = 0;
            var len = this.modules_selected.length;
            for (k = 0; k < len; k = k + 1) {
                if (this.modules_selected[k] === elementData) {
                    this.modules_selected.splice(k, 1);
                }
            }
		}
	}

	onRoleChange(){
		this.showContent= true;
		if (this.selectedRole.access) {
			this.modules_selected = this.selectedRole.access;
		}
		else {
			this.selectedRole.access = [];
			this.modules_selected = [];
		}
	}

	onRoleKey = (event: Event) => {
        var value = (event.target as HTMLInputElement).value;
        this.filteredRoles = this.searchRoles(value);
		
    }

    searchRoles(value: string) {
        let filter = value.toLowerCase();        
        return this.allRoles.filter(option =>
            option.name.toLowerCase().includes(filter)
        );
    }
}

