import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CommonApiService } from '../../common-api.service';
import { JobOrderLine } from './joborderline.interface';


@Component({
    selector: 'app-order-line',
    templateUrl: './order-line.component.html',
    styleUrls: ['./joborder.form.component.css']
})
export class JobOrderLineComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<JobOrderLineComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private api: CommonApiService
    ) {
        this.orderLineId = data.uid;
        this.isFormDisabled = data.passFlagToDisable;
        this.jobId = data.jobId
        this.orderLine = data.orderLine;
        this.allProducts = data.allProducts;
        this.filteredProducts = data.allProducts;
    }

    public orderLine: JobOrderLine;
    public orderLineId: number;
    public jobId: number;
    public isFormDisabled: boolean;
    public allProducts: any[];
    public filteredProducts: any[];
    public selectedProduct: number;
    public productId: number;
    public model = 'orderline'
    public jobOrderLine = {} as JobOrderLine;

    left_top_tooth_selected: any = [];
    left_bottom_tooth_selected: any = [];
    right_top_tooth_selected: any = [];
    right_bottom_tooth_selected: any = [];
    stage_selected: any = [];
    pontic_design_selected: any = [];
    collar_and_metal_selected: any = [];

    ngOnInit(): void {
        this.getOrderLine(Number(this.orderLineId));
    }

    searchProducts(value: string) {
        let filter = value.toLowerCase();
        return this.allProducts.filter(option =>
            option.name.toLowerCase().includes(filter)
        );
    }

    onProductKey = (event: Event) => {
        var value = (event.target as HTMLInputElement).value;
        this.filteredProducts = this.searchProducts(value);
    }

    close() {
        this.dialogRef.close();
    }

    saveOrderLine(formValid: any) {
        if (formValid == false) {
            alert("Please fill mandatory fields.");
            return;
        }
        if (this.selectedProduct == undefined) {
            alert("Please select product name.");
            return;
        }

        if (this.selectedProduct) {
            this.api.get('product', this.selectedProduct).subscribe(
                (data: any) => {
                    this.jobOrderLine.product = data;
                }
            );
        }
        this.jobOrderLine.left_top_tooth_selected = this.left_top_tooth_selected
        this.jobOrderLine.left_bottom_tooth_selected = this.left_bottom_tooth_selected
        this.jobOrderLine.right_top_tooth_selected = this.right_top_tooth_selected
        this.jobOrderLine.right_bottom_tooth_selected = this.right_bottom_tooth_selected
        this.jobOrderLine.stage = this.stage_selected
        this.jobOrderLine.pontic_design = this.pontic_design_selected
        this.jobOrderLine.collor_metal_design = this.collar_and_metal_selected
        this.jobOrderLine.product_id = this.productId

        if (!this.orderLineId) {
            this.dialogRef.close({ orderLine: this.jobOrderLine });
        }
        else {
            this.dialogRef.close({ orderLine: this.jobOrderLine });
        }
    }

    getOrderLine(orderLineId: number) {
        if (this.orderLine) {
            this.jobOrderLine = this.orderLine
            if (this.orderLine.product) {
                this.selectedProduct = this.orderLine.product.id
                this.left_top_tooth_selected = this.orderLine.left_top_tooth_selected;
                this.left_bottom_tooth_selected = this.orderLine.left_bottom_tooth_selected;
                this.right_top_tooth_selected = this.orderLine.right_top_tooth_selected;
                this.right_bottom_tooth_selected = this.orderLine.right_bottom_tooth_selected;
                this.stage_selected = this.orderLine.stage;
                this.pontic_design_selected = this.orderLine.pontic_design;
                this.collar_and_metal_selected = this.orderLine.collor_metal_design;
            }
        }
        else if (orderLineId) {
            this.api.get(this.model, orderLineId).subscribe(
                (data: any) => {
                    if (data) {
                        this.jobOrderLine = data;
                        this.selectedProduct = data.product ? data.product.id : null
                        this.left_top_tooth_selected = data.left_top_tooth_selected;
                        this.left_bottom_tooth_selected = data.left_bottom_tooth_selected;
                        this.right_top_tooth_selected = data.right_top_tooth_selected;
                        this.right_bottom_tooth_selected = data.right_bottom_tooth_selected;
                        this.stage_selected = data.stage;
                        this.pontic_design_selected = data.pontic_design;
                        this.collar_and_metal_selected = data.collor_metal_design;
                    }
                }
            );
        }
    }

    leftTopTooth(e: any) {
        let checkboxValue = e.source.value;
        if (e.checked) {
            this.left_top_tooth_selected.push(Number(checkboxValue));
        }
        else {
            let k = 0;
            var len = this.left_top_tooth_selected.length;
            for (k = 0; k < len; k = k + 1) {
                if (this.left_top_tooth_selected[k] === Number(checkboxValue)) {
                    this.left_top_tooth_selected.splice(k, 1);
                }
            }
        }
    }

    leftBottomTooth(e: any) {

        let checkboxValue = Number(e.source.value);
        if (e.checked) {
            this.left_bottom_tooth_selected.push(checkboxValue);
        }
        else {
            let k = 0;
            var len = this.left_bottom_tooth_selected.length;
            for (k = 0; k < len; k = k + 1) {
                if (this.left_bottom_tooth_selected[k] === checkboxValue) {
                    this.left_bottom_tooth_selected.splice(k, 1);
                }
            }
        }
    }

    rightTopTooth(e: any) {
        let checkboxValue = Number(e.source.value);
        if (e.checked) {
            this.right_top_tooth_selected.push(checkboxValue);
        }
        else {
            let k = 0;
            var len = this.right_top_tooth_selected.length;
            for (k = 0; k < len; k = k + 1) {
                if (this.right_top_tooth_selected[k] === checkboxValue) {
                    this.right_top_tooth_selected.splice(k, 1);
                }
            }
        }
    }

    rightBottomTooth(e: any) {
        let checkboxValue = Number(e.source.value);
        if (e.checked) {
            this.right_bottom_tooth_selected.push(checkboxValue);
        }
        else {
            let k = 0;
            var len = this.right_bottom_tooth_selected.length;
            for (k = 0; k < len; k = k + 1) {
                if (this.right_bottom_tooth_selected[k] === checkboxValue) {
                    this.right_bottom_tooth_selected.splice(k, 1);
                }
            }
        }
    }

    stageChkBox(e: any) {
        let checkboxValue = e.source.value;
        if (e.checked) {
            this.stage_selected.push(checkboxValue);
        }
        else {
            let k = 0;
            var len = this.stage_selected.length;
            for (k = 0; k < len; k = k + 1) {
                if (this.stage_selected[k] === checkboxValue) {
                    this.stage_selected.splice(k, 1);
                }
            }
        }
    }

    ponticDesignChkBox(e: any) {
        let checkboxValue = e.source.value;
        if (e.checked) {
            this.pontic_design_selected.push(checkboxValue);
        }
        else {
            let k = 0;
            var len = this.pontic_design_selected.length;
            for (k = 0; k < len; k = k + 1) {
                if (this.pontic_design_selected[k] === checkboxValue) {
                    this.pontic_design_selected.splice(k, 1);
                }
            }
        }
    }

    collarMetailDesignChkBox(e: any) {
        let checkboxValue = e.source.value;
        if (e.checked) {
            this.collar_and_metal_selected.push(checkboxValue);
        }
        else {
            let k = 0;
            var len = this.collar_and_metal_selected.length;
            for (k = 0; k < len; k = k + 1) {
                if (this.collar_and_metal_selected[k] === checkboxValue) {
                    this.collar_and_metal_selected.splice(k, 1);
                }
            }
        }
    }
}
