import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { SnackbarService } from '../../snackbar.service';
import { Company } from './company.interface';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-company-form',
    templateUrl: './company-form.component.html',
    styleUrls: ['./company-form.component.css']
})
export class CompanyFormComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private api: CommonApiService,
        private router: Router,
        public dialog: MatDialog,
        private snackbar: SnackbarService
    ) { }

    public model = 'company'
    public company = {} as Company;
    isFormDisabled = true;
    result: string;
    onSaveDisable = false;
    public allCities: any[] = [];
    public filteredCities = this.allCities;
    public selectedCity: number;
    public allStates: any[] = [];
    public filteredStates = this.allStates;
    public selectedState: number;

    ngOnInit(): void {
        let companyId = this.route.snapshot.params.id
        if (companyId === 'create') {
            this.isFormDisabled = false;
        } 
        else {
            this.getCompany(Number(companyId));
        }
        this.api.getAll('area').subscribe(res => {
            this.allCities = res;
            this.filteredCities = res;
        })
        this.api.getAll('state').subscribe(res => {
            this.allStates = res;
            this.filteredStates = res;
        })
    }

    getCompany(companyId: number) {
        this.api.get(this.model, companyId).subscribe(
            (data: any) => {
                if (data) {
                    this.company = data;
                    this.selectedCity = data.city_id ? data.city_id : null;
                    this.selectedState = data.state_id ? data.state_id : null;
                }
            }
        );
    }

    onCityKey = (event: Event) => {
        var value = (event.target as HTMLInputElement).value;
        this.filteredCities = this.searchCities(value);
    }

    searchCities(value: string) {
        let filter = value.toLowerCase();        
        return this.allCities.filter(option =>
            option.name.toLowerCase().includes(filter)
        );
    }

    onStateKey = (event: Event) => {
        var value = (event.target as HTMLInputElement).value;
        this.filteredStates = this.searchStates(value);
    }

    searchStates(value: string) {
        let filter = value.toLowerCase();        
        return this.allStates.filter(option =>
            option.name.toLowerCase().includes(filter)
        );
    }

    save(formValid:any) {
        if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
        if(this.selectedCity == undefined){
            alert("Please select city.");
            return;
        }
        if(this.selectedState == undefined){
            alert("Please select state.");
            return;
        }
        this.onSaveDisable = true;
        this.company.city_id = this.selectedCity;
        this.company.state_id = this.selectedState;

        this.api.createUpdate(this.model, this.company).subscribe(
            (data: any) => {
                if (data) {
                    this.company = data;
                    this.isFormDisabled = true;
                    this.snackbar.success('Saved company data successfully!')
                    this.onSaveDisable = false;
                }
            },
            error => {
                alert(error.error.message)
                this.onSaveDisable = false;
            }
        );
    }

    edit(){
        this.isFormDisabled = false;
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
                if(this.company.id){
                    this.isFormDisabled = true;
                    this.getCompany(this.company.id);
                }else{
                    this.router.navigate([`/area`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('company/create');
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

}
