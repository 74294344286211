<div class="container-fluid pt-4 pl-4 pb-4">
	<div class="row align-items-center">
		<div class="col-9">
			<h3>Production Schedule</h3>
		</div> 
		<div class="col-3">
			<button mat-raised-button (click)="openDialogFilter()" color="primary" class="example-action-button m-2">Filter</button>
			<button mat-icon-button  (click)="resetFilters()" color="primary" class="example-action-button m-2">
				<mat-icon>refresh</mat-icon>
			</button>
		</div> 
	</div>
</div>
<br><br>

<table matTableExporter #exporter="matTableExporter" mat-table matSort [dataSource]="dataSource" class="mat-elevation-z2">

    <ng-container matColumnDef="select">
        <td mat-cell *matCellDef="let row">
            <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
            </mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element">
            <button type="button" (click)="confirmDialog(element.id)" mat-flat-button color="primary">Schedule</button>
        </td>
    </ng-container>

    <ng-container matColumnDef="job_type">
        <th class="font-weight-bold" mat-header-cell mat-sort-header *matHeaderCellDef>Job Type</th>
        <td mat-cell *matCellDef="let element"> {{element.job_type | titlecase }} </td>
    </ng-container>

    <ng-container matColumnDef="procedure">
        <th class="font-weight-bold" mat-header-cell mat-sort-header *matHeaderCellDef>Procedure</th>
        <td mat-cell *matCellDef="let element"> {{element.procedure | titlecase}} </td>
    </ng-container>

    <ng-container matColumnDef="job_no">
        <th class="font-weight-bold" mat-header-cell mat-sort-header *matHeaderCellDef>Job No.</th>
        <td mat-cell *matCellDef="let element"> {{element.job_no}} </td>
    </ng-container>

    <ng-container matColumnDef="job_date">
        <th class="font-weight-bold" mat-header-cell mat-sort-header *matHeaderCellDef>Job Date</th>
        <td mat-cell *matCellDef="let element"> {{element.job_date}} </td>
    </ng-container>

    <ng-container matColumnDef="doctor_name">
        <th class="font-weight-bold" mat-header-cell mat-sort-header *matHeaderCellDef>Doctor Name</th>
        <td mat-cell *matCellDef="let element"> {{element.doctor_name}} </td>
    </ng-container>

    <ng-container matColumnDef="category_name">
        <th class="font-weight-bold" mat-header-cell mat-sort-header *matHeaderCellDef>Category Name</th>
        <td mat-cell *matCellDef="let element"> {{element.category_name}} </td>
    </ng-container>

    <ng-container matColumnDef="delivery_date">
        <th class="font-weight-bold" mat-header-cell mat-sort-header *matHeaderCellDef>Delivery Date</th>
        <td mat-cell *matCellDef="let element"> {{element.delivery_date}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th class="font-weight-bold" mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element"> {{element.status | titlecase}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumnsWithSelect"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumnsWithSelect;"></tr>

</table>

<mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize" [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex" aria-label="Select page">
</mat-paginator>