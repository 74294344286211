import { Component, OnInit, EventEmitter, Output, Inject, QueryList, ViewChild } from '@angular/core';
import { MatDialogRef , MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CommonApiService } from '../../common-api.service';
import {MatRadioModule} from '@angular/material/radio';

@Component({
	selector: 'app-dynamic-filter',
	templateUrl: './dynamic-filter.component.html',
	styleUrls: ['./dynamic-filter.component.css']
})

export class DynamicFilterComponent implements OnInit {
	
	public dynamicFilterField: any[];
	public dateRangeStart: any;
	public dateRangeEnd: any;
	public startDate: any;
	public endDate: any;
	data:any =[];
	pageIndex = 1;
	checkLastMatScroll:any;
	orderBy: string = 'asc';

	@Output() filterSubmitted = new EventEmitter<any>();

	constructor(
		public dialogRef: MatDialogRef<DynamicFilterComponent>,
		@Inject(MAT_DIALOG_DATA) data: any,
		private api: CommonApiService
		) {
			this.dynamicFilterField = data.filterFields;
		}

	ngOnInit(): void {
	}


	endpointLabelMapping: Record<string, string> = {
		'auth': 'fullname',
		// Add other mappings here
	}

	onOptionButtonClick(event: Event) {
		event.preventDefault();
	}

	dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
		this.dateRangeStart =dateRangeStart.value;
		this.dateRangeEnd =dateRangeEnd.value;
	}

	submitFilter() {
		const filterData: { [key: string]: any } = {};
		for (const field of this.dynamicFilterField) {
			if (field.type == 'date_range') {
				filterData[field.name] = ['between', this.dateRangeStart, this.dateRangeEnd];
			} else if(field.type == 'string') {
				filterData[field.name] = ['like', field.value];
			} else if(field.type == 'dropdown') {
				filterData[field.name] = ['dropdown', field.value];
			} else if(field.type == 'radio_option') {
				filterData[field.name] = ['radio_option', field.value];
			} else if(field.type == 'multi_select') {
				filterData[field.name] = ['multi_select', field.value];
				filterData['order_by'] = this.orderBy;
			}
			field.value = '';
		}
		console.log(filterData)
		
		this.filterSubmitted.emit(filterData);
		this.dialogRef.close(filterData);
		
	}

    onDropdownKey(event: Event, field: any) {
		const value = (event.target as HTMLInputElement).value;
		const params = {
			searchValue: value
		}
	  
		this.api.postAll(field.model_name + '/list/display', params).subscribe((res: any) => {
			
			const total_rows = res.rowCount;
			this.pageIndex = res.pageIndex + 1
			const labelProperty = this.endpointLabelMapping[field.model_name] || 'name'; // Use 'name' as default label propert
			const newData = res.items.map((element: any) => ({
				value: element.id, // it contains id of the database column = element.[column id]
				label: element[labelProperty], // it contains  name of the column = element.[column name]
				total_count: total_rows
			}));
			
			// Find the index of the field in the filterFields array
			const fieldIndex = this.dynamicFilterField.findIndex((f) => f.name === field.name);
		
			// Update the dropdown options for the specific field
			if (fieldIndex !== -1) {
				this.dynamicFilterField[fieldIndex].options = []
				this.dynamicFilterField[fieldIndex].options = newData;
			}
		});
	}

	checkLastMatScrollID (latest_mat_select_id:any) {
		if(this.checkLastMatScroll != latest_mat_select_id) {
			this.pageIndex = 1;
		}
	}

	onDropdownScroll(field: any, matSelectRef:any) {
		
		if (!matSelectRef.panel || !matSelectRef.panel.nativeElement) {
			// Panel or nativeElement is not available, return or handle the situation accordingly.
			return;
		}
		const fieldIndex = this.dynamicFilterField.findIndex((f) => f.name === field.name);
		this.checkLastMatScrollID(matSelectRef._id); // if clicked on new mat select, then i will reset to 1 again for page index
		this.checkLastMatScroll = matSelectRef._id
		matSelectRef.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
			const tolerance = 10
			const element = event.target;
			let totalCount = 0;
			if (Math.floor(element.scrollTop) + Math.floor(element.clientHeight) + tolerance >= Math.floor(element.scrollHeight)) {

				let params = {
					pageIndex: this.pageIndex,
					pageSize: 10
				};

				totalCount = this.dynamicFilterField[fieldIndex]?.options[0].total_count;
				if (this.dynamicFilterField[fieldIndex].options.length >= totalCount) {
					return;
				}

				this.api.postAll(field.model_name + '/list/display', params).subscribe((res: any) => {
					
					const labelProperty = this.endpointLabelMapping[field.model_name] || 'name'; // Use 'name' as default label property
					const newData = res.items.map((element: any) => ({
						value: element.id, // it contains id of the database column = element.[column id]
						label: element[labelProperty], // it contains  name of the column = element.[column name]
					}));
					this.dynamicFilterField[fieldIndex].options.push(...newData);	
					
				});
				this.pageIndex++; // send page index for fetching the records
			}
		});	
	}

}
