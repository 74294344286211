import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { SnackbarService } from '../../snackbar.service';
import { MatSelect } from '@angular/material/select';
import { CommonDropdownService } from '../../common-dropdown.service';
import { environment } from "../../../../environments/environment";
import { tap, filter, finalize } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { Balance } from './balance.interface';
import { EventEmitterService } from '../../event-emitter.service';
import { SpeakerService } from '../../speaker.service';


@Component({
    selector: 'app-balance.form',
    templateUrl: './balance.form.component.html',
    styleUrls: ['./balance.form.component.css']
})

export class BalanceFormComponent implements OnInit{

    @ViewChild('doctor_id', { static: false }) doctor_id: MatSelect;
    @ViewChild('financial_year_element', { static: false }) financial_year_element: MatSelect;

    constructor(
        private route: ActivatedRoute,
        private api: CommonApiService,
        private router: Router,
        public dialog: MatDialog,
        private snackbar: SnackbarService,
        private dropdownService: CommonDropdownService,
        private eventEmitterService: EventEmitterService,
        public speaker: SpeakerService,
    ) { }

    public model = 'balance'
    public balance = {} as Balance;
    isFormDisabled = true;
    result: string;
    onSaveDisable = false;

    doctorSearchPage = 0;
    allDoctorsCount = 0
    public allDoctors: any[] = [];
    public selectedDoctor: number;
    public selectedDoctorObject: any[] = [];
    disableDoctorSelection: boolean;

    fySearchPage = 0;
    allFYCount = 0
    public allFinancialYears: any[] = [];
    public selectedFinancialYearObject: any[] = [];
    public selectedFinancialYear: number;

    ngOnInit() {
        this.disableDoctorSelection=false
        let balance_Id = this.route.snapshot.params.id
        if (balance_Id === 'create') {
            this.isFormDisabled = false;
        }
        else {
            this.disableDoctorSelection=true
            this.getBalance(Number(balance_Id));
            
        }
        this.onLoadDoctorList();
        this.getFinancialYears();

    }

    getFinancialYears() {

        let params = {
            'pageIndex': this.eventEmitterService.pageIndex ? this.eventEmitterService.pageIndex: environment.defaultPageIndexOnLoad,
            'pageSize': this.eventEmitterService.pageSize ? this.eventEmitterService.pageSize: environment.defaultPageSizeOnLoad,
            filterCriteria: []
        }

        this.api.postAll('financial_year/list/display', params).subscribe((res: any) => {
            this.allFinancialYears = res.items;
            this.selectedFinancialYear = res.items[0].id;
            this.selectedFinancialYearObject.push(res.items[0]);
            this.speaker.setListData(this.allFinancialYears, res.rowCount, res.pageIndex, res.pageSize);
        });
    }

    onFinancialYearKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'financial_year', this.allFinancialYears, this.selectedFinancialYearObject, 'fySearchPage')
            .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allFinancialYears = result.entityList;
            this.fySearchPage = result.pageVariable;
        });
    }

    onFinancialYearScroll(event:any) {
        if (event) {
            if (!this.financial_year_element?.panel || !this.financial_year_element?.panel.nativeElement) {
                // Panel or nativeElement is not available, return or handle the situation accordingly.
                return;
            }
            this.financial_year_element.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.financial_year_element.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.financial_year_element.panel.nativeElement.scrollHeight) -
                    Math.floor(this.financial_year_element.panel.nativeElement.offsetHeight)
                ){
                    let stateparams = {
                        'pageIndex': this.fySearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }

                    if (this.allFinancialYears.length >= this.allFYCount) {
                        return;
                    }
                    this.api.postAll('state/list/display', stateparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedFinancialYearObject[0]?.id);
                        this.allFinancialYears.push(...res.items);
                    });
                    this.fySearchPage++;// send page number for fetching the records
                }
            });
        }
    }

    onDoctorKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'doctor', this.allDoctors, this.selectedDoctorObject, 'doctorSearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allDoctors = result.entityList;
            this.doctorSearchPage = result.pageVariable;
        });
    }

    onDoctorScroll(event:any) {

        if (!this.doctor_id?.panel || !this.doctor_id?.panel.nativeElement) {
            // Panel or nativeElement is not available, return or handle the situation accordingly.
            return;
        }
        if (event) {
            this.doctor_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.doctor_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.doctor_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.doctor_id.panel.nativeElement.offsetHeight)
                    ){
                    let doctorparams = {
                        'pageIndex': this.doctorSearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }
                    
                    if (this.allDoctors.length >= this.allDoctorsCount) {
                        return;
                    }
                    this.api.postAll('doctor/list/display', doctorparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedDoctorObject[0]?.id);
                        this.allDoctors.push(...res.items);
                    });
                    this.doctorSearchPage++;// send page number for fetching the records
                }
            });
        }
    }

    onLoadDoctorList() {
        let params = {
            'pageIndex': this.doctorSearchPage,
            'pageSize': environment.defaultPageSizeOnScroll
        }

        this.api.postAll('doctor/list/display', params).pipe(
            tap((res: any) => {
                this.allDoctors.push(...res.items);
                this.allDoctorsCount = res.rowCount
            }),
            filter(() => this.selectedDoctorObject.length !== 0),
            tap(() => {
              const selectedDoctorId = this.selectedDoctorObject[0].id;
              this.allDoctors = this.allDoctors.filter(doctor => doctor.id !== selectedDoctorId);
              this.allDoctors.unshift(this.selectedDoctorObject[0]);
              
            }),
            finalize(() => this.doctorSearchPage++)
        ).subscribe();
    }

    getBalance(balance_Id:number){
        this.api.get(this.model,balance_Id).subscribe(
            (data:any)=>{
                this.balance=data
              
                this.selectedDoctor = data.doctor_id;
                this.allDoctors = this.allDoctors.filter(doctor => doctor.id !== data.doctor_id);
                this.allDoctors.push(data.doctor) // insert specific selected doctor into the dropdown list
                this.selectedDoctorObject.push(data.doctor)
            }
        )
    }

    save(formValid:any) {
        if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
       
        this.balance.doctor_id=this.selectedDoctor
        this.onSaveDisable = true;
       
        // this.getFinancialYear(this.selectedFinancialYear)
        this.balance.fy_code = this.allFinancialYears.filter(fy => fy.id === this.selectedFinancialYear)[0].fy_code

        this.api.createUpdate(this.model, this.balance).subscribe(
            (data: any) => {
                if (data) {
                    this.balance = data;
                    this.isFormDisabled = true;
                    this.snackbar.success('Saved Successfully!');
                    this.router.navigate([`/${this.model}/${data.id}`])
                    this.onSaveDisable = false;
                }
                this.api.refreshNeeded.subscribe(() => {
                    this.getBalance(Number(this.balance.id));
                });
            },
            error => {
                alert(error.error.message)
                this.onSaveDisable = false;
            }
        );
    }

    edit(){
        this.isFormDisabled = false;
        this.disableDoctorSelection=true
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
                if(this.balance.id){
                    this.isFormDisabled = true;
                    this.getBalance(this.balance.id);
                }else{
                    this.router.navigate([`/balance`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('balance/create');
    }

    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }
}