import { Component, OnInit,ViewChild } from '@angular/core';
import { CommonApiService } from '../../../common-api.service';
import { SpeakerService } from '../../../speaker.service';
import { SnackbarService } from '../../../snackbar.service';
import { CommonDropdownService } from '../../../common-dropdown.service';
import { environment } from "../../../../../environments/environment"
import { MatSelect } from '@angular/material/select';
import { tap, filter, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-job-order-log',
  templateUrl: './job-order-log.component.html',
  styleUrls: ['./job-order-log.component.css']
})

export class JobOrderLogComponent implements OnInit {

	@ViewChild('job_id', { static: false }) job_id: MatSelect;// for dropdown

	constructor(
		public speaker: SpeakerService,
		private api: CommonApiService,
		private snackbar: SnackbarService,
        private dropdownService: CommonDropdownService
	) { }

	public data :any[]=[]

	public allListJobs: any[] = [];
	jobSearchPage = 0;
    allJobsCount = 0
    public allJobs: any[] = [];
    public selectedJobObject: any[] = [];
    public selectedJob: number;
	
	displayColumns = [
		'process',
		'type',
		'time',
		'employee',
		'rework_process',
	];
	displayHeaders = [
		'Process Name',
		'Type',
		'Time',
		'Employee',
		'Rework Process',
	];
  
	ngOnInit(): void {
		// this.api.getAll('joborder/pendingjobs').subscribe(res => {
		// 	this.allJobLogs = res;	
		// 	this.filteredJobs = res;
		// })
		this.speaker.listData = this.allListJobs;
		this.selectedJob = 0;
		this.onLoadJobList();
    }
	//api call to bring search values in dropdown and also the selected value in dropdown is displayed
	onJobKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'joborder', this.allJobs, this.selectedJobObject, 'jobSearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allJobs = result.entityList;
            this.jobSearchPage = result.pageVariable;
        });
    }

    // On scroll of dropdown api call to fetch next records
    onJobScroll(event:any) {
        if (event) {
            if (!this.job_id?.panel || !this.job_id?.panel.nativeElement) {
                // Panel or nativeElement is not available, return or handle the situation accordingly.
                return;
            }
            this.job_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.job_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.job_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.job_id.panel.nativeElement.offsetHeight)
                ){
                    let jobparams = {
                        'pageIndex': this.jobSearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }

                    if (this.allJobs.length >= this.allJobsCount) {
                        return;
                    }
                    this.api.postAll('joborder/list/display', jobparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedJobObject[0]?.id);
                        this.allJobs.push(...res.items);
                    });
                    this.jobSearchPage++;// send page number for fetching the records 
                }
            });
        }
    }

    // On load of component default pageSize for dropdown to load and also set selected value if present
    onLoadJobList() {
        let params = {
            'pageIndex': this.jobSearchPage,
            'pageSize': environment.defaultPageSizeOnScroll
        }

        this.api.postAll('joborder/list/display', params).pipe(
            tap((res: any) => {
              this.allJobs.push(...res.items);
              this.allJobsCount = res.rowCount
            }),
            filter(() => this.selectedJobObject.length !== 0),
            tap(() => {
              const selectedJobId = this.selectedJobObject[0].id;
              this.allJobs = this.allJobs.filter(job => job.id !== selectedJobId);
              this.allJobs.unshift(this.selectedJobObject[0]);
              
            }),
            finalize(() => this.jobSearchPage++)
        ).subscribe();
    }


    getReportDetails(data: any) {
		this.api.callRoute('joborder','/production/log', data).subscribe(res => {
			if (res?.status == false) {
				this.snackbar.info(res.message);
			} else {
				this.allListJobs = res;
				this.speaker.listData = this.allListJobs;
			}
        })
    }

	generateReport(){
		if(this.selectedJob == undefined){
			alert("Select Job.");
			return;
		}
		const data = {
			'job_id': this.selectedJob,

		}
		this.speaker.listData = [];
		this.getReportDetails(data);
        this.speaker.model = 'joborder';
        this.speaker.displayColumns = this.displayColumns;
        this.speaker.displayHeaders = this.displayHeaders;
	}

}
