<form #depForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-9 form-breadcrumb">
            <h3><a [routerLink]="['/department']" routerLinkActive="router-link-active">Department</a> > {{department.name}}</h3>
        </div>
        <div class="col-3 form-operation">
            <button *ngIf="!isFormDisabled" [disabled]="onSaveDisable" class="m-2" type="submit" mat-raised-button color="primary" (click)='save(depForm.valid)'>Save</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="primary" (click)='create()'>Create</button>
            <button *ngIf="!isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>
    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Name</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #name="ngModel" type="text" matInput name="name" [(ngModel)]="department.name" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="name.touched && name.invalid">Required</div>
                </div>
            </div>


            <div class="row">
                <div class="example-container col-sm-6">
                    <h4>Processes</h4>
                    <div [ngClass]="{'disableOnClick':isFormDisabled}" cdkDropList #todoList="cdkDropList" [cdkDropListData]="todo" [cdkDropListConnectedTo]="[doneList]" class="example-list" (cdkDropListDropped)="drop($event)">
                        <div class="example-box" *ngFor="let item of todo" cdkDrag>{{item['name']}}</div>
                    </div>
                </div>

                <div class="example-container col-sm-6">
                    <h4>Mapped Processes</h4>
                    <div [ngClass]="{'disableOnClick':isFormDisabled}" cdkDropList #doneList="cdkDropList" [cdkDropListData]="done" [cdkDropListConnectedTo]="[todoList]" class="example-list" (cdkDropListDropped)="drop($event)">
                        <div class="example-box" *ngFor="let item of done" cdkDrag>{{item['name']}}</div>
                    </div>
                </div>
            </div>

            <div cdkDropList #listOne="cdkDropList" [cdkDropListConnectedTo]="[listTwo]"></div>
            <div cdkDropList #listTwo="cdkDropList" [cdkDropListConnectedTo]="[listOne]"></div>
        </fieldset>
    </div>
</form>