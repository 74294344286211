
<!-- <div *ngIf="this.currentUser == 1" class="dashboard">
  <div class="dashboard-tile">
    <div class="tile-content">
      <div class="count">{{clientCount}}</div>
      <div class="label">Clients</div>
    </div>
  </div>
  <div class="dashboard-tile">
    <div class="tile-content">
      <div class="count">{{visitCountCurrentMonth}}</div>
      <div class="label">Current Month Visits</div>
    </div>    
  </div>
  <div class="dashboard-tile">
    <div class="tile-content">
      <div class="count">{{totalVisitsCount}}</div>
      <div class="label">Total Visits</div>
    </div>
  </div>
  <div class="dashboard-tile">
    <div class="tile-content">
      <div class="count">{{totalUpcomingVisitsCount}}</div>
      <div class="label">Current month <br>&nbsp;Upcoming Visits</div>
    </div>
  </div>
</div> -->
<div *ngIf="this.currentUser == 2" class="">
    <div class="row p-4">
      <div class=" col-xl-3 col-md-3">
         <div class=" card-stats">
            <div class="dashboard-tile card-body">
               <div class=" row">
                  <div class=" col">
                     <h5 class=" card-title text-uppercase text-muted mb-0"> Doctors</h5>
                     <span class=" h4 font-weight-bold mb-0"> {{doctorCount}} </span>
                  </div>
                  <div class=" col-auto">
                     <div class=" icon icon-shape bg-gradient-red text-white rounded-circle shadow"><i class=" ni ni-active-40"></i></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class=" col-xl-3 col-md-3">
         <div class=" card-stats">
            <div class="dashboard-tile card-body">
               <div class=" row">
                  <div class=" col">
                     <h5 class=" card-title text-uppercase text-muted mb-0"> Products </h5>
                     <span class=" h4 font-weight-bold mb-0"> {{productCount}} </span>
                  </div>
                  <div class=" col-auto">
                     <div class=" icon icon-shape bg-gradient-orange text-white rounded-circle shadow"><i class=" ni ni-chart-pie-35"></i></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class=" col-xl-3 col-md-3">
         <div class=" card-stats">
            <div class="dashboard-tile card-body">
               <div class=" row">
                  <div class=" col">
                     <h5 class=" card-title text-uppercase text-muted mb-0"> Users </h5>
                     <span class=" h4 font-weight-bold mb-0"> {{userCount}} </span>
                  </div>
                  <div class=" col-auto">
                     <div class=" icon icon-shape bg-gradient-green text-white rounded-circle shadow"><i class=" ni ni-money-coins"></i></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class=" col-xl-3 col-md-3">
         <div class=" card-stats">
            <div class="dashboard-tile card-body">
               <div class=" row">
                  <div class=" col">
                     <h5 class=" card-title text-uppercase text-muted mb-0"> Employees </h5>
                     <span class=" h4 font-weight-bold mb-0"> {{employeeCount}} </span>
                  </div>
                  <div class=" col-auto">
                     <div class=" icon icon-shape bg-gradient-info text-white rounded-circle shadow"><i class=" ni ni-chart-bar-32"></i></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>

    <div class="p-4 row">
      <div class=" col-xl-3 col-md-3">
         <div class=" card-stats">
            <div class="dashboard-tile card-body">
               <div class=" row">
                  <div class="col">
                    <!-- IN_PRODUCTION  and HOLD JOBS-->
                     <h5 class=" card-title text-uppercase text-muted mb-0"> Current month in production jobs </h5>
                     <span class=" h4 font-weight-bold mb-0"> {{inProductionJobsCount}} </span>
                  </div>
                  <div class=" col-auto">
                     <div class=" icon icon-shape bg-gradient-red text-white rounded-circle shadow"><i class=" ni ni-active-40"></i></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class=" col-xl-3 col-md-3">
         <div class=" card-stats">
            <div class="dashboard-tile card-body">
               <div class=" row">
                  <div class=" col">
                     <h5 class=" card-title text-uppercase text-muted mb-0"> Current month on hold jobs  </h5>
                     <span class=" h4 font-weight-bold mb-0"> {{holdJobsCount}} </span>
                  </div>
                  <div class=" col-auto">
                     <div class=" icon icon-shape bg-gradient-orange text-white rounded-circle shadow"><i class=" ni ni-chart-pie-35"></i></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class=" col-xl-3 col-md-3">
         <div class=" card-stats">
            <div class="dashboard-tile card-body">
               <div class=" row">
                  <div class=" col">
                     <h5 class=" card-title text-uppercase text-muted mb-0"> Current month jobs repeated </h5>
                     <span class=" h4 font-weight-bold mb-0"> {{repeatJobsCount}} </span>
                  </div>
                  <div class=" col-auto">
                     <div class=" icon icon-shape bg-gradient-green text-white rounded-circle shadow"><i class=" ni ni-money-coins"></i></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class=" col-xl-3 col-md-3">
         <div class=" card-stats">
            <div class="dashboard-tile card-body">
               <div class=" row">
                  <div class=" col">
                     <h5 class=" card-title text-uppercase text-muted mb-0"> Current month jobs repaired </h5>
                     <span class=" h4 font-weight-bold mb-0"> {{repairJobsCount}} </span>
                  </div>
                  <div class=" col-auto">
                     <div class=" icon icon-shape bg-gradient-info text-white rounded-circle shadow"><i class=" ni ni-chart-bar-32"></i></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="table-container">
                    <div class="m-4">
                        <h4>Late Deliveries</h4>
                        <div class="scrollable">
                            <table matTableExporter #exporter="matTableExporter" mat-table matSort [dataSource]="lateDeliveries" class="mat-elevation-z2">

                                <ng-container matColumnDef="job_no">
                                    <th mat-header-cell *matHeaderCellDef> Job No. </th>
                                    <td mat-cell *matCellDef="let element">{{element.job_no}} </td>
                                </ng-container>
                                <ng-container matColumnDef="delivery_date">
                                    <th mat-header-cell *matHeaderCellDef> Delivery Date</th>
                                    <td mat-cell *matCellDef="let element">{{element.delivery_date}} </td>
                                </ng-container>
                                <ng-container matColumnDef="delivery_due">
                                    <th mat-header-cell *matHeaderCellDef> Status</th>
                                    <td mat-cell *matCellDef="let element">{{element.delivery_due}} </td>
                                </ng-container>
        
                                <tr mat-header-row *matHeaderRowDef="displayColumnsWithSelect"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayColumnsWithSelect;"></tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="col-12 col-md-4">
                <div class="table-container">
                    <div class="m-4">
                        <h4>Today Deliveries</h4>
                        <div class="scrollable">
                            <table matTableExporter #exporter="matTableExporter" mat-table matSort [dataSource]="todayDeliveries" class="mat-elevation-z2">
                                <ng-container matColumnDef="job_no">
                                    <th mat-header-cell *matHeaderCellDef> Job No. </th>
                                    <td mat-cell *matCellDef="let element">{{element.job_no}} </td>
                                </ng-container>
                                <ng-container matColumnDef="delivery_date">
                                    <th mat-header-cell *matHeaderCellDef> Delivery Date</th>
                                    <td mat-cell *matCellDef="let element">{{element.delivery_date}} </td>
                                </ng-container>
                                <ng-container matColumnDef="delivery_due">
                                    <th mat-header-cell *matHeaderCellDef> Status</th>
                                    <td mat-cell *matCellDef="let element">{{element.delivery_due}} </td>
                                </ng-container>
        
                                <tr mat-header-row *matHeaderRowDef="displayColumnsWithSelect"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayColumnsWithSelect;"></tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="col-12 col-md-4">
                <div class="table-container">
                    <div class="m-4">
                        <h4>Upcoming Deliveries</h4>
                        <div class="scrollable">
                            <table matTableExporter #exporter="matTableExporter" mat-table matSort [dataSource]="upcomingDeliveries" class="mat-elevation-z2">
                                <ng-container matColumnDef="job_no">
                                    <th mat-header-cell *matHeaderCellDef> Job No. </th>
                                    <td mat-cell *matCellDef="let element">{{element.job_no}} </td>
                                </ng-container>
                                <ng-container matColumnDef="delivery_date">
                                    <th mat-header-cell *matHeaderCellDef> Delivery Date</th>
                                    <td mat-cell *matCellDef="let element">{{element.delivery_date}} </td>
                                </ng-container>
                                <ng-container matColumnDef="delivery_due">
                                    <th mat-header-cell *matHeaderCellDef> Status</th>
                                    <td mat-cell *matCellDef="let element">{{element.delivery_due}} </td>
                                </ng-container>
        
                                <tr mat-header-row *matHeaderRowDef="displayColumnsWithSelect"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayColumnsWithSelect;"></tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- <div class="p-4">
      <div class="row">
        <div class="col-sm-6">
          <div class="dashboard-chart-container">
            <app-line-chart></app-line-chart>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="dashboard-chart-container">
            <app-doughnut-chart></app-doughnut-chart>
          </div>
        </div>
      </div>
    
      <div class="row">
        <div class="col-sm-6">
          <div class="dashboard-chart-container">
            <app-pie-chart></app-pie-chart>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="dashboard-chart-container">
            <app-bar-chart></app-bar-chart>
          </div>
        </div>
      </div>
    </div> -->
    
    
      