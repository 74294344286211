import {Component,OnInit, ElementRef, ViewChild} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { JobOrder } from './joborder.interface';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SnackbarService } from '../../snackbar.service';
import { environment } from "../../../../environments/environment"
import { MatTable } from '@angular/material/table';
import { JobOrderLine } from './joborderline.interface';
import { JobOrderLineComponent } from './order-line.component';
import { MatSelect } from '@angular/material/select';
import { tap, filter, finalize } from 'rxjs/operators';
import { CommonDropdownService } from '../../common-dropdown.service';

interface Job_Type {
    value: string;
    viewValue: string;
}
interface Procedure {
    value: string;
    viewValue: string;
}
@Component({
  selector: 'app-joborder.form',
  templateUrl: './joborder.form.component.html',
  styleUrls: ['./joborder.form.component.css']
})



export class JobOrderFormComponent implements OnInit {
      
    
    @ViewChild('remarkInput') remarkInput: ElementRef<HTMLInputElement>;
    @ViewChild('product_lines') product_lines: MatTable<any>;
    @ViewChild('doctor_id', { static: false }) doctor_id: MatSelect;// for dropdown
    @ViewChild('category_id', { static: false }) category_id: MatSelect;// for dropdown
  
    constructor(
        private route: ActivatedRoute,
        private api: CommonApiService,
        private router: Router,
        public dialog: MatDialog,
        private snackbar: SnackbarService,
        private dropdownService: CommonDropdownService

    ) {}

    procedure: Procedure[] = [
        {value: 'digital', viewValue: 'Digital'},
        {value: 'physical', viewValue: 'Physical'},
    ];

    job_type: Job_Type[] = [
        {value: 'regular', viewValue: 'Regular'},
        {value: 'repair', viewValue: 'Repair'},
        {value: 'repeat', viewValue: 'Repeat'},
    ];
    
    public model = 'joborder';
    public selectedJobType: string;
    public selectedProcedure: string;
    public joborder = {} as JobOrder;
    public afuConfig = {
        multiple: false,
        formatsAllowed: ".jpg,.png,.pdf,.jpeg",
        maxSize: 1,
        // theme:'attachPin',
        uploadAPI: {
            url: ''
        },
        hideProgressBar: false,
        hideResetBtn: true,
        replaceTexts: {
            selectFileBtn: 'Browse',
            uploadBtn: 'Upload',
            sizeLimit: 'Size Limit',
        },  
    }
    
    minDate=new Date(1999, 0, 2)

    public dialogResult: any;


    public allJobPrefix: any[] = [];
    public selectedJobPrefixObject: any[] = [];
    public selectedJobPrefix: number;

    doctorSearchPage = 0;
    allDoctorsCount = 0
    public allDoctors: any[] = [];
    public selectedDoctor: number;
    public selectedDoctorObject: any[] = [];
    
    categorySearchPage = 0;
    allCategoriesCount = 0
    public allCategories: any[] = [];
    public selectedCategoryObject: any[] = [];
    public selectedCategory: number;
    
    public jobProcesses: any[] = [];
    public selectedRepairProcess: number;

    public allRemarks: any[] = [];
    public filteredRemarks = this.allRemarks;
    public selectedRemark: number;
    
    public allProducts: any[] = [];
    
    result: string;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    remarkCtrl = new FormControl();
    remarks: string[] = [];
    isFormDisabled = true;
    isShowing = false;
    onSaveDisable = false;
    productLinesSource: any = []
    orderLineDisplayedColumns: string[] = ['product_name', 'delete'];


    restoration_type:any =
    [
        'Joint Crowns',
        'Separate Crown',
        'Bridge'
    ];
    
    enclosed_cases:any =
    [
        'Impression Upper',
        'Impression Lower',
        'Bite',
        'Photos',
        'Model Upper',
        'Model Lower',
        'Others' 
    ];
      
    enclosed_cases_selected:any =[];
    restoration_type_selected:any =[];

    public jobFormGroup: FormGroup;
    public dupicateJob: boolean = false;

    ngOnInit() {

        let jobId = this.route.snapshot.params.id
        if (jobId === 'create') {
            this.isFormDisabled = false;
            this.selectedProcedure = 'physical';
            this.selectedJobType = 'regular';
            this.onLoadPrefixList();
        } else {
            this.getJobOrder(Number(jobId));
        }

        this.api.getAll('remark').subscribe(res => {
            this.allRemarks = res;
            this.filteredRemarks = res;
        })

        this.onLoadCategoryList();
        this.onLoadDoctorList();
    }

    docUpload(event: any) {
        console.log(event)
        if (event.ok) {
            this.joborder.filename = event.body.filename
        }
    }

    //api call to bring search values in dropdown and also the selected value in dropdown is displayed
    onDoctorKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'doctor', this.allDoctors, this.selectedDoctorObject, 'doctorSearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allDoctors = result.entityList;
            this.doctorSearchPage = result.pageVariable;
        });
    }

    // On scroll of dropdown api call to fetch next records
    onDoctorScroll(event:any) {

        if (!this.doctor_id?.panel || !this.doctor_id?.panel.nativeElement) {
            // Panel or nativeElement is not available, return or handle the situation accordingly.
            return;
        }
        if (event) {
            this.doctor_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.doctor_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.doctor_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.doctor_id.panel.nativeElement.offsetHeight)
                    ){
                    let doctorparams = {
                        'pageIndex': this.doctorSearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }
                    
                    if (this.allDoctors.length >= this.allDoctorsCount) {
                        return;
                    }
                    this.api.postAll('doctor/list/display', doctorparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedDoctorObject[0]?.id);
                        this.allDoctors.push(...res.items);
                    });
                    this.doctorSearchPage++;// send page number for fetching the records
                }
            });
        }
    }

    // On load of component default pageSize for dropdown to load and also set selected value if present
    onLoadDoctorList() {
        let params = {
            'pageIndex': this.doctorSearchPage,
            'pageSize': environment.defaultPageSizeOnScroll
        }

        this.api.postAll('doctor/list/display', params).pipe(
            tap((res: any) => {
                this.allDoctors.push(...res.items);
                this.allDoctorsCount = res.rowCount
            }),
            filter(() => this.selectedDoctorObject.length !== 0),
            tap(() => {
              const selectedDoctorId = this.selectedDoctorObject[0].id;
              this.allDoctors = this.allDoctors.filter(doctor => doctor.id !== selectedDoctorId);
              this.allDoctors.unshift(this.selectedDoctorObject[0]);
              
            }),
            finalize(() => this.doctorSearchPage++)
        ).subscribe();
    }

    onLoadPrefixList() {

        this.api.callRoute('joborder', '/job_prefix', null).pipe(
            tap((res: any) => {
                this.allJobPrefix = res;
            }),
            filter(() => this.selectedJobPrefixObject.length !== 0),
            tap(() => {
              const selectedJobPrefixId = this.selectedDoctorObject[0].id;
              this.allJobPrefix = this.allJobPrefix.filter(prefix => prefix.id !== selectedJobPrefixId);
              this.allJobPrefix.unshift(this.selectedJobPrefixObject[0]);
              
            }),
        ).subscribe();
    }

    //api call to bring search values in dropdown and also the selected value in dropdown is displayed
    onCategoryKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'category', this.allCategories, this.selectedCategoryObject, 'categorySearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allCategories = result.entityList;
            this.categorySearchPage = result.pageVariable;
        });
    }

    // On scroll of dropdown api call to fetch next records
    onCategoryScroll(event:any) {

        if (!this.category_id?.panel || !this.category_id?.panel.nativeElement) {
            // Panel or nativeElement is not available, return or handle the situation accordingly.
            return;
        }
        if (event) {
            this.category_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.category_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.category_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.category_id.panel.nativeElement.offsetHeight)
                    ){
                    let categoryparams = {
                        'pageIndex': this.categorySearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }
                    
                    if (this.allCategories.length >= this.allCategoriesCount) {
                        return;
                    }
                    this.api.postAll('category/list/display', categoryparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedCategoryObject[0]?.id);
                        this.allCategories.push(...res.items);
                    });
                    this.categorySearchPage++;// send page number for fetching the records
                }
            });
        }
    }

    // On load of component default pageSize for dropdown to load and also set selected value if present
    onLoadCategoryList() {
        let params = {
            'pageIndex': this.categorySearchPage,
            'pageSize': environment.defaultPageSizeOnScroll
        }

        this.api.postAll('category/list/display', params).pipe(
            tap((res: any) => {
                this.allCategories.push(...res.items);
                this.allCategoriesCount = res.rowCount
            }),
            filter(() => this.selectedCategoryObject.length !== 0),
            tap(() => {
              const selectedCategoryId = this.selectedCategoryObject[0].id;
              this.allCategories = this.allCategories.filter(category => category.id !== selectedCategoryId);
              this.allCategories.unshift(this.selectedCategoryObject[0]);
              
            }),
            finalize(() => this.categorySearchPage++)
        ).subscribe();
    }

    onCategoryChange(event:any) {
        this.api.get('product/category', this.selectedCategory).subscribe(
			(products: any) => {
				if (products) {
					this.allProducts = products
                    this.productLinesSource = [];
				}
                this.joborder.order_lines = this.productLinesSource
                this.product_lines.renderRows();
			}
		)
    }
  
    add(event: MatChipInputEvent): void {
      const value = (event.value || '').trim();
      // Add our remark
        if (value) {
            this.remarks.push(value);
        }
        // Clear the input value
        event.chipInput!.clear();
        this.remarkCtrl.setValue(null);

    }
  
    remove(remark: string): void {
      const index = this.remarks.indexOf(remark);
        
        if (index >= 0) {
            this.remarks.splice(index, 1);
        }
        var textarea = <HTMLInputElement>document.getElementById("remarks_section");
        textarea.value = this.remarks.join("\n");
    }
  
    selected(event: MatAutocompleteSelectedEvent): void {
        // Focus out (blur) the input element
        this.remarkInput.nativeElement.blur();
    
        this.remarks.push(event.option.viewValue);
        this.remarkInput.nativeElement.value = '';
        this.remarkCtrl.setValue(null);
        this.remarks = this.remarks
        var description = event.option.value.description
        console.log(event.option.value.description)

        var textarea = <HTMLInputElement>document.getElementById("remarks_section");
        textarea.value += description + "\n";
        this.joborder.remarks_section =textarea.value;
    }
  
    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.filteredRemarks.filter(fruit => fruit.toLowerCase().includes(filterValue));
    }

    enclosedCaseChkBox(e:any){
        let checkboxValue = e.source.value;
        if(e.checked){
            this.enclosed_cases_selected.push(checkboxValue);
        }
        else{
            let  k = 0;
            var len = this.enclosed_cases_selected.length;
            for (k = 0; k < len; k = k + 1) {
                if (this.enclosed_cases_selected[k] === checkboxValue) {
                    this.enclosed_cases_selected.splice(k, 1);
                }
            }
        }
    }

    restoTypeChkBox(e:any){
        let checkboxValue = e.source.value;
        if(e.checked){
            this.restoration_type_selected.push(checkboxValue);
        }
        else{
            let  k = 0;
            var len = this.restoration_type_selected.length;
            for (k = 0; k < len; k = k + 1) {
                if (this.restoration_type_selected[k] === checkboxValue) {
                    this.restoration_type_selected.splice(k, 1);
                }
            }
        }
    }

    getJobOrder(jobId:number) {
        this.api.get(this.model, jobId).subscribe(
            (data: any) => {
                if (data) {
                    this.joborder = data;
                    this.productLinesSource = data.order_lines ? data.order_lines : []
                    this.enclosed_cases_selected = data.enclosed_with_case;
                    this.restoration_type_selected = data.restoration_type;
                    this.selectedJobType = data.job_type;
                    this.selectedProcedure = data.procedure;

                    if(data.doctor){
                        this.selectedDoctor = data.doctor.id;
                        this.allDoctors = this.allDoctors.filter(doctor => doctor.id !== data.doctor.id);
                        this.allDoctors.push(data.doctor) // insert specific selected doctor into the dropdown list
                        this.selectedDoctorObject.push(data.doctor)
                    }

                    if(data.category){
                        this.selectedCategory = data.category.id;
                        this.allCategories = this.allCategories.filter(category => category.id !== data.category.id);
                        this.allCategories.push(data.category) // insert specific selected doctor into the dropdown list
                        this.selectedCategoryObject.push(data.category)

                        if (this.selectedCategoryObject[0].id != 0) {
                            
                            this.api.get('product/category', this.selectedCategoryObject[0].id).subscribe(
                                (products: any) => {
                                    if (products) {
                                        this.allProducts = products
                                    }
                                }
                            )
                        }

                    }

                    this.afuConfig.uploadAPI.url = `${environment.api}${this.model}/${this.joborder.id}/uploader`
                }
            }
        );
    }

    addProductLine(order_line: JobOrderLine, activeAddress: any) {
        var replaced = false;
        for (let i = 0; i < this.productLinesSource.length; i++) {
            if (this.productLinesSource[i] == activeAddress) {
                this.productLinesSource[i] = order_line
                replaced = true
                break
            }
        }
        if (!replaced) {
            this.productLinesSource.push(order_line);
        }
        this.joborder.order_lines = this.productLinesSource
        this.product_lines.renderRows();
    }

    confirmDialog(action:any): void {

        var message = '';
        var title = '';
        var repairJob = false
        if(action == 'schedule') {
            message = `Are you sure you want to schedule this job for production?`
            title = 'Confirm Job Schedule'
        } else if (action == 'hold') {
            message = `Are you sure you want to hold this job?`
            title = 'Confirm Job Hold'
        } else if (action == 'release') {
            message = `Are you sure you want to release this job?`
            title = 'Confirm Job Release'
        } else if (action == 'cancel') {
            message = `Are you sure you want to cancel this job?`
            title = 'Confirm Job Cancel'
        } else if (action == 'repeat') {
            message = `Are you sure you want to repeat this job?`
            title = 'Confirm Job Repeat'
        } else if (action == 'repair') {
            message = `Are you sure you want to repair this job?`
            title = 'Confirm Job Repair'
            repairJob = true
        }
	
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title:   title,
			message: message,
            job_id:  this.joborder.id,
            repairJob : repairJob
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe((dialogRes:any) => {
            
			this.dialogResult = dialogRes;
			if (this.dialogResult) {
                if (action == 'repair') {
                    this.repairJobOrder(this.dialogResult.repairProcess);
                } else if (action == 'repeat') {
                    this.repeatJobOrder();
                } else {
                    this.jobOrderAction(action);
                }
			}
		});
	}

    printJobOrder(): void {
        const printWindow = window.open('/api/joborder/' + this.joborder.id + '/download');
        if (printWindow) {
          printWindow.print();
        }
      }
      

    repairJobOrder (process_id: number) {
        this.api.callRoute('joborder', `/create_repair_order`, {'parent_id': this.joborder.id,'repair_process_id': process_id}).subscribe(
            (data: any) => {
                if (data.status) {
                    alert("Job Repaired successfully")
                    this.redirectTo(`joborder`);
                }
                else {
                    alert(data.message)
                }
            },
            error => {
                console.log(error)
            }
        );
    }

    repeatJobOrder (){
        this.api.callRoute('joborder', `/create_repeat_order`, {'parent_id': this.joborder.id}).subscribe(
            (data: any) => {
                if (data.status) {
                    alert("Job Repeated successfully")
                    this.redirectTo(`joborder`);
                }
                else {
                    alert(data.message)
                }
            },
            error => {
                console.log(error)
            }
        );
    }

    openDialog(activeLine: any) {
        if(this.selectedProcedure == undefined){
            alert("Please select procedure.");
            return;
        }
        if(this.selectedCategory == undefined){
            alert("Please select category name.");
            return;
        }
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            uid: activeLine.id,
            jobId: this.joborder.id,
            passFlagToDisable: this.isFormDisabled,//send flag
            orderLine: activeLine,
            allProducts: this.allProducts,
        }
        const dialogRef = this.dialog.open(JobOrderLineComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.addProductLine(result.orderLine, activeLine);
            }
        });

    }

    deleteLine(index: any) {
        if(this.isFormDisabled){
            return;
        }
        this.productLinesSource.splice(index, 1)
        this.joborder.order_lines = this.productLinesSource     
        this.product_lines.renderRows();
    }    

    jobOrderAction(action: any) {
        if(action == ''){
            alert("please retry again")
            return;
        }
		this.api.callRoute('joborder', `/${action}/${this.joborder.id}`, null).subscribe(
            (resp) => {
                if (resp.status != 'error') {
                    if (action == 'schedule') {
                        alert('Job order Scheduled Successfully!');
                    } else if ( action == 'hold') {
                        alert('Job order status changed to hold!');
                    } else if ( action == 'release') {
                        alert('Job order status changed to release!');
                    } else if ( action == 'cancel') {
                        alert('Job order status changed to cancel!');
                    } else if ( action == 'repeat') {
                        alert('Job order repeated successfully!');
                    } else if ( action == 'repair') {
                        alert('Job order sent for repair!');
                    }   
                    this.redirectTo(`joborder`);
                }
                else {
                    alert(resp.message)
                }
            },
            error => {
                console.log(error)
            }
        );
	}
    

    save(formValid:any) {
        if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
        if(this.selectedJobType == undefined){
            alert("Please select job type.");
            return;
        }
        if(this.selectedProcedure == undefined){
            alert("Please select procedure.");
            return;
        }
        if(this.selectedDoctor == undefined){
            alert("Please select doctor name.");
            return;
        }
        if(this.selectedCategory == undefined){
            alert("Please select category name.");
            return;
        }
        if(this.joborder.order_lines == undefined){
            alert("Please add atleast one product.");
            return;
        }
        if(this.joborder.order_lines.length <= 0){
            alert("Please add atleast one product.");
            return;
        }
        
        this.onSaveDisable = true;
        
        this.joborder.enclosed_with_case = this.enclosed_cases_selected;
        this.joborder.restoration_type = this.restoration_type_selected;
        this.joborder.doctor_id = this.selectedDoctor;
        this.joborder.category_id = this.selectedCategory;
        this.joborder.job_type = this.selectedJobType;
        this.joborder.doc_prefix_id = this.selectedJobPrefix;
        this.joborder.procedure = this.selectedProcedure;
       
        this.api.createUpdate(this.model, this.joborder).subscribe(
            (data:any) => {
                if (data) {
                    this.joborder = data;  
                    // changing previously selected value as blank and adding latest selected value 
                    if (data.doctor) {
                    this.dropdownService.addToSelectedObjectArray(this.selectedDoctorObject, data.doctor);
                    }
                    
                    if (data.category) {
                        this.dropdownService.addToSelectedObjectArray(this.selectedCategoryObject, data.category);
                    }

                    this.isFormDisabled = true;
                    this.snackbar.success('Saved Successfully!');
                    this.onSaveDisable = false;
                }
            },
            error => {
                alert(error.error.message)
                console.log(error)
                this.onSaveDisable = false;
            }
        );
    }

    edit(){
        this.isFormDisabled = false;
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			console.log(this.result);
			if (this.result) {
                if(this.joborder.id){
                    this.isFormDisabled = true;
                    this.getJobOrder(this.joborder.id);
                }else{
                    this.router.navigate([`/joborder`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('joborder/create');
    }

    checkJobDuplicate(){
        this.api.callRoute('joborder', `/check_duplicate`, {'job_no': this.joborder.job_no, 'job_id': this.joborder.id}).subscribe(
            (data: any) => {
                    if (data.success) {
                        this.dupicateJob = false;
                        return true;
                    }
                    else {
                        this.dupicateJob = true;
                        return false;
                    }
            }
        );
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

    public checkError = (controlName: string, errorName: string) => {
        return this.jobFormGroup.controls[controlName].hasError(errorName);}

}
