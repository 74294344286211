import { Component, OnInit,ViewChild } from '@angular/core';
import { CommonApiService } from '../../../common-api.service';
import { SpeakerService } from '../../../speaker.service';
import { SnackbarService } from '../../../snackbar.service';
import { CommonDropdownService } from '../../../common-dropdown.service';
import { environment } from "../../../../../environments/environment"
import { MatSelect } from '@angular/material/select';
import { tap, filter, finalize } from 'rxjs/operators';


@Component({
	selector: 'app-pending-job-data',
	templateUrl: './pending-job-data.component.html',
	styleUrls: ['./pending-job-data.component.css']
})
export class PendingJobDataComponent implements OnInit {

	@ViewChild('city_id', { static: false }) city_id: MatSelect;// for dropdown

	constructor(
		public speaker: SpeakerService,
		private api: CommonApiService,
		private snackbar: SnackbarService,
        private dropdownService: CommonDropdownService
	) { }

	public allJobs: any[] = [];
	public dateRangeStart: any;
	public dateRangeEnd: any;
	public startDate: any;
	public endDate: any;

	citySearchPage = 0;
    allCitiesCount = 0
    public allCities: any[] = [];
    public selectedCityObject: any[] = [];
    public selectedCity: number;
	
	
	displayColumns = [
		'job_no',
		'job_date',
		'doctor_name',
		'city_name',
		'patient_name',
		'product_name',
		'category_name',
		'delivery_date',
		'remarks_section',
		'status',
		'delivery_due',
	];
	displayHeaders = [
		'Job No',
		'Job Date',
		'Doctor Name',
		'Location',
		'Patient Name',
		'Product Name',
		'Category Name',
		'Delivery Date',
		'Remarks',
		'Status',
		'Delivery Due',
	];
  
	ngOnInit(): void {
		this.speaker.listData = this.allJobs;
		this.selectedCity = 0;
		this.onLoadCityList();
    }

	//api call to bring search values in dropdown and also the selected value in dropdown is displayed
	onCityKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'area', this.allCities, this.selectedCityObject, 'citySearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allCities = result.entityList;
            this.citySearchPage = result.pageVariable;
        });
    }

    // On scroll of dropdown api call to fetch next records
    onCityScroll(event:any) {
        if (event) {
            if (!this.city_id?.panel || !this.city_id?.panel.nativeElement) {
                // Panel or nativeElement is not available, return or handle the situation accordingly.
                return;
            }
            this.city_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.city_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.city_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.city_id.panel.nativeElement.offsetHeight)
                ){
                    let cityparams = {
                        'pageIndex': this.citySearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }

                    if (this.allCities.length >= this.allCitiesCount) {
                        return;
                    }
                    this.api.postAll('area/list/display', cityparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedCityObject[0]?.id);
                        this.allCities.push(...res.items);
                    });
                    this.citySearchPage++;// send page number for fetching the records 
                }
            });
        }
    }

    // On load of component default pageSize for dropdown to load and also set selected value if present
    onLoadCityList() {
        let params = {
            'pageIndex': this.citySearchPage,
            'pageSize': environment.defaultPageSizeOnScroll
        }

        this.api.postAll('area/list/display', params).pipe(
            tap((res: any) => {
              this.allCities.push(...res.items);
              this.allCitiesCount = res.rowCount
            }),
            filter(() => this.selectedCityObject.length !== 0),
            tap(() => {
              const selectedCityId = this.selectedCityObject[0].id;
              this.allCities = this.allCities.filter(city => city.id !== selectedCityId);
              this.allCities.unshift(this.selectedCityObject[0]);
              
            }),
            finalize(() => this.citySearchPage++)
        ).subscribe();
    }

	dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
		this.dateRangeStart =dateRangeStart.value;
		this.dateRangeEnd =dateRangeEnd.value;

	}

    getReportDetails(data: any) {
		this.api.callRoute('joborder','/list/pending', data).subscribe(res => {
			if (res?.status == false) {
				this.snackbar.info(res.message);
			} else {
				this.allJobs = res;
				this.speaker.listData = this.allJobs;
			}
        })
    }

	generateReport(){
		this.startDate =this.dateRangeStart;
		this.endDate =this.dateRangeEnd;
		
		if(this.startDate == undefined || this.startDate == ""){
			alert("Select date range.");
			return;
		}
		if(this.endDate == undefined || this.endDate == ""){
			alert("Select date range.");
			return;
		}
		const data = {
			'start_date': this.startDate,
			'end_date': this.endDate,
			'city_id': this.selectedCity,

		}
		this.speaker.listData = [];
		this.getReportDetails(data);
        this.speaker.model = 'joborder';
        this.speaker.displayColumns = this.displayColumns;
        this.speaker.displayHeaders = this.displayHeaders;
	}


    searchCities(value: string) {
        let filter = value.toLowerCase();        
        return this.allCities.filter(option =>
            option.name.toLowerCase().includes(filter)
        );
    }

}
