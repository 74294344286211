import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { SpeakerService } from '../../speaker.service';
import {PageEvent} from '@angular/material/paginator';
import { EventEmitterService } from '../../event-emitter.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SnackbarService } from '../../snackbar.service';


@Component({
	selector: 'app-list-container',
	templateUrl: './list-container.component.html',
	styleUrls: ['./list-container.component.css']
})
export class ListContainerComponent implements OnInit {

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	length = 0;
	pageSize = 10; // on load page size
	pageIndex = 0; // on load page index
	pageSizeOptions = [2, 10, 25, 50]; // we can set the pagination size options from here
	showFirstLastButtons = true;
	selectedIds: number[] = [];
	public allIds: number[] = [];

	public displayColumns: any[];
	public displayHeaders: any[];
	public listData: any[] = [];
	public afterCall: any = [];
	dataSource = new MatTableDataSource<any>(this.listData);
	selection = new SelectionModel<any>(true, []);
	displayColumnsWithSelect = ['select'];
	displayHeadersWithSelect: any =[];
	IsWait = false;
	hideCreateBtnFor = [
		'/invoice'
	]
	hideDeleteBtnFor = [
		'/challan',
		'/invoice',
		'/joborder',
		'/proforma',
		'/account_receipt',
		'/note',
		'/balance',
		'/sales_return'
	]

	showBulkAction = [
		'/challan',
		'/proforma',
	]

	dialogBoxResult:any

	constructor(
		public speaker: SpeakerService,
		private api: CommonApiService,
		public router: Router,
		private eventEmitterService: EventEmitterService,
		private dialog: MatDialog,
		private snackbar: SnackbarService,
	) { }

	ngOnInit(): void {
		this.displayHeaders = this.speaker.displayHeaders;
		this.displayColumns = this.speaker.displayColumns;

		

		this.speaker.listData$.subscribe((data) => {
			this.listData = data.data;
			this.dataSource = new MatTableDataSource<any> (this.listData);			
			this.length = data.length;
			this.pageIndex = data.pageIndex;
			this.pageSize = data.pageSize;
			this.allIds = data.allIds

			// Restore selection state
			this.selection.clear();
			this.dataSource.data.forEach(row => {
				if (this.selectedIds.includes(row.id)) {
				this.selection.select(row);
				}
			});
		});
		this.listData = this.speaker.listData
		this.dataSource = new MatTableDataSource<any> (this.listData);			
		this.pageIndex = this.speaker.pageIndex
		this.length = this.speaker.length
		this.pageSize = this.speaker.pageSize
		this.allIds = this.speaker.allIds
		this.displayColumnsWithSelect = this.displayColumnsWithSelect.concat(this.displayColumns);

		//this.dataSource.sort = this.sort;
	}

	/* for pagination, passing parameters to the component list onload of component*/
	firstComponentFunction(){ 
		
		/* wait will async the code and then execute the further line of code */
		const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
		this.eventEmitterService.onFirstComponentButtonClick(this.pageSize, this.pageIndex)
		wait(1*4).then(() => {

			this.displayColumnsWithSelect = ['select'];
			this.pageIndex = this.speaker.pageIndex
			this.length = this.speaker.length
			this.pageSize = this.speaker.pageSize	
			this.allIds = this.speaker.allIds	
			this.displayHeaders = this.speaker.displayHeaders;
			this.dataSource = new MatTableDataSource<any> (this.speaker.listData);			
			this.displayColumnsWithSelect = this.displayColumnsWithSelect.concat(this.speaker.displayColumns);

			//this.dataSource.sort = this.sort;
				
		});
	} 

	handlePageEvent(pagination_event: PageEvent) {
		this.length = pagination_event.length;
		this.pageSize = pagination_event.pageSize;
		this.pageIndex = pagination_event.pageIndex;
		this.firstComponentFunction();
	}
	
	
	public doFilter = (event: Event) => {
		var value = (event.target as HTMLInputElement).value;

		if (typeof value === 'string') {
			this.dataSource.filter = value.trim().toLocaleLowerCase();
		}
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.filteredData.length;
		return numSelected === numRows;
	}
	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		if (this.isAllSelected()) {
		  this.selection.clear();
		  this.selectedIds = [];
		} else {
		  this.dataSource.filteredData.forEach(row => {
			if (!this.selection.isSelected(row)) {
			  this.selection.select(row);
			  this.selectedIds.push(row.id); // Add ID if it's not already included
			}
		  });
		}
	}

	/** Select / Deselect  specific rows if they are not all selected; otherwise clear selection. */ 
	toggleSelection(row: any) {
		this.selection.toggle(row);
		if (this.selection.isSelected(row)) {
		  this.selectedIds.push(row.id); // Add ID if it's selected
		} else {
		  const index = this.selectedIds.indexOf(row.id);
		  if (index > -1) {
			this.selectedIds.splice(index, 1); // Remove ID if it's deselected
		  }
		}
	}	

	rowClicked(objId: number) {
		this.router.navigate([`/${this.speaker.model}`, objId])
	}

	create() {
		this.router.navigate([`/${this.speaker.model}`, 'create'])
	}

	changeStatus(status:any) {
		if (this.selectedIds.length === 0) {
			alert('Please select at least one record');
			return;
		}
		const payload = {
			selectedIds: this.selectedIds,
			status: status
		};

		if (this.selectedIds.length != 0 && status != '') {
			const message = `Are you sure you want to change the status?`
			const dialogConfig = new MatDialogConfig();
			dialogConfig.data = {
				title: "Change Status",
				message: message
			}
			const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
			dialogRef.afterClosed().subscribe(dialogResult => {
				this.dialogBoxResult = dialogResult;
				// return true or false or undefined , hence we can handle the functionality on true and false likewise here
				if (this.dialogBoxResult) {
					this.api.callRoute(`${this.speaker.model}`, `/changeStatus`, payload).subscribe(
						(resp) => {
							if (resp.status != 'error') {
								alert('Status updated Successfully!');
								this.redirectTo(this.speaker.model);
							}
							else {
								alert(resp.message)
							}
						},
						error => {
							console.log(error)
						}
					);
				}		
			});
		} else {
			return;
		}	
	}

	generateBulk(model: string) {
		switch (model) {
			case 'challan':
				return this.processGenerateBulkProformaInvoices();
			case 'proforma':
				return this.processGenerateBulkInvoices();
			default:
				break;
		}
		return;
		
	}

	processGenerateBulkInvoices() {
		if (this.selectedIds.length === 0) {
			alert('Please select at least one record');
			return;
		}
		const payload = {
			selectedIds: this.selectedIds,

		};

		if (this.selectedIds.length != 0) {
			var message = `Are you sure you want to proceed with the operation?`
			if (this.speaker.model == 'proforma') {
				message = `Are you sure you want to generate bulk invoices? <br/>This will create invoices for all selected proformas and submit them in bulk.`
			}

			var model = 'invoice/proforma'

			const dialogConfig = new MatDialogConfig();
			dialogConfig.data = {
				title: "Generate Bulk Invoices",
				message: message
			}
			const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
			dialogRef.afterClosed().subscribe(dialogResult => {
				this.dialogBoxResult = dialogResult;
				// return true or false or undefined , hence we can handle the functionality on true and false likewise here
				if (this.dialogBoxResult) {
					this.api.callRoute(`${model}`, `/generateBulk`, payload).subscribe(
						(resp) => {
							if (resp.status) {
								this.snackbar.success(resp.message);
								this.redirectTo(this.speaker.model);
							}
							else {
								this.snackbar.info(resp.message);
							}
						},
						error => {
							console.log(error)
						}
					);
				}		
			});
		} else {
			return;
		}	
	}

	processGenerateBulkProformaInvoices() {
		if (this.selectedIds.length === 0) {
			alert('Please select at least one record');
			return;
		}
		var payload: any = {
			selectedIds: this.selectedIds,
		};

		if (this.selectedIds.length != 0) {
			var message = `Are you sure you want to proceed with the operation?`
			if (this.speaker.model == 'challan') {
				message = `Select Company to generate proformas for all selected challans.<br><br>Note:<br>a. Draft proformas will be created for all challans, grouped by doctor.<br>b. Only challans with a "regular" job type will be included.<br>c. Challans that have already been proforma invoiced will be skipped.<br>d. Challans with a "Draft" or "Cancelled" status will not be processed.`
			}

			const dialogConfig = new MatDialogConfig();
			dialogConfig.data = {
				title:    "Generate Bulk Proforma Invoices",
				message: message,
				showCompanySelection:  true,
			}

			const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
			dialogRef.afterClosed().subscribe(dialogResult => {
				this.dialogBoxResult = dialogResult;
				// return true or false or undefined , hence we can handle the functionality on true and false likewise here
				if (this.dialogBoxResult) {
					payload['selectedCompanyId'] = this.dialogBoxResult['selectedCompanyId']
					this.api.callRoute(`${this.speaker.model}`, `/generateBulk`, payload).subscribe(
						(resp) => {
							if (resp.status) {
								this.snackbar.success(resp.message);
								this.redirectTo(this.speaker.model);
							}
							else {
								this.snackbar.info(resp.message);
							}
						},
						error => {
							console.log(error)
						}
					);
				}		
			});
		} else {
			return;
		}	
	}
	//redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

	// Set the list data received from the client component
	setListData(data: any[]) {
		this.listData = data;
	}

	selectAllRecords() {
		this.selectedIds = []
		this.selectedIds = [...this.allIds]

		this.dataSource.data.forEach(row => {
			if (this.selectedIds.includes(row.id)) {
			this.selection.select(row);
			}
		});
	}

	unselectAllRecords() {
		this.selection.clear();
		this.selectedIds = [];
	
	}
}
