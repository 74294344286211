import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SnackbarService } from '../../snackbar.service';
import { Invoice } from './invoice.interface';
import { InvoiceLine } from './invoice-line.interface';
import { MatSelect } from '@angular/material/select';
import { tap, filter, finalize } from 'rxjs/operators';
import { CommonDropdownService } from '../../common-dropdown.service';
import { environment } from "../../../../environments/environment";
import { DateStorageService } from '../../date-storage.service';

@Component({
	selector: 'app-main-invoice-form',
	templateUrl: './main-invoice-form.component.html',
	styleUrls: ['./invoice-form.component.css']
})
export class MainInvoiceFormComponent implements OnInit {

    @ViewChild('doctor_id', { static: false }) doctor_id: MatSelect;// for dropdown
	@ViewChild('jobInput') jobInput: ElementRef<HTMLInputElement>;
	@ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;

	constructor(
		private route: ActivatedRoute,
		private api: CommonApiService,
		private dialog: MatDialog,
        private router: Router,
        private snackbar: SnackbarService,
        private dropdownService: CommonDropdownService,
        private dateStorageService: DateStorageService
		
	) {}

	public model = 'invoice'
	public invoice = {} as Invoice;

	doctorSearchPage = 0;
    allDoctorsCount = 0
    public allDoctors: any[] = [];
    public selectedDoctor: number;
    public selectedDoctorObject: any[] = [];

	public allCompanies: any[] = [];
	public filteredCompanies = this.allCompanies;
	public selectedCompany: number;

	public allChallans: any[] = [];
	public notInvoicedChallans = this.allChallans;

	public challanJobs: any[] = [];
	public pickedChallanJobs = this.challanJobs;

	isFormDisabled = true;
	result: string;
	selectable = true;
	removable = true;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	challanCtrl = new FormControl();
	selectedChallanChips: string[] = [];
	selectedChallanChipsId: number[] = [];

	dataSource = new MatTableDataSource<any>();
	displayedColumns: any[] = ['job_no', 'product_name', 'product_units', 'rate', 'discount_percent', 'amount_total'];

	renewedList: any[] = [];
	tempListData:any[] = [];
	tempSelectedChallanChipsId:any[] = [];
	public invoiceLines: InvoiceLine[] = [];
	public tempInvLine: InvoiceLine;

	afterRemovedList:any[]=[];
	tempJobsArray:any[]=[];
	tempJobsPolulateArray:any[]=[];
	remainingChallan:any=[];
	addRemovedChallan:any=[];
	price:any
	disableDoctorSelection: boolean;
	onSaveDisable = false;
	
    minDate: Date | null = new Date();
    maxDate: Date | null = new Date();

	ngOnInit(): void {
		this.disableDoctorSelection = false;

		this.api.getAll('company').subscribe(companies => {
			this.allCompanies = companies;
			this.filteredCompanies = companies;
		})

		let invoiceId = this.route.snapshot.params.id
		if (invoiceId === 'create') {
			this.isFormDisabled = false;
		}
		else {
			this.disableDoctorSelection = true;
			this.getInvoice(Number(invoiceId));
		}
		this.onLoadDoctorList();
		
		this.minDate = this.dateStorageService.getDateFromLocalStorage('fy_start_date')
        this.maxDate = this.dateStorageService.getDateFromLocalStorage('fy_end_date')
	}

	//api call to bring search values in dropdown and also the selected value in dropdown is displayed
    onDoctorKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'doctor', this.allDoctors, this.selectedDoctorObject, 'doctorSearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allDoctors = result.entityList;
            this.doctorSearchPage = result.pageVariable;
        });
    }

    // On scroll of dropdown api call to fetch next records
    onDoctorScroll(event:any) {

        if (!this.doctor_id?.panel || !this.doctor_id?.panel.nativeElement) {
            // Panel or nativeElement is not available, return or handle the situation accordingly.
            return;
        }
        if (event) {
            this.doctor_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.doctor_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.doctor_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.doctor_id.panel.nativeElement.offsetHeight)
                    ){
                    let doctorparams = {
                        'pageIndex': this.doctorSearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }
                    
                    if (this.allDoctors.length >= this.allDoctorsCount) {
                        return;
                    }
                    this.api.postAll('doctor/list/display', doctorparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedDoctorObject[0]?.id);
                        this.allDoctors.push(...res.items);
                    });
                    this.doctorSearchPage++;// send page number for fetching the records
                }
            });
        }
    }

    // On load of component default pageSize for dropdown to load and also set selected value if present
    onLoadDoctorList() {
        let params = {
            'pageIndex': this.doctorSearchPage,
            'pageSize': environment.defaultPageSizeOnScroll
        }

        this.api.postAll('doctor/list/display', params).pipe(
            tap((res: any) => {
                this.allDoctors.push(...res.items);
                this.allDoctorsCount = res.rowCount
            }),
            filter(() => this.selectedDoctorObject.length !== 0),
            tap(() => {
              const selectedDoctorId = this.selectedDoctorObject[0].id;
              this.allDoctors = this.allDoctors.filter(doctor => doctor.id !== selectedDoctorId);
              this.allDoctors.unshift(this.selectedDoctorObject[0]);
              
            }),
            finalize(() => this.doctorSearchPage++)
        ).subscribe();
    }

	searchDoctors(value: string) {
		let filter = value.toLowerCase();
		return this.allDoctors.filter(option =>
			option.name.toLowerCase().includes(filter)
		);
	}

	onCompanyKey = (event: Event) => {
		var value = (event.target as HTMLInputElement).value;
		this.filteredCompanies = this.searchCompanies(value);		
	}

	searchCompanies(value: string) {
		let filter = value.toLowerCase();
		return this.allCompanies.filter(option =>
			option.name.toLowerCase().includes(filter)
		);
	}

	add(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();
		if (value) {
			this.selectedChallanChips.push(value);
		}
		event.chipInput!.clear();
		this.challanCtrl.setValue(null);
	}

	remove(challan: any): void {
		// For refreshing dropdown
		this.searchChips(challan);
		this.addRemovedChallan = this.searchChips(challan);
		this.notInvoicedChallans.push(this.addRemovedChallan[0]);		
		const index = this.selectedChallanChips.indexOf(challan);
		this.dataSource=new MatTableDataSource<any>();

		 if (index >= 0) {
		 	this.selectedChallanChips.splice(index, 1);
		 	this.selectedChallanChipsId.splice(index, 1);
		}

		if(this.selectedChallanChips.length == 0) {
			this.invoice.invoice_lines = []
			this.calculateTaxesAndTotals(this.invoice)
			this.dataSource=new MatTableDataSource<any>();
		}
		else {
			if (this.invoice.invoice_lines) {
				this.renewedList  = Object.assign([], this.invoice.invoice_lines);
				this.renewedList.forEach(element => {
					if (element.challan_id == this.addRemovedChallan[0].id) {
						const index = this.invoice.invoice_lines.indexOf(element);
						this.invoice.invoice_lines.splice(index, 1)
					}
				});
			}
			this.calculateTaxesAndTotals(this.invoice)
			this.dataSource = new MatTableDataSource<any>(this.invoice.invoice_lines);
		}
	}

	selected(event: any): void {
		this.chipInput.nativeElement.blur();
		var challan = event.option.value
		var jobs = challan.jobs;
		var challan_no = challan.challan_no;

		jobs.forEach((job: any) => {
			job.order_lines.forEach((job_line:any) => {
				this.tempInvLine = <InvoiceLine>{};
				this.tempInvLine.challan_id = challan.id
				this.tempInvLine.job_id = job.id
				this.tempInvLine.job_no = job.job_no
				this.tempInvLine.discount_percent = 0
				this.tempInvLine.job_line_id = job_line.id
				this.tempInvLine.product_units = job_line.product_units
				this.tempInvLine.product_id = job_line.product.id
				this.tempInvLine.product_name = job_line.product.name
				this.tempInvLine.rate = job_line.product.rate
				
				if (this.invoice.invoice_lines) {
					this.invoice.invoice_lines.push(this.tempInvLine);
				}
				else {
					this.invoice.invoice_lines = [this.tempInvLine]
				}
			});
			
		});
		this.calculateTaxesAndTotals(this.invoice)
		this.dataSource = new MatTableDataSource<any>(this.invoice.invoice_lines);
		this.removeChallanFromFilteredChallans(challan_no);
		this.notInvoicedChallans=this.removeChallanFromFilteredChallans(challan_no);
		this.selectedChallanChips.push(challan_no);
		this.selectedChallanChipsId.push(event.option.value.id);
		this.challanCtrl.setValue(null);
		this.selectedChallanChips = this.selectedChallanChips;
		this.selectedChallanChipsId = this.selectedChallanChipsId;

	}

	searchChips(value: any) {
		return this.allChallans.filter(object => {
			return object.challan_no == value;
		});
	}
	
	removeChallanFromFilteredChallans(value: any) {
		return this.notInvoicedChallans.filter(object => {
			return object.challan_no != value;
		});
	}

	getChallanId(value: any){
		return this.allChallans.filter(object => {
			return object.id == value;
		});
	}

	getJobDetails(value: any){
		return this.challanJobs.filter(object => {
			return object.id == value;
		});
	}


	getInvoice(invoiceId: number) {
		this.api.get(this.model, invoiceId).subscribe(
			(data: any) => {
				if (data) {

					this.invoice = data;
					if(data.doctor){
                        this.selectedDoctor = data.doctor.id;
                        this.allDoctors = this.allDoctors.filter(doctor => doctor.id !== data.doctor.id);
                        this.allDoctors.push(data.doctor) // insert specific selected doctor into the dropdown list
                        this.selectedDoctorObject.push(data.doctor)
                    }
					this.selectedCompany = data.company.id;
					this.selectedChallanChipsId= data.challan_ids;
					this.api.callRoute('challan', '/doctor_challans', {'doctor_id': this.selectedDoctor, 'type': 'main'}).subscribe(
						(challans: any) => {
							if (challans) {
								this.allChallans = challans.all_challans
								this.notInvoicedChallans = challans.not_invoiced_challans
								this.challanJobs = [];
								this.allChallans.forEach(element => {
									this.challanJobs.push(element.jobs)
								});
							}

							//  TODO: on doctor change, 1 -> 2 -> 1, populate correct challans
							this.selectedChallanChipsId.forEach((val:any) => {
								this.tempSelectedChallanChipsId = this.getChallanId(val);
								this.selectedChallanChips.push(this.tempSelectedChallanChipsId[0].challan_no);
							}); 

							for (let q = 0; q < this.selectedChallanChips.length; q = q + 1) {
								this.removeChallanFromFilteredChallans(this.selectedChallanChips[q]);
								this.notInvoicedChallans=this.removeChallanFromFilteredChallans(this.selectedChallanChips[q]);
							}
							this.dataSource = new MatTableDataSource<any>(this.invoice.invoice_lines);
						}
					)

					// this.listData =[];
				}
			}
		);	
	}

	save(formValid:any) {
		if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
		if(this.selectedDoctor == undefined){
            alert("Please select doctor name.");
            return;
        }
		if(this.selectedCompany == undefined){
            alert("Please select company.");
            return;
        }
		if(this.selectedChallanChipsId.length <= 0){
            alert("Please select atleast 1 challan.");
            return;
        }
		
		this.invoice.doctor_id = this.selectedDoctor
		this.invoice.company_id = this.selectedCompany
		this.invoice.status = 'draft';
		this.invoice.type = 'main';
		
		this.onSaveDisable = true;

		this.api.callRoute(this.model, '/calculate', this.invoice).subscribe(
			(calculatedData: any) => {
				this.invoice = calculatedData;
				this.api.createUpdate(this.model, this.invoice).subscribe(
					(data: any) => {
						if (data) {
							this.invoice = data;
							this.isFormDisabled = true;
							this.snackbar.success('Saved Successfully!');
							this.dataSource = new MatTableDataSource<any>(this.invoice.invoice_lines);
							this.onSaveDisable = false;
						}
					},
					error => {
						alert(error.error.message)
						this.onSaveDisable = false;
					}
				);
			}
		);
	}

	edit(){
        this.isFormDisabled = false;
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			if (this.result) {
                if(this.invoice.id){
                    this.isFormDisabled = true;
					// this.listData=[];
					this.tempListData=[];
					this.tempSelectedChallanChipsId=[];
					this.selectedChallanChips=[];
                    this.getInvoice(this.invoice.id);
                }
				else {
                    this.router.navigate([`/proforma`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('proforma/create');
    }

    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }
	
	calculateTaxesAndTotals(invoice:any) {
		invoice.doctor_id = this.selectedDoctor
		invoice.company_id = this.selectedCompany
		if (!(this.selectedChallanChipsId.length > 0 && invoice.doctor_id > 0 && invoice.company_id > 0)) {
			return;
		}
		this.api.callRoute(this.model, '/calculate', invoice).subscribe(
			(data: any) => {
				this.invoice = data;
				this.dataSource = new MatTableDataSource<any>(this.invoice.invoice_lines);
			}
		);
	}

	keyPressNumbersDecimal(evt:any) {
		var charCode = (evt.which) ? evt.which : evt.keyCode;
          if (charCode != 46 && charCode > 31 
            && (charCode < 48 || charCode > 57))
             return false;

          return true;
	}


	onDoctorChange() {
		this.api.callRoute('challan', '/doctor_challans', {'doctor_id': this.selectedDoctor, 'type': 'main'}).subscribe(
			(challans: any) => {
				if (challans) {
					this.allChallans = challans.all_challans
					this.notInvoicedChallans = challans.not_invoiced_challans
					this.challanJobs = [];
					this.allChallans.forEach(element => {
						this.challanJobs.push(element.jobs)
 					});
				}

				this.selectedChallanChipsId = [];
				this.selectedChallanChips = [];
				this.invoice.invoice_lines = [];
				this.calculateTaxesAndTotals(this.invoice)
				this.dataSource = new MatTableDataSource<any>([])
			}
		)
	}

	onCompanyChange() {
		this.calculateTaxesAndTotals(this.invoice)
	}

	confirmDialog(): void {
		const message = `Are you sure you want submit Invoice?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Submit Invoice",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
				this.submitInvoice();
			}
		});
	}

	cancelConfirmDialog(): void {

		const message = 'Are you sure you want to cancel invoice?'
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Cancel invoice",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
				this.api.callRoute(this.model, `/invoice/cancel/${this.invoice.id}`, null).subscribe(
					(data: any) => {
						if (data) {
							this.invoice = data;
							this.isFormDisabled = true;
							this.snackbar.success('Cancelled Successfully!');
						}
					},
					error => {
						alert(error.error.message)
						console.log(error)
					}
				);
			}
		});
	}

	submitInvoice() {
		this.api.callRoute(this.model, `/${this.invoice.id}/submit`, this.invoice).subscribe(
			(data: any) => {
				if (data) {
					this.invoice = data;
                    this.snackbar.success('Invoice submitted successfully');
				}
			},
			error => {
				alert(error.error.message)
				console.log(error)
			}
		);
	}
}
