import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SpeakerService } from '../../../speaker.service';

@Component({
	selector: 'app-report-list-container',
	templateUrl: './report-list-container.component.html',
	styleUrls: ['./report-list-container.component.css']
})
export class ReportListContainerComponent implements OnInit {

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	public displayColumns: any[];
	public displayHeaders: any[];
	public listData: any[] = [];
	dataSource = new MatTableDataSource<any>(this.listData);
	IsWait = false;

	constructor(
		private speaker: SpeakerService
	) { }

	ngOnInit(): void {
		this.listData = this.speaker.listData
		this.displayHeaders = this.speaker.displayHeaders;
		this.displayColumns = this.speaker.displayColumns;
		this.dataSource = new MatTableDataSource<any>(this.listData);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	public doFilter = (event: Event) => {
		var value = (event.target as HTMLInputElement).value;

		if (typeof value === 'string') {
			this.dataSource.filter = value.trim().toLocaleLowerCase();
		}
	}
	
}


