<form #prodForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-9 form-breadcrumb">
            <h3><a [routerLink]="['/category']" routerLinkActive="router-link-active">Product Category</a> > {{category.name}}</h3>
        </div>
        <div class="col-3 form-operation">
            <button *ngIf="!isFormDisabled" [disabled]="onSaveDisable" class="m-2" type="submit" mat-raised-button color="primary" (click)='save(prodForm.valid)'>Save</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="primary" (click)='create()'>Create</button>
            <button *ngIf="!isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>
    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Name <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #name="ngModel" type="text" matInput name="name" [(ngModel)]="category.name" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="name.touched && name.invalid">Name Required</div>
                </div>
            </div>

            <mat-tab-group animationDuration="0ms" class="mt-5">
                <mat-tab id="digital_processes" label="Digital Processes">
                    <div class="example-container col-sm-6">
                        <h4>Digital Processes</h4>
                        <div [ngClass]="{'disableOnClick':isFormDisabled}" cdkDropList #todoList="cdkDropList" [cdkDropListData]="unmappedDigitalProcess" [cdkDropListConnectedTo]="[doneList]" class="example-list" (cdkDropListDropped)="drop($event)">
                            <div class="example-box" *ngFor="let item of unmappedDigitalProcess" cdkDrag>{{item['name']}}</div>
                        </div>
                    </div>
                    <div class="example-container col-sm-6">
                        <h4>Mapped Digital Processes</h4>
                        <div [ngClass]="{'disableOnClick':isFormDisabled}" cdkDropList #doneList="cdkDropList" [cdkDropListData]="mappedDigitalProcess" [cdkDropListConnectedTo]="[todoList]" class="example-list" (cdkDropListDropped)="drop($event)" required>
                            <div class="example-box" *ngFor="let item of mappedDigitalProcess" cdkDrag>{{item['name']}}</div>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab id="physical_processes" label="Physical Processes">
                    <div class="example-container col-sm-6">
                        <h4>Physical Processes</h4>
                        <div [ngClass]="{'disableOnClick':isFormDisabled}" cdkDropList #todoList1="cdkDropList" [cdkDropListData]="unmappedPhysicalProcess" [cdkDropListConnectedTo]="[doneList1]" class="example-list" (cdkDropListDropped)="drop($event)">
                            <div class="example-box" *ngFor="let item of unmappedPhysicalProcess" cdkDrag>{{item['name']}}</div>
                        </div>
                    </div>
                    <div class="example-container col-sm-6">
                        <h4>Mapped Physical Processes</h4>
                        <div [ngClass]="{'disableOnClick':isFormDisabled}" cdkDropList #doneList1="cdkDropList" [cdkDropListData]="mappedPhysicalProcess" [cdkDropListConnectedTo]="[todoList1]" class="example-list" (cdkDropListDropped)="drop($event)" required>
                            <div class="example-box" *ngFor="let item of mappedPhysicalProcess" cdkDrag>{{item['name']}}</div>
                        </div>
                    </div>
                </mat-tab>

            </mat-tab-group>

            <div cdkDropList #listOne="cdkDropList" [cdkDropListConnectedTo]="[listTwo]"></div>
            <div cdkDropList #listTwo="cdkDropList" [cdkDropListConnectedTo]="[listOne]"></div>
        </fieldset>
    </div>
</form>