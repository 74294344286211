import { State } from "../state/state.interface";
export interface Company {
    id: number;
    company_name: string;
    email_id: string;
    gst_number: string;
    pan_number: string;
    bank_name_and_branch: string;
    bank_acount_number: string;
    ifsc_code: string;
    cheque_in_favour_of: string;
    contact_number: string;
    gst_percentage: number;
    address_1: string;
    address_2: string;
    city_id: number;
    state_id: number;
    pincode: string;
    state: State;
  };

export var displayColumns = [
  'company_name',
  'email_id',
]
export var displayHeaders = [
  'Name',
  'Email'
]
