<div class="container-fluid pt-4 pl-4">
    <h3>Access Control</h3>
</div>

<div class="p-4">
    <div class="row">
        <label for="description" class="col-sm-2 col-form-label">Select User Role</label>
        <div class="col-sm-3">
            <mat-form-field class="MatFormFieldControl">
                <mat-select (selectionChange)="onRoleChange()" [(value)]="selectedRole">
                    <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keyup)="onRoleKey($event)" matInput name="role_list">
                    <mat-option *ngFor="let role of filteredRoles" [value]="role">{{role.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="showContent" class="col-sm-4 p-4">
            <button type="button" mat-flat-button color="primary" (click)='save()'>Save</button>
        </div>
    </div>
</div>


<table *ngIf="showContent" style="width:50%" mat-table matSort [dataSource]="dataSource" class="mat-elevation-z2 p-4">

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Menus</th>
        <td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
    </ng-container>

    <ng-container matColumnDef="no">
        <th mat-header-cell *matHeaderCellDef>Yes / No</th>
        <td mat-cell *matCellDef="let element">
            <mat-slide-toggle (change)="onChangeToggle(element.name,$event)" [checked]="selectedRole.access.includes(element.name)">
            </mat-slide-toggle>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>