export interface Account {
    id: number;
    type: string;
    name:string;
    tally_account:string;
    company_id:number;
    company_name:string;
  };

export var displayColumns = [
  'name',
  'type',
  'tally_account',
  'company_name'
]
export var displayHeaders = [
  'Name',
  'Type',
  'Tally Account',
  'Company' 
]
// actual database columns
export var orderByColumns = [
  'name',
  'type',
  'tally_account',
  'company_name'
]

// for dropdown the options are set via ts file, so modify the code there
export const filterParameters = [
  { 
      name: 'created_at', 
      type: 'date_range', 
      operator: 'between', 
      label: 'Select Date Range' 
  },
  { 
    name: 'name', 
    type: 'string', 
    operator: 'like', 
    label: 'Account Name', 
  },
  { 
    name: 'tally_account', 
    type: 'string', 
    operator: 'like', 
    label: 'Tally Account', 
  },
  { 
    name: 'company_id', 
    type: 'dropdown', 
    operator: '=',
    model_name:'company',
    label: 'Company' 
  },
  { 
    name: 'type', 
    type: 'radio_option', 
    operator: '=',
    values: [
                { label: 'Cash', value: "cash" },
                { label: 'Bank', value: "bank" },
                { label: 'Other', value: "other" }
            ],
    model_name:'account',
    label: 'Type' 
},
{ 
  name: 'order_by_columns', 
  type: 'multi_select', 
  operator: 'order_by_columns',
  values: displayHeaders.map((header, index) => ({
          label: header,
          value: orderByColumns[index]
      })),
  model_name:'account',
  label: 'Select columns for order by' 
}
]; 
