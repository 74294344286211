import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SnackbarService } from '../../snackbar.service';
import { SalesReturn } from './sales-return.interface';
import { SalesLine } from './sales-return-line.interface';
import { MatSelect } from '@angular/material/select';
import { tap, filter, finalize } from 'rxjs/operators';
import { CommonDropdownService } from '../../common-dropdown.service';
import { environment } from "../../../../environments/environment";
import { DateStorageService } from '../../date-storage.service';
import { MatTable } from '@angular/material/table';


interface Sales_Type {
    value: string;
    viewValue: string;
}

@Component({
	selector: 'app-sales-return-form',
	templateUrl: './sales-return-form.component.html',
	styleUrls: ['./sales-return-form.component.css']
})

export class SalesReturnFormComponent implements OnInit {

	@ViewChild('sales_lines') sales_lines: MatTable<any>;
	@ViewChild('jobInput') jobInput: ElementRef<HTMLInputElement>;
	@ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
    @ViewChild('doctor_id', { static: false }) doctor_id: MatSelect;// for dropdown
    @ViewChild('account_id', { static: false }) account_id: MatSelect;// for dropdown

	constructor(
		private route: ActivatedRoute,
		private api: CommonApiService,
		private dialog: MatDialog,
        private router: Router,
        private snackbar: SnackbarService,
        private dropdownService: CommonDropdownService,
        private dateStorageService: DateStorageService
	) {}

	public model = 'sales_return'
	public sales_return = {} as SalesReturn;

	doctorSearchPage = 0;
    allDoctorsCount = 0
    public allDoctors: any[] = [];
    public selectedDoctor: number;
    public selectedDoctorObject: any[] = [];

	accountSearchPage = 0;
    allAccountsCount = 0
    public allAccounts: any[] = [];
    public selectedAccount: number;
    public selectedAccountObject: any[] = [];

	public allCompanies: any[] = [];
	public filteredCompanies = this.allCompanies;
	public selectedCompany: number;

	public allInvoices: any[] = [];
	public notReturnedInvoices = this.allInvoices;

	public invoiceJobs: any[] = [];
	public pickedInvoiceJobs = this.invoiceJobs;

	public selectedSalesType: string;

	isFormDisabled = true;
	result: string;
	selectable = true;
	removable = true;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	invoiceCtrl = new FormControl();
	selectedInvoiceChips: string[] = [];
	selectedInvoiceChipsId: number[] = [];

	dataSource = new MatTableDataSource<any>();
	displayedColumns: any[] = ['delete', 'job_no',  'product_name', 'product_units', 'rate', 'change_rate', 'discount_percent', 'amount_total'];

	renewedList: any[] = [];
	tempListData:any[] = [];
	tempSelectedInvoiceChipsId:any[] = [];
	public salesLines: SalesLine[] = [];
	public tempSalesLine: SalesLine;

	afterRemovedList:any[]=[];
	tempJobsArray:any[]=[];
	tempJobsPolulateArray:any[]=[];
	remainingInvoice:any=[];
	addRemovedInvoice:any=[];
	price:any
	disableDoctorSelection: boolean;
	onSaveDisable = false;

    minDate: Date | null = new Date();
    maxDate: Date | null = new Date();

	sales_ret_type: Sales_Type[] = [
        {value: 'sales_return', viewValue: 'Sales Return'},
        {value: 'change_rate', viewValue: 'Change Rate'},
    ];

	ngOnInit(): void {
		this.disableDoctorSelection = false;
		this.api.getAll('company').subscribe(companies => {
			this.allCompanies = companies;
			this.filteredCompanies = companies;
		})

		let salesId = this.route.snapshot.params.id
		if (salesId === 'create') {
			this.isFormDisabled = false;
		}
		else {
			this.disableDoctorSelection = true;
			this.getSalesReturn(Number(salesId));
		}
		this.onLoadDoctorList();

		this.minDate = this.dateStorageService.getDateFromLocalStorage('fy_start_date')
        this.maxDate = this.dateStorageService.getDateFromLocalStorage('fy_end_date')
	}

	//api call to bring search values in dropdown and also the selected value in dropdown is displayed
    onDoctorKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'doctor', this.allDoctors, this.selectedDoctorObject, 'doctorSearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allDoctors = result.entityList;
            this.doctorSearchPage = result.pageVariable;
        });
    }

    // On scroll of dropdown api call to fetch next records
    onDoctorScroll(event:any) {

        if (!this.doctor_id?.panel || !this.doctor_id?.panel.nativeElement) {
            // Panel or nativeElement is not available, return or handle the situation accordingly.
            return;
        }
        if (event) {
            this.doctor_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.doctor_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.doctor_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.doctor_id.panel.nativeElement.offsetHeight)
                    ){
                    let doctorparams = {
                        'pageIndex': this.doctorSearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }
                    
                    if (this.allDoctors.length >= this.allDoctorsCount) {
                        return;
                    }
                    this.api.postAll('doctor/list/display', doctorparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedDoctorObject[0]?.id);
                        this.allDoctors.push(...res.items);
                    });
                    this.doctorSearchPage++;// send page number for fetching the records
                }
            });
        }
    }

    // On load of component default pageSize for dropdown to load and also set selected value if present
    onLoadDoctorList() {
        let params = {
            'pageIndex': this.doctorSearchPage,
            'pageSize': environment.defaultPageSizeOnScroll
        }

        this.api.postAll('doctor/list/display', params).pipe(
            tap((res: any) => {
                this.allDoctors.push(...res.items);
                this.allDoctorsCount = res.rowCount
            }),
            filter(() => this.selectedDoctorObject.length !== 0),
            tap(() => {
              const selectedDoctorId = this.selectedDoctorObject[0].id;
              this.allDoctors = this.allDoctors.filter(doctor => doctor.id !== selectedDoctorId);
              this.allDoctors.unshift(this.selectedDoctorObject[0]);
              
            }),
            finalize(() => this.doctorSearchPage++)
        ).subscribe();
    }

	searchDoctors(value: string) {
		let filter = value.toLowerCase();
		return this.allDoctors.filter(option =>
			option.name.toLowerCase().includes(filter)
		);
	}

	onCompanyKey = (event: Event) => {
		var value = (event.target as HTMLInputElement).value;
		this.filteredCompanies = this.searchCompanies(value);		
	}

	searchCompanies(value: string) {
		let filter = value.toLowerCase();
		return this.allCompanies.filter(option =>
			option.name.toLowerCase().includes(filter)
		);
	}

	add(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();
		if (value) {
			this.selectedInvoiceChips.push(value);
		}
		event.chipInput!.clear();
		this.invoiceCtrl.setValue(null);
	}

	remove(invoice: any): void {
		// For refreshing dropdown
		this.searchChips(invoice);
		this.addRemovedInvoice = this.searchChips(invoice);
		this.notReturnedInvoices.push(this.addRemovedInvoice[0]);		
		const index = this.selectedInvoiceChips.indexOf(invoice);
		this.dataSource=new MatTableDataSource<any>();

		 if (index >= 0) {
		 	this.selectedInvoiceChips.splice(index, 1);
		 	this.selectedInvoiceChipsId.splice(index, 1);
		}

		if(this.selectedInvoiceChips.length == 0) {
			this.sales_return.sales_lines = []
			this.calculateTaxesAndTotals(this.sales_return)
			this.dataSource=new MatTableDataSource<any>();
		}
		else {
			if (this.sales_return.sales_lines) {
				this.renewedList  = Object.assign([], this.sales_return.sales_lines);
				this.renewedList.forEach(element => {
					if (element.challan_id == this.addRemovedInvoice[0].id) {
						const index = this.sales_return.sales_lines.indexOf(element);
						this.sales_return.sales_lines.splice(index, 1)
					}
				});
			}
			this.calculateTaxesAndTotals(this.sales_return)
			this.dataSource = new MatTableDataSource<any>(this.sales_return.sales_lines);
		}
	}

	selected(event: any): void {
		this.chipInput.nativeElement.blur();
		var invoice = event.option.value
		var jobs = invoice.invoice_lines;
		var invoice_no = invoice.invoice_no;

		if(this.selectedInvoiceChipsId.length > 0) {
			alert('You can only select only one invoice');
			return;
		}
		jobs.forEach((job_line:any) => {
			
			this.tempSalesLine = <SalesLine>{};
			this.tempSalesLine.invoice_id = job_line.invoice_id
			this.tempSalesLine.job_id = job_line.job_id
			this.tempSalesLine.job_no = job_line.job_no
			this.tempSalesLine.discount_percent = job_line.discount_percent
			this.tempSalesLine.change_rate = job_line.change_rate ? job_line.change_rate : 0
			this.tempSalesLine.job_line_id = job_line.job_line_id
			this.tempSalesLine.product_units = job_line.product_units
			this.tempSalesLine.product_id = job_line.product.id
			this.tempSalesLine.product_name = job_line.product.name
			this.tempSalesLine.rate = job_line.product.rate
			// if is_sales_returned is true skip that row and also
			// sales_return_id is not equal to line sales_return_id then skip that row
			if(job_line.is_sales_returned && job_line.sales_return_id != this.sales_return.id) {
				return;
			}

			if (this.sales_return.sales_lines) {
				this.sales_return.sales_lines.push(this.tempSalesLine);
			} else {
				this.sales_return.sales_lines = [this.tempSalesLine]
			}
		});

		this.selectedInvoiceChipsId.push(event.option.value.id);
		this.selectedInvoiceChips.push(invoice_no);
		this.invoiceCtrl.setValue(null);
		this.selectedInvoiceChips = this.selectedInvoiceChips;
		
		this.calculateTaxesAndTotals(this.sales_return)
		this.dataSource = new MatTableDataSource<any>(this.sales_return.sales_lines);
		this.removeInvoiceFromFilteredInvoices(invoice_no);
		this.notReturnedInvoices=this.removeInvoiceFromFilteredInvoices(invoice_no);
		

	}

	searchChips(value: any) {
		return this.allInvoices.filter(object => {
			return object.invoice_no == value;
		});
	}
	
	removeInvoiceFromFilteredInvoices(value: any) {
		return this.notReturnedInvoices.filter(object => {
			return object.invoice_no != value;
		});
	}

	getInvoiceId(value: any){
		return this.allInvoices.filter(object => {
			return object.id == value;
		});
	}

	getJobDetails(value: any){
		return this.invoiceJobs.filter(object => {
			return object.id == value;
		});
	}


	getSalesReturn(salesId: number) {
		this.api.get(this.model, salesId).subscribe(
			(data: any) => {
				if (data) {

					this.sales_return = data;
					this.OnLoadAccountList();
					if(data.doctor){
                        this.selectedDoctor = data.doctor.id;
                        this.allDoctors = this.allDoctors.filter(doctor => doctor.id !== data.doctor.id);
                        this.allDoctors.push(data.doctor) // insert specific selected doctor into the dropdown list
                        this.selectedDoctorObject.push(data.doctor)
						
                    }
					this.selectedCompany = data.company.id;

					this.selectedAccount = data.account.id;
					this.selectedSalesType = data.sales_ret_type;
                    this.allAccounts = this.allAccounts.filter(account => account.id !== data.account.id);
                    this.allAccounts.push(data.account) // insert specific selected doctor into the dropdown list
                    this.selectedAccountObject.push(data.account)

					this.api.callRoute('invoice', '/doctor_invoices', {'doctor_id': this.selectedDoctor, 'company_id': this.selectedCompany}).subscribe(
						(invoices: any) => {
							if (invoices) {
								this.allInvoices = invoices.all_invoices
								this.notReturnedInvoices = invoices.not_returned_invoices
								this.invoiceJobs = [];
								this.allInvoices.forEach(element => {
									this.invoiceJobs.push(element.all_invoices)
								});
							}
							

							this.tempSelectedInvoiceChipsId = this.getInvoiceId(data.invoice_id);
							this.selectedInvoiceChips.push(this.tempSelectedInvoiceChipsId[0].invoice_no);
							this.selectedInvoiceChipsId.push(data.invoice_id);
							
							for (let q = 0; q < this.selectedInvoiceChips.length; q = q + 1) {
								this.removeInvoiceFromFilteredInvoices(this.selectedInvoiceChips[q]);
								this.notReturnedInvoices=this.removeInvoiceFromFilteredInvoices(this.selectedInvoiceChips[q]);
							}
							this.dataSource = new MatTableDataSource<any>(this.sales_return.sales_lines);
						}
					)
				}
			}
		);	
	}

	save(formValid:any) {
		if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
		if(this.selectedDoctor == undefined){
            alert("Please select doctor name.");
            return;
        }
		if(this.selectedCompany == undefined){
            alert("Please select company.");
            return;
        }
		if(this.selectedInvoiceChipsId.length <= 0){
            alert("Please select atleast 1 invoice.");
            return;
        }
		if(this.sales_return.sales_lines.length <= 0){
			alert("Please add atleast 1 product.");
			return;
		}
		if(this.selectedAccount == undefined){
			alert("Please select Account.");
			return;
		}

		this.sales_return.doctor_id = this.selectedDoctor
		this.sales_return.company_id = this.selectedCompany
		this.sales_return.account_id = this.selectedAccount
		this.sales_return.invoice_id = this.selectedInvoiceChipsId
		this.sales_return.status = 'draft';
		this.sales_return.sales_ret_type = this.selectedSalesType;
		
		this.onSaveDisable = true;

		this.api.callRoute(this.model, '/calculate', this.sales_return).subscribe(
			(calculatedData: any) => {
				this.sales_return = calculatedData;
				this.api.createUpdate(this.model, this.sales_return).subscribe(
					(data: any) => {
						if (data) {
							this.sales_return = data;
							this.isFormDisabled = true;
							this.snackbar.success('Saved Successfully!');
							this.dataSource = new MatTableDataSource<any>(this.sales_return.sales_lines);
							this.onSaveDisable = false;
						}
					},
					error => {
						alert(error.error.message)
						this.onSaveDisable = false;
					}
				);
			}
		);
	}

	edit(){
        this.isFormDisabled = false;
		this.disableDoctorSelection = true;
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			if (this.result) {
                if(this.sales_return.id){
                    this.isFormDisabled = true;
					// this.listData=[];
					this.tempListData=[];
					this.tempSelectedInvoiceChipsId=[];
					this.selectedInvoiceChips=[];
                    this.getSalesReturn(this.sales_return.id);
                }
				else {
                    this.router.navigate([`/sales_reuturn`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('sales_return/create');
    }

    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }
	
	calculateTaxesAndTotals(sales_return:any) {
		sales_return.doctor_id = this.selectedDoctor
		sales_return.company_id = this.selectedCompany
		sales_return.sales_ret_type = this.selectedSalesType

		if (!(this.selectedInvoiceChipsId.length > 0 && sales_return.doctor_id > 0 && sales_return.company_id > 0)) {
			return;
		}
		
		this.api.callRoute(this.model, '/calculate', sales_return).subscribe(
			(data: any) => {
				this.sales_return = data;
				this.dataSource = new MatTableDataSource<any>(this.sales_return.sales_lines);
			}
		);
	}

	keyPressNumbersDecimal(evt:any) {
		var charCode = (evt.which) ? evt.which : evt.keyCode;
          if (charCode != 46 && charCode > 31 
            && (charCode < 48 || charCode > 57))
             return false;

          return true;
	}

	onDoctorChange() {
		this.onCompanyorDoctororTypeChange();
	}
	onSalesTypeChange() {
		this.onCompanyorDoctororTypeChange();
	}

	onCompanyChange() {
		this.onCompanyorDoctororTypeChange();
		this.OnLoadAccountList();
	}

	onCompanyorDoctororTypeChange() {
		if(this.selectedDoctor == undefined || this.selectedCompany == undefined || this.selectedSalesType == undefined){
			return;
		}
		
		this.api.callRoute('invoice', '/doctor_invoices', {'doctor_id': this.selectedDoctor, 'company_id': this.selectedCompany}).subscribe(
			(invoices: any) => {
				if (invoices) {
					this.allInvoices = invoices.all_invoices
					this.notReturnedInvoices = invoices.not_returned_invoices
					this.invoiceJobs = [];
					this.allInvoices.forEach(element => {
						this.invoiceJobs.push(element.invoice_lines)
 					});
				}

				this.selectedInvoiceChipsId = [];
				this.selectedInvoiceChips = [];
				this.sales_return.sales_lines = [];
				this.calculateTaxesAndTotals(this.sales_return)
				this.dataSource = new MatTableDataSource<any>([])
			}
		)
	}

	OnLoadAccountList() {
        let params = {
            'pageIndex': this.accountSearchPage,
            'pageSize': environment.defaultPageSizeOnScroll,
            'filterCriteria': {
                'company_id': ['dropdown', this.selectedCompany],
                'type': ['=', ['other']],
                "order_by_columns": [
                    "multi_select",
                    null
                ],
                "order_by": "asc"
            }
        }
        this.api.postAll('account/list/display', params).pipe(
            tap((res: any) => {
                this.allAccounts.push(...res.items);
                this.allAccountsCount = res.rowCount
            }),
            filter(() => this.selectedAccountObject.length !== 0),
            tap(() => {
              const selectedAccountId = this.selectedAccountObject[0].id;
              this.allAccounts = this.allAccounts.filter(account => account.id !== selectedAccountId);
              this.allAccounts.unshift(this.selectedAccountObject[0]);
              
            }),
        ).subscribe();
    }

	confirmDialog(): void {
		const message = `Are you sure you want submit Sales Return?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Submit Sales Return",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
				this.submitSalesReturn();
			}
		});
	}


	cancelConfirmDialog(): void {

		const message = 'Are you sure you want to cancel sales return?'
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Cancel sales return",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
				this.api.callRoute(this.model, `/sales_return/cancel/${this.sales_return.id}`, null).subscribe(
					(data: any) => {
						if (data) {
							this.sales_return = data;
							this.snackbar.success('Cancelled Successfully!');
						}
					},
					error => {
						alert(error.error.message)
						console.log(error)
					}
				);
			}
		});
	}

	submitSalesReturn() {
		this.api.callRoute(this.model, `/submit`,  {'id': this.sales_return.id}).subscribe(
			(data: any) => {
				if (data) {
					this.sales_return = data;
					this.isFormDisabled = true;
                    this.snackbar.success('Sales return submitted successfully');
				}
			},
			error => {
				alert(error.error.message)
				console.log(error)
			}
		);
	}

	deleteLine(index: any) {
        if (this.isFormDisabled) {
            return;
        }
        const data = this.dataSource.data;
        data.splice(index, 1);
        this.dataSource.data = data;
        this.sales_return.sales_lines = data;
        this.sales_lines.renderRows();
		this.calculateTaxesAndTotals(this.sales_return)
		
    } 

	//api call to bring search values in dropdown and also the selected value in dropdown is displayed
    onAccountKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'account', this.allAccounts, this.selectedAccountObject, 'accountSearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allAccounts = result.entityList;
            this.accountSearchPage = result.pageVariable;
        });
    }

    // On scroll of dropdown api call to fetch next records
    onAccountScroll(event:any) {

        if (!this.account_id?.panel || !this.account_id?.panel.nativeElement) {
            // Panel or nativeElement is not available, return or handle the situation accordingly.
            return;
        }
        if (event) {
            this.account_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.account_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.account_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.account_id.panel.nativeElement.offsetHeight)
                    ){
                    let accountparams = {
                        'pageIndex': this.accountSearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }
                    
                    if (this.allAccounts.length >= this.allAccountsCount) {
                        return;
                    }
                    this.api.postAll('account/list/display', accountparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedAccountObject[0]?.id);
                        this.allAccounts.push(...res.items);
                    });
                    this.accountSearchPage++;// send page number for fetching the records
                }
            });
        }
    }

	searchAccounts(value: string) {
		let filter = value.toLowerCase();
		return this.allAccounts.filter(option =>
			option.name.toLowerCase().includes(filter)
		);
	}

}
