<form #accountForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-9 form-breadcrumb">
            <h3><a [routerLink]="['/account']" routerLinkActive="router-link-active">Account</a> > {{account.name}}</h3>
        </div>
        <div class="col-3 form-operation">
            <button *ngIf="!isFormDisabled" [disabled]="onSaveDisable" class="m-2" type="submit" mat-raised-button color="primary" (click)='save(accountForm.valid)'>Save</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="primary" (click)='create()'>Create</button>
            <button *ngIf="!isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>
    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Name <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #name="ngModel" type="text" matInput name="name" [(ngModel)]="account.name" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="name.touched && name.invalid">Required</div>
                </div>
                <label for="type" class="col-sm-2 col-form-label">Type <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [disabled]="isFormDisabled" [(value)]="selectedAccountType">
                            <input class="select-input" type="text" autocomplete="nope" matInput name="account_type">
                            <mat-option *ngFor="let accountType of allAccountTypes" [value]="accountType">{{accountType | titlecase }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Tally Account <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #tally_account="ngModel" type="text" matInput name="tally_account" [(ngModel)]="account.tally_account" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="tally_account.touched && tally_account.invalid">Required</div>
                </div>
                <label for="company_id" class="col-sm-2 col-form-label">Company<span class="span-class">
                    *</span></label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <mat-select #company_id (openedChange)="OnCompanyScroll($event)"
                        [disabled]="isFormDisabled || disableCompanySelection" [(value)]="selectedCompany" required>
                        <input class="select-input" placeholder="Search..." type="text" autocomplete="nope"
                            (keydown)="$event.stopPropagation()" (keyup)="OnCompanyKey($event)" matInput
                            name="company_list">
                        <mat-option *ngFor="let company of allCompanies"
                            [value]="company.id">{{company.company_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            </div>
        </fieldset>
    </div>
</form>