
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SpeakerService } from '../../speaker.service';
import { User, displayColumns,displayHeaders, filterParameters } from './user.interface';
import { CommonApiService } from "../../common-api.service";
import { EventEmitterService } from '../../event-emitter.service';
import { environment } from "../../../../environments/environment";
import { DynamicFilterComponent } from '../dynamic-filter/dynamic-filter.component';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RenderFilterDropdownService } from '../../render-filter-dropdown.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})

export class UserComponent implements OnInit {

    
    displayColumns = displayColumns;
    displayHeaders = displayHeaders;
    user: User[] =[];
    filterFields: any[] =[]
    filterCriteria: any[] =[] 
    roleOptions:any[] = [];

    @ViewChild('dynamicFilterContainer', { read: ViewContainerRef, static: true })
	dynamicFilterContainer!: ViewContainerRef;

    constructor(
        public speaker: SpeakerService,
        private api: CommonApiService,
        private eventEmitterService: EventEmitterService,
        private router: Router,
		private dialog: MatDialog,
        private renderFilterDropdown: RenderFilterDropdownService
    ) { }


    ngOnInit(): void {
        //resetting event emmitter variables onload of component
        this.eventEmitterService.pageSize = environment.defaultPageSizeOnLoad
        this.eventEmitterService.pageIndex = environment.defaultPageIndexOnLoad 

        this.getListDetails();
        this.speaker.model = 'auth';
        this.speaker.displayColumns = this.displayColumns;
        this.speaker.displayHeaders = this.displayHeaders;
        this.speaker.listData = this.user;

        //function gets called by default when the component is loaded
        this.eventEmitterService.subsVar = this.eventEmitterService.    
        invokeFirstComponentFunction.subscribe(() => {  
            this.getListDetails();   
        });
        this.onDropdownRenderOptions('userrole/list/display', this.roleOptions);

    }

    // dynamic func for dropdown loading in filters
    onDropdownRenderOptions(apiEndpoint: string, targetOptions: any[]) {
        this.renderFilterDropdown.onDropdownLoadOptions(apiEndpoint, targetOptions);
    }

    ngOnDestroy(): void {
        this.eventEmitterService.subsVar.unsubscribe(); 
    }

    getListDetails() {

        let params = {
            pageIndex: this.eventEmitterService.pageIndex ? this.eventEmitterService.pageIndex: environment.defaultPageIndexOnLoad,
            pageSize: this.eventEmitterService.pageSize ? this.eventEmitterService.pageSize: environment.defaultPageSizeOnLoad,
            filterCriteria: this.filterCriteria
        }

        this.api.postAll('auth/list/display', params).subscribe((res: any) => {

            this.user = res.items;
            this.user.forEach((element) => {
                if(element.role){
                    element.role_name = element.role.name;
                }
            });
            this.speaker.setListData(this.user, res.rowCount, res.pageIndex, res.pageSize);
        });
    }
    // set options for dropdown filters
    getOptionArray<T>(modelName: any): T[] {
        const ModelOptionsMap: Record<string, T[]> = {
          userrole: this.roleOptions,
          // Add other model names and their corresponding options arrays here
        };
        
        const optionKey = ModelOptionsMap[modelName];
        return optionKey;
    }

    openDialogFilter() {
  
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            filterFields: filterParameters.map(field => {
                if (field.type == 'dropdown') {
                    return {
                    ...field,
                    options: this.getOptionArray<typeof field.model_name>(field.model_name),
                    };
                }
                return field;
            }),
        };
        const dialogRef = this.dialog.open(DynamicFilterComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.filterCriteria = result
                this.eventEmitterService.pageIndex = environment.defaultPageIndexOnLoad
                this.eventEmitterService.pageSize = environment.defaultPageSizeOnLoad
                this.getListDetails();
            }
        });
    }

    resetFilters() {
        this.redirectTo(this.speaker.model);
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }
}

