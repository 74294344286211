import { Component, OnInit } from '@angular/core';
import { SpeakerService } from '../../speaker.service';
import { Shade, displayColumns } from './shade.interface';
import { CommonApiService } from "../../common-api.service";

@Component({
  selector: 'app-shade',
  templateUrl: './shade.component.html',
  styleUrls: ['./shade.component.css']
})
export class ShadeComponent implements OnInit {

  displayColumns = displayColumns;
  shade : Shade[];

  constructor(
    private speaker: SpeakerService,
    private api: CommonApiService
  ) { }

  ngOnInit(): void {
    this.speaker.model = 'shade';
    this.speaker.listData = this.shade;
    this.speaker.displayColumns = this.displayColumns
    }
}
