import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonApiService } from '../../common-api.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { JobOrder } from '../joborder/joborder.interface';
import { CommonDropdownService } from '../../common-dropdown.service';
import { environment } from "../../../../environments/environment"
import { MatSelect } from '@angular/material/select';
import { tap, finalize } from 'rxjs/operators';


@Component({
    selector: 'app-prod-job-in',
    templateUrl: './prod-job-in.component.html',
    styleUrls: ['./prod-job-in.component.css']
})
export class ProdJobInComponent implements OnInit, OnDestroy {
    
    @ViewChild('job_id', { static: false }) job_id: MatSelect;// for dropdown

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private api: CommonApiService,
        private dropdownService: CommonDropdownService
        
    ) { }

    result: string = '';
    passwordLength: any;

    jobSearchPage = 0;
    allJobsCount = 0
    public allJobs: any[] = [];
    public selectedJobObject: any[] = [];
    public selectedJob: JobOrder;

    public allComments: any[] = [];

    viewData: boolean;
    processSelected: boolean;
    public employee_id: number;
    public comment_description : string;

    public allProcesses: any[] = [];
    public filteredProcesses = this.allProcesses;
    public selectedProcess: number;
    public product_name: string;
    public process_id: number

    ngOnInit(): void {
        this.employee_id = Number(localStorage.getItem('employee_id'));
        if (!this.employee_id) {
            alert('Employee session expired');
            this.router.navigate(['/employeelogin/in']);
        }

        this.api.getAll('process').subscribe(res => {
            this.allProcesses = res;
            this.filteredProcesses = res;
        })

        this.viewData = false;
    }

    //api call to bring search values in dropdown and also the selected value in dropdown is displayed
	onJobKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResultsSpecificUrl(value, this.process_id, 'joborder/list/in', this.allJobs, this.selectedJobObject, 'jobSearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allJobs = result.entityList;
            this.jobSearchPage = result.pageVariable;
        });
    }

    // On scroll of dropdown api call to fetch next records
    onJobScroll(event:any) {
        if (event) {
            if (!this.job_id?.panel || !this.job_id?.panel.nativeElement) {
                // Panel or nativeElement is not available, return or handle the situation accordingly.
                return;
            }
            this.job_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.job_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.job_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.job_id.panel.nativeElement.offsetHeight)
                ){
                    let jobparams = {
                        'pageIndex': this.jobSearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll,
                        'process_id': this.process_id
                    }
                    if (this.allJobs.length >= this.allJobsCount) {

                        return;
                    } 
                    this.api.postAll('joborder/list/in', jobparams).subscribe((res: any) =>{
                        this.allJobs.push(...res.items);
                    });
                    this.jobSearchPage++;// send page number for fetching the records 
                }
            });
        }
    }

    // On load of component default pageSize for dropdown to load and also set selected value if present
    onLoadJobList() {
        let params = {
            'pageIndex': this.jobSearchPage,
            'pageSize': environment.defaultPageSizeOnScroll,
            'process_id': this.process_id
        }

        this.api.postAll('joborder/list/in', params).pipe(
            tap((res: any) => {
              this.allJobs.push(...res.items);
              this.allJobsCount = res.rowCount
            }),
            finalize(() => this.jobSearchPage++)
        ).subscribe();
    }

    onJobChange(event:any) {
        this.viewData = true;      
        var value = event.value.id;
        const data = {
           'job_id': value
        }
        var product_names: any = []
        this.selectedJob.products.forEach((p: any) => {
            product_names.push(p.name)
        });
        this.product_name = product_names.join(", ");
        this.api.callRoute('joborder', '/comments', data).subscribe(
            (data: any) => {
                if (data) {
                    this.allComments = data;
                }   
            },
            error => {
                alert(error.message)
            }
        );
    }

    onProcessChange(event:any) {
        this.processSelected = true;
        this.viewData = false;
        
        if(this.selectedJob){
            this.selectedJob.id = 0
        }

        this.process_id = event.value.id;
        this.jobSearchPage = 0
        this.allJobs = []
        this.onLoadJobList();
    }

    searchProcesses(value: string) {
        let filter = value.toLowerCase();
        return this.allProcesses.filter(option =>
            option.name.toLowerCase().includes(filter)
        );
    }

    onProcessKey = (event: Event) => {
        var value = (event.target as HTMLInputElement).value;
        this.filteredProcesses = this.searchProcesses(value);

    }

    confirmDialog(): void {
        const message = 'Are you sure you want to inward this job?';
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Confirm job in",
			message: message
		}
        if(!this.selectedJob){
            alert("Please select Job No.");
            return
        }
        
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			if (this.result) {
				this.createJobIn();
			}
		});
    }

    keyPress(event: any) {
        this.passwordLength = event.target.value.length;
        if (this.passwordLength >= 0) {
            //show job details div
        }
    }

    createJobIn() {
        const data = {
            'employee_id': this.employee_id,
            'job_id': this.selectedJob.id,
            'comment_description': this.comment_description,
            'process_id': this.selectedJob.next_process.process_id
        }
        
        this.api.callRoute('joborder', '/job/in', data).subscribe(
            (data: any) => {
                if (data) {
                    // alert(data.message)
                    if (data.status == 'success') {
                        alert("Successfully processed job inward");
                        this.router.navigate(['/employeelogin/in']);
                    }
                    else if (data.status == 'error') {
                        alert(data.message)
                    }
                }
            },
            error => {
                alert(error.message)
            }
            
        );
    }

    ngOnDestroy(): void {
        localStorage.removeItem('employee_id')
    }
}

