import { Injectable } from '@angular/core';
import { CommonApiService } from './common-api.service';

@Injectable({
	providedIn: 'root'
})


export class RenderFilterDropdownService {

	constructor(private api: CommonApiService) { }

	endpointLabelMapping: Record<string, string> = {
		'auth/list/display': 'fullname',
		// Add other mappings here
	}

	onDropdownLoadOptions(apiEndpoint: string, targetOptions: any[], pageSize: number = 10) {
		const params = { 'pageSize': pageSize };
		this.api.postAll(apiEndpoint, params).subscribe((res: any) => {
			const items = res.items;
			const total_rows = res.rowCount;
			const labelProperty = this.endpointLabelMapping[apiEndpoint] || 'name'; // Use 'name' as default label property

			targetOptions.push(...items.map((element: any) => ({
				value: element.id,
				label: element[labelProperty],
				total_count: total_rows
			})));
		});
	}
}
