import { Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { SpeakerService } from '../../speaker.service';
import { Doctor, displayColumns,displayHeaders, filterParameters} from './doctor.interface';
import { CommonApiService } from "../../common-api.service";
import { EventEmitterService } from '../../event-emitter.service';
import { environment } from "../../../../environments/environment";
import { DynamicFilterComponent } from '../dynamic-filter/dynamic-filter.component';
import { RenderFilterDropdownService } from '../../render-filter-dropdown.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.css']
})
export class DoctorComponent implements OnInit {

    displayColumns = displayColumns;
    displayHeaders = displayHeaders;
    doctor: Doctor[] =[];
    filterFields: any[] =[]
    filterCriteria: any[] =[]   
    cityOptions:any[] = [];
    statesOptions:any[] = [];

    @ViewChild('dynamicFilterContainer', { read: ViewContainerRef, static: true })
	dynamicFilterContainer!: ViewContainerRef;

    constructor(
        public speaker: SpeakerService,
        private api: CommonApiService,
        private eventEmitterService: EventEmitterService,
		private dialog: MatDialog,
        private router: Router,
        private renderFilterDropdown: RenderFilterDropdownService

    ) { }

    ngOnInit(): void {
        //resetting event emmitter variables onload of component
        this.eventEmitterService.pageSize = environment.defaultPageSizeOnLoad
        this.eventEmitterService.pageIndex = environment.defaultPageIndexOnLoad 

        this.getListDetails();
        this.speaker.model = 'doctor';
        this.speaker.displayColumns = this.displayColumns;
        this.speaker.displayHeaders = this.displayHeaders;
        this.speaker.listData = this.doctor;
        
        //function gets called by default when the component is loaded
        this.eventEmitterService.subsVar = this.eventEmitterService.    
        invokeFirstComponentFunction.subscribe(() => {  
            this.getListDetails();   
        });

        // add dropdown to filters
        this.onDropdownRenderOptions('area/list/display', this.cityOptions);
        this.onDropdownRenderOptions('state/list/display', this.statesOptions);
    }

    // dynamic func for dropdown loading in filters
    onDropdownRenderOptions(apiEndpoint: string, targetOptions: any[]) {
        this.renderFilterDropdown.onDropdownLoadOptions(apiEndpoint, targetOptions);
    }

    ngOnDestroy(): void {
        this.eventEmitterService.subsVar.unsubscribe(); 
    }

    getListDetails() {
 
        let params = {
            'pageIndex': this.eventEmitterService.pageIndex ? this.eventEmitterService.pageIndex: environment.defaultPageIndexOnLoad,
            'pageSize': this.eventEmitterService.pageSize ? this.eventEmitterService.pageSize: environment.defaultPageSizeOnLoad,
            filterCriteria: this.filterCriteria
        }

        this.api.postAll('doctor/list/display', params).subscribe((res: any) => {
            this.doctor = res.items;

            this.doctor.forEach((element) => {
                element.city_name = element.city?.name;
                element.state_name = element.state?.name;
                
            });

            this.speaker.setListData(this.doctor, res.rowCount, res.pageIndex, res.pageSize);
        });
    }

    // set options for dropdown filters
    getOptionArray<T>(modelName: any): T[] {
        const ModelOptionsMap: Record<string, T[]> = {
          area: this.cityOptions,
          state: this.statesOptions,
          // Add other model names and their corresponding options arrays here
        };
        
        const optionKey = ModelOptionsMap[modelName];
        return optionKey;
    }

	openDialogFilter() {
  
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            filterFields: filterParameters.map(field => {
                if (field.type == 'dropdown') {
                    return {
                    ...field,
                    options: this.getOptionArray<typeof field.model_name>(field.model_name),
                    };
                }
                return field;
            }),
        };
        const dialogRef = this.dialog.open(DynamicFilterComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.filterCriteria = result
                this.eventEmitterService.pageIndex = environment.defaultPageIndexOnLoad
                this.eventEmitterService.pageSize = environment.defaultPageSizeOnLoad
                this.getListDetails();
            }
        });
    }

    resetFilters() {
        this.filterCriteria = []
        this.redirectTo(this.speaker.model);
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }
}
