<form #prodForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-9 form-breadcrumb">
            <h3><a [routerLink]="['/product']" routerLinkActive="router-link-active">Products</a> > {{product.name}}</h3>
        </div>
        <div class="col-3 form-operation">
            <button *ngIf="!isFormDisabled" [disabled]="onSaveDisable" class="m-2" type="submit" mat-raised-button color="primary" (click)='save(prodForm.valid)'>Save</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="primary" (click)='create()'>Create</button>
            <button *ngIf="!isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>

    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Name <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #name="ngModel" type="text" matInput name="name" [(ngModel)]="product.name" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="name.touched && name.invalid">
                        Product Name is required
                    </div>
                </div>

                <label for="hsn_list" class="col-sm-2 col-form-label">HSN/SAC</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [disabled]="isFormDisabled" [(value)]="selectedHsn">
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keyup)="onHsnKey($event)" matInput name="hsn_list">
                            <mat-option *ngFor="let hsn of filteredHsns" [value]="hsn.id">{{hsn.hsn_sac}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <label for="category_list" class="col-sm-2 col-form-label">Category <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [disabled]="isFormDisabled" [(value)]="selectedCategory" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keyup)="onCategoryKey($event)" matInput name="category_list">
                            <mat-option *ngFor="let category of filteredCategorys" [value]="category.id">{{category.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <label for="lead_time" class="col-sm-2 col-form-label">Lead Time (In Days )</label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input  (keypress)="onlyNumber($event)"  type="text" maxlength="3" matInput name="lead_time" [(ngModel)]="product.lead_time">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <label for="rate" class="col-sm-2 col-form-label">Rate (Rs.)<span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input  #rate="ngModel" (keypress)="onlyNumber($event)"  type="text" matInput name="rate" [(ngModel)]="product.rate" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="rate.touched && rate.invalid">
                        Rate is required
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
</form>