import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { AccountReceipt, knockOffdisplayColumns, knockOffdisplayHeaders } from './account-receipt.interface';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { SnackbarService } from '../../snackbar.service';
import { MatSelect } from '@angular/material/select';
import { CommonDropdownService } from '../../common-dropdown.service';
import { environment } from "../../../../environments/environment";
import { tap, filter, finalize } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { Company } from '../company/company.interface';
import { DateStorageService } from '../../date-storage.service';


@Component({
    selector: 'app-account-receipt.form',
    templateUrl: './account-receipt.form.component.html',
    styleUrls: ['./account-receipt.form.component.css']
})

export class AccountReceiptFormComponent implements OnInit {


    @ViewChild('doctor_id', { static: false }) doctor_id: MatSelect;
    @ViewChild('account_id', { static: false }) account_id: MatSelect;
    @ViewChild('company_id', { static: false }) company_id: MatSelect;
    constructor(
        private route: ActivatedRoute,
        private api: CommonApiService,
        private router: Router,
        public dialog: MatDialog,
        private snackbar: SnackbarService,
        private dropdownService: CommonDropdownService,
        private dateStorageService: DateStorageService
    ) { }

    public model = 'account_receipt'
    public account_receipt = {} as AccountReceipt;
    isFormDisabled = true;
    result: string;
    onSaveDisable = false;

    public all_account_receipt_types : any[] = [ "cash", "cheque", "online"];
    public selected_account_receipt_type : string

    doctorSearchPage = 0;
    allDoctorsCount = 0
    public allDoctors: any[] = [];
    public selectedDoctor: number;
    public selectedDoctorObject: any[] = [];
    disableDoctorSelection: boolean;

    accountSearchPage=0
    allAccountsCount = 0
    public allAccounts: any[] = [];
    public selectedAccount: number;
    public selectedAccountObject: any[] = [];
    disableAccountSelection: boolean;

    companySearchPage=0
    allCompaniesCount = 0
    public allCompanies: any[] = [];
    public selectedCompany: number;
    public selectedCompanyObject: Company[] = [];
    disableCompanySelection: boolean;

    knockoffsDataSource:any = []
   
    displayHeaders = knockOffdisplayHeaders
    displayColumns = knockOffdisplayColumns
    displayColumnsWithSelect = ['select']

    selection = new SelectionModel<any>(true, []);
    knockOffSelected: any[] = [];
    
    minDate: Date | null = new Date();
    maxDate: Date | null = new Date();

    ngOnInit() {
        this.disableCompanySelection=false
        this.disableDoctorSelection=false
        let account_receipt_Id = this.route.snapshot.params.id
        if (account_receipt_Id === 'create') {
            this.isFormDisabled = false;
        }
        else {
            this.disableCompanySelection=true
            this.disableDoctorSelection=true
            this.getAccountReceipt(Number(account_receipt_Id));
        }
        this.onLoadDoctorList();
        this.OnLoadCompanyList();

        this.displayColumnsWithSelect = this.displayColumnsWithSelect.concat(this.displayColumns);
       
        this.minDate = this.dateStorageService.getDateFromLocalStorage('fy_start_date')
        this.maxDate = this.dateStorageService.getDateFromLocalStorage('fy_end_date')

    }

    /**
     * Handles the key event for the doctor input and updates search results.
     *
     * @param {Event} event - the key event
     * @return {void} 
     */
    onDoctorKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'doctor', this.allDoctors, this.selectedDoctorObject, 'doctorSearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allDoctors = result.entityList;
            this.doctorSearchPage = result.pageVariable;
        });
    }

    OnCompanyKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'company', this.allCompanies, this.selectedCompanyObject, 'companySearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            
            this.allCompanies = result.entityList;
            this.companySearchPage = result.pageVariable;
        });
    }

    onAccountKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'account', this.allAccounts, this.selectedAccountObject, 'accountSearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allAccounts = result.entityList;
            this.accountSearchPage = result.pageVariable;
        });
    }

    OnCompanyScroll(event:any) {
        if (!this.company_id?.panel || !this.company_id?.panel.nativeElement) {
            // Panel or nativeElement is not available, return or handle the situation accordingly.
            return;
        }
        if (event) {
            this.company_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.company_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.company_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.company_id.panel.nativeElement.offsetHeight)
                    ){
                    let companyparams = {
                        'pageIndex': this.companySearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }
                    
                    if (this.allCompanies.length >= this.allCompaniesCount) {
                        return;
                    }
                    this.api.postAll('company/list/display', companyparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedCompanyObject[0]?.id);
                        this.allCompanies.push(...res.items);
                    });
                    this.companySearchPage++;// send page number for fetching the records
                }
            });
        }
    }


    onDoctorScroll(event:any) {

        if (!this.doctor_id?.panel || !this.doctor_id?.panel.nativeElement) {
            // Panel or nativeElement is not available, return or handle the situation accordingly.
            return;
        }
        if (event) {
            this.doctor_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.doctor_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.doctor_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.doctor_id.panel.nativeElement.offsetHeight)
                    ){
                    let doctorparams = {
                        'pageIndex': this.doctorSearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }
                    
                    if (this.allDoctors.length >= this.allDoctorsCount) {
                        return;
                    }
                    this.api.postAll('doctor/list/display', doctorparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedDoctorObject[0]?.id);
                        this.allDoctors.push(...res.items);
                    });
                    this.doctorSearchPage++;// send page number for fetching the records
                }
            });
        }
    }

    onAccountScroll(event:any) {
        if (!this.account_id?.panel || !this.account_id?.panel.nativeElement) {
            // Panel or nativeElement is not available, return or handle the situation accordingly.
            return;
        }
        if (event) {
            this.account_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.account_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.account_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.account_id.panel.nativeElement.offsetHeight)
                    ){
                    let accountparams = {
                        'pageIndex': this.accountSearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }
                    
                    if (this.allAccounts.length >= this.allAccountsCount) {
                        return;
                    }
                    this.api.postAll('account/list/display', accountparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedAccountObject[0]?.id);
                        this.allAccounts.push(...res.items);
                    });
                    this.accountSearchPage++;// send page number for fetching the records
                }
            });
        }
    }


    // On load of component default pageSize for dropdown to load and also set selected value if present
    onLoadDoctorList() {
        let params = {
            'pageIndex': this.doctorSearchPage,
            'pageSize': environment.defaultPageSizeOnScroll
        }

        this.api.postAll('doctor/list/display', params).pipe(
            tap((res: any) => {
                this.allDoctors.push(...res.items);
                this.allDoctorsCount = res.rowCount
            }),
            filter(() => this.selectedDoctorObject.length !== 0),
            tap(() => {
              const selectedDoctorId = this.selectedDoctorObject[0].id;
              this.allDoctors = this.allDoctors.filter(doctor => doctor.id !== selectedDoctorId);
              this.allDoctors.unshift(this.selectedDoctorObject[0]);
              
            }),
            finalize(() => this.doctorSearchPage++)
        ).subscribe();
    }

    OnLoadCompanyList() {
        this.api.getAll('company').pipe(
            tap((res: any) => {
                this.allCompanies=res
            }),
            filter(() => this.selectedCompanyObject.length !== 0),
            tap(() => {
              const selectedCompanyId = this.selectedCompanyObject[0].id;
              this.allCompanies = this.allCompanies.filter(company => company.id !== selectedCompanyId);
              this.allCompanies.unshift(this.selectedCompanyObject[0]);
              
            }),
        ).subscribe();
    }

    OnLoadAccountList() {
        let params = {
            'pageIndex': this.accountSearchPage,
            'pageSize': environment.defaultPageSizeOnScroll,
            'filterCriteria': {
                'company_id': ['dropdown', this.selectedCompany],
                'type': ['=', ['cash','bank']],
                "order_by_columns": [
                    "multi_select",
                    null
                ],
                "order_by": "asc"
            }
        }
        this.api.postAll('account/list/display', params).pipe(
            tap((res: any) => {
                this.allAccounts.push(...res.items);
                this.allAccountsCount = res.rowCount
            }),
            filter(() => this.selectedAccountObject.length !== 0),
            tap(() => {
              const selectedAccountId = this.selectedAccountObject[0].id;
              this.allAccounts = this.allAccounts.filter(account => account.id !== selectedAccountId);
              this.allAccounts.unshift(this.selectedAccountObject[0]);
              
            }),
        ).subscribe();
    }

    getAccountReceipt(account_receipt_Id: number) {
        this.api.get(this.model, account_receipt_Id).subscribe(
            (data: any) => {
                if (data) {
                    this.account_receipt = data;
                    this.selected_account_receipt_type = data.type

                    this.OnLoadAccountList();

                    this.selectedDoctor = data.receiving_account.id;
                    this.allDoctors = this.allDoctors.filter(doctor => doctor.id !== data.receiving_account.id);
                    this.allDoctors.push(data.receiving_account) // insert specific selected doctor into the dropdown list
                    this.selectedDoctorObject.push(data.receiving_account)

                    this.selectedAccount = data.company_account.id;
                    this.allAccounts = this.allAccounts.filter(account => account.id !== data.company_account.id);
                    this.allAccounts.push(data.company_account) // insert specific selected doctor into the dropdown list
                    this.selectedAccountObject.push(data.company_account)

                    this.selectedCompany = data.company.id;
                    this.allCompanies = this.allCompanies.filter(company => company.id !== data.company.id);
                    this.allCompanies.push(data.company) // insert specific selected doctor into the dropdown list
                    this.selectedCompanyObject.push(data.company)

                    this.getKnockOffsList(data.receiving_account.id,data.company_account.id)
                    // console.log(this.knockOffSelected, this.knockoffsDataSource)
                    // this.account_receipt.knocked_off.forEach((accReceipt: any) => {
                    //     this.knockOffSelected.push({
                    //         "id": accReceipt.id,
                    //         "type": accReceipt.type
                    //     })
                    // });

                     // Clear the selection before setting default selected items

                }
            }
        );
    }


    save(formValid:any) {
        if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
        if (this.selected_account_receipt_type == undefined) {
			alert("Please select account receipt type.");
			return;
		}
        if (this.selectedCompany == undefined) {
			alert("Please select company.");
			return;
		}
        this.account_receipt.type   = this.selected_account_receipt_type
        this.account_receipt.company_account_id=this.selectedAccount
        this.account_receipt.receiving_account_id=this.selectedDoctor

        this.account_receipt.company_id=this.selectedCompany
       
        this.account_receipt.knock_offs = this.knockOffSelected
        this.onSaveDisable = true;
        // console.log(this.account_receipt.knock_offs)

        // console.log(this.account_receipt)
        this.api.createUpdate(this.model, this.account_receipt).subscribe(
            (data: any) => {
                if (data) {
                    this.account_receipt = data;
                    this.isFormDisabled = true;
                    this.snackbar.success('Saved Successfully!');
                    this.onSaveDisable = false;
                }
                this.api.refreshNeeded.subscribe(() => {
                    this.getAccountReceipt(Number(this.account_receipt.id));
                });
            },
            error => {
                alert(error.error.message)
                this.onSaveDisable = false;
            }
        );
    }
    edit(){
        this.isFormDisabled = false;
        this.disableCompanySelection=true
        this.disableDoctorSelection=true
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here

			if (this.result) {
                if(this.account_receipt.id){
                    this.isFormDisabled = true;
                    this.getAccountReceipt(this.account_receipt.id);
                }else{
                    this.router.navigate([`/account_receipt`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('account_receipt/create');
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }


    /**
     * Retrieve a list of knock-offs for a specific doctor and account.
     *
     * @param {number} doctorId - the ID of the doctor
     * @param {number} accountId - the ID of the account
     */
    getKnockOffsList(doctorId:number,accountId:number){
        this.api.getAll("account_knock_off/list/account_receipt",{"doctor_id": doctorId, "account_id": accountId} ).subscribe(
            (data: any) => {
                if (data) {
                    this.knockoffsDataSource=data
                    this.selection.clear();

                    if (this.account_receipt.knocked_off != undefined && this.account_receipt.knocked_off.length > 0) {
                        
                    this.account_receipt.knocked_off.forEach((accReceipt: any) => {
                        // Push the default selected items to knockOffSelected array
                        this.knockOffSelected.push({
                            "id": accReceipt.for_object.id,
                            "type": accReceipt.for_object.type
                        });
                        // Select the default items in MatTable selection

                        const exists = this.knockoffsDataSource.find((row: any) => row.id === accReceipt.for_object.id && row.type === accReceipt.for_object.type);
                        if (!exists) {                            
                            this.knockoffsDataSource = this.knockoffsDataSource.concat({
                                "id": accReceipt.for_object.id,
                                "type": accReceipt.for_object.type,
                                "name": accReceipt.for_object.name,
                                "date": accReceipt.for_object.date,
                                "amount": accReceipt.for_object.amount,
                                "balance": accReceipt.for_object.balance
                            });
                        }


                        const selectedRow = this.knockoffsDataSource.find((row: any) => row.id === accReceipt.for_object.id && row.type === accReceipt.for_object.type);
                        if (selectedRow) {
                            this.selection.select(selectedRow);
                        }
                        
                    });
                }

                }
            }
        );
    }


    isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.knockoffsDataSource.filteredData.length;
		return numSelected === numRows;
	}
	/** Selects all rows if they are not all selected; otherwise clear selection. */
	// masterToggle() {
	// 	if (this.isAllSelected()) {
	// 	  this.selection.clear();
	// 	  this.selectedIds = [];
	// 	} else {
	// 	  this.knockoffsDataSource.filteredData.forEach((row: any) => {
	// 		if (!this.selection.isSelected(row)) {
	// 		  this.selection.select(row);
	// 		  this.selectedIds.push(row.id); // Add ID if it's not already included
	// 		}
	// 	  });
	// 	}
	// }


    toggleSelection(row: any) {
		this.selection.toggle(row);
		if (this.selection.isSelected(row)) {
		  this.knockOffSelected.push({
            'id': row.id,
            'type': row.type
          });
          console.log(this.knockOffSelected) // Add ID if it's selected
		} else {
        
            var index = this.knockOffSelected.findIndex((item: any) => item.id === row.id && item.type === row.type);
            while (index > -1) {                
                var index = this.knockOffSelected.findIndex((item: any) => item.id === row.id && item.type === row.type);
                if (index > -1) {
                  this.knockOffSelected.splice(index, 1); // Remove ID if it's deselected                            
                }
            }
		}
	}
    
    onDoctorOrAccountChange(){
        if (this.selectedAccount != undefined && this.selectedDoctor != undefined) {
            this.getKnockOffsList(this.selectedDoctor, this.selectedAccount)
        }
        
    }

    calculateTotalAmount(){    
        this.account_receipt.total_amount = (this.account_receipt.net_amount || 0) + (this.account_receipt.tds_amount || 0)
    }

    confirmDialog(account_receipt_id: number, state:string): void {

		var dialog_message = '';
		if(state == 'submit') {
			dialog_message = 'Submit';
		} else if(state == 'cancel') {
			dialog_message = 'Cancel';
		}

		const message = 'Are you sure you want to '+dialog_message+' receipt?'
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: dialog_message+" account_receipt",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
				if (state == 'submit') {
					this.submitReceipt(account_receipt_id);
				} else if(state == 'cancel'){
					this.cancelReceipt(account_receipt_id);
				}
			}
		});
	}

	/**
	 * Submits a receipt with the given account receipt ID.
	 *
	 * @param {number} account_receipt_id - The ID of the account receipt to submit.
	 * @return {void} This function does not return anything.
	 */
    submitReceipt(account_receipt_id: number) {
		this.api.callRoute('account_receipt', `/submit/${account_receipt_id}`, null).subscribe(
			(data: any) => {
				if (data) {
					this.account_receipt = data;
					this.isFormDisabled = true;
					this.snackbar.success('Submitted Successfully!');
				}
			},
			error => {
				alert(error.error.message)
				console.log(error)
			}
		);
	}


	cancelReceipt(account_receipt_id: number) {
		this.api.callRoute('account_receipt', `/cancel/${account_receipt_id}`, null).subscribe(
			(data: any) => {
				if (data) {
					this.account_receipt = data;
					this.isFormDisabled = true;
					this.snackbar.success('Cancelled Successfully!');
				}
			},
			error => {
				alert(error.error.message)
				console.log(error)
			}
		);
	}

    onCompanyChange() {        
        this.allAccounts = [];
        this.selectedAccountObject = [];
        this.OnLoadAccountList();
    }


}
