import { Component, OnInit, ElementRef, ViewChild, } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { Challan } from './challan.interface';
import { Orderline } from './orderline.inferface';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { JobOrder } from '../joborder/joborder.interface';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SnackbarService } from '../../snackbar.service';
import { DatePipe } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { tap, filter, finalize } from 'rxjs/operators';
import { CommonDropdownService } from '../../common-dropdown.service';
import { environment } from "../../../../environments/environment"

@Component({
	selector: 'app-challan-form',
	templateUrl: './challan-form.component.html',
	styleUrls: ['./challan-form.component.css']
})
export class ChallanFormComponent implements OnInit {
	userForm: any;

    @ViewChild('doctor_id', { static: false }) doctor_id: MatSelect;// for dropdown
	@ViewChild('jobInput') jobInput: ElementRef<HTMLInputElement>;
	@ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;

	constructor(
		private route: ActivatedRoute,
		private api: CommonApiService,
		private dialog: MatDialog,
		private formBuilder: FormBuilder,
		private router: Router,
		private snackbar: SnackbarService,
		private elementRef: ElementRef,
		private datePipe: DatePipe,
        private dropdownService: CommonDropdownService
	) {
		this.userForm = this.formBuilder.group({
			'remark': ['', Validators.required],

		});
	}

	public model = 'challan'
	public challan = {} as Challan;

	maxDate = new Date(2020, 0, 1)
	minDate = new Date(1999, 0, 2)

	public selectedJob: JobOrder;

	doctorSearchPage = 0;
    allDoctorsCount = 0
    public allDoctors: any[] = [];
    public selectedDoctor: number;
    public selectedDoctorObject: any[] = [];

	public allShips: any[] = [];
	public filteredShips = this.allShips;
	public selectedShips: number;

	public allJobs: any[] = [];
	public filteredJobs = this.allJobs;
	public tempOrderLine: Orderline;

	public allJobTypes: any[] = ['regular', 'repair', 'repeat'];
	public selectedJobType : string

	isFormDisabled = true;
	result: string;
	selectable = true;
	removable = true;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	jobCtrl = new FormControl();
	selectedJobChips: string[] = [];
	selectedJobChipsId: string[] = [];

	dataSource = new MatTableDataSource<any>();
	displayedColumns: any[] = ['job_no', 'job_date', 'patient_name', 'product_name', 'product_units', 'product_rate', 'amount_total'];

	renewedList: any[] = [];
	listData: any[] = [];
	tempListData: any[] = [];
	tempSelectedJobChipsId: any[] = [];

	afterRemovedList: any[] = [];
	remainingJob: any = [];
	addRemovedJob: any = [];
	price: any;
	disableDoctorSelection: boolean;
	onSaveDisable = false;

	ngOnInit(): void {
		this.disableDoctorSelection = false;

		this.api.getAll('shipment').subscribe(res => {
			this.allShips = res;
			this.filteredShips = res;
		})

		let challanId = this.route.snapshot.params.id
		if (challanId === 'create') {
			console.log('False')
			this.isFormDisabled = false;
		}
		else {
			this.disableDoctorSelection = true;
			this.getChallan(Number(challanId));

		}
		this.onLoadDoctorList();
	}
	//api call to bring search values in dropdown and also the selected value in dropdown is displayed
    onDoctorKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'doctor', this.allDoctors, this.selectedDoctorObject, 'doctorSearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allDoctors = result.entityList;
            this.doctorSearchPage = result.pageVariable;
        });
    }

    // On scroll of dropdown api call to fetch next records
    onDoctorScroll(event:any) {

        if (!this.doctor_id?.panel || !this.doctor_id?.panel.nativeElement) {
            // Panel or nativeElement is not available, return or handle the situation accordingly.
            return;
        }
        if (event) {
            this.doctor_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.doctor_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.doctor_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.doctor_id.panel.nativeElement.offsetHeight)
                    ){
                    let doctorparams = {
                        'pageIndex': this.doctorSearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }
                    
                    if (this.allDoctors.length >= this.allDoctorsCount) {
                        return;
                    }
                    this.api.postAll('doctor/list/display', doctorparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedDoctorObject[0]?.id);
                        this.allDoctors.push(...res.items);
                    });
                    this.doctorSearchPage++;// send page number for fetching the records
                }
            });
        }
    }

    // On load of component default pageSize for dropdown to load and also set selected value if present
    onLoadDoctorList() {
        let params = {
            'pageIndex': this.doctorSearchPage,
            'pageSize': environment.defaultPageSizeOnScroll
        }

        this.api.postAll('doctor/list/display', params).pipe(
            tap((res: any) => {
                this.allDoctors.push(...res.items);
                this.allDoctorsCount = res.rowCount
            }),
            filter(() => this.selectedDoctorObject.length !== 0),
            tap(() => {
              const selectedDoctorId = this.selectedDoctorObject[0].id;
              this.allDoctors = this.allDoctors.filter(doctor => doctor.id !== selectedDoctorId);
              this.allDoctors.unshift(this.selectedDoctorObject[0]);
              
            }),
            finalize(() => this.doctorSearchPage++)
        ).subscribe();
    }

	transformDate(date: any) {
        date = new Date(date);
        var converteddate: any = this.datePipe.transform(date, 'dd-MM-yyyy');
        return converteddate;
    }

	toPrice() {
		console.log(this.price)

	}

	searchDoctors(value: string) {
		let filter = value.toLowerCase();
		return this.allDoctors.filter(option =>
			option.name.toLowerCase().includes(filter)
		);
	}

	onShipKey = (event: Event) => {
		var value = (event.target as HTMLInputElement).value;
		this.filteredShips = this.searchShips(value);
	}

	searchShips(value: string) {
		let filter = value;
		return this.allShips.filter(option =>
			option.includes(filter)
		);
	}

	add(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();
		// Add our job
		if (value) {
			this.selectedJobChips.push(value);

		}
		// Clear the input value
		event.chipInput!.clear();
		this.jobCtrl.setValue(null);

	}

	remove(job: any): void {

		this.searchChips(job);
		this.addRemovedJob = this.searchChips(job);
		this.filteredJobs.push(this.addRemovedJob[0]);

		const index = this.selectedJobChips.indexOf(job);

		this.listData = [];
		this.dataSource = new MatTableDataSource<any>();

		if (index >= 0) {
			this.selectedJobChips.splice(index, 1);
			this.selectedJobChipsId.splice(index, 1);
		}

		if (this.selectedJobChips.length == 0) {
			this.challan.order_lines = []
			this.selectedJobChips = []
			this.selectedJobChipsId = [];
			this.tempSelectedJobChipsId = [];
			this.tempListData = []
			this.dataSource = new MatTableDataSource<any>([])
		}
		else {
			if (this.challan.order_lines) {
				this.renewedList  = Object.assign([], this.challan.order_lines);
				this.renewedList.forEach(element => {
					
					if (element.job_id == this.addRemovedJob[0].id) {
						const index = this.challan.order_lines.indexOf(element);
						this.challan.order_lines.splice(index, 1)
					}
				});
			}
			this.dataSource = new MatTableDataSource<any>(this.challan.order_lines)

		}
	}

	selected(event: any): void {

		this.chipInput.nativeElement.blur();

		var val = event.option.value.job_no;
		this.searchChips(val);

		this.tempListData = this.searchChips(val);

		this.tempListData.forEach(job => {
			job.order_lines.forEach((line: any) => {
				
				this.tempOrderLine = <Orderline>{};
				this.tempOrderLine.job_no = job.job_no
				this.tempOrderLine.job_id = job.id
				this.tempOrderLine.job_date = job.job_date
				this.tempOrderLine.patient_name = job.patient_name
				this.tempOrderLine.product_name = line.product.name
				this.tempOrderLine.rate = line.product.rate
				this.tempOrderLine.product_units = line.product_units
				this.tempOrderLine.amount_total = line.amount_total

				if (this.challan.order_lines) {
					this.challan.order_lines.push(this.tempOrderLine)
				}
				else {
					this.challan.order_lines = [this.tempOrderLine]
				}
			});

		});

		this.removeJobFromFilteredJobs(val);
		this.filteredJobs = this.removeJobFromFilteredJobs(val);
		//this.listData.push(this.tempListData[0]);

		this.dataSource = new MatTableDataSource<any>(this.challan.order_lines);
		this.selectedJobChips.push(val);
		this.selectedJobChipsId.push(event.option.value.id);

		this.jobCtrl.setValue(null);
		this.selectedJobChips = this.selectedJobChips;
		this.selectedJobChipsId = this.selectedJobChipsId;

	}

	searchChips(value: any) {
		return this.allJobs.filter(object => {
			return object.job_no == value;
		});
	}

	removeJobFromFilteredJobs(value: any) {
		return this.filteredJobs.filter(object => {
			return object.job_no != value;
		});
	}

	getJobNo(value: any) {
		return this.allJobs.filter(object => {
			return object.id == value;
		});
	}


	getChallan(challanId: number) {

		this.api.get(this.model, challanId).subscribe(
			(data: any) => {
				if (data) {

					this.challan = data;
					if(data.doctor){
                        this.selectedDoctor = data.doctor.id;
                        this.allDoctors = this.allDoctors.filter(doctor => doctor.id !== data.doctor.id);
                        this.allDoctors.push(data.doctor) // insert specific selected doctor into the dropdown list
                        this.selectedDoctorObject.push(data.doctor)
                    }

					this.selectedJobType = data.job_type;
					if (data.shipment) {
						this.selectedShips = data.shipment.id;
					}
					
					this.api.callRoute('joborder', '/doctor_jobs', {'doctor_id': this.selectedDoctor, 'job_type': this.selectedJobType}).subscribe(
						(jobs: any) => {
							console.log(data.job_id)
							if (jobs) {
								this.allJobs = jobs.all_jobs
								this.filteredJobs = jobs.filtered_jobs
							}

							this.selectedJobChipsId = data.job_id;
							data.job_id.forEach((val: any) => {
								this.tempSelectedJobChipsId = this.getJobNo(val);
								this.selectedJobChips.push(this.tempSelectedJobChipsId[0].job_no);


								this.tempSelectedJobChipsId.forEach(job => {
									job.order_lines.forEach((line: any) => {
										
										this.tempOrderLine = <Orderline>{};
										this.tempOrderLine.job_no = job.job_no
										this.tempOrderLine.job_id = job.id
										this.tempOrderLine.job_date = job.job_date
										this.tempOrderLine.patient_name = job.patient_name
										this.tempOrderLine.product_name = line.product.name
										this.tempOrderLine.rate = line.product.rate
										this.tempOrderLine.product_units = line.product_units
										this.tempOrderLine.amount_total = line.amount_total
										if (!this.selectedJobType) {
											this.selectedJobType = job.job_type
										}
	
										if (this.challan.order_lines) {
											this.challan.order_lines.push(this.tempOrderLine)
										}
										else {
											this.challan.order_lines = [this.tempOrderLine]
										}
									});
	
								});
								this.dataSource = new MatTableDataSource<any>(this.challan.order_lines);

							});
						}
					)
				}
			}
		);
	}

	save(formValid: any) {
		if (formValid == false) {
			alert("Please fill mandatory fields.");
			return;
		}
		if (this.selectedDoctor == undefined) {
			alert("Please select doctor name.");
			return;
		}
		if (this.selectedJobType == undefined) {
			alert("Please select job type.");
			return;
		}
		if (this.selectedJobChipsId.length <= 0) {
			alert("Please select atleast 1 job.");
			return;
		}
		this.challan.job_id = this.selectedJobChipsId
		this.challan.doctor_id = this.selectedDoctor
		this.challan.shipment_id = this.selectedShips
		this.challan.job_type = this.selectedJobType
		this.challan.status = 'draft';

		this.onSaveDisable = true;

		this.api.createUpdate(this.model, this.challan).subscribe(
			(data: any) => {
				if (data) {
					this.challan = data;
					this.isFormDisabled = true;
					this.snackbar.success('Saved Successfully!');
					this.onSaveDisable = false;

				}
			},
			error => {
				alert(error.error.message)
				this.onSaveDisable = false;
			}
		);
	}

	edit() {
		this.isFormDisabled = false;
	}

	discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			console.log(this.result);
			if (this.result) {
				if (this.challan.id) {
					this.isFormDisabled = true;
					this.listData = [];
					this.tempListData = [];
					this.tempSelectedJobChipsId = [];
					this.selectedJobChips = [];
					this.getChallan(this.challan.id);
				} else {
					this.router.navigate([`/challan`]);
				}
			}
		});
	}

	create() {
		this.redirectTo('challan/create');
	}

	//redirects to the expected route
	//this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
	redirectTo(uri: string) {
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
			this.router.navigate([uri]));
	}

	confirmDialog(challan_id: number): void {
		const message = `Are you sure you want to submit challan?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Submit challan",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
				this.submitChallan(challan_id);
			}
		});
	}

	submitChallan(challan_id: number) {
		this.api.callRoute('challan', `/submit/${challan_id}`, null).subscribe(
			(data: any) => {
				if (data) {
					this.challan = data;
					this.isFormDisabled = true;
					this.snackbar.success('Submitted Successfully!');
				}
			},
			error => {
				alert(error.error.message)
				console.log(error)
			}
		);
		// this.redirectTo(`challan`);
	}

	onDoctorChange() {
		
		if (this.selectedDoctor != undefined && this.selectedJobType != undefined) {
			this.api.callRoute('joborder', '/doctor_jobs', {'doctor_id': this.selectedDoctor, 'job_type': this.selectedJobType}).subscribe(
				(jobs: any) => {
					if (jobs) {
						this.allJobs = jobs.all_jobs
						this.filteredJobs = jobs.filtered_jobs
					}
	
					this.challan.order_lines = []
					this.selectedJobChips = []
					this.selectedJobChipsId = [];
					this.tempSelectedJobChipsId = [];
					this.tempListData = []
					this.dataSource = new MatTableDataSource<any>([])
				}
			)
		}
	}
}
