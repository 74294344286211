<form #userform="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-9 form-breadcrumb">
            <h3><a [routerLink]="['/auth']" routerLinkActive="router-link-active">Users</a> > {{user.username}}</h3>
        </div>
        <div class="col-3 form-operation">
            <button *ngIf="!isFormDisabled" class="m-2" type="submit" mat-raised-button color="primary" (click)='save(userform.valid)'>Save</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="primary" (click)='create()'>Create</button>
            <button *ngIf="!isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>

    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">User Name <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #username="ngModel" type="text" matInput name="username" [(ngModel)]="user.username" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="username.touched && username.invalid">
                        Username is required
                    </div>
                </div>

                <label for="role_list" class="col-sm-2 col-form-label">Role<span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [disabled]="isFormDisabled" [(value)]="selectedRole">
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keyup)="onRoleKey($event)" matInput name="user_role" required>
                            <mat-option *ngFor="let role of filteredRoles" [value]="role.id">{{role.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <label for="rate" class="col-sm-2 col-form-label">Email <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #email="ngModel" type="text" matInput name="email" [(ngModel)]="user.email" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="email.touched && email.invalid">
                        Email is required
                    </div>
                </div>
                <label *ngIf="!user.id" for="password" class="col-sm-2 col-form-label">Password <span class="span-class"> *</span></label>
                <div *ngIf="!user.id" class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input  #password="ngModel" type="password" matInput name="password" [(ngModel)]="user.password" required autocomplete="new-password">
                    </mat-form-field>
                    <div class="validation-class" *ngIf="password.touched && password.invalid">
                        Password is required
                    </div>
                </div>
                <label *ngIf="user.id" for="password" class="col-sm-2 col-form-label">New Password</label>
                <div *ngIf="user.id" class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #password="ngModel" type="password" matInput name="password" [(ngModel)]="user.password" autocomplete="new-password">
                    </mat-form-field>
                </div>
            </div>
        </fieldset>
    </div>
</form>