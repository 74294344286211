<!-- <mat-progress-spinner mode="indeterminate" *ngIf="IsWait">
</mat-progress-spinner> -->
<!-- 
<div class="center">
    <mat-progress-spinner mode="indeterminate" *ngIf="IsWait">
    </mat-progress-spinner>
</div> -->
<div class="overlay" *ngIf="IsWait">
    <div class="center">
        <mat-progress-spinner mode="indeterminate">
        </mat-progress-spinner>
    </div>
</div>

<div class="row p-4">
    <span class="col-9">
        <button class="btnExportExcel" mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'export excel', sheet: 'sheet_A', Props: {Author: 'software'}})">Export to Excel</button>
    </span>

    <mat-form-field class="col-3">
        <input matInput type="text" (keyup)="doFilter($event)" placeholder="Search">
    </mat-form-field>
</div>

<table matTableExporter #exporter="matTableExporter" mat-table matSort [dataSource]="dataSource" class="mat-elevation-z2 ">

    <ng-container class="mat-table-row" [matColumnDef]="column" *ngFor="let column of displayColumns; let i = index;">
        <th class="font-weight-bold" mat-header-cell mat-sort-header *matHeaderCellDef> {{displayHeaders[i]}} </th>
        <td mat-cell *matCellDef="let obj"> {{obj[column]}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
</table>

<mat-paginator #paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50]" aria-label="Select page">
</mat-paginator>