import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { Area } from './area.interface';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { SnackbarService } from '../../snackbar.service';

@Component({
    selector: 'app-area.form',
    templateUrl: './area.form.component.html',
    styleUrls: ['./area.form.component.css']
})
export class AreaFormComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private api: CommonApiService,
        private router: Router,
        public dialog: MatDialog,
        private snackbar: SnackbarService,
    ) { }

    public model = 'area'
    public area = {} as Area;
    isFormDisabled = true;
    result: string;
    onSaveDisable = false;

    ngOnInit() {
        let areaId = this.route.snapshot.params.id
        if (areaId === 'create') {
            this.isFormDisabled = false;
        }
        else {
            this.getArea(Number(areaId));
        }
    }

    getArea(areaId: number) {
        this.api.get(this.model, areaId).subscribe(
            (data: any) => {
                if (data) {
                    this.area = data;
                }
            }
        );
    }

    save(formValid:any) {
        if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
        this.onSaveDisable = true;
        this.api.createUpdate(this.model, this.area).subscribe(
            (data: any) => {
                if (data) {
                    this.area = data;
                    this.isFormDisabled = true;
                    this.snackbar.success('Saved Successfully!');
                    this.onSaveDisable = false;
                }
            },
            error => {
                alert(error.error.message)
                this.onSaveDisable = false;
            }
        );
    }

    edit(){
        this.isFormDisabled = false;
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
                if(this.area.id){
                    this.isFormDisabled = true;
                    this.getArea(this.area.id);
                }else{
                    this.router.navigate([`/area`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('area/create');
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }
}
