import { Component, OnInit,ViewChild } from '@angular/core';
import { CommonApiService } from '../../../common-api.service';
import { SpeakerService } from '../../../speaker.service';
import { SnackbarService } from '../../../snackbar.service';
import { CommonDropdownService } from '../../../common-dropdown.service';
import { environment } from "../../../../../environments/environment"
import { MatSelect } from '@angular/material/select';
import { tap, filter, finalize } from 'rxjs/operators';


@Component({
	selector: 'app-account-aging',
	templateUrl: './account-aging.component.html',
	styleUrls: ['./account-aging.component.css']
})
export class AccountAgingComponent implements OnInit {

	@ViewChild('doctor_id', { static: false }) doctor_id: MatSelect;// for dropdown

	constructor(
		public speaker: SpeakerService,
		private api: CommonApiService,
		private snackbar: SnackbarService,
        private dropdownService: CommonDropdownService
	) { }

	public allTransactions: any[] = [];
	public tillDate: any;

	doctorSearchPage = 0;
    allDoctorsCount = 0
    public allDoctors: any[] = [];
    public selectedDoctorObject: any[] = [];
    public selectedDoctor: number;
	public pdf_url : string;
	
	displayColumns = [
		'doctor_name', // doctor_name
		'invoice_outstanding', // sum of all invoice balance amount doctor wise
		'unadjusted_dr_cr_amount', // sum of all unadjusted dr cr jv balance amount doctor wise
		'net_outstanding', // invoice_outstanding - unadjusted_dr_cr
		'not_due_amount',
		'days_1_to_30', 
		'days_31_to_60',
		'days_61_to_90',
		'days_91_to_120',
		'days_121_and_above'
	];
	displayHeaders = [
		'Doctor Name',
		'Total Outstanding',
		'Unadjusted DR/CR Amount',
		'Net Outstanding',
		'Not Due Amount',
		'1-30',
		'31-60',
		'61-90',
		'91-120',
		'121 & Above'
	];
  
	ngOnInit(): void {
		this.speaker.listData = this.allTransactions;
		this.selectedDoctor = 0;
		this.onLoadDoctorList();
    }

    // On scroll of dropdown api call to fetch next records
    onDoctorScroll(event:any) {

        if (!this.doctor_id?.panel || !this.doctor_id?.panel.nativeElement) {
            // Panel or nativeElement is not available, return or handle the situation accordingly.
            return;
        }
        if (event) {
            this.doctor_id.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.doctor_id.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.doctor_id.panel.nativeElement.scrollHeight) -
                    Math.floor(this.doctor_id.panel.nativeElement.offsetHeight)
                    ){
                    let doctorparams = {
                        'pageIndex': this.doctorSearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }
                    
                    if (this.allDoctors.length >= this.allDoctorsCount) {
                        return;
                    }
                    this.api.postAll('doctor/list/display', doctorparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedDoctorObject[0]?.id);
                        this.allDoctors.push(...res.items);
                    });
                    this.doctorSearchPage++;// send page number for fetching the records
                }
            });
        }
    }

    // On load of component default pageSize for dropdown to load and also set selected value if present
    onLoadDoctorList() {
        let params = {
            'pageIndex': this.doctorSearchPage,
            'pageSize': environment.defaultPageSizeOnScroll
        }

        this.api.postAll('doctor/list/display', params).pipe(
            tap((res: any) => {
                this.allDoctors.push(...res.items);
                this.allDoctorsCount = res.rowCount
            }),
            filter(() => this.selectedDoctorObject.length !== 0),
            tap(() => {
              const selectedDoctorId = this.selectedDoctorObject[0].id;
              this.allDoctors = this.allDoctors.filter(doctor => doctor.id !== selectedDoctorId);
              this.allDoctors.unshift(this.selectedDoctorObject[0]);
              
            }),
            finalize(() => this.doctorSearchPage++)
        ).subscribe();
    }

    getReportDetails(data: any) {   
        this.allTransactions = []
		this.api.callRoute('account','/list/aging', data).subscribe(res => {
			if (res?.status == false) {
				this.snackbar.info(res.message);
			} else {
				this.allTransactions = res.data;
				this.speaker.listData = this.allTransactions;
			}
        })
    }

	generateReport(){
		if(this.tillDate == undefined || this.tillDate == ""){
			alert("Select Till Date.");
			return;
		}
		const data = {
			'end_date': this.tillDate,
			'doctor_id': this.selectedDoctor,

		}
		this.speaker.listData = [];
		this.getReportDetails(data);
        const formattedTillDate = this.tillDate.toISOString();
        this.pdf_url = 'api/account/outstanding_receivbles_pdf?doctor_id=' + this.selectedDoctor + '&till_date=' + formattedTillDate;
        this.speaker.model = 'account';
        this.speaker.displayColumns = this.displayColumns;
        this.speaker.displayHeaders = this.displayHeaders;
        this.speaker.setListData(this.allTransactions, 0, 0, 0);
	}


    searchCities(value: string) {
        let filter = value.toLowerCase();        
        return this.allDoctors.filter(option =>
            option.name.toLowerCase().includes(filter)
        );
    }

    //api call to bring search values in dropdown and also the selected value in dropdown is displayed
	onDoctorKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'doctor', this.allDoctors, this.selectedDoctorObject, 'citySearchPage')
          .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allDoctors = result.entityList;
            this.doctorSearchPage = result.pageVariable;
        });
    }

}
