<form #accountForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-sm-7 form-breadcrumb">
            <h3><a [routerLink]="['/account_receipt']" routerLinkActive="router-link-active">Account Receipt</a> >
                {{account_receipt.receipt_number}}</h3>
        </div>
        <div class="col-sm-1 form-operation">
            <a *ngIf="account_receipt.id && isFormDisabled && !['cancelled'].includes(account_receipt.status)" target="_blank" href="/api/account_receipt/{{account_receipt.id}}/download">
                <button class="m-2" type="button" mat-raised-button color="primary">Print</button>
            </a>
        </div>
        <div class="col-sm-4 form-operation" *ngIf="!['submitted','canceled'].includes(account_receipt.status)">
            <button *ngIf="isFormDisabled && account_receipt.status == 'draft'" class="m-2" type="button" mat-raised-button color="primary" (click)="confirmDialog(account_receipt.id, 'submit')">Submit</button>
            <button *ngIf="isFormDisabled && account_receipt.status == 'draft'" class="m-2" type="button" mat-raised-button color="link" (click)="confirmDialog(account_receipt.id,'cancel')">Cancel</button>
            <button *ngIf="!isFormDisabled" [disabled]="onSaveDisable" class="m-2" type="submit" mat-raised-button
                color="primary" (click)='save(accountForm.valid)'>Save</button>
            <button *ngIf="!isFormDisabled" type="button" class="m-2" mat-raised-button color="primary" (click)='create()'>Create</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="link"
                (click)='edit()'>Edit</button>
            <button *ngIf="!isFormDisabled" class="m-2" type="button" mat-raised-button color="link"
                (click)='discardChanges()'>Discard</button>
        </div>
    </div>
    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Receipt No. <span class="span-class">
                        *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #receipt_number="ngModel" [disabled]="true" type="text" matInput name="receipt_number"
                            [(ngModel)]="account_receipt.receipt_number" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="receipt_number.touched && receipt_number.invalid">Required
                    </div>
                </div>
                <label for="receipt_date" class="col-sm-2 col-form-label">Receipt Date <span class="span-class">
                        *</span></label>
                <div class="col-sm-4 dateRangePickStyle">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <input #date="ngModel" matInput [(ngModel)]="account_receipt.receipt_date" name="receipt_date" required
                            [matDatepicker]="receipt_date" required [min]="minDate" [max]="maxDate">
                        <mat-datepicker-toggle matSuffix [for]="receipt_date">
                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #receipt_date></mat-datepicker>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="date.touched && date.invalid">Required</div>
                </div>
            </div>
            <div class="row">
                <label for="type" class="col-sm-2 col-form-label">Type <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [disabled]="isFormDisabled" [(value)]="selected_account_receipt_type">
                            <input class="select-input" type="text" autocomplete="nope" matInput
                                name="account_receipt_type">
                            <mat-option *ngFor="let accountReceiptType of all_account_receipt_types"
                                [value]="accountReceiptType">{{accountReceiptType | titlecase }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <label for="doctor_name" class="col-sm-2 col-form-label">Doctor Name <span class="span-class">
                    *</span></label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <mat-select #doctor_id (openedChange)="onDoctorScroll($event)"
                        [disabled]="isFormDisabled || disableDoctorSelection" [(value)]="selectedDoctor"
                        (selectionChange)="onDoctorOrAccountChange()" required>
                        <input class="select-input" placeholder="Search..." type="text" autocomplete="nope"
                            (keydown)="$event.stopPropagation()" (keyup)="onDoctorKey($event)" matInput
                            name="doctort_list">
                        <mat-option *ngFor="let doctor of allDoctors"
                            [value]="doctor.id">{{doctor.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            </div>
            <div class="row">
                <label for="company_id" class="col-sm-2 col-form-label">Company<span class="span-class">
                    *</span></label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <mat-select #company_id (openedChange)="OnCompanyScroll($event)"
                        [disabled]="isFormDisabled || disableCompanySelection" [(value)]="selectedCompany" (selectionChange)="onCompanyChange()" required>
                        <input class="select-input" placeholder="Search..." type="text" autocomplete="nope"
                            (keydown)="$event.stopPropagation()" (keyup)="OnCompanyKey($event)" matInput
                            name="company_list">
                        <mat-option *ngFor="let company of allCompanies"
                            [value]="company.id">{{company.company_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
                <label for="account_id" class="col-sm-2 col-form-label">Account Name <span class="span-class">
                        *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select #account_id (openedChange)="onAccountScroll($event)"
                            [disabled]="isFormDisabled || disableAccountSelection" [(value)]="selectedAccount"
                            (selectionChange)="onDoctorOrAccountChange()" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope"
                                (keydown)="$event.stopPropagation()" (keyup)="onAccountKey($event)" matInput
                                name="account_list">
                            <mat-option *ngFor="let account of allAccounts"
                                [value]="account.id">{{account.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Net Amount <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #net_amount="ngModel" type="number" matInput name="net_amount"
                            [(ngModel)]="account_receipt.net_amount" (change)="calculateTotalAmount()" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="net_amount.touched && net_amount.invalid">Required</div>
                </div>
                <label for="name" class="col-sm-2 col-form-label">TDS Amount <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #tds_amount="ngModel" type="number" matInput name="tds_amount"
                            [(ngModel)]="account_receipt.tds_amount" (change)="calculateTotalAmount()" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="tds_amount.touched && tds_amount.invalid">Required</div>
                </div>
            </div>
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Total Amount <span class="span-class">
                        *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #total_amount="ngModel" type="number" matInput name="total_amount"
                            [(ngModel)]="account_receipt.total_amount" [disabled]="true" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="total_amount.touched && total_amount.invalid">Required</div>
                </div>
                <label for="name" class="col-sm-2 col-form-label">Narration <span class="span-class"></span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <textarea #narration="ngModel" type="textarea-bg" matInput name="narration"
                            [(ngModel)]="account_receipt.narration"></textarea>
                    </mat-form-field>
                    <!-- <div class="validation-class" *ngIf="narration.touched && narration.invalid">Required</div> -->
                </div>
            </div>
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Balance</label>
                <div class="col-sm-4">
                        <span class="bottom-0">
                            <p class="receipt-balance">{{account_receipt.balance}}</p>
                        </span>                    
                </div>
                <label for="name" class="col-sm-2 col-form-label">Reference No. <span
                    class="span-class"></span></label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input #reference_number="ngModel" type="text" matInput name="reference_number"
                        [(ngModel)]="account_receipt.reference_number">
                </mat-form-field>
                <!-- <div class="validation-class" *ngIf="reference_number.touched && reference_number.invalid">Required</div> -->
            </div>
            </div>
            <mat-tab-group animationDuration="0ms" class="mt-5">
                <mat-tab id="knock_off_list" label="Knock-Off">
                    <table mat-table [dataSource]="knockoffsDataSource" #knock_off_lines class="mat-elevation-z8">
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                Select
                                <!-- <mat-checkbox color="primary" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                                </mat-checkbox> -->
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                    (change)="toggleSelection(row)" [checked]="selection.isSelected(row)" [disabled]="isFormDisabled || (account_receipt.balance <= 0 && !selection.isSelected(row))">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container class="mat-table-row" [matColumnDef]="column"
                            *ngFor="let column of displayColumns; let i = index;">
                            <th class="font-weight-bold" mat-header-cell *matHeaderCellDef> {{displayHeaders[i]}} </th>
                            <td mat-cell *matCellDef="let obj" class="header-cell"> {{obj[column]}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayColumnsWithSelect"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayColumnsWithSelect;"></tr>

                    </table>
                </mat-tab>
            </mat-tab-group>
        </fieldset>
    </div>
</form>