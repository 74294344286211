<form #empForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-9 form-breadcrumb">
            <h3><a [routerLink]="['/employee']" routerLinkActive="router-link-active">Employee </a> > {{employee.name}}</h3>
        </div>
        <div class="col-3 form-operation">
            <button *ngIf="!isFormDisabled" [disabled]="onSaveDisable" class="m-2" type="submit" mat-raised-button color="primary" (click)='save(empForm.valid)'>Save</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='edit()'>Edit</button>
            <button *ngIf="isFormDisabled" class="m-2" type="button" mat-raised-button color="primary" (click)='create()'>Create</button>
            <button *ngIf="!isFormDisabled" class="m-2" type="button" mat-raised-button color="link" (click)='discardChanges()'>Discard</button>
        </div>
    </div>
    <div class="base-form mat-elevation-z2 p-4">
        <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <label for="name" class="col-sm-2 col-form-label">Employee Name <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #empname="ngModel" type="text" matInput name="name" [(ngModel)]="employee.name" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="empname.touched && empname.invalid">Required</div>
                </div>

                <label for="category" class="col-sm-2 col-form-label">Category <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [disabled]="isFormDisabled" [(value)]="selectedCategory" (valueChange)="changeValue($event)" required>
                            <mat-option *ngFor="let category of categories" [value]="category.value">{{category.viewValue}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- <div class="validation-class" *ngIf="cat.touched && cat.invalid">Required</div> -->
                </div>
            </div>
            <div class="row">
                <label for="user_name" class="col-sm-2 col-form-label">User Name <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #user="ngModel" type="text" matInput name="user_name" [(ngModel)]="employee.user_name" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="user.touched && user.invalid">Required</div>

                </div>

                <label for="password" class="col-sm-2 col-form-label">Password <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <input #pass="ngModel" type="text" matInput name="password" [(ngModel)]="employee.password" required>
                    </mat-form-field>
                    <div class="validation-class" *ngIf="pass.touched && pass.invalid">Required</div>
                </div>
            </div>
            <div class="row">
                <label for="department_list" class="col-sm-2 col-form-label">Department <span class="span-class"> *</span></label>
                <div class="col-sm-4">
                    <mat-form-field class="MatFormFieldControl">
                        <mat-select [disabled]="isFormDisabled" [(value)]="selectedDepartment" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keyup)="onDepartmentKey($event)" matInput name="category_list">
                            <mat-option *ngFor="let department of filteredDepartments" [value]="department.id">{{department.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- <div class="validation-class" *ngIf="dep.touched && dep.invalid">Required</div> -->
                </div>
            </div>
        </fieldset>
    </div>
</form>