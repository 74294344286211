
import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { CommonApiService } from '../common-api.service';
import { EventEmitterService } from '../event-emitter.service';
import { environment } from 'src/environments/environment';
import { FinancialYear } from './financial_year.interface';
import { SpeakerService } from '../speaker.service';
import { CommonDropdownService } from '../common-dropdown.service';
import { tap, filter, finalize } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';



@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    @ViewChild('financial_year_element', { static: false }) financial_year_element: MatSelect;

    constructor(
        private router: Router,
        private auth: LoginService,
        private api: CommonApiService,
        private eventEmitterService: EventEmitterService,
        public speaker: SpeakerService,
        private dropdownService: CommonDropdownService
    ) { }

    public username: string;
    public password: string;

    fySearchPage = 0;
    allFYCount = 0
    public allFinancialYears: any[] = [];
    public selectedFinancialYearObject: any[] = [];
    public selectedFinancialYear: number;

    ngOnInit(): void {
        // Get financial years from server
        this.getFinancialYears();
        const currentUser = this.auth.currentUserValue;
        if (currentUser) {
            this.router.navigate(['/dashboard'])
        }
    }

    login() {
        this.auth.login(this.username, this.password, this.selectedFinancialYear);
    }

    getFinancialYears() {

        let params = {
            'pageIndex': this.eventEmitterService.pageIndex ? this.eventEmitterService.pageIndex: environment.defaultPageIndexOnLoad,
            'pageSize': this.eventEmitterService.pageSize ? this.eventEmitterService.pageSize: environment.defaultPageSizeOnLoad,
            filterCriteria: []
        }

        this.api.postAll('financial_year/list/display', params).subscribe((res: any) => {
            this.allFinancialYears = res.items;
            this.selectedFinancialYear = res.items[0].id;
            this.selectedFinancialYearObject.push(res.items[0]);
            this.speaker.setListData(this.allFinancialYears, res.rowCount, res.pageIndex, res.pageSize);
        });
    }

    //api call to bring search values in dropdown and also the selected value in dropdown is displayed
    onFinancialYearKey(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.dropdownService.updateSearchResults(value, 'financial_year', this.allFinancialYears, this.selectedFinancialYearObject, 'fySearchPage')
            .subscribe((result: { entityList: any[], pageVariable: number }) => {
            this.allFinancialYears = result.entityList;
            this.fySearchPage = result.pageVariable;
        });
    }
    

    onFinancialYearScroll(event:any) {
        if (event) {
            if (!this.financial_year_element?.panel || !this.financial_year_element?.panel.nativeElement) {
                // Panel or nativeElement is not available, return or handle the situation accordingly.
                return;
            }
            this.financial_year_element.panel.nativeElement.addEventListener(
            'scroll',   
            (event: any) => {
                const tolerance = 10
                if (
                    Math.floor(this.financial_year_element.panel.nativeElement.scrollTop) + tolerance >= 
                    Math.floor(this.financial_year_element.panel.nativeElement.scrollHeight) -
                    Math.floor(this.financial_year_element.panel.nativeElement.offsetHeight)
                ){
                    let stateparams = {
                        'pageIndex': this.fySearchPage,
                        'pageSize': environment.defaultPageSizeOnScroll
                    }

                    if (this.allFinancialYears.length >= this.allFYCount) {
                        return;
                    }
                    this.api.postAll('state/list/display', stateparams).subscribe((res: any) =>{
                        res.items = res.items.filter((entity: any) => entity.id !== this.selectedFinancialYearObject[0]?.id);
                        this.allFinancialYears.push(...res.items);
                    });
                    this.fySearchPage++;// send page number for fetching the records
                }
            });
        }
    }
    
}
