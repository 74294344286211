<div class="title">
    <h3>Point Of Contact</h3>
</div>
<form #pocForm="ngForm" autocomplete="off" (ngSubmit)="savePoc(pocForm.valid)" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">

    <div class="base-form p-4">

        <div class="row">
            <label for="name" class="col-sm-2 col-form-label">Name <span class="span-class"> *</span></label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input #name="ngModel" type="text" matInput name="name" [(ngModel)]="doctorPoc.name" required>
                </mat-form-field>
                <div class="validation-class" *ngIf="name.touched && name.invalid">Required</div>
            </div>

            <label for="email" class="col-sm-2 col-form-label">Email</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input type="text" matInput name="email" [(ngModel)]="doctorPoc.email">
                </mat-form-field>
            </div>
        </div>

      
        <div class="row">
            <label for="mobile" class="col-sm-2 col-form-label">Mobile</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input type="text" matInput name="mobile" [(ngModel)]="doctorPoc.mobile">
                </mat-form-field>
            </div>

            <label for="designation" class="col-sm-2 col-form-label">Designation</label>
            <div class="col-sm-4">
                <mat-form-field class="MatFormFieldControl">
                    <input type="text" matInput name="designation" [(ngModel)]="doctorPoc.designation">
                </mat-form-field>
            </div>
        </div>
    </div>
    <br><br>

    <ng-container>
        <button class="mx-3" type="button" (click)="close()" mat-raised-button color="link">Discard</button>
    </ng-container>

    <ng-container>
        <button type="submit" mat-raised-button color="primary">Add</button>
    </ng-container>

</form>