import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SpeakerService } from '../../speaker.service';
import { AccountReceipt, displayColumns,displayHeaders, filterParameters } from './account-receipt.interface';
import { CommonApiService } from "../../common-api.service";
import { EventEmitterService } from '../../event-emitter.service';
import { environment } from "../../../../environments/environment";
import { DynamicFilterComponent } from '../dynamic-filter/dynamic-filter.component';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { RenderFilterDropdownService } from '../../render-filter-dropdown.service';


@Component({
    selector: 'app-account-receipt',
    templateUrl: './account-receipt.component.html',
    styleUrls: ['./account-receipt.component.css']
})
export class AccountReceiptComponent implements OnInit {

    displayColumns = displayColumns;
    displayHeaders = displayHeaders;
    account_receipt: AccountReceipt[] =[];
    filterFields: any[] =[]
    filterCriteria: any[] =[] 
    companyOptions:any[] = [];
    doctorOptions:any[] = [];

    @ViewChild('dynamicFilterContainer', { read: ViewContainerRef, static: true })
    dynamicFilterContainer!: ViewContainerRef;

    constructor(
        public speaker: SpeakerService,
        private api: CommonApiService,
        private eventEmitterService: EventEmitterService,
        private router: Router,
        private dialog: MatDialog,
        private datePipe: DatePipe,
        private renderFilterDropdown: RenderFilterDropdownService

    ) { }

    ngOnInit(): void {
        //resetting event emmitter variables onload of component
        this.eventEmitterService.pageSize = environment.defaultPageSizeOnLoad
        this.eventEmitterService.pageIndex = environment.defaultPageIndexOnLoad 

        this.getListDetails();
        this.speaker.model = 'account_receipt';
        this.speaker.displayColumns = this.displayColumns;
        this.speaker.displayHeaders = this.displayHeaders;
        this.speaker.listData = this.account_receipt;

        //function gets called by default when the component is loaded
        this.eventEmitterService.subsVar = this.eventEmitterService.    
        invokeFirstComponentFunction.subscribe(() => {  
            this.getListDetails();   
        });
        this.onDropdownRenderOptions('doctor/list/display', this.doctorOptions);
        this.onDropdownRenderOptions('company/list/display', this.companyOptions);


    }

    // dynamic func for dropdown loading in filters
    onDropdownRenderOptions(apiEndpoint: string, targetOptions: any[]) {
        this.renderFilterDropdown.onDropdownLoadOptions(apiEndpoint, targetOptions);
    }

    ngOnDestroy(): void {
        this.eventEmitterService.subsVar.unsubscribe(); 
    }

    transformDate(date: any) {
        date = new Date(date);
        var converteddate: any = this.datePipe.transform(date, 'dd-MM-yyyy');
        return converteddate;
    }

    getListDetails() {
        let params = {
            pageIndex: this.eventEmitterService.pageIndex ? this.eventEmitterService.pageIndex: environment.defaultPageIndexOnLoad,
            pageSize: this.eventEmitterService.pageSize ? this.eventEmitterService.pageSize: environment.defaultPageSizeOnLoad,
            filterCriteria: this.filterCriteria
        }

        this.api.postAll('account_receipt/list/display', params).subscribe((res: any) => {
            this.account_receipt = res.items;
            this.account_receipt.forEach(element => {   
                element.doctor_name = element.receiving_account?.name;
                element.company_name = element.company?.company_name;
                if (element.type == "cash") {
                    element.type = "Cash";
                } else if(element.type == "cheque") {
                    element.type = "Cheque";
                } else if (element.type == "online") {
                    element.type = "Online";
                }
                if(element.receipt_date){
                    element.receipt_date = this.transformDate(element.receipt_date)
                }
                element.status = element.status.toUpperCase()
            })
            this.speaker.setListData(this.account_receipt, res.rowCount, res.pageIndex, res.pageSize);
        });
    }

    getOptionArray<T>(modelName: any): T[] {
        const ModelOptionsMap: Record<string, T[]> = {
            company: this.companyOptions,
            doctor: this.doctorOptions,
            // visittype: this.visittypeOptions
            // Add other model names and their corresponding options arrays here
        };
        
        const optionKey = ModelOptionsMap[modelName];
        return optionKey;
    }

    openDialogFilter() {
  
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            filterFields: filterParameters.map(field => {
                if (field.type == 'dropdown') {
                    return {
                    ...field,
                    options: this.getOptionArray<typeof field.model_name>(field.model_name),
                    };
                }
                return field;
            }),
        };
        const dialogRef = this.dialog.open(DynamicFilterComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.filterCriteria = result
                this.eventEmitterService.pageIndex = environment.defaultPageIndexOnLoad
                this.eventEmitterService.pageSize = environment.defaultPageSizeOnLoad
                this.getListDetails();
            }
        });
    }

    resetFilters() {
        this.redirectTo(this.speaker.model);
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

}