import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonApiService } from '../../common-api.service';

// interface Users {
//   value: string;
//   viewValue: string;
// }

@Component({
    selector: 'app-employee-login',
    templateUrl: './employee-login.component.html',
    styleUrls: ['./employee-login.component.css']
})
export class EmployeeLoginComponent implements OnInit {

    constructor(
        private router: Router,
        private api: CommonApiService,
        private route: ActivatedRoute,
    ) {}

    passwordLength: any;
    public allEmployees: any[] = [];
    public filteredEmployees = this.allEmployees;
    public selectedEmployee: number;
    public navigateTo: string = 'jobschedule';
    public type: string;


    ngOnInit() {
        this.route.params.subscribe(params => {
            this.type = params.type
            if (this.type == 'in') {
                this.navigateTo = 'prodjobin';
            }
            else if (this.type == 'out') {
                this.navigateTo = 'prodjobout';
            }
        });
        
        this.api.getAll('employee').subscribe(res => {
            this.allEmployees = res;
            this.filteredEmployees = res;
        })
    }

    // onfocus(e:any){
    //     this.removeAttr('readonly');
    // }

    onEmployeeKey = (event: Event) => {
        var value = (event.target as HTMLInputElement).value;
        this.filteredEmployees = this.searchEmployees(value);
    }

    searchEmployees(value: string) {
        let filter = value.toLowerCase();
        return this.allEmployees.filter(option =>
            option.name.toLowerCase().includes(filter)
        );
    }

    keyPress(event: any) {
        this.passwordLength = event.target.value.length;
        if (this.passwordLength == 3) {
            localStorage.setItem('employee_id', String(this.selectedEmployee));
            this.router.navigate([this.navigateTo]);
        }
    }

}
