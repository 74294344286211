import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './base.component';
import { BodyModule } from './body/body.module';
import { NavComponent } from './nav/nav.component';
import { AngularMaterialModule } from '../material.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';


@NgModule({
  declarations: [
    BaseComponent,
    NavComponent,
    LoginComponent
  ],
  exports: [
    BaseComponent
  ],
  imports: [
    CommonModule,
    BodyModule,
    AngularMaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class BaseModule { }
