<h1 mat-dialog-title>
    {{title}}
  </h1>
  
  <div mat-dialog-content>
    <p>{{message}}</p>
  </div>

  <label *ngIf="this.repairJob" for="process_name" class="col-sm-12 col-form-label">Repair from Process <span class="span-class"> *</span></label>
  <div *ngIf="this.repairJob" class="row">
    <div class="col-sm-6">
        <mat-form-field class="MatFormFieldControl">
            <mat-select #process_id [(value)]="selectedProcess" required>
                <mat-option *ngFor="let process of jobProcesses" [value]="process.id">{{process.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
  </div>
  
  <div mat-dialog-actions>
    <button mat-button (click)="onDismiss()">No</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
  </div>