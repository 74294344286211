import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonApiService } from './common-api.service';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CommonDropdownService {

  constructor(
	private api: CommonApiService,
  ) { }
     
	updateSearchResults = (searchValue: string, entityType: string, entityList: any[], selectedEntityObject: any[], pageVariable: string): Observable<{ entityList: any[], pageVariable: number }> => {
		
		if (searchValue === '') {
		return this.resetSearchResults(entityList, selectedEntityObject, entityType, pageVariable);
		} else {
		const params = { 'searchValue': searchValue };
		
	
		return this.api.postAll(`${entityType}/list/display`, params).pipe(
			map((res: any) => {
			const newItems = res.items.filter((entity: any) => entity.id !== selectedEntityObject[0]?.id);
			const updatedEntityList = selectedEntityObject[0] ? [selectedEntityObject[0], ...newItems] : [...newItems];
			return {
				entityList: updatedEntityList,
				pageVariable: 1
			};
			})
		);
		}
	};
	
	resetSearchResults = (entityList: any[], selectedEntityObject: any[], entityType: string, pageVariable: string): Observable<{ entityList: any[], pageVariable: number }> => {
		(this as any)[pageVariable] = 0;
	
		const params = {
		'pageIndex': (this as any)[pageVariable],
		'pageSize': environment.defaultPageSizeOnScroll
		};
	
		return this.api.postAll(`${entityType}/list/display`, params).pipe(
		map((res: any) => {

			const newItems = res.items.filter((entity: any) => entity.id !== selectedEntityObject[0]?.id);
			const updatedEntityList = selectedEntityObject[0] ? [selectedEntityObject[0], ...newItems] : [...newItems];
			return {
			entityList: updatedEntityList,
			pageVariable: (this as any)[pageVariable] + 1
			};
		})
		);
	}

	addToSelectedObjectArray(selectedObjectArray:any, item:any) {
		if (!selectedObjectArray) {
			selectedObjectArray = [];
		}
		
		selectedObjectArray.push(item);
	}

	updateSearchResultsSpecificUrl = (searchValue: string, process:number, entityType: string, entityList: any[], selectedEntityObject: any[], pageVariable: string): Observable<{ entityList: any[], pageVariable: number }> => {
		if (searchValue === '') {
		return this.resetSearchResultsSpecificUrl(entityList, process, selectedEntityObject, entityType, pageVariable);
		} else {
		const params = { 
			'searchValue': searchValue ,
			'process_id': process
		};

		return this.api.postAll(`${entityType}`, params).pipe(
			map((res: any) => {
			const newItems = res.items.filter((entity: any) => entity.id !== selectedEntityObject[0]?.id);
			const updatedEntityList = selectedEntityObject[0] ? [selectedEntityObject[0], ...newItems] : [...newItems];
			return {
				entityList: updatedEntityList,
				pageVariable: 1
			};
			})
		);
		}
	};

	resetSearchResultsSpecificUrl = (entityList: any[], process:number ,selectedEntityObject: any[], entityType: string, pageVariable: string): Observable<{ entityList: any[], pageVariable: number }> => {
		(this as any)[pageVariable] = 0;
	
		const params = {
		'pageIndex': (this as any)[pageVariable],
		'pageSize': environment.defaultPageSizeOnScroll,
		'process_id': process
		};
	
		return this.api.postAll(`${entityType}`, params).pipe(
		map((res: any) => {

			const newItems = res.items.filter((entity: any) => entity.id !== selectedEntityObject[0]?.id);
			const updatedEntityList = selectedEntityObject[0] ? [selectedEntityObject[0], ...newItems] : [...newItems];
			return {
			entityList: updatedEntityList,
			pageVariable: (this as any)[pageVariable] + 1
			};
		})
		);
	}
}
