
    <div>
      <canvas baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [legend]="barChartLegend"
        [chartType]="barChartType">
      </canvas>
    </div>
    <!-- <button mat-button mat-raised-button color="primary" (click)="randomize()">Update</button>
   -->
<!-- [
  ]="barChartPlugins" -->