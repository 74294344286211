<form #accountForm="ngForm" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <div class="form-header pt-4 row">
        <div class="col-9 form-breadcrumb">
            <h3>Knock Off</h3>
        </div>
        <div class="col-sm-3 form-operation">
            <button class="m-2" type="button" mat-raised-button color="primary" (click)="confirmDialog()">Submit</button>
        </div>
    </div>
    <div class="base-form mat-elevation-z2 p-4">
        <fieldset>
            <div class="row">
                <label for="against" class="col-sm-2 col-form-label">Against <span class="span-class">
                        *</span></label>
                <div class="col-sm-10">
                    <div class="d-flex justify-content-start">
                        <mat-radio-group name="against" [(ngModel)]="selectedAgainstModel" required (change)="onAgainstModelChange()">
                            <mat-radio-button *ngFor="let item of againstMapping" [value]="item.value">{{item.label}}
                                <h5>{{item.label}}&nbsp;&nbsp;&nbsp;</h5>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- <ng-container *ngIf="selectedAgainstModel == 'account_receipt'"> -->
                <ng-container>
                    <label for="against_object" class="col-sm-2 col-form-label">Select {{labels[selectedAgainstModel]}}<span class="span-class">*</span></label>
                    <div class="col-sm-4">
                        <mat-form-field class="MatFormFieldControl">
                            <mat-select #against_object_id (openedChange)="onAgainstObjectScroll($event)" [(value)]="selectedAgainstObject" (selectionChange)="onAgainstObjectChange()" required>
                                <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keydown)="$event.stopPropagation()" (keyup)="onAgainstObjectKey($event)" matInput name="against_object">
                                <mat-option *ngFor="let against_object of allAgainstObjects" [value]="against_object.id">{{against_object.receipt_number || against_object.voucher_number }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>

            <div class="row">
                <label for="balance" class="col-sm-2 col-form-label">Balance</label>
                <div class="col-sm-4">
                    <span class="bottom-0" *ngIf="againstObject">
                            <p class="receipt-balance" *ngIf="againstObject.balance != undefined">{{againstObject.balance}}</p>
                            <p class="receipt-balance" *ngIf="againstObject.credit_balance != undefined">Credit Balance - {{againstObject.credit_balance}}</p>
                            <p class="receipt-balance" *ngIf="againstObject.debit_balance != undefined">Debit Balance - {{againstObject.debit_balance}}</p>
                            
                        </span>
                </div>
            </div>

            <div class="row mt-5">
                <mat-tab-group animationDuration="0ms" class="mt-5">
                    <mat-tab id="knock_off_list" label="Knock-Off">
                        <table mat-table [dataSource]="knockoffsDataSource" #knock_off_lines class="mat-elevation-z8">
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                    Select
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="toggleSelection(row)" [checked]="selection.isSelected(row)" [disabled]="(againstObject && againstObject.balance <= 0 && !selection.isSelected(row))">
                                    </mat-checkbox>
                                </td>
                            </ng-container>

                            <ng-container class="mat-table-row" [matColumnDef]="column" *ngFor="let column of displayColumns; let i = index;">
                                <th class="font-weight-bold" mat-header-cell *matHeaderCellDef> {{displayHeaders[i]}} </th>
                                <td mat-cell *matCellDef="let obj" class="header-cell"> {{obj[column]}} </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayColumnsWithSelect"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayColumnsWithSelect;"></tr>

                        </table>
                    </mat-tab>
                </mat-tab-group>
            </div>

        </fieldset>
    </div>
</form>