import { Component, OnInit, Inject } from '@angular/core';
import { DoctorAddress } from "./doctor-address.interface";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CommonApiService } from '../../common-api.service';


@Component({
    selector: 'app-doctor-address',
    templateUrl: './doctor-address.component.html',
    styleUrls: ['./doctor-address.component.css']
})
export class DoctorAddressComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DoctorAddressComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private api: CommonApiService
    ) {
        this.doctorAddressId = data.uid;
        this.doctorId = data.doctorId
        this.address = data.address
    }

    public address: DoctorAddress;
    public doctorAddressId: number;
    public doctorId: number;
    public model = 'doctoraddress'
    public doctorAddress = {} as DoctorAddress;
    public allStates: any[] = [];
    public filteredStates = this.allStates;
    public selectedState: number;
    public allAreas: any[] = [];
    public filteredAreas = this.allAreas;
    public selectedArea: number;
   


    ngOnInit(): void {
        if (this.doctorAddressId !== 0) {
            this.getDoctorAddress(Number(this.doctorAddressId));
        }

        this.api.getAll('state').subscribe(res => {
            this.allStates = res;
            this.filteredStates = res;
        })

        this.api.getAll('area').subscribe(res => {
            this.allAreas = res;
            this.filteredAreas = res;
        })
      
    }

 

    onStateKey = (event: Event) => {
        var value = (event.target as HTMLInputElement).value;
        this.filteredStates = this.searchStates(value);
    }

    searchStates(value: string) {
        let filter = value.toLowerCase();        
        return this.allStates.filter(option =>
            option.name.toLowerCase().includes(filter)
        );
    }

    onAreaKey = (event: Event) => {
        
        var value = (event.target as HTMLInputElement).value;
        this.filteredAreas = this.searchAreas(value);
    }

    searchAreas(value: string) {
        let filter = value.toLowerCase();        
        return this.allAreas.filter(option =>
            option.name.toLowerCase().includes(filter)
        );
    }
    
    close() {
        this.dialogRef.close();
    }

    saveAddress(formValid:any) {
        if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
        if (this.selectedState) {
            this.api.get('state', this.selectedState).subscribe(
                (data: any) => {
                    this.doctorAddress.state = data;
                }
            );
        }
        if (this.selectedArea) {
            this.api.get('area', this.selectedArea).subscribe(
                (data: any) => {
                    this.doctorAddress.area = data;
                }
            );
        }
        this.doctorAddress.doctor_id = this.doctorId
        if (!this.doctorAddressId) {
            this.dialogRef.close({doctorAddress: this.doctorAddress});
        }
        else {
            this.dialogRef.close({doctorAddress: this.doctorAddress});
            // this.dialogRef.close();
        }
    }

    getDoctorAddress(doctorAddressId: number) {
        
        if (this.address) {
            this.doctorAddress = this.address
            if (this.address.state) {
                this.selectedState = this.address.state.id
            }
            if (this.address.area) {
                this.selectedArea = this.address.area.id
            }
        }
        else if (doctorAddressId) {
            this.api.get(this.model, doctorAddressId).subscribe(
                (data: any) => {
                    if (data) {
                        this.doctorAddress = data;
                        this.selectedState = data.state ? data.state.id : null 
                        this.selectedArea = data.area ? data.area.id : null
                    }
                }
            );
        }

    }

}
