<div class="container-fluid pt-4 pl-4">
	<div class="row align-items-center">
		<div class="col-9">
			<h3>Proforma Invoice</h3>
		</div>
		<div class="col-3">
			<button mat-raised-button (click)="openDialogFilter()" color="primary" class="example-action-button m-2">Filter</button>
			<button mat-icon-button  (click)="resetFilters()" color="primary" class="example-action-button m-2">
				<mat-icon>refresh</mat-icon>
			</button>
		</div> 
	</div>
</div>

<app-list-container *ngIf="this.invoice.length > 0"></app-list-container>
<app-list-container *ngIf="this.invoice.length == 0"></app-list-container>
