<div class="container-fluid pt-4 pl-4">
    <h3><a [routerLink]="['/reports']" routerLinkActive="router-link-active">Reports</a> > Outstanding Receivables</h3>
</div>

<div class="pt-4 pl-4">
    <div class="row">
        <label for="till_date" class="col-sm-2 col-form-label">Select Till Date <span class="span-class"> *</span></label>
        <div class="col-sm-4 dateRangePickStyle">
            <mat-form-field class="example-full-width" appearance="fill">
                <input [(ngModel)]="tillDate"  matInput name="till_date" [matDatepicker]="till_date" required>
                <mat-datepicker-toggle matSuffix [for]="till_date">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #till_date></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <label for="doctor_list" class="col-sm-2 col-form-label">Select Doctor <span class="span-class"> *</span></label>
        <div class="col-sm-4">
            <mat-form-field class="MatFormFieldControl">
                <mat-select #doctor_id (openedChange)="onDoctorScroll($event)" [(value)]="selectedDoctor" required>
                    <input class="select-input" placeholder="Search..." type="text" autocomplete="off" (keydown)="$event.stopPropagation()" (keyup)="onDoctorKey($event)" matInput name="doctor_list">
                    <mat-option [value]="0">All</mat-option>
                    <mat-option *ngFor="let doctor of allDoctors" [value]="doctor.id">{{doctor.name}}</mat-option>
                </mat-select>

            </mat-form-field>
        </div>
    </div><br>
    <div class="row col-12 p-4">
        <div class="col-2"></div>
        <div class="col-4 btnGenerate">
            <button type="button" mat-flat-button color="primary" (click)='generateReport()'>Generate Report</button>
        </div>
    </div>
</div>
<div class="col-sm-1 form-operation" *ngIf="speaker.listData.length > 0">
    <a target="_blank" [href]="pdf_url"> 
        <button class="m-4 btnExportExcel" type="button" mat-raised-button color="default">View Pdf</button>
    </a>
</div>

<app-report-list-container *ngIf="speaker.listData.length > 0"></app-report-list-container>