<form #productForm="ngForm" autocomplete="off" (ngSubmit)="saveOrderLine(productForm.valid)" class="MatFormFieldControl col-xs-12 col-sm-12 col-lg-12 col-md-12">
    <fieldset [disabled]="isFormDisabled">
        <div class="p-4 ">
            <div class="row">
                <label for="product_name" class="col-sm-3 col-form-label">Product Name <span class="span-class"> *</span></label>
                <div class="col-sm-9">
                    <mat-form-field class="MatFormFieldControl prodDropdown">
                        <mat-select [(value)]="selectedProduct" [disabled]="isFormDisabled" required>
                            <input class="select-input" placeholder="Search..." type="text" autocomplete="nope" (keyup)="onProductKey($event)" matInput name="product_list">
                            <mat-option *ngFor="let product of filteredProducts" [value]="product.id">{{product.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <!-- this div should be for pc view  starts-->
            <div class="row pt-4 d-none d-md-block">
                <label for="restoration_on_tooth" class="col-sm-3 col-form-label">Restoration on Tooth</label>

                <div class="col-sm-4">
                    <section class="example-section">
                        <div class="col-sm-12" style="border-bottom: groove;">
                            <div *ngFor="let leftTop of [8,7,6,5,4,3,2,1];" class="col-sm-1">
                                <label class="toothlabel col-form-label"> {{ leftTop }}</label><br>

                                <mat-checkbox class="toothcheckbx" color="primary" (change)="leftTopTooth($event)" [checked]="left_top_tooth_selected.includes(leftTop)" type="checkbox" value={{leftTop}} id=lt_{{leftTop}} name="lt_{{leftTop}}"></mat-checkbox>
                            </div>
                        </div>
                    </section>
                    <section class="example-section">
                        <div class="col-sm-12">
                            <div *ngFor="let leftBottom of [8,7,6,5,4,3,2,1];" class="col-sm-1">
                                <label for="8" class="toothlabel col-form-label"> {{ leftBottom }}</label><br>

                                <mat-checkbox class="toothcheckbx" color="primary" (change)="leftBottomTooth($event)" [checked]="left_bottom_tooth_selected.includes(leftBottom)" type="checkbox" value={{leftBottom}} id=lb_{{leftBottom}} name="lb_{{leftBottom}}"></mat-checkbox>
                            </div>
                        </div>
                    </section>
                </div>

                <div style="border-left: groove;" class="col-sm-4">
                    <section class="example-section">
                        <div class="col-sm-12" style="border-bottom: groove;">
                            <div *ngFor="let rightTop of [1,2,3,4,5,6,7,8];" class="col-sm-1">
                                <label for="1" class="toothlabel col-form-label"> {{ rightTop }}</label><br>

                                <mat-checkbox class="toothcheckbx" color="primary" (change)="rightTopTooth($event)" [checked]="right_top_tooth_selected.includes(rightTop)" type="checkbox" value={{rightTop}} id=rt_{{rightTop}} name="rt_{{rightTop}}"></mat-checkbox>
                            </div>
                        </div>
                    </section>
                    <section class="example-section">
                        <div class="col-sm-12">
                            <div *ngFor="let rightBottom of [1,2,3,4,5,6,7,8];" class="col-sm-1">
                                <label for="1" class="toothlabel col-form-label">{{ rightBottom }}</label><br>
                                <mat-checkbox class="toothcheckbx" color="primary" (change)="rightBottomTooth($event)" [checked]="right_bottom_tooth_selected.includes(rightBottom)" type="checkbox" value={{rightBottom}} id=rb_{{rightBottom}} name="rb_{{rightBottom}}"></mat-checkbox>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <!-- this div should be for pc view  ends-->

            <!-- this div should be for mobile view starts -->
            <div class="row pt-4 d-md-none">
                <hr>
                <label for="restoration_on_tooth" class="col-form-label">Restoration on Tooth</label>
                <div class="col-12">
                    <label for="restoration_on_tooth_left_top" class="col-form-label pt-4">Left Top</label>
                    <div class="col-sm-9 tooth-container-horizontal">
                        <div class="row">
                            <div *ngFor="let leftTop of [1,2,3,4,5,6,7,8]" class="col-1">
                                <label class="toothlabel col-form-label"> {{ leftTop }}</label>
                                <mat-checkbox class="toothcheckbx" color="primary" (change)="leftTopTooth($event)" [checked]="left_top_tooth_selected.includes(leftTop)" type="checkbox" value={{leftTop}} id=lt_{{leftTop}} name="lt_{{leftTop}}"></mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 pt-4">
                    <label for="restoration_on_tooth_left_bottom" class="col-form-label">Left Bottom</label>
                    <div class="col-sm-9 tooth-container-horizontal">
                        <div class="row">
                            <div *ngFor="let leftBottom of [1,2,3,4,5,6,7,8];"  class="col-1">
                            <label class="toothlabel col-form-label"> {{ leftBottom }}</label>
                            <mat-checkbox class="toothcheckbx" color="primary" (change)="leftBottomTooth($event)" [checked]="left_bottom_tooth_selected.includes(leftBottom)" type="checkbox" value={{leftBottom}} id=lb_{{leftBottom}} name="lb_{{leftBottom}}"></mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 pt-4">
                    <label for="restoration_on_tooth_right_top" class="col-form-label">Right Top</label>
                    <div class="col-sm-9 tooth-container-horizontal">
                        <div class="row">
                            <div *ngFor="let rightTop of [1,2,3,4,5,6,7,8];"  class="col-1">
                                <label class="toothlabel col-form-label"> {{ rightTop }}</label>
                                <mat-checkbox class="toothcheckbx" color="primary" (change)="rightTopTooth($event)" [checked]="right_top_tooth_selected.includes(rightTop)" type="checkbox" value={{rightTop}} id=rt_{{rightTop}} name="rt_{{rightTop}}"></mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 pt-4">
                    <label for="restoration_on_tooth_right_bottom" class="col-form-label">Right Bottom</label>
                    <div class="col-sm-9 tooth-container-horizontal">
                        <div class="row">
                            <div *ngFor="let rightBottom of [1,2,3,4,5,6,7,8];"   class="col-1">
                                <label class="toothlabel col-form-label">{{ rightBottom }}</label>
                            <mat-checkbox class="toothcheckbx" color="primary" (change)="rightBottomTooth($event)" [checked]="right_bottom_tooth_selected.includes(rightBottom)" type="checkbox" value={{rightBottom}} id=rb_{{rightBottom}} name="rb_{{rightBottom}}"></mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

              <!-- this div should be for mobile view ends -->

            <!-- this div should be for pc  starts -->
            <div class="row pt-4 d-none d-md-block">
                <div class="row col-sm-12 pt-4">
                    <div class="four">
                        <div class="col-sm-12">
                            <label for="stage" class="col-form-label">Stage</label>
                        </div>
                        <div class="col-sm-12 threeSection">
                            <!-- <div class="col-sm-6"> -->
                            <mat-checkbox color="primary" class="example-margin" (change)="stageChkBox($event)" [checked]="stage_selected.includes('Metal try-in')" type="checkbox" value="Metal try-in" id="Metal try-in" name="Metal try-in">Metal try-in</mat-checkbox>

                            <mat-checkbox color="primary" class="example-margin" (change)="stageChkBox($event)" [checked]="stage_selected.includes('Copying try-in')" type="checkbox" value="Copying try-in" id="Copying try-in" name="Copying try-in">Copying try-in</mat-checkbox>
                            <!-- </div>
                            <div class="col-sm-6"> -->
                            <mat-checkbox color="primary" class="example-margin" (change)="stageChkBox($event)" [checked]="stage_selected.includes('Bisque try-in')" type="checkbox" value="Bisque try-in" id="Bisque try-in" name="Bisque try-in">Bisque try-in</mat-checkbox>

                            <mat-checkbox color="primary" class="example-margin" (change)="stageChkBox($event)" [checked]="stage_selected.includes('Finish')" type="checkbox" value="Finish" id="Finish" name="Finish">Finish</mat-checkbox>
                            <!-- </div> -->
                        </div>
                    </div>

                    <div class="five">
                        <div class="col-sm-12">
                            <label for="pontic_design" class="col-form-label">Pontic Design</label>
                        </div>
                        <div class="col-sm-12 threeSection">
                            <!-- <div class="col-sm-6"> -->
                            <mat-checkbox color="primary" class="example-margin" (change)="ponticDesignChkBox($event)" [checked]="pontic_design_selected.includes('Modified Ridge')" type="checkbox" value="Modified Ridge" id="Modified Ridge" name="Modified Ridge">Modified Ridge</mat-checkbox>

                            <mat-checkbox color="primary" class="example-margin" (change)="ponticDesignChkBox($event)" [checked]="pontic_design_selected.includes('Full Ridge')" type="checkbox" value="Full Ridge" id="Full Ridge" name="Full Ridge">Full Ridge</mat-checkbox>
                            <!-- </div>
                            <div class="col-sm-6"> -->
                            <mat-checkbox color="primary" class="example-margin" (change)="ponticDesignChkBox($event)" [checked]="pontic_design_selected.includes('Hygienic')" type="checkbox" value="Hygienic" id="Hygienic" name="Hygienic">Hygienic</mat-checkbox>

                            <mat-checkbox color="primary" class="example-margin" (change)="ponticDesignChkBox($event)" [checked]="pontic_design_selected.includes('Ovate')" type="checkbox" value="Ovate" id="Ovate" name="Ovate">Ovate</mat-checkbox>
                            <!-- </div> -->
                        </div>
                    </div>

                    <div class="five">
                        <div class="col-sm-12">
                            <label for="collor_metal_design" class="col-form-label">Collar & Metal Design</label>
                        </div>
                        <div class="col-sm-12 threeSection">
                            <!-- <div class="col-sm-6"> -->
                            <mat-checkbox color="primary" class="example-margin" (change)="collarMetailDesignChkBox($event)" [checked]="collar_and_metal_selected.includes('No Metal Collar')" type="checkbox" value="No Metal Collar" id="No Metal Collar" name="No Metal Collar">No Metal Collar</mat-checkbox>
                            <mat-checkbox color="primary" class="example-margin" (change)="collarMetailDesignChkBox($event)" [checked]="collar_and_metal_selected.includes('Metal Lingual Collar')" type="checkbox" value="Metal Lingual Collar" id="Metal Lingual Collar" name="Metal Lingual Collar">Metal Lingual Collar</mat-checkbox>
                            <!-- </div>
                            <div class="col-sm-6"> -->
                            <mat-checkbox color="primary" class="example-margin" (change)="collarMetailDesignChkBox($event)" [checked]="collar_and_metal_selected.includes('360 Degree Metal Color')" type="checkbox" value="360 Degree Metal Color" id="360 Degree Metal Color" name="360 Degree Metal Color">360 Degree Metal Color</mat-checkbox>

                            <mat-checkbox color="primary" class="example-margin" (change)="collarMetailDesignChkBox($event)" [checked]="collar_and_metal_selected.includes('Metal Occlusal/ Lingual')" type="checkbox" value="Metal Occlusal/ Lingual" id="Metal Occlusal/ Lingual" name="Metal Occlusal/ Lingual">Metal Occlusal/ Lingual</mat-checkbox>

                            <!-- </div> -->
                        </div>
                    </div>
                </div>
            </div>
             <!-- this div should be for pc ends -->

            <!-- this div should be for mobile starts -->
            <div class="row pt-4 d-md-none">
                <hr>
                <div class="row col-sm-12 pt-4">
                    <div class="col-sm-12">
                        <label for="stage" class="col-form-label">Stage</label>
                    </div>
                    <div class="col-sm-12">
                        <!-- <div class="col-sm-6"> -->
                        <mat-checkbox color="primary" class="example-margin" (change)="stageChkBox($event)" [checked]="stage_selected.includes('Metal try-in')" type="checkbox" value="Metal try-in" id="Metal try-in" name="Metal try-in">Metal try-in</mat-checkbox>

                        <mat-checkbox color="primary" class="example-margin" (change)="stageChkBox($event)" [checked]="stage_selected.includes('Copying try-in')" type="checkbox" value="Copying try-in" id="Copying try-in" name="Copying try-in">Copying try-in</mat-checkbox>
                        <!-- </div>
                        <div class="col-sm-6"> -->
                        <mat-checkbox color="primary" class="example-margin" (change)="stageChkBox($event)" [checked]="stage_selected.includes('Bisque try-in')" type="checkbox" value="Bisque try-in" id="Bisque try-in" name="Bisque try-in">Bisque try-in</mat-checkbox>

                        <mat-checkbox color="primary" class="example-margin" (change)="stageChkBox($event)" [checked]="stage_selected.includes('Finish')" type="checkbox" value="Finish" id="Finish" name="Finish">Finish</mat-checkbox>
                        <!-- </div> -->
                    </div>

                    <div class="col-sm-12 pt-4">
                        <label for="pontic_design" class="col-form-label">Pontic Design</label>
                    </div>
                    <div class="col-sm-12">
                        <!-- <div class="col-sm-6"> -->
                        <mat-checkbox color="primary" class="example-margin" (change)="ponticDesignChkBox($event)" [checked]="pontic_design_selected.includes('Modified Ridge')" type="checkbox" value="Modified Ridge" id="Modified Ridge" name="Modified Ridge">Modified Ridge</mat-checkbox>

                        <mat-checkbox color="primary" class="example-margin" (change)="ponticDesignChkBox($event)" [checked]="pontic_design_selected.includes('Full Ridge')" type="checkbox" value="Full Ridge" id="Full Ridge" name="Full Ridge">Full Ridge</mat-checkbox>
                        <!-- </div>
                        <div class="col-sm-6"> -->
                        <mat-checkbox color="primary" class="example-margin" (change)="ponticDesignChkBox($event)" [checked]="pontic_design_selected.includes('Hygienic')" type="checkbox" value="Hygienic" id="Hygienic" name="Hygienic">Hygienic</mat-checkbox>

                        <mat-checkbox color="primary" class="example-margin" (change)="ponticDesignChkBox($event)" [checked]="pontic_design_selected.includes('Ovate')" type="checkbox" value="Ovate" id="Ovate" name="Ovate">Ovate</mat-checkbox>
                        <!-- </div> -->
                    </div>

                    <div class="col-sm-12 pt-4">
                        <label for="collor_metal_design" class="col-form-label">Collar & Metal Design</label>
                    </div>
                    <div class="col-sm-12">
                        <!-- <div class="col-sm-6"> -->
                        <mat-checkbox color="primary" class="example-margin" (change)="collarMetailDesignChkBox($event)" [checked]="collar_and_metal_selected.includes('No Metal Collar')" type="checkbox" value="No Metal Collar" id="No Metal Collar" name="No Metal Collar">No Metal Collar</mat-checkbox>
                        <mat-checkbox color="primary" class="example-margin" (change)="collarMetailDesignChkBox($event)" [checked]="collar_and_metal_selected.includes('Metal Lingual Collar')" type="checkbox" value="Metal Lingual Collar" id="Metal Lingual Collar" name="Metal Lingual Collar">Metal Lingual Collar</mat-checkbox>
                        <!-- </div>
                        <div class="col-sm-6"> -->
                        <mat-checkbox color="primary" class="example-margin" (change)="collarMetailDesignChkBox($event)" [checked]="collar_and_metal_selected.includes('360 Degree Metal Color')" type="checkbox" value="360 Degree Metal Color" id="360 Degree Metal Color" name="360 Degree Metal Color">360 Degree Metal Color</mat-checkbox>

                        <mat-checkbox color="primary" class="example-margin" (change)="collarMetailDesignChkBox($event)" [checked]="collar_and_metal_selected.includes('Metal Occlusal/ Lingual')" type="checkbox" value="Metal Occlusal/ Lingual" id="Metal Occlusal/ Lingual" name="Metal Occlusal/ Lingual">Metal Occlusal/ Lingual</mat-checkbox>

                        <!-- </div> -->
                    </div>
                </div>
            </div>
             <!-- this div should be for mobile ends -->

            <div class="col-4 p-4">
                <ng-container>
                    <button *ngIf="!isFormDisabled" class="m-2" type="button" (click)="close()" mat-raised-button color="link">Discard</button>
                    <button *ngIf="!isFormDisabled" class="m-2" type="submit" mat-raised-button color="primary">Add</button>
                </ng-container>
            </div>
        </div>
    </fieldset>
    <div class="col-4 p-4">
        <ng-container>
            <button *ngIf="isFormDisabled" (click)="close()" type="button" mat-raised-button color="primary">Close</button>
        </ng-container>
    </div>
</form>