import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { Product } from './product.interface';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { SnackbarService } from '../../snackbar.service';

@Component({
    selector: 'product-form',
    templateUrl: './product.form.component.html',
    styleUrls: ['./product.form.component.css']
})

export class ProductFormComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private api: CommonApiService,
        private router: Router,
        public dialog: MatDialog,
        private snackbar: SnackbarService,
    ) 
    { }

    public allCategorys: any[] = [];
    public filteredCategorys = this.allCategorys;
    public selectedCategory: number;

    public allHsns: any[] = [];
    public filteredHsns = this.allHsns;
    public selectedHsn: number;


    public model = 'product'
    public product = {} as Product;
    isFormDisabled = true;
    result: string;
    onSaveDisable = false;
 
    ngOnInit() {
        let productId = this.route.snapshot.params.id
        if (productId === 'create') {
            console.log('False')
            this.isFormDisabled = false;
        }
        else {
            this.getProduct(Number(productId));
        }

        this.api.getAll('category').subscribe(res => {
            this.allCategorys = res;
            this.filteredCategorys = res;
        })
        this.api.getAll('hsn').subscribe(res => {
            this.allHsns = res;
            this.filteredHsns = res;
        })
        //console.log(this.product);
    }
    
    onlyNumber(evt:any) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    onCategoryKey = (event: Event) => {
        var value = (event.target as HTMLInputElement).value;
        this.filteredCategorys = this.searchCategorys(value);
    }
    searchCategorys(value: string) {
        let filter = value.toLowerCase();        
        return this.allCategorys.filter(option =>
            option.name.toLowerCase().includes(filter)
        );
    }  
    onHsnKey = (event: Event) => {
        var value = (event.target as HTMLInputElement).value;
        this.filteredHsns = this.searchHsns(value);
    }
    searchHsns(value: string) {
        let filter = value.toLowerCase();        
        return this.allHsns.filter(option =>
            option.hsn_sac.toLowerCase().includes(filter)
        );
    }

    getProduct(productId:number) {
        this.api.get(this.model, productId).subscribe(
            (data: any) => {
                if (data){
                    this.product = data;
                    if(data.hsn_sac){
                        this.selectedHsn = data.hsn_sac.id;
                    }
                    if(data.product_category){
                        this.selectedCategory = data.product_category.id;
                    }
                }
            }
        );
    }

    save(formValid:any) {
        if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
        if(this.selectedCategory == undefined){
            alert("Please select category.");
            return;
        }
        this.onSaveDisable = true;
        this.product.hsn_sac_id = this.selectedHsn
        this.product.category_id = this.selectedCategory

        this.api.createUpdate(this.model, this.product).subscribe(
            (data:any) => {
                if (data) {
                    this.product = data;
                    this.isFormDisabled = true;
                    this.snackbar.success('Saved Successfully!');
                    this.onSaveDisable = false;
                }
            },
            error => {
                alert(error.error.message)
                this.onSaveDisable = false;
            }
        );
    }
    edit(){
        this.isFormDisabled = false;
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			console.log(this.result);
			if (this.result) {
                if(this.product.id){
                    this.isFormDisabled = true;
                    this.getProduct(this.product.id);
                }else{
                    this.router.navigate([`/product`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('product/create');
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

}
