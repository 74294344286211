import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { ProductCategory } from './product-category.interface';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { SnackbarService } from '../../snackbar.service';

@Component({
    selector: 'app-product-catergory-form',
    templateUrl: './product-category.form.component.html',
    styleUrls: ['./product-category.form.component.css']
})

export class ProductCategoryFormComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private api: CommonApiService,
        private router: Router,
        public dialog: MatDialog,
        private snackbar: SnackbarService,
    ) { }


    public model = 'category'
    public category = {} as ProductCategory;
    public unmappedDigitalProcess: any[] = [];  
    public onLoadDigitalProcess: any[] = [];  
    public mappedDigitalProcess: any[] = [];
    public unmappedPhysicalProcess: any[] = [];  
    public onLoadPhysicalProcess: any[] = [];  
    public mappedPhysicalProcess: any[] = [];
    isFormDisabled = true;
    result: string;
    onSaveDisable = false;

    ngOnInit() {

        let categoryId = this.route.snapshot.params.id
        if (categoryId === 'create') {
            console.log('False')
            this.isFormDisabled = false;
            this.api.getAll('process').subscribe((res: any[]) => {
                this.unmappedDigitalProcess = res
                this.unmappedPhysicalProcess = res
            })
        }
        else {
            this.api.getAll('process').subscribe((res: any[]) => {
                // Add the 'procedure' element to each item in the array    
                const updatedRes = res.map(item => ({ ...item, procedure: 'digital' }));
                this.onLoadDigitalProcess = updatedRes;
                this.onLoadPhysicalProcess = updatedRes;
                this.getProductCategory(Number(categoryId));
            })

        }  
    }

    drop(event: CdkDragDrop<any[]>) {
        if (event.previousContainer === event.container) {
          moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
          transferArrayItem(event.previousContainer.data,
                            event.container.data,
                            event.previousIndex,
                            event.currentIndex);
        }
    }

    getMappedProcess(){
        this.mappedDigitalProcess.forEach((value, index) => {
            var a = index + 1;
            this.mappedDigitalProcess[index]['sequence'] = a;
            this.mappedDigitalProcess[index]['procedure'] = 'digital';
        });
        this.mappedPhysicalProcess.forEach((value, index) => {
            var a = index + 1;
            this.mappedPhysicalProcess[index]['sequence'] = a;
            this.mappedPhysicalProcess[index]['procedure'] = 'physical';
        });
    }
   
    getProductCategory(categoryId: number) {
        this.api.get(this.model, categoryId).subscribe(
            (data: any) => {
                if (data) {
                    this.category = data;
                    data.processes.forEach((value:any, index:any) => {
                        if(data.processes[index]['procedure'] == 'digital') {
                            this.mappedDigitalProcess.push(data.processes[index]['process']);
                        } else {
                            this.mappedPhysicalProcess.push(data.processes[index]['process']);
                        }
                    });
                    console.log(this.mappedDigitalProcess)
                    console.log(data.processes)
                    this.rearrangeProcesses();
                }
            }
        );
    }


    // rearrangeProcesses(){
    //     this.mappedDigitalProcess.forEach((value:any, index:any) => {
    //         let  k = 0;      
    //         var len = this.onLoadDigitalProcess.length;
    //         for (k = 0; k < len; k = k + 1) {
    //             if (this.onLoadDigitalProcess[k]['id'] === this.mappedDigitalProcess[index]['id']) {
    //                 this.onLoadDigitalProcess.splice(k, 1);
    //                 break;
    //             } 
    //         }
    //     });
    //     this.mappedPhysicalProcess.forEach((value:any, index:any) => {
    //         let  k = 0;      
    //         var len = this.onLoadPhysicalProcess.length;
    //         for (k = 0; k < len; k = k + 1) {
    //             if (this.onLoadPhysicalProcess[k]['id'] === this.mappedPhysicalProcess[index]['id']) {
    //                 this.onLoadPhysicalProcess.splice(k, 1);
    //                 break;
    //             } 
    //         }
    //     });
    //     this.unmappedDigitalProcess =this.onLoadDigitalProcess
    //     this.unmappedPhysicalProcess =this.onLoadPhysicalProcess
    // }

    rearrangeProcesses() {
        this.onLoadDigitalProcess = this.onLoadDigitalProcess.filter(
          digitalProcess => !this.mappedDigitalProcess.some(
            mappedProcess => mappedProcess.id === digitalProcess.id
          )
        );
      
        this.onLoadPhysicalProcess = this.onLoadPhysicalProcess.filter(
          physicalProcess => !this.mappedPhysicalProcess.some(
            mappedProcess => mappedProcess.id === physicalProcess.id
          )
        );
      
        this.unmappedDigitalProcess = this.onLoadDigitalProcess;
        this.unmappedPhysicalProcess = this.onLoadPhysicalProcess;
      }
      

    save(formValid:any) {

        if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
        this.getMappedProcess();
        const digitalAndPhysicalProcesses = [...this.mappedDigitalProcess, ...this.mappedPhysicalProcess];

        this.category.processes = digitalAndPhysicalProcesses
        if(this.mappedDigitalProcess.length == 0){
            alert("Map atleast one digital process");
            return
        }
        if(this.mappedPhysicalProcess.length == 0){
            alert("Map atleast one physical process");
            return
        }

        this.onSaveDisable = true;
        
        this.api.createUpdate(this.model, this.category).subscribe(
            (data: any) => {
                if (data) {
                    this.category = data;
                    this.isFormDisabled = true;
                    this.snackbar.success('Saved Successfully!');
                    this.onSaveDisable = false;
                }
            },
            error => {
                alert(error.error.message)
                this.onSaveDisable = false;
            }
        );
    }

    edit(){
        this.isFormDisabled = false;
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			console.log(this.result);
			if (this.result) {
                if(this.category.id){
                    this.isFormDisabled = true;
                    this.mappedDigitalProcess=[];
                    this.mappedPhysicalProcess=[];
                    this.unmappedDigitalProcess=[];
                    this.unmappedPhysicalProcess=[];
                    this.api.getAll('process').subscribe((res: any[]) => {
                        this.onLoadDigitalProcess = res
                        this.onLoadPhysicalProcess = res
                        
                    })
                    this.getProductCategory(this.category.id);
                }else{
                    this.router.navigate([`/category`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('category/create');
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

}
