import { Component, OnInit } from '@angular/core';
import { ChangePassword } from './change-password.interface';
import { SnackbarService } from '../../snackbar.service';
import { CommonApiService } from '../../common-api.service';
import { Permissions } from '../../auth-guard.guard';
import { LoginService } from '../../login/login.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.css']
})
	
export class ChangePasswordComponent implements OnInit {

	constructor(
		private snackbar: SnackbarService,
		private api: CommonApiService,
		public auth: LoginService,
		public permissions: Permissions,
		private router: Router
	) { }

	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
	public changePassword = {} as ChangePassword;
	currentUser :any
	
	ngOnInit(): void {
	
		this.currentUser = this.auth.currentUserValue
		this.changePassword.currentUser = this.currentUser.data.user_id
	}

	save(formValid:any) {
		if(formValid == false){
			alert("Please fill mandatory fields.");
			return;
		}

		this.api.callRoute(`auth`, `/changePassword`, this.changePassword).subscribe(
			(resp) => {
				if (resp.status != 'error') {
					alert(resp.message);
				}
				else {
					alert(resp.message)
				}
			},
			error => {
				console.log(error)
			}
		);
		this.resetFilters();
			
	}

	resetFilters() {
        this.redirectTo('change-password');
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

}
