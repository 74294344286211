import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'any'
})
export class SpeakerService {

	public listData: any[];
	public displayColumns: any[];
	public displayHeaders: any[];
	public model: string;
	public pageIndex: number;
	public pageSize: number;
	public length: number;
	constructor() { }

	private listDataSubject = new Subject<any>();
  	listData$ = this.listDataSubject.asObservable();

	setListData(data: any[], length: number, pageIndex: number, pageSize: number) {
		this.length = length;
		this.pageIndex = pageIndex;
		this.pageSize = pageSize;
		this.listData = data;
	
		// Emit the data and pagination details through the Subject
		this.listDataSubject.next({ data, length, pageIndex, pageSize });
	}

}
