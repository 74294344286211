import { Component, OnInit, Inject } from '@angular/core';
import { DoctorPoc } from "./poc.interface";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CommonApiService } from '../../common-api.service';


@Component({
    selector: 'app-poc',
    templateUrl: './poc.component.html',
    styleUrls: ['./poc.component.css']
})
export class PocComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<PocComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private api: CommonApiService
    ) {
        this.doctorPocId = data.uid;
        this.doctorId = data.doctorId
        this.poc = data.poc
    }

    public poc: DoctorPoc;
    public doctorPocId: number;
    public doctorId: number;
    public model = 'doctorpoc'
    public doctorPoc = {} as DoctorPoc;


    ngOnInit(): void {
        if (this.doctorPocId !== 0) {
            this.getDoctorPoc(Number(this.doctorPocId));
        }
    }

   
    close() {
        this.dialogRef.close();
    }

    savePoc(formValid:any) {

        if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
      
        this.doctorPoc.doctor_id = this.doctorId
        if (!this.doctorPocId) {
            this.dialogRef.close({doctorPoc: this.doctorPoc});
        }
        else {
            this.dialogRef.close({doctorPoc: this.doctorPoc});
        }
    }

    getDoctorPoc(doctorPocId: number) {
        
        if (this.poc) {
            this.doctorPoc = this.poc
        }
        else if (doctorPocId) {
            this.api.get(this.model, doctorPocId).subscribe(
                (data: any) => {
                    if (data) {
                        this.doctorPoc = data;
                    }
                }
            );
        }
    }


}
