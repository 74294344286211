import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonApiService } from '../../common-api.service';
import { SpeakerService } from '../../speaker.service';
import { Invoice, displayColumns, displayHeaders, filterParameters } from "./invoice.interface";
import { EventEmitterService } from '../../event-emitter.service';
import { environment } from "../../../../environments/environment";
import { DynamicFilterComponent } from '../dynamic-filter/dynamic-filter.component';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RenderFilterDropdownService } from '../../render-filter-dropdown.service';


@Component({
    selector: 'app-main-invoice',
    templateUrl: './main-invoice.component.html',
    styleUrls: ['./invoice.component.css']
})
export class MainInvoiceComponent implements OnInit {


    displayColumns = displayColumns;
    displayHeaders = displayHeaders;
    invoice: Invoice[] = [];
    filterFields: any[] =[]
    filterCriteria: any[] =[]   
    doctorOptions:any[] = [];
    companyOptions:any[] = [];

	@ViewChild('dynamicFilterContainer', { read: ViewContainerRef, static: true })
	dynamicFilterContainer!: ViewContainerRef;

	constructor(
		public speaker: SpeakerService,
		private api: CommonApiService,
		private eventEmitterService: EventEmitterService,
		private datePipe: DatePipe,
		private dialog: MatDialog,
        private router: Router,
        private renderFilterDropdown: RenderFilterDropdownService
	) {}

    // common code above this line for all components
	transformDate(date: any) {
		date = new Date(date);
		var converteddate: any = this.datePipe.transform(date, 'dd-MM-yyyy');
		return converteddate;
	}

	ngOnInit(): void {
        //resetting event emmitter variables onload of component
        this.eventEmitterService.pageSize = environment.defaultPageSizeOnLoad
        this.eventEmitterService.pageIndex = environment.defaultPageIndexOnLoad  

        this.getListDetails();
		this.speaker.model = 'invoice';
		this.speaker.displayColumns = this.displayColumns;
		this.speaker.displayHeaders = this.displayHeaders;
		this.speaker.listData = this.invoice

        //function gets called by default when the component is loaded
        this.eventEmitterService.subsVar = this.eventEmitterService.    
        invokeFirstComponentFunction.subscribe(() => {  
            this.getListDetails();   
        });

        this.onDropdownRenderOptions('doctor/list/display', this.doctorOptions);
        this.onDropdownRenderOptions('company/list/display', this.companyOptions);

    }

    // dynamic func for dropdown loading in filters
    onDropdownRenderOptions(apiEndpoint: string, targetOptions: any[]) {
        this.renderFilterDropdown.onDropdownLoadOptions(apiEndpoint, targetOptions);
    }

    ngOnDestroy(): void {
        this.eventEmitterService.subsVar.unsubscribe(); 
    }

    getListDetails() {

		let params = {
            pageIndex: this.eventEmitterService.pageIndex ? this.eventEmitterService.pageIndex : environment.defaultPageIndexOnLoad,
            pageSize: this.eventEmitterService.pageSize ? this.eventEmitterService.pageSize : environment.defaultPageSizeOnLoad,
            filterCriteria: this.filterCriteria
		};

        this.api.postAll('invoice/list/display', params).subscribe((res: any) => {
			this.invoice = res.items
			this.invoice.forEach((element) => {

                element.company_name = element.company?.company_name;
				if(element.invoice_date){
					element.invoice_date = this.transformDate(element.invoice_date)
				}
				if(element.doctor){
					element.doctor_name = element.doctor.name;
				}   
				element.status = element.status.toUpperCase()
			});
		
			// Set the transformed response to the speaker service
            this.speaker.setListData(this.invoice, res.rowCount, res.pageIndex, res.pageSize);
		});
	}
    // set options for dropdown filters
    getOptionArray<T>(modelName: any): T[] {
        const ModelOptionsMap: Record<string, T[]> = {
          doctor: this.doctorOptions,
          company: this.companyOptions
          // Add other model names and their corresponding options arrays here
        };
        
        const optionKey = ModelOptionsMap[modelName];
        return optionKey;
    }

	openDialogFilter() {
  
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            filterFields: filterParameters.map(field => {
                if (field.type == 'dropdown') {
                    return {
                    ...field,
                    options: this.getOptionArray<typeof field.model_name>(field.model_name),
                    };
                }
                return field;
            }),
        };
        const dialogRef = this.dialog.open(DynamicFilterComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.filterCriteria = result
                this.eventEmitterService.pageIndex = environment.defaultPageIndexOnLoad
                this.eventEmitterService.pageSize = environment.defaultPageSizeOnLoad
                this.getListDetails();
            }
        });
    }

    resetFilters() {
        this.filterCriteria = []
        this.redirectTo(this.speaker.model);
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }
	  
	

}
