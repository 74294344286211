import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, Inject } from '@angular/core';
import { CommonApiService } from '../../common-api.service';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
    title: string;
    message: string;
    job_id: number;
    jobProcesses: any[] = [];
    public selectedProcess: number;
    respData: any
    repairJob : boolean

    constructor(
        private api: CommonApiService,
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        // Update view with given values
        this.title = data.title;
        this.message = data.message;    
        this.job_id = data.job_id;
        this.repairJob = data.repairJob;

    }

    ngOnInit() {
        if(this.repairJob){
            this.getAllProcesses();
        }
    }

    getAllProcesses() {
        this.api.callRoute('joborder', `/processes`, {'job_id': this.job_id}).subscribe( 
           (data: any) => { 
                this.jobProcesses = data;
            },
            error => {
                console.log(error)
            }
        );
    }

    onConfirm(): void {
        // Close the dialog, return true
        if (this.repairJob && this.selectedProcess == undefined) {
            alert("Please select process");
            return;
        }
        this.respData = {
            status: true,
            repairProcess: this.selectedProcess
        }
    
        this.dialogRef.close(this.respData);
    }

    onDismiss(): void {
        // Close the dialog, return false
        this.dialogRef.close(false);
    }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {

    constructor(public title: string, public message: string) {
    }
}
