import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from '../../common-api.service';
import { User } from './user.interface';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { SnackbarService } from '../../snackbar.service';


@Component({
    selector: 'user-form',
    templateUrl: './user.form.component.html',
    styleUrls: ['./user.form.component.css']
})

export class UserFormComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private api: CommonApiService,
        private router: Router,
        public dialog: MatDialog,
        private snackbar: SnackbarService,
    ) 
    { }

    public allRoles: any[] = [];
    public filteredRoles = this.allRoles;
    public selectedRole: number;

  
    public model = 'auth'
    public user = {} as User;
    isFormDisabled = true;
    result: string;
 

    ngOnInit() {
        let userId = this.route.snapshot.params.id
        if (userId === 'create') {
            this.isFormDisabled = false;
        }
        else {
            this.getUser(Number(userId));
        }

        this.api.getAll('userrole').subscribe(res => {
            this.allRoles = res;
            this.filteredRoles = res;
        })
        
    }

    onRoleKey = (event: Event) => {
        var value = (event.target as HTMLInputElement).value;
        this.filteredRoles = this.searchRoles(value);
    }
    searchRoles(value: string) {
        let filter = value.toLowerCase();        
        return this.allRoles.filter(option =>
            option.name.toLowerCase().includes(filter)
        );
    }  

    getUser(userId:number) {
        this.api.get(this.model, userId).subscribe(
            (data: any) => {
                if (data){
                    this.user = data;
                    this.selectedRole = data.role.id ? data.role.id : null
                    this.user.password = "";
                }
            }
        );
    }

    save(formValid: any) {
        if(formValid == false){
            alert("Please fill mandatory fields.");
            return;
        }
        this.user.role_id = this.selectedRole
        this.api.createUpdate(this.model, this.user).subscribe(
            (data:any) => {
                if (data) {
                    console.log(data)
                    this.user = data;
                    this.isFormDisabled = true;
                    this.snackbar.success('Saved Successfully!');
                    this.router.navigate([`/${this.model}/${this.user.id}`])
                    this.user.password = "";
                }
            },
            error => {
                alert(error.error.message)
            }
        );
    }
    edit(){
        this.isFormDisabled = false;
    }

    discardChanges(): void {
		const message = `Are you sure you want to discard the changes?`
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = {
			title: "Discard Changes",
			message: message
		}
		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(dialogResult => {
			this.result = dialogResult;
			// return true or false or undefined , hence we can handle the functionality on true and false likewise here
			if (this.result) {
                if(this.user.id){
                    this.isFormDisabled = true;
                    this.getUser(this.user.id);
                }else{
                    this.router.navigate([`/user`]);
                }
			}
		});
	}

    create(){
        this.redirectTo('auth/create');
    }

    //redirects to the expected route
    //this function will redirect to a dummy route and quickly return to the destination route without the user realizing it.
    redirectTo(uri:string){  
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate([uri]));
    }

}
