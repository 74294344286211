import { Company } from "../company/company.interface";
export interface JournalVoucher {
    id: number
    voucher_number:string;
    voucher_date:Date
    debit_account_internal_id:number;
    debit_account_doctor_id:number;
    credit_account_internal_id:number;
    credit_account_doctor_id:number;
    company_id:number;
    amount:number;
    narration:string;
    status:string;
    credit_balance:number;
    debit_balance:number;
    company_name:string;
    company:Company;
}

export var displayColumns=[
    'voucher_number',
    'voucher_date',
    'company_name',
    'amount',
    'status'
]

export var displayHeaders=[
    'Voucher No.',
    'Voucher Date',
    'Company',
    'Amount',
    'Status'
]

export var orderByColumns=[
    'voucher_number',
    'voucher_date',
    'company_name',
    'amount',
    'status'
]

export const filterParameters = [
    { 
        name: 'created_at', 
        type: 'date_range', 
        operator: 'between', 
        label: 'Select Date Range' 
    },
    { 
		name: 'voucher_number', 
		type: 'string', 
		operator: 'like', 
		label: 'Voucher No.', 
	},
    { 
		name: 'company_id', 
		type: 'dropdown', 
		operator: '=',
		model_name:'company',
		label: 'Company' 
	},
	{ 
		name: 'status', 
		type: 'radio_option', 
		operator: '=',
		values: [
					{ label: 'Draft', value: "draft" },
					{ label: 'Submitted', value: "submitted" },
					{ label: 'Cancelled', value: "canceled" }
				],
		model_name:'invoice',
		label: 'Status' 
	},
    { 
        name: 'order_by_columns', 
        type: 'multi_select', 
        operator: 'order_by_columns',
        values: displayHeaders.map((header, index) => ({
                label: header,
                value: orderByColumns[index]
            })),
        model_name:'journal_voucher',
        label: 'Select columns for order by' 
    }
]; 
  
