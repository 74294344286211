import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';    
    
@Injectable({    
  	providedIn: 'root'    
})
export class EventEmitterService {    
    
  invokeFirstComponentFunction = new EventEmitter();    
  subsVar: Subscription;       
  pageSize: number;       
  pageIndex: number;       
    
  constructor() { }    
//    to do - reset pageIndex and pageSize while going to one component from another
  onFirstComponentButtonClick(pageSize:number, pageIndex:number) {   

		this.pageSize = pageSize; 
		this.pageIndex = pageIndex; 
		this.invokeFirstComponentFunction.emit(); 
	}    
}