<!-- <mat-progress-spinner mode="indeterminate" *ngIf="IsWait">
</mat-progress-spinner> -->
<!-- 
<div class="center">
    <mat-progress-spinner mode="indeterminate" *ngIf="IsWait">
    </mat-progress-spinner>
</div> -->
<div class="overlay" *ngIf="IsWait">
    <div class="center">
        <mat-progress-spinner mode="indeterminate">
        </mat-progress-spinner>
    </div>
</div>

<div class="row p-4">
    <div class="col-4">
        <button mat-raised-button color="primary" class="m-2" (click)="create()" *ngIf="!hideCreateBtnFor.includes(router.url)">Create</button>
        <!-- <button mat-raised-button color="accent" class="m-2" (click)="changeStatus('active')">Active</button> -->
        <button mat-raised-button color="warn" class="m-2"  *ngIf="!hideDeleteBtnFor.includes(router.url)" (click)="changeStatus('delete')">Delete</button>
        
    </div>
    <!-- <div class="col-4">
    </div>
    <div class="col-4">
    </div> -->
    <span class="col-4">

    </span>
    <!-- <div class="col-1 filter-container">
        <button mat-mini-fab color="primary" aria-label="Filter">
            <mat-icon>filter_list</mat-icon>
        </button>
    </div> -->
    <!-- <mat-form-field class="col-3">
        <input matInput type="text" (keyup)="doFilter($event)" placeholder="Search">
    </mat-form-field> -->

</div>
<div class="row pb-4">
    <!-- <div class="col-4">
        <button class="btnExportExcel" mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'export excel', sheet: 'sheet_A', Props: {Author: 'software'}})">Export to Excel</button>
    </div> -->

</div>

<table matTableExporter #exporter="matTableExporter" mat-table matSort [dataSource]="dataSource" class="mat-elevation-z2">
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox color="primary" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
            <mat-checkbox color="primary"
                        (click)="$event.stopPropagation()"
                        (change)="toggleSelection(row)"
                        [checked]="selection.isSelected(row)">
            </mat-checkbox>
        </td>
        
    </ng-container>
    <ng-container class="mat-table-row" [matColumnDef]="column" *ngFor="let column of displayColumns; let i = index;">
        <th class="font-weight-bold" mat-header-cell *matHeaderCellDef> {{displayHeaders[i]}} </th>
        <td mat-cell *matCellDef="let obj" (click)="rowClicked(obj.id)" class="header-cell"> {{obj[column]}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayColumnsWithSelect"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumnsWithSelect;"></tr>
</table>


<mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize" [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex" aria-label="Select page">
</mat-paginator>