import { Doctor } from "../doctor/doctor.interface";
import { Orderline } from "./orderline.inferface";

export interface Challan{
    id: number;
    challan_no: number;
    challan_date: Date;
    order_lines: Orderline[];
    job_id: string[];
    remark: string;
    shipment_id: number;
    is_invoiced: boolean;
    is_proforma_invoiced: boolean;
    doctor: Doctor;
    doctor_id: number;
    doctor_name: string;
    job_type:  string;
    status: string;
};

export var displayColumns = [
    'challan_no',   
    'challan_date',
    'doctor_name',
    'status',
]

export var displayHeaders = [
    'Challan No.',   
    'Challan Date',
    'Doctor Name',
    'Status',
]


// actual database columns
export var orderByColumns = [
    'challan_no',
    'challan_date',
    'doctor_name',
    'status'
]


export const filterParameters = [
    { 
        name: 'job_date', 
        type: 'date_range', 
        operator: 'between', 
        label: 'Date Range' 
    },
    { 
        name: 'doctor_id', 
        type: 'dropdown', 
        operator: '=',
        model_name:'doctor',
        label: 'Doctor' 
    },
    { 
        name: 'challan_no', 
        type: 'string', 
        operator: 'like', 
        label: 'Challan No.', 
    },
    { 
        name: 'status', 
        type: 'radio_option', 
        operator: '=',
        values: [
                    { label: 'Draft', value: "draft" },
                    { label: 'Submitted', value: "submitted" },
                    { label: 'Cancelled', value: "cancelled" }
                ],
        model_name:'challan',
        label: 'Status' 
    },
    { 
        name: 'order_by_columns', 
        type: 'multi_select', 
        operator: 'order_by_columns',
        values: displayHeaders.map((header, index) => ({
                label: header,
                value: orderByColumns[index]
            })),
        model_name:'challan',
        label: 'Select columns for order by' 
    }
]  
